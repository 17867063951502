import React from 'react';
import { colors } from '../../assets/utils/constants';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    ArrowCircleRightOutlined,
    LockClock,
    Person,
    Place,
    RequestQuote,
    SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import useCurrency from '../../hooks/useCurrency';
import strHelper from '../../helpers/strHelper';
import { useSelector } from 'react-redux';
import { jobApplicationsSelector } from '../../states/features/selectors';
import { size } from 'lodash';

// !############### MAIN FUNC #################
const AppliedJobWidget = ({ job, applicant }) => {
    // ########### CONST ##############
    const STR = strHelper();
    const currency = useCurrency();

    // ########## Redux state #################
    const { singleJobApplications } = useSelector(jobApplicationsSelector);

    const jobStatusColor = () => {
        let color = '';
        applicant?.application.status === 'pending'
            ? (color = colors.warning)
            : applicant?.application.status === 'accepted'
            ? (color = colors.success)
            : applicant?.application.status === 'declined'
            ? (color = 'red')
            : applicant?.application.status === 'dropped'
            ? (color = colors.primary)
            : (color = colors.secondary);
        return color;
    };
    return (
        <React.Fragment>
            <Box
                className="neo-buldge"
                sx={{
                    'borderRadius': 2,
                    'overflow': 'hidden',
                    'marginBottom': 2,
                    'transition': '.2s all ease-in-out',
                    'border': `1px solid ${colors.secondary}`,
                    'borderLeft': `4px solid ${jobStatusColor()}`,
                    'boxShadow': `2px 5px 10px 0px ${colors.bgColor3}`,
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                    '& .more': {
                        transition: '.2s all ease-in-out',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                    },
                    ':hover': {
                        'boxShadow': '4p 4px 4px 4px rgba(46, 119, 174, .8)',
                        'transition': '.2s all ease-in-out',
                        '& .more': {
                            marginLeft: 1,
                            transition: '.2s all ease-in-out',
                        },
                    },
                }}
            >
                <Box>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        columnSpacing={2}
                    >
                        <Grid item sm={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    mb: 0,
                                    pb: 0,
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="logo"
                                            src={
                                                job.organization
                                                    .organization_logo
                                            }
                                            variant="square"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                                borderRadius: 2,
                                                border: `1px dotted ${colors.warning}`,
                                                padding: 0.2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                img: {
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        sx={{
                                            '.MuiListItemText-primary': {
                                                fontSize: 17,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: 'bold',
                                                    opacity: 0.7,
                                                    ml: 1,
                                                }}
                                            >
                                                {STR.strCapitalizeFirstChar(
                                                    job.title,
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                        fontWeight: 'bold',
                                                        ml: 1,
                                                        fontSize: 14,
                                                        color: colors.info,
                                                    }}
                                                    component="span"
                                                >
                                                    {job.category.name.toLowerCase() ===
                                                    'other'
                                                        ? job.other_job_category_name
                                                        : job.category.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={2}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: '1px solid silver',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    Applicants
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.warning,
                                        fontWeight: 'bold',
                                    }}
                                    noWrap
                                >
                                    {size(singleJobApplications)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={2}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: '1px solid silver',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    Posted
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.info,
                                        fontWeight: 'bold',
                                    }}
                                    noWrap
                                >
                                    {moment(job.created_at).fromNow()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={1}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                <Link to={`/jobs/${job.id}`}>
                                    <IconButton className="more" color="info">
                                        <Tooltip title={'Job details'} arrow>
                                            <ArrowCircleRightOutlined />
                                        </Tooltip>
                                    </IconButton>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ p: 1, ml: 1 }}>
                        {job.isFor === 'company'
                            ? `For ${job.organizationCategory.name} (companies)`
                            : job.isFor === 'group'
                            ? 'For (groups)'
                            : 'For (individuals)'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        'position': 'relative',
                        'p': 1,
                        '& .chip': {
                            border: `1px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                        },
                    }}
                >
                    <Chip
                        icon={<Place />}
                        label={job.location}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<Person />}
                        label={job.isFor}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<LockClock />}
                        label={job.job_type.replace(/[^a-zA-Z ]/g, ' ')}
                        sx={{ mr: 1, textTransform: 'capitalize' }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    {job.salary_scale && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary scale: ${job.salary_scale}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    {job.salary && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary: ${currency.formatCurrency(
                                'TZS',
                                job.salary,
                            )}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    <Box sx={{ float: 'right' }}>
                        <Chip
                            icon={<SupervisedUserCircleOutlined />}
                            size="small"
                            sx={{
                                textTransform: 'capitalize',
                                fontSize: 12,
                                fontWeight: 'bold',
                                px: 1,
                                opacity: 0.8,
                            }}
                            color={
                                applicant?.application.status === 'pending'
                                    ? 'warning'
                                    : applicant?.application.status ===
                                      'accepted'
                                    ? 'success'
                                    : applicant?.application.status ===
                                      'declined'
                                    ? 'error'
                                    : applicant?.application.status ===
                                      'dropped'
                                    ? 'primary'
                                    : 'secondary'
                            }
                            label={applicant?.application?.status}
                        />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default AppliedJobWidget;
