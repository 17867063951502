import * as React from 'react';
import { Box } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import usePT from '../../hooks/usePT';
import UpdatePracticalTrainingStep from '../../components/steppers/UpdatePracticalTrainingStep';

const UpdatePTInDialog = ({ handleDialogClose }) => {
    // ############ CONST ################
    const ptHook = usePT();

    // ############# RTK #################
    ptHook.useMetaDataDispatch();

    return (
        <>
            <Box>
                <CustomCard2>
                    <UpdatePracticalTrainingStep
                        handleDialogClose={handleDialogClose}
                    />
                </CustomCard2>
            </Box>
        </>
    );
};

export default UpdatePTInDialog;
