import React from 'react';
import { Box } from '@mui/material';
import RegionFilterForm from '../Forms/RegionFilterForm';
import OccupationFilterForm from '../Forms/OccupationFilterForm';
import OrganizationFilterForm from '../Forms/OrganizationFilterForm';
import { colors } from '../../assets/utils/constants';

const PracticalTrainingFilter = ({ width }) => {
    return (
        <>
            <Box
                sx={{
                    height: 90,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    px: 3,
                    mb: 2,
                    borderBottom: `10px solid ${colors.bgColor5}`,
                }}
            >
                <Box sx={{ display: 'flex', width: width }}>
                    <Box sx={{ mr: 2, width: '100%' }}>
                        <OccupationFilterForm />
                    </Box>
                    <Box sx={{ mr: 2, width: '100%' }}>
                        <OrganizationFilterForm />
                    </Box>
                    <Box sx={{ width: '100%' }}>
                        <RegionFilterForm />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default PracticalTrainingFilter;
