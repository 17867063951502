import React from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import {
    DangerousRounded,
    Pending,
    Verified,
    Warning,
} from '@mui/icons-material';
import { colors } from '../assets/utils/constants';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { profileSelector } from '../states/features/selectors';

const VerificationReq = () => {
    // ############### Redux state #######################
    const { profile: user, orgDocs, orgKeyPerson } = useSelector(
        profileSelector,
    );
    const profileReqDetails = [
        {
            label: `${user?.organization_type?.name} logo`,
            status: user.org_logo ? true : false,
        },
        {
            label: `${user?.organization_type?.name} description`,
            status: user.description ? true : false,
        },
        {
            label: `${user?.organization_type?.name} phone number verification`,
            status: user.phone_number_verified_at ? true : false,
        },
        {
            label: `${user?.organization_type?.name} email verification`,
            status: user.email_verified_at ? true : false,
        },
        {
            label: `${user?.organization_type?.name} verification document`,
            status:
                !isEmpty(orgDocs) && orgDocs.verification_status !== 'denied'
                    ? true
                    : false,
        },
        {
            label: `${user?.organization_type?.name} key person and verification document`,
            status:
                !isEmpty(orgKeyPerson) &&
                orgKeyPerson.documents &&
                orgKeyPerson.documents?.verification_status !== 'denied'
                    ? true
                    : false,
        },
    ];
    return (
        <React.Fragment>
            <Grid
                container
                sx={{
                    height: '80vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid item md={7} sm={12} xs={12} sx={{ p: 2 }}>
                    <Typography sx={{ textAlign: 'center' }}>
                        <ManageAccountsIcon
                            sx={{
                                fontSize: 150,
                                color: colors.bgColor5,
                            }}
                        />
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Corporis illum omnis, quaerat sapiente, animi,
                        consequatur dolorum facere accusantium facilis accusamus
                        eveniet.
                    </Typography>
                    <Box sx={{ pb: 2, textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                my: 1,
                                fontSize: 20,
                                color: colors.success,
                            }}
                        >
                            Important required details
                        </Typography>
                        {profileReqDetails?.map((detail, index) => (
                            <Box component={'span'} key={index} sx={{ mr: 2 }}>
                                <Chip
                                    label={detail.label}
                                    sx={{
                                        mb: 2,
                                        bgcolor: colors.bgColor1,
                                        border: `1px solid ${colors.bgColor3}`,
                                    }}
                                    icon={
                                        detail.status ? (
                                            <DoneIcon
                                                sx={{
                                                    color: 'green !important',
                                                }}
                                            />
                                        ) : (
                                            <ClearIcon
                                                sx={{ color: 'red !important' }}
                                            />
                                        )
                                    }
                                />
                            </Box>
                        ))}
                    </Box>
                    <center>
                        {user.is_verified === 'pending' ? (
                            <>
                                <Typography className="warning-box">
                                    <Warning sx={{ mr: 2 }} />
                                    Please fill and submit all required details
                                    for your profile to be verified
                                </Typography>
                                <Link to="/profile">
                                    <Button
                                        startIcon={<ManageAccountsIcon />}
                                        variant="contained"
                                        sx={{ marginTop: 3 }}
                                        className="app-btn"
                                    >
                                        Manage you account
                                    </Button>
                                </Link>
                            </>
                        ) : (
                            <>
                                {user.is_verified === 'verified' ? (
                                    <>
                                        <Typography className="success-box">
                                            <Verified sx={{ mr: 2 }} />
                                            Congratulation! your profile has
                                            been verified
                                        </Typography>
                                    </>
                                ) : orgDocs?.verification_status === 'denied' ||
                                  orgKeyPerson.documents
                                      ?.verification_status === 'denied' ? (
                                    <>
                                        <Typography className="error-box">
                                            <DangerousRounded sx={{ mr: 2 }} />
                                            Sorry document has been denied .
                                            Please check it and make sure you
                                            re-upload the right information.
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography className="info-box">
                                            <Pending sx={{ mr: 2 }} />
                                            Please wait for you profile to be
                                            verified
                                        </Typography>
                                    </>
                                )}
                                <Link to="/profile">
                                    <Button
                                        startIcon={<ManageAccountsIcon />}
                                        variant="contained"
                                        sx={{ marginTop: 3 }}
                                        className="app-btn"
                                    >
                                        Manage you account
                                    </Button>
                                </Link>
                            </>
                        )}
                    </center>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default VerificationReq;
