import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jobPostingFee: "",
    qualificationAwards: [],
    educationLevels: [],
    experienceLevels: [],
    title: "",
    description: "",
    location: "",
    region_id: "",
    district_id: "",
    salary: "",
    salary_scale: "",
    max_applicants: "",
    positions: "",
    open_date: "",
    close_date: "",
    isFor: "",
    job_category_id: "",
    other_category: "",
    job_type: "",
    award_id: "",
    org_category_id: "",
    education_level_id: "",
    experience_level_id: "",
    jobResponsibilities: [],
    jobRequirements: [],
    classficationPer: 0,
    positionPer: 0,
    educationPer: 0,
    experiencePer: 0,
    paymentMethod: "",
    paymentNumber: "",
    saveAndPay: false,
};

// !############ SLICE ##############
const JobPostingSlice = createSlice({
    name: "job-posting",
    initialState,
    reducers: {
        jobDetails: (state, action) => {
            state.title = action.payload.title;
            state.salary = action.payload.salary;
            state.salary_scale = action.payload.salary_scale;
            state.open_date = action.payload.open_date;
            state.close_date = action.payload.close_date;
            state.job_type = action.payload.job_type;
            state.job_category_id = action.payload.job_category_id;
            state.other_category = action.payload.other_category;
            state.description = action.payload.description;
            state.location = action.payload.location;
            state.region_id = action.payload.region_id;
            state.district_id = action.payload.district_id;
        },
        addJobResponsibility: (state, action) => {
            state.jobResponsibilities = [
                action.payload,
                ...state.jobResponsibilities,
            ];
        },
        removeJobResponsibility: (state, action) => {
            state.jobResponsibilities = state.jobResponsibilities.filter(
                (res, i) => {
                    return i !== action.payload;
                }
            );
        },
        addJobRequirement: (state, action) => {
            state.jobRequirements = [action.payload, ...state.jobRequirements];
        },
        removeJobRequirement: (state, action) => {
            state.jobRequirements = state.jobRequirements.filter((res, i) => {
                return i !== action.payload;
            });
        },
        jobApplicants: (state, action) => {
            state.max_applicants = action.payload.max_applicants;
            state.positions = action.payload.positions;
            state.isFor = action.payload.isFor;
        },
        addQualificationAward: (state, action) => {
            state.award_id = action.payload;
        },
        addEducationLevel: (state, action) => {
            state.education_level_id = action.payload;
        },
        addExperienceLevel: (state, action) => {
            state.experience_level_id = action.payload;
        },
        addJobPostingFee: (state, action) => {
            state.jobPostingFee = action.payload;
        },
        addPositions: (state, action) => {
            state.positions = action.payload;
        },
        addMaxApplicants: (state, action) => {
            state.max_applicants = action.payload;
        },
        addIsFor: (state, action) => {
            state.isFor = action.payload;
        },
        addClassficationPer: (state, action) => {
            state.classficationPer = action.payload;
        },
        addPositionPer: (state, action) => {
            state.positionPer = action.payload;
        },
        addEducationPer: (state, action) => {
            state.educationPer = action.payload;
        },
        addExperiencePer: (state, action) => {
            state.experiencePer = action.payload;
        },
        addORGCategory: (state, action) => {
            state.org_category_id = action.payload;
        },
        addPaymentDetails: (state, action) => {
            state.paymentMethod = action.payload.paymentMethod;
            state.paymentNumber = action.payload.paymentNumber;
        },
        setSaveAndPay: (state, action) => {
            state.saveAndPay = action.payload;
        },
        resetQualificationAward: (state, action) => {
            state.award_id = action.payload;
            state.education_level_id = action.payload;
            state.experience_level_id = action.payload;
        },
        resetJobPosting: (state) => {
            state.jobPostingFee = "";
            state.qualificationAwards = [];
            state.educationLevels = [];
            state.experienceLevels = [];
            state.title = "";
            state.description = "";
            state.location = "";
            state.salary = "";
            state.salary_scale = "";
            state.max_applicants = "";
            state.positions = "";
            state.open_date = "";
            state.close_date = "";
            state.isFor = "";
            state.job_category_id = "";
            state.job_type = "";
            state.award_id = "";
            state.education_level_id = "";
            state.experience_level_id = "";
            state.jobResponsibilities = [];
            state.jobRequirements = [];
            state.classficationPer = 0;
            state.positionPer = 0;
            state.educationPer = 0;
            state.experiencePer = 0;
            state.jobCategories = [];
            state.other_category = "";
            state.org_category_id = "";
            state.region_id = "";
            state.district_id = "";
        },
    },
});

export const {
    jobDetails,
    addJobResponsibility,
    removeJobResponsibility,
    addJobRequirement,
    removeJobRequirement,
    jobApplicants,
    addQualificationAward,
    addEducationLevel,
    addExperienceLevel,
    addJobPostingFee,
    addPositions,
    addMaxApplicants,
    addIsFor,
    addClassficationPer,
    addPositionPer,
    addEducationPer,
    addExperiencePer,
    addORGCategory,
    resetQualificationAward,
    resetJobPosting,
    addPaymentDetails,
    setSaveAndPay,
} = JobPostingSlice.actions;

export default JobPostingSlice.reducer;
