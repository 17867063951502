import { LocationOn, MoreVert } from '@mui/icons-material';
import {
    Avatar,
    Box,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/constants';
import ORGDetails from '../../pages/practicalTraining/components/ORGDetails';
import CustomDialog from '../Dialogs/CustomDialog';

const OrganizationFieldWidget = ({ organization }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            <CustomDialog
                head={'Organization details'}
                openDialog={openDialog}
                customWidth={'md'}
                handleDialogClose={handleDialogClose}
            >
                <ORGDetails organization={organization} />
            </CustomDialog>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    m: 0.5,
                    border: `1px solid ${colors.bgColor5}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pl: 1,
                        pb: 0.5,
                    }}
                >
                    <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                        <ListItemAvatar>
                            <Avatar
                                alt="logo"
                                src={organization.org_logo}
                                variant="square"
                                sx={{
                                    borderRadius: 2,
                                    width: 50,
                                    height: 50,
                                    border: `1px solid ${colors.bgColor5}`,
                                    p: 0.5,
                                    img: {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                },
                            }}
                            primaryTypographyProps={{
                                color: colors.primary,
                            }}
                            primary={organization.organization_name}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 12,
                                        }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <LocationOn
                                            sx={{
                                                fontSize: 12,
                                                color: colors.secondary,
                                            }}
                                        />
                                        {organization.location}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Box>
                        <Tooltip title={'More details'} arrow>
                            <IconButton
                                size="small"
                                color="info"
                                sx={{
                                    mr: 1,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                                }}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                            >
                                <MoreVert />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OrganizationFieldWidget;
