import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pt_id: '',
    title: '',
    description: '',
    max_applicants: '',
    positions: '',
    open_date: '',
    end_date: '',
    location: '',
    attachmentFile: '',
    institutions: [],
    sector_id: '',
    department_id: '',
    sections: [],
    isUpdate: false,
};

const restructureInstitutions = (arr) => {
    let restructured = [];
    arr?.forEach((institution) => {
        let newObj = {
            id: institution.id,
            title: institution.organization_name,
        };
        restructured.push(newObj);
    });
    return restructured;
};

// !############ SLICE ##############
const PTPostingSlice = createSlice({
    name: 'practical-training-posting',
    initialState,
    reducers: {
        practicalTrainingDetail: (state, action) => {
            state.title = action.payload.title;
            state.open_date = action.payload.open_date;
            state.end_date = action.payload.end_date;
            state.description = action.payload.description;
            state.max_applicants = action.payload.max_applicants;
            state.positions = action.payload.positions;
            state.location = action.payload.location;
            state.attachmentFile = action.payload.file;
        },
        setUpdateDetails: (state, actions) => {
            state.pt_id = actions.payload.id;
            state.title = actions.payload.title;
            state.description = actions.payload.description;
            state.max_applicants = actions.payload.max_applicants;
            state.positions = actions.payload.positions;
            state.open_date = actions.payload.open_date;
            state.end_date = actions.payload.end_date;
            state.isUpdate = true;
            state.attachmentFile = actions.payload.attachmentFile;
            state.institutions = restructureInstitutions(
                actions.payload.selectedInstitutions,
            );
            state.sections = [];
            // state.location = actions.payload.region;
            // state.sector_id = actions.payload.sector.id;
            // state.department_id = actions.payload.department.id;
        },
        instituteSelection: (state, action) => {
            state.institutions = action.payload;
        },
        sectorDepartimentSelection: (state, action) => {
            state.sector_id = action.payload.sector_id;
            state.department_id = action.payload.department_id;
        },
        sectionSelection: (state, action) => {
            state.sections = action.payload;
        },
        setSelectedSector: (state, action) => {
            state.selectedSector = action.payload;
        },
        setSelectedDepartment: (state, action) => {
            state.selectedDepartment = action.payload;
        },
        setSelectedSections: (state, action) => {
            state.selectedSections = action.payload;
        },
        resetPTPostingDetails: (state, actions) => {
            state.title = '';
            state.description = '';
            state.max_applicants = '';
            state.positions = '';
            state.location = '';
            state.open_date = '';
            state.end_date = '';
            state.institutions = [];
            state.sector_id = '';
            state.department_id = '';
            state.sections = [];
        },
    },
});

export const {
    practicalTrainingDetail,
    setUpdateDetails,
    instituteSelection,
    sectorDepartimentSelection,
    sectionSelection,
    setSelectedSector,
    setSelectedDepartment,
    setSelectedSections,
    resetPTPostingDetails,
} = PTPostingSlice.actions;

export default PTPostingSlice.reducer;
