// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Profile selector
export const profileSelector = (state) => state.profileReducer;

// => Setting selector
export const settingsSelector = (state) => state.settingsReducer;

// => Practical training posting selector
export const PTPostingSelector = (state) => state.PTPostingReducer;

// => PT selector
export const PTSelector = (state) => state.practicalTrainingsReducer;

// => Jobs posting selector
export const jobPostingSelector = (state) => state.jobPostingReducer;

// => Jobs selector
export const jobsSelector = (state) => state.jobsReducer;

// => Jobs applications selector
export const jobApplicationsSelector = (state) => state.jobApplicationsReducer;

// => Institute programs selector
export const instituteProgramsSelector = (state) =>
    state.instituteProgramsReducer;

// => Students selector
export const studentsSelector = (state) => state.studentsReducer;

// => Transaction selector
export const transactionSelector = (state) => state.transactionsReducer;

// => Training season selector
export const trainingSeasonSelector = (state) => state.trainingSeasonReducer;

// => Help center selector
export const helpCenterSelector = (state) => state.helpCenterReducer;

// => Notification selector
export const notificationsSelector = (state) => state.notificationsReducer;
