import React, { useState } from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Button, Grid,  } from '@mui/material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import TrainingSeasonTable from '../../../components/Tables/TrainingSeasonTable';
import { AddBox } from '@mui/icons-material';
import CustomDialog from '../../../components/Dialogs/CustomDialog';
import AddTrainingSeason from './CRUD/AddTrainingSeason';
import { useDispatch, useSelector } from 'react-redux';
import {
    studentsSelector,
    trainingSeasonSelector,
} from '../../../states/features/selectors';
import NoContent from '../../../components/NoContent';
import { isEmpty } from 'lodash';
import useRTK from '../../../hooks/useRTK';
import { useGetTrainingSeasonsQuery } from '../../../api/students/trainingSeasonAPI';
import { setTrainingSeason } from '../../../states/features/students/trainingSeasonSlice';
import TrainingSeasonSidebar from '../../../components/sidebars/TrainingSeasonSidebar';

const TrainingManagement = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################ Comp state ############
    const [crudType, setCRUDType] = useState('');
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // ############## Redux state ###############
    const { trainingSeasons } = useSelector(trainingSeasonSelector);
    const { students } = useSelector(studentsSelector);

    // ############## FUNC #################
    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        isError,
        error,
        data: TSData,
    } = useGetTrainingSeasonsQuery();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setTrainingSeason(data));
    };
    RTK.useRTKFetch(isSuccess, TSData, rtkActions);

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose
                customWidth={'sm'}
            >
                <AddTrainingSeason handleDialogClose={handleDialogClose} />
            </CustomDialog>
            <PageLayout
                head={'Practical training season management'}
                sidebar={<TrainingSeasonSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Practical traning seasons'}
                            padding={0}
                            action={
                                <Button
                                    size="small"
                                    startIcon={<AddBox />}
                                    variant="contained"
                                    onClick={() => {
                                        let crud = 'addSeason';
                                        let head =
                                            'Add practical training season';
                                        handleDialogyOpen(crud, head);
                                    }}
                                >
                                    Add training season
                                </Button>
                            }
                        >
                            {!isEmpty(trainingSeasons) ? (
                                <TrainingSeasonTable
                                    height={'66vh'}
                                    students={students}
                                    trainingSeasons={trainingSeasons}
                                />
                            ) : (
                                <NoContent
                                    height={'69vh'}
                                    message={
                                        'No practical training season added'
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default TrainingManagement;
