import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Grid } from '@mui/material';
import { colors } from '../../assets/utils/constants';
// import faker from "faker"

export function VerticalBarChart({ title, chartData }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    // const labels = [
    //     'Jan',
    //     'Feb',
    //     'Mar',
    //     'Apr',
    //     'May',
    //     'Jun',
    //     'Jul',
    //     'Aug',
    //     'Sep',
    //     'Oct',
    //     'Nov',
    //     'Dec',
    // ];

    const data = {
        labels: chartData?.labels,
        datasets: [
            {
                label: "Dataset 1",
                data: chartData?.data,
                backgroundColor: colors.bgColor5,
                borderRadius: 5,
            },
        ],
    };

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{ height: 350, width: { xs: '87vw', sm: '100%' } }}
                    >
                        <Bar
                            options={options}
                            data={data}
                            style={{ height: '200px' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
