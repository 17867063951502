import { ManageAccounts } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../assets/utils/constants';

const JobPostinReq = () => {
    return (
        <>
            <Box sx={{}}>
                <Box className="warning-message">
                    <Typography
                        sx={{
                            m: 2,
                            fontSize: 16,
                            opacity: 0.8,
                            color: colors.primary,
                            textAlign: 'left',
                        }}
                    >
                        <strong style={{ color: 'red' }}>Not verified: </strong>
                        <br />
                        In order to user job posting service your account has to
                        be verified. So please make sure you submit all required
                        documents and request profile verification on your
                        account profile.
                    </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Link to="/profile">
                        <Button
                            startIcon={<ManageAccounts />}
                            variant="contained"
                            sx={{ marginTop: 3 }}
                            className="app-btn"
                        >
                            Manage you account
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default JobPostinReq;
