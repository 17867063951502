import { configureStore } from '@reduxjs/toolkit';
import { RTKmiddleware } from './RTKmiddlewares';

// => SLICE reduces
import authReducer from '../features/auth/authSlice';
import profileReducer from '../features/profile/profileSlice';
import settingsReducer from '../features/settings/settingsSlice';
import jobsReducer from '../features/job/jobsSlice';
import PTPostingReducer from '../features/practicalTraining/PTPostingSlice';
import jobPostingReducer from '../features/job/JobPostingSlice';
import practicalTrainingsReducer from '../features/practicalTraining/practicalTrainingsSlice';
import instituteProgramsReducer from '../features/students/instituteProgramsSlice.js';
import studentsReducer from '../features/students/studentsSlice';
import transactionsReducer from '../features/transactions/transactionsSlice';
import trainingSeasonReducer from '../features/students/trainingSeasonSlice';
import helpCenterReducer from '../features/helpCenter/helpCenterSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';

// => RTK reducers
import { authAPI } from '../../api/auth/authAPI';
import { profileAPI } from '../../api/profile/profileAPI';
import { settingAPI } from '../../api/settings/settingAPI';
import { jobAPI2 } from '../../api/job/jobAPI2';
import { practicalTraingsAPI } from '../../api/practicalTraining/practicalTrainingsAPI';
import { instituteProgramsAPI } from '../../api/students/instituteProgramsAPI';
import { studentsAPI } from '../../api/students/studentsAPI';
import { transactionAPI } from '../../api/transactions/transactionAPI';
import { trainingSeasonAPI } from '../../api/students/trainingSeasonAPI';
import { practicalTrainingApplicantsAPI } from '../../api/practicalTraining/practicalTrainingApplicantsAPI';
import { jobApplicationAPI } from '../../api/job/jobApplicationAPI';
import jobApplicationsReducer from '../features/job/jobApplicationsSlice';
import { helpCenterAPI } from '../../api/helpCenter/helpCenterAPI';
import { contactUsAPI } from '../../api/helpCenter/contactUsAPI';
import { notificationsAPI } from '../../api/notifications/notificationsAPI';

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [profileAPI.reducerPath]: profileAPI.reducer,
        [settingAPI.reducerPath]: settingAPI.reducer,
        [jobAPI2.reducerPath]: jobAPI2.reducer,
        [jobApplicationAPI.reducerPath]: jobApplicationAPI.reducer,
        [practicalTraingsAPI.reducerPath]: practicalTraingsAPI.reducer,
        [instituteProgramsAPI.reducerPath]: instituteProgramsAPI.reducer,
        [studentsAPI.reducerPath]: studentsAPI.reducer,
        [transactionAPI.reducerPath]: transactionAPI.reducer,
        [trainingSeasonAPI.reducerPath]: trainingSeasonAPI.reducer,
        [helpCenterAPI.reducerPath]: helpCenterAPI.reducer,
        [practicalTrainingApplicantsAPI.reducerPath]:
            practicalTrainingApplicantsAPI.reducer,
        [contactUsAPI.reducerPath]: contactUsAPI.reducer,
        [notificationsAPI.reducerPath]: notificationsAPI.reducer,
        authReducer,
        profileReducer,
        settingsReducer,
        PTPostingReducer,
        jobsReducer,
        jobPostingReducer,
        jobApplicationsReducer,
        practicalTrainingsReducer,
        instituteProgramsReducer,
        studentsReducer,
        transactionsReducer,
        trainingSeasonReducer,
        helpCenterReducer,
        notificationsReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
