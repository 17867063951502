// => Get profile
const GET_PROFILE = "/auth/profile";

// => Update name
const UPDATE_NAME = "/auth/profile/organization-name";

// => Update organization description
const UPDATE_ORG_DESCRIPTION = "/auth/profile/description";

// => Update logo
const UPDATE_LOGO = "/auth/profile/organization-logo";

// => Update organization banner
const UPDATE_ORG_BANNER = "/auth/profile/organization-banner";

// => Update organization location
const UPDATE_ORG_LOCATION = "/auth/profile/location";

// => Update organization email
const UPDATE_ORG_EMAIL = "/auth/profile/email";

// => Update organization phone
const UPDATE_ORG_PHONE = "/auth/profile/phone-number";

// => add organization key person
const ADD_ORG_KEY_PERSON = "/org-key-people";

// => update organization key person
const UPDATE_ORG_KEY_PERSON = "/org-key-people";

// => add organization key person docs
const ADD_ORG_KEY_PERSON_DOCS = "/key-people-documents";

// => update organization key person docs
const UPDATE_ORG_KEY_PERSON_DOCS = "/key-people-documents/update";

// => re-upload key person docs
const RE_UPLOAD_KEY_PERSON_DOCS = "/key-people-documents/re-upload";

// => add organization docs
const ADD_ORG_DOCS = "/organization-documents";

// => update organization docs
const UPDATE_ORG_DOCS = "/organization-documents/update";

// => re-upload organization docs
const RE_UPLOAD_ORG_DOCS = "/organization-documents/re-upload";

// => Request OTP
const REQUEST_PHONE_OTP = "/auth/request-otp";

// => Verify OTP
const VERIFY_PHONE_OTP = "/auth/verify-otp";

// => Request Email ver
const REQUEST_EMAIL_OTP = "/auth/request-verification-email";

// => Verify Email ver code
const VERIFY_EMAIL_OTP = "/auth/verify-email";

// => req org verification
const REQUEST_ORG_VERIFICATION = "/auth/profile/request-verification";

// LOGOUT user
const USER_LOGOUT = "/auth/logout";

// CHANGE password
const CHANGE_PASSWORD = "/auth/change-password";

// FREE TRIAL Activation
const FREE_TRIAL = "/free-trials";

export const profileEndpoints = {
    GET_PROFILE,
    UPDATE_NAME,
    UPDATE_ORG_DESCRIPTION,
    UPDATE_LOGO,
    UPDATE_ORG_BANNER,
    UPDATE_ORG_LOCATION,
    UPDATE_ORG_EMAIL,
    UPDATE_ORG_PHONE,
    ADD_ORG_KEY_PERSON,
    UPDATE_ORG_KEY_PERSON,
    ADD_ORG_KEY_PERSON_DOCS,
    RE_UPLOAD_KEY_PERSON_DOCS,
    UPDATE_ORG_KEY_PERSON_DOCS,
    ADD_ORG_DOCS,
    UPDATE_ORG_DOCS,
    RE_UPLOAD_ORG_DOCS,
    REQUEST_PHONE_OTP,
    VERIFY_PHONE_OTP,
    REQUEST_EMAIL_OTP,
    VERIFY_EMAIL_OTP,
    REQUEST_ORG_VERIFICATION,
    USER_LOGOUT,
    CHANGE_PASSWORD,
    FREE_TRIAL,
};
