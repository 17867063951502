import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ArrowRightAltOutlined, OpenWithTwoTone } from "@mui/icons-material";
import { useOpenJobMutation } from "../../../api/job/jobAPI2";
import { setJobDetails } from "../../../states/features/job/jobsSlice";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";

// * ################### VALIDATION SCHEMA ####################
const OpenJob = ({ job: jobDetails, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        openAction,
        {
            isLoading: openLoading,
            isSuccess: openSuccess,
            isError: openIsError,
            error: openError,
            data: openData,
        },
    ] = useOpenJobMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setJobDetails(data));
    };
    RTK.useRTKResponse(
        openSuccess,
        openIsError,
        openError,
        openData,
        rtkActions
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="success-message">
                        <Typography>
                            Your about to open this job application. Therefor
                            applicants can applying for this job.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<OpenWithTwoTone />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor="success"
                        variant={"contained"}
                        loading={openLoading}
                        onClick={() => {
                            openAction(jobDetails.id);
                        }}
                        sx={{ py: 2, fontSize: 16 }}
                    >
                        Open job
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default OpenJob;
