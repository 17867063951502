import { Delete } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { colors } from '../../../assets/utils/constants';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { setNotifications } from '../../../states/features/notifications/notificationsSlice';
import { useDeleteSingleNotificationMutation } from '../../../api/notifications/notificationsAPI';

const DeleteNotification2 = ({ notification }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deleteAction,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteSingleNotificationMutation();

    // => Res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setNotifications(data));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );

    return (
        <>
            <Tooltip title="Delete notification" arrow>
                <IconButton
                    size="small"
                    color="error"
                    disabled={deleteLoading ? true : false}
                    sx={{
                        mr: 1,
                        boxShadow: `1px 2px 5px 0px ${colors.bgColor3} !important`,
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                    }}
                    onClick={() => deleteAction(notification.id)}
                >
                    <Delete className="icon" />
                    {deleteLoading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                            }}
                        >
                            <CircularProgress size={22} sx={{ mt: 1.2 }} />
                        </Box>
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DeleteNotification2;
