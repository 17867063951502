import React from 'react';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { ErrorMessage, useField } from 'formik';

const CustomInput = styled(TextField)({});

const CustomMultlineInput = ({ label, type, value, rows, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <Box
                sx={{
                    borderRadius: 1.5,
                    overflow: 'hidden',
                }}
            >
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    size={'small'}
                    multiline
                    minRows={rows}
                    maxRows={rows}
                    focused={type === 'date' || type === 'time' ? true : false}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomMultlineInput;
