import React, { useEffect } from 'react';
import { Groups2, SchoolOutlined, WorkHistory } from '@mui/icons-material';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import PracticalTrainingDetailsSidebar from '../../components/sidebars/PracticalTrainingDetailsSidebar';
import CustomTabs from '../../components/tabs/CustomTabs';
import PTGeneralDetails from './components/PTGeneralDetails';
import { useGetSinglePracticalTraingQuery } from '../../api/practicalTraining/practicalTrainingsAPI';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import Box from '@mui/material/Box';
import {
    setPracticalTrainingDetails,
    setSinglePracticalTrainingApplicants,
} from '../../states/features/practicalTraining/practicalTrainingsSlice';
import ContentLoader from '../../components/ContentLoader';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import { isEmpty, size } from 'lodash';
import PTApplicantTable from '../../components/Tables/PTApplicantTable';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import PTInstitutionApprovalTable from '../../components/Tables/PTInstitutionApprovalTable.jsx';
import { useLazyGetSingleTrainingApplicantsQuery } from '../../api/practicalTraining/practicalTrainingApplicantsAPI';

// !######################### MAIL FUNC #####################
const PracticalTrainingDetails = () => {
    // ############## CONST ################
    const RTK = useRTK();
    const { pt_id } = useParams();
    const dispatch = useDispatch();

    // ############# Redux state ###########
    const {
        practicalTrainingDetails,
        singlePracticalTrainingApplicants,
    } = useSelector(PTSelector);
    const { profile: user } = useSelector(profileSelector);

    // ############## Distructure ##########
    const selectedInstitutions = practicalTrainingDetails.selectedInstitutions;

    // ############### RTK ################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: PTData,
    } = useGetSinglePracticalTraingQuery(pt_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setPracticalTrainingDetails(data));
    };
    RTK.useRTKFetch(isSuccess, PTData, rtkAction);
    const [
        getApplicants,
        {
            isLoading: applicantIsLoading,
            isSuccess: applicantIsSuccess,
            data: applicantData,
        },
    ] = useLazyGetSingleTrainingApplicantsQuery();
    // => Response
    const rtkApplicantAction = (data) => {
        dispatch(setSinglePracticalTrainingApplicants(data));
    };
    RTK.useRTKFetch(applicantIsSuccess, applicantData, rtkApplicantAction);

    // ############ Tabs ##################
    const PTTabs = [
        {
            id: 1,
            title: 'General details',
            icon: <WorkHistory />,
            tabsContent: !isEmpty(practicalTrainingDetails) ? (
                <Box sx={{ p: 2 }}>
                    <PTGeneralDetails
                        training={practicalTrainingDetails}
                        applicantIsLoading={applicantIsLoading}
                    />
                </Box>
            ) : (
                ''
            ),
        },
        {
            id: 2,
            title: 'Institutions approval',
            icon: <SchoolOutlined />,
            tabsContent: !isEmpty(practicalTrainingDetails) ? (
                <PTInstitutionApprovalTable
                    institutions={selectedInstitutions}
                />
            ) : (
                ''
            ),
            total: size(selectedInstitutions),
            chipColor: 'info',
        },
        {
            id: 3,
            title: 'Training applicants',
            icon: <Groups2 />,
            displayNone:
                user.id === practicalTrainingDetails.organization_id
                    ? false
                    : true,
            tabsContent: !isEmpty(singlePracticalTrainingApplicants) ? (
                <PTApplicantTable
                    applicants={singlePracticalTrainingApplicants}
                />
            ) : (
                ''
            ),
            total: size(singlePracticalTrainingApplicants),
            chipColor: 'success',
        },
    ];

    // ############# useEffect ############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setSinglePracticalTrainingApplicants([]));
            getApplicants(pt_id);
        }
        return () => {
            isSubscribed = false;
        };
    }, [pt_id, getApplicants, dispatch]);

    return (
        <React.Fragment>
            <>
                <PageLayout
                    head={'Practical training details'}
                    sidebar={
                        !isEmpty(practicalTrainingDetails) && !isLoading ? (
                            <PracticalTrainingDetailsSidebar
                                applicantIsLoading={applicantIsLoading}
                                training={practicalTrainingDetails}
                            />
                        ) : (
                            ''
                        )
                    }
                    noSidebar={isLoading || isError ? true : false}
                >
                    {/* ##################### MAIN CONTENT ################## */}
                    {isLoading ? (
                        <CustomCard2 height={620}>
                            <ContentLoader />
                        </CustomCard2>
                    ) : isError ? (
                        <>
                            <CustomCard2 height={620}>
                                <InnerDataFetchingError
                                    height={550}
                                    handleDataRefetch={refetch}
                                />
                            </CustomCard2>
                        </>
                    ) : (
                        <CustomTabs
                            tabs={PTTabs}
                            // height={'68.5vh'}
                            padding={`0 !important`}
                        />
                    )}
                </PageLayout>
            </>
        </React.Fragment>
    );
};

export default PracticalTrainingDetails;
