import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    CircularProgress,
    IconButton,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Delete } from '@mui/icons-material';
import { setSectors } from '../../states/features/students/instituteProgramsSlice.js';
import {
    useActivateDepartmentMutation,
    useDeactivateDepartmentMutation,
    useRemoveDepartmentMutation,
} from '../../api/students/instituteProgramsAPI';
import { useDispatch } from 'react-redux';
import useRTK from '../../hooks/useRTK';
import DepartmentActions from '../../pages/studentsManagement/CRUD/DepartmentActions';

const columns = [
    { id: 's_n', label: 'S/N', minWidth: 0, align: 'center' },
    { id: 'name', label: 'Sector name', align: 'left' },
    { id: 'action', label: 'Action', minWidth: 15, align: 'center' },
];

function createData(s_n, name, action) {
    return {
        s_n,
        name,
        action,
    };
}

const SectorsTable = ({ departments }) => {
    // ################ Comp state ############
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // ############### FUNC ###############
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = departments?.map((department, index) => {
        return createData(
            <Typography sx={{ fontWeight: 'bold', opacity: 0.8 }}>
                {++index}
            </Typography>,
            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                {department.department.name}
            </Typography>,
            <Box>
                <DepartmentActions department={department} />
            </Box>,
        );
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
            <TableContainer
                sx={{
                    height: '62vh',
                    overflow: 'auto485',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Table stickyHeader aria-label="sticky table" sx={{}}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        fontSize: 12,
                                        color: colors.primary,
                                        whiteSpace: 'nowrap',
                                        opacity: 0.8,
                                    }}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    sx={{ py: 1 }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                }}
            />
        </Paper>
    );
};

export default SectorsTable;
