import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteStudentMutation } from '../../../api/students/studentsAPI';
import SuccessFeedback from '../../../components/feedbacks/SuccessFeedback';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, DeleteForever } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { removeStudent } from '../../../states/features/students/studentsSlice';

// !############## MAIN FUNC ################
const DeleteProduct = ({ student, handleDialogClose }) => {
    // ############## CONST ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############## RTK ################
    const [
        deleteStudent,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useDeleteStudentMutation();

    // ############### useEffect ###########
    const rtkActions = () => {
        dispatch(removeStudent(student.id));
        setTimeout(() => {
            handleDialogClose();
            navigate('/students');
        }, 1000);
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            {isSuccess ? (
                <>
                    <SuccessFeedback message={studentData.message} />
                </>
            ) : (
                <>
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <Box sx={{ mb: 2 }} className="warning-message">
                                <Typography>
                                    <strong>NOTE : </strong>
                                    You'r about to delete this student account
                                    and all related infomation. This process
                                    lead to loss of data and is irrevisible.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <CustomSubmitButton
                                startIcon={<DeleteForever />}
                                endIcon={<ArrowRightAltOutlined />}
                                btnColor={'error'}
                                variant={'contained'}
                                loading={isLoading}
                                onClick={() => {
                                    deleteStudent(student.id);
                                }}
                                sx={{ py: 2, fontSize: 16 }}
                            >
                                Delete student
                            </CustomSubmitButton>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default DeleteProduct;
