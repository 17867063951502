import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orgBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { profileEndpoints } from "./profileEndpoints";

export const profileAPI = createApi({
    reducerPath: "profileAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ["Profile"],
    endpoints: (builder) => ({
        // => Profile
        getProfile: builder.query({
            query: () => `${profileEndpoints.GET_PROFILE}`,
            providesTags: ["Profile"],
        }),
        // => Organization name
        updateORGName: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_NAME}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Description
        updateORGDescription: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_DESCRIPTION}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Organization logo
        updateORGLogo: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_LOGO}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization banner
        updateORGBanner: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_BANNER}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization location
        updateORGLocation: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_LOCATION}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Organization email
        updateORGEmail: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_EMAIL}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Organization phone number
        updateORGPhone: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_PHONE}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Organization key person
        addORGKeyPerson: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.ADD_ORG_KEY_PERSON}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization key person update
        updateORGKeyPerson: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_KEY_PERSON}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Organization key person docs
        addORGKeyPersonDocs: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.ADD_ORG_KEY_PERSON_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization key person docs update
        updateORGKeyPersonDocs: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_KEY_PERSON_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization key person re-upload
        reUploadKeyPerson: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.RE_UPLOAD_KEY_PERSON_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization docs
        addORGDocs: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.ADD_ORG_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization docs update
        updateORGDocs: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.UPDATE_ORG_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Organization docs update
        reUploadORGDocs: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.RE_UPLOAD_ORG_DOCS}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Request OTP
        requestORGPhoneVerification: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.REQUEST_PHONE_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Verify OTP
        verifyORGPhoneNumber: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.VERIFY_PHONE_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Request Email ver
        requestORGEmailVerification: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.REQUEST_EMAIL_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Verify Email ver code
        verifyORGEmail: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.VERIFY_EMAIL_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => FREE TRIAL activation
        freeTrialActivation: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.FREE_TRIAL}`,
                method: `POST`,
                body: payload,
            }),
            onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
                await queryFulfilled;
                dispatch(profileAPI.util.invalidateTags(["Profile"]));
            },
        }),
        // => Request ORG verification
        requestORGVerification: builder.mutation({
            query: () => ({
                url: `${profileEndpoints.REQUEST_ORG_VERIFICATION}`,
                method: `POST`,
            }),
        }),
        // => Change password
        changePassword: builder.mutation({
            query: (payload) => ({
                url: `${profileEndpoints.CHANGE_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Logout
        logout: builder.mutation({
            query: () => ({
                url: `${profileEndpoints.USER_LOGOUT}`,
                method: `POST`,
            }),
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(profileAPI.util.invalidateTags(["Profile"]));
            },
        }),
    }),
});

export const {
    useGetProfileQuery,
    useUpdateORGNameMutation,
    useUpdateORGDescriptionMutation,
    useUpdateORGLogoMutation,
    useUpdateORGBannerMutation,
    useUpdateORGLocationMutation,
    useUpdateORGEmailMutation,
    useUpdateORGPhoneMutation,
    useAddORGKeyPersonMutation,
    useUpdateORGKeyPersonMutation,
    useAddORGKeyPersonDocsMutation,
    useUpdateORGKeyPersonDocsMutation,
    useReUploadKeyPersonMutation,
    useAddORGDocsMutation,
    useUpdateORGDocsMutation,
    useReUploadORGDocsMutation,
    useRequestORGPhoneVerificationMutation,
    useVerifyORGPhoneNumberMutation,
    useRequestORGEmailVerificationMutation,
    useVerifyORGEmailMutation,
    useRequestORGVerificationMutation,
    useLogoutMutation,
    useChangePasswordMutation,
    useFreeTrialActivationMutation,

    // => Lazy
    useLazyGetProfileQuery,
} = profileAPI;
