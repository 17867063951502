import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, Upload } from '@mui/icons-material';
import { useDeleteJobMutation } from '../../../api/job/jobAPI2';
import { removeJob } from '../../../states/features/job/jobsSlice';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DeleteJob = ({ job: jobDetails, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deleteAction,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeleteJobMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = () => {
        setTimeout(() => {
            navigate(`/jobs`);
        }, 0);
        handleDialogClose();
        dispatch(removeJob(jobDetails.id));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="warning-message">
                        <Typography>
                            Please confirm job deletion. This process is
                            irreversible. The deleted job can not be undone.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<Upload />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor="error"
                        variant={'contained'}
                        loading={deleteLoading}
                        onClick={() => {
                            deleteAction(jobDetails.id);
                        }}
                        sx={{ py: 2, fontSize: 16 }}
                    >
                        delete job
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default DeleteJob;
