import {
    ArrowCircleRightOutlined,
    ArrowRight,
    CastForEducationOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/constants";
import { useSelector } from "react-redux";
import { PTSelector, profileSelector } from "../../states/features/selectors";
import { filter, size } from "lodash";

const PracticalTrainingCard = () => {
    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ############## CONST ######################
    const userType =
        user.organization_type.name === "Institution"
            ? "institution"
            : "company";

    // => Drafted
    const draftedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === "draft"
    );
    // => Published
    const publishedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === "published"
    );
    // => Closed
    const closedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === "closed"
    );
    // => Suspended
    const suspendedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === "suspended"
    );

    return (
        <React.Fragment>
            <Box
                sx={{
                    borderRadius: 4,
                    overflow: "hidden",
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "space-between",
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary}) !important`,
                }}
            >
                <Box sx={{ display: "flex", padding: 1.5 }}>
                    <Box
                        sx={{
                            p: 2,
                            borderBottom: `5px solid ${colors.secondary}`,
                            borderRadius: 5,
                            mr: 1.5,
                        }}
                    >
                        <CastForEducationOutlined
                            sx={{
                                fontSize: 60,
                                color: colors.secondary,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: 300,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.secondary,
                                    position: "relative",
                                    ":before": {
                                        content: "''",
                                        height: 2,
                                        width: `100%`,
                                        position: "absolute",
                                        bottom: -7,
                                    },
                                }}
                            >
                                Practical training
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mt: 2,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />
                                    {size(ORGPracticalTrainings)} Posted
                                </Typography>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />
                                    {size(publishedPTs)} Published
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />
                                    {size(draftedPTs)} Drafted
                                </Typography>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />
                                    {size(suspendedPTs)} Suspended
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Link to={"/practical-trainings"}>
                    <Box
                        sx={{
                            bgcolor: colors.secondary,
                            pl: 4,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ":hover": {
                                ".icon": {
                                    mr: 3,
                                    transition: ".3s all ease-in-out",
                                },
                            },
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: "bold", color: colors.primary }}
                        >
                            Manage practical training
                        </Typography>
                        <Typography>
                            <ArrowCircleRightOutlined
                                className="icon"
                                sx={{
                                    mt: 1,
                                    mr: 4,
                                    transition: ".3s all ease-in-out",
                                    color: colors.primary,
                                }}
                            />
                        </Typography>
                    </Box>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default PracticalTrainingCard;
