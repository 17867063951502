import React, { useState } from 'react';
import { Edit, Subject } from '@mui/icons-material';
import { Box, Button, styled, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { colors } from '../../assets/utils/constants';
import SmallCustomTextField from '../Forms/SmallCustomTextField';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import { isEmpty } from 'lodash';
import NoContent from '../NoContent';
import {
    useCreateRequirementMutation,
    useUpdateRequirementMutation,
} from '../../api/job/jobAPI2';
import useRTK from '../../hooks/useRTK';
import { setJobDetails } from '../../states/features/job/jobsSlice';
import DeleteRequirement from './CRUD/DeleteRequirement';

// ########### STYLED COMPONENT #################
const JobRequirementCard = styled(Box)({
    'padding': 20,
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'pb': 0.5,
        'color': colors.warning,
        'opacity': 0.8,
        'marginBottom': 2,
        '.icon': {
            marginRight: 1,
            fontSize: 14,
            color: colors.warning,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
        },
    },
});

// ########## VALIDATION SCHEMA #################
const requirementValidationSchema = Yup.object({
    job_requirement: Yup.string().required('Requirement is required field'),
});

// !################## MAIN FUNC ##################
const JobRequirements = ({ job_id, jobRequirements }) => {
    // ########### CONST #####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ########## IN COMP STATE #################
    const [requirement, setRequirement] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [requirementId, setRequirementId] = useState('');
    const handleEdit = (data) => {
        setIsEdit(true);
        setRequirement(data.requirement);
        setRequirementId(data.requirement_id);
    };
    // -> reset comp state
    const resetCompState = () => {
        setIsEdit(false);
        setRequirement('');
        setRequirementId('');
    };

    // ################### RTK ###################
    const rtkActions = (data) => {
        resetCompState();
        dispatch(setJobDetails(data));
    };
    const [
        addRequirement,
        {
            isLoading: requirementAddLoading,
            isSuccess: requirementAddSuccess,
            isError: requirementAddIsError,
            error: requirementAddError,
            data: requirementAddData,
        },
    ] = useCreateRequirementMutation();
    RTK.useRTKResponse(
        requirementAddSuccess,
        requirementAddIsError,
        requirementAddError,
        requirementAddData,
        rtkActions,
    );
    // => Update
    const [
        updateRequirement,
        {
            isLoading: requirementUpdateLoading,
            isSuccess: requirementUpdateSuccess,
            isError: requirementUpdateIsError,
            error: requirementUpdateError,
            data: requirementUpdateData,
        },
    ] = useUpdateRequirementMutation();
    RTK.useRTKResponse(
        requirementUpdateSuccess,
        requirementUpdateIsError,
        requirementUpdateError,
        requirementUpdateData,
        rtkActions,
    );
    return (
        <>
            <JobRequirementCard>
                <Typography className="title">
                    <Subject className="icon" />
                    Job requirement
                </Typography>
                {!isEmpty(jobRequirements) ? (
                    <>
                        {/* ######################### CONTENT #################### */}
                        <Box>
                            {jobRequirements?.map((requirement, index) => (
                                <Box className="res-con" key={requirement.id}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                                width: 40,
                                                height: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mr: 1,
                                            }}
                                        >
                                            {++index} :
                                        </Typography>
                                        <Typography sx={{ fontSize: 14 }}>
                                            {requirement.job_requirement}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                p: 0.4,
                                                m: 0.4,
                                            }}
                                            onClick={() => {
                                                let data = {
                                                    requirement_id:
                                                        requirement.id,
                                                    requirement:
                                                        requirement.job_requirement,
                                                };
                                                handleEdit(data);
                                            }}
                                        >
                                            <Edit
                                                color="warning"
                                                sx={{
                                                    fontSize: 14,
                                                }}
                                            />
                                        </Button>
                                        <DeleteRequirement
                                            requirement={requirement}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </>
                ) : (
                    <>
                        <NoContent
                            height={100}
                            message={'No requirement added'}
                        />
                    </>
                )}
                {/* ######################### FORM #################### */}
                <Box sx={{ mt: 2 }}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            job_requirement: requirement,
                        }}
                        validationSchema={requirementValidationSchema}
                        onSubmit={(payload, { resetForm }) => {
                            let newPayload = {
                                job_id,
                                ...payload,
                            };
                            let editPayload = {
                                requirementId,
                                ...payload,
                            };
                            !isEdit
                                ? addRequirement(newPayload)
                                : updateRequirement(editPayload);
                            resetForm();
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Box
                                    sx={{
                                        position: 'relative',
                                    }}
                                >
                                    <SmallCustomTextField
                                        label="Add requirement"
                                        name="job_requirement"
                                        cuPadding={14}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            right: 3,
                                            bottom: 0,
                                        }}
                                    >
                                        <CustomSubmitButton
                                            loading={
                                                requirementAddLoading
                                                    ? requirementAddLoading
                                                    : requirementUpdateLoading
                                            }
                                            btnColor={
                                                isEdit ? 'secondary' : 'primary'
                                            }
                                            // loading={isSubmitRes}
                                            onClick={formik.handleSubmit}
                                        >
                                            {isEdit ? 'Update' : 'Add'}
                                        </CustomSubmitButton>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </JobRequirementCard>
        </>
    );
};

export default JobRequirements;
