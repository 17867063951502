import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Link } from "react-router-dom";
import { RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import { size } from "lodash";

const columns = [
    { id: "s_n", label: "S/N", minWidth: 0, align: "center" },
    { id: "title", label: "title", align: "left" },
    { id: "opened", label: "opened", align: "center" },
    { id: "closed", label: "closed", align: "center" },
    { id: "enrolled", label: "enrolled", align: "center" },
    {
        id: "status",
        label: "Status",
        align: "center",
    },
    {
        id: "action",
        label: "",
    },
];

function createData(s_n, title, opened, closed, enrolled, status, action) {
    return {
        s_n,
        title,
        opened,
        closed,
        enrolled,
        status,
        action,
    };
}

const TrainingSeasonTable = ({ trainingSeasons, students, height }) => {
    console.log(trainingSeasons);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = trainingSeasons?.map((season, index) => {
        return createData(
            <Typography sx={{ fontWeight: "bold", opacity: 0.6 }}>
                {++index}
            </Typography>,
            <Typography sx={{ fontWeight: "bold", fontSize: 15, opacity: 0.8 }}>
                {season.title}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: colors.success,
                    opacity: 0.7,
                }}
            >
                {moment(season.open_date).format("LL")}
            </Typography>,
            <Typography
                sx={{
                    fontSize: 13,
                    fontWeight: "bold",
                    color: "red",
                    opacity: 0.6,
                }}
            >
                {moment(season.close_date).format("LL")}
            </Typography>,
            <Typography
                sx={{ fontSize: 14, fontWeight: "bold", color: colors.info }}
            >
                {`${season.totalEnrolled} out of ${size(students)}`}
            </Typography>,
            <Chip
                label={season.status}
                color={
                    season.status === "opened"
                        ? "success"
                        : season.status === "closed"
                        ? "error"
                        : "warning"
                }
                size="small"
                sx={{
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "bold",
                    px: 1,
                    opacity: 0.8,
                }}
            />,
            <Link to={`/students/training-season/${season.id}`}>
                <Tooltip title="Season details" arrow>
                    <IconButton size="small">
                        <RemoveRedEye color="info" />
                    </IconButton>
                </Tooltip>
            </Link>
        );
    });

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer
                sx={{
                    height: height,
                    overflow: "auto",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Table stickyHeader aria-label="sticky table" sx={{}}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        fontSize: 14,
                                        color: colors.primary,
                                        whiteSpace: "nowrap",
                                        opacity: 0.8,
                                    }}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{ py: 1 }}
                                                >
                                                    {column.format &&
                                                    typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                }}
            />
        </Paper>
    );
};

export default TrainingSeasonTable;
