import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { differenceWith, isEmpty } from 'lodash';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { useAddDepartmentMutation } from '../../../api/students/instituteProgramsAPI';
import { setDepartments } from '../../../states/features/students/instituteProgramsSlice.js';
import CustomAutocomplete from '../../../components/Forms/CustomAutocomplete';
import { settingsSelector } from '../../../states/features/selectors';
import CustomCard2 from '../../../components/cards/CustomCard2';

// * ################### VALIDATION SCHEMA ####################
const departmentValidationSchema = Yup.object({
    departments: Yup.array().required('Please select atleast one department'),
});

const AddDepartments = ({
    addedSectors,
    addedDepartments,
    handleDialogClose,
}) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp State #############
    const [departmentIds, setDepartmentsIds] = useState('');

    // ############# Redux state ################
    const { activeDepartments } = useSelector(settingsSelector);

    // ############# RTK ##################
    const [
        addSectorAction,
        { isLoading, isSuccess, isError, error, data: sectorData },
    ] = useAddDepartmentMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setDepartments(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, sectorData, rtkActions);

    // ########## FUNC ###############
    // => Restructure sector obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                title: obj.name,
                sector_id: obj.sector_id,
                label: obj.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    // => Get available departments
    const instituteSectorDepartments = () => {
        let availableDepartments = [];
        if (!isEmpty(addedSectors)) {
            availableDepartments = activeDepartments.filter((department) => {
                return addedSectors.find(
                    (sector) => sector.sector_id === department.sector_id,
                );
            });
        }
        return availableDepartments;
    };
    // => Get unadded departments
    const getPendingAddedDepartments = () => {
        let pendingDepartments = instituteSectorDepartments();
        if (!isEmpty(addedDepartments)) {
            pendingDepartments = differenceWith(
                pendingDepartments,
                addedDepartments,
                (a, b) => a.id === b.department_id,
            );
        }
        return pendingDepartments;
    };
    const availableDepartments = restructureArray(getPendingAddedDepartments());

    const handleSelectedValues = (payload) => {
        isEmpty(payload) ? setDepartmentsIds('') : setDepartmentsIds(payload);
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    departments: departmentIds,
                }}
                validationSchema={departmentValidationSchema}
                onSubmit={(payload) => {
                    addSectorAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomCard2 height={'50vh'}>
                            <Grid container>
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{ mb: 2 }}
                                        className="warning-message"
                                    >
                                        <Typography>
                                            <strong>NOTE : </strong>
                                            Select departments which available
                                            on your institute.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <CustomAutocomplete
                                        label={'Select departments'}
                                        placeholder={'Departments'}
                                        name="departments"
                                        options={availableDepartments}
                                        getSelectedValues={handleSelectedValues}
                                        defaultOption={undefined}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography
                                        className="error-message"
                                        sx={{ mt: 3 }}
                                    >
                                        <strong>Note: </strong>
                                        Available departments based on added
                                        institute sectors.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CustomCard2>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<PublishOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={'info'}
                                    variant={'contained'}
                                    loading={isLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Add department(s)
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddDepartments;
