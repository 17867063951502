import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const StudentMiniWidget = ({ student }) => {
    return (
        <>
            <ListItem
                sx={{
                    position: 'relative',
                    px: 0,
                }}
            >
                <ListItemAvatar
                    sx={{
                        ml: -1,
                        mr: 2,
                    }}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={student.profile_picture}
                        sx={{
                            height: 65,
                            width: 65,
                            border: `2px dotted ${colors.info}`,
                            background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primaryTypographyProps={{ whiteSpace: 'nowrap' }}
                    primary={
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            {`${student.first_name} ${student.last_name}`}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            noWrap
                            sx={{
                                display: 'inline',
                                fontSize: 14,
                                opacity: 0.8,
                            }}
                            component="span"
                            variant="body2"
                        >
                            {student.reg_id}
                        </Typography>
                    }
                />
            </ListItem>
        </>
    );
};

export default StudentMiniWidget;
