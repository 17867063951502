import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setPracticalTrainingDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import { useApprovePracticalTraingMutation } from '../../../api/practicalTraining/practicalTrainingsAPI';
import useRTK from '../../../hooks/useRTK';

const ApprovePTTOStudents = ({ training, handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        approvePT,
        {
            isLoading: approveLoading,
            isSuccess: approveSuccess,
            isError: approveIsError,
            error: approveError,
            data: approveData,
        },
    ] = useApprovePracticalTraingMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setPracticalTrainingDetails(data));
    };
    RTK.useRTKResponse(
        approveSuccess,
        approveIsError,
        approveError,
        approveData,
        rtkActions,
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="warning-message">
                        <Typography>
                            <strong>NOTE : </strong>
                            You'r about to approve this training to student
                            where it will be visible to your students for
                            applying.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<PublishOutlined />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor={'info'}
                        variant={'contained'}
                        loading={approveLoading}
                        sx={{ py: 2, fontSize: 16 }}
                        onClick={() => {
                            approvePT(training.id);
                        }}
                    >
                        Approve training to students
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ApprovePTTOStudents;
