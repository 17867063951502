import { Box, Grid } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import PaymentOptions from '../../../components/payments/PaymentOptions';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';

const PaymentAndPosting = ({ steps, activeStep, handleNext, handleBack }) => {
    return (
        <>
            <Box
                sx={{
                    height: 450,
                    position: 'relative',
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={2} padding={2}>
                    <Grid item sm={12}>
                        <CustomCard2>
                            <PaymentOptions />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </Box>
            <NextAndBackBtn
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                action={handleNext}
            />
        </>
    );
};

export default PaymentAndPosting;
