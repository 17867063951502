import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
const PrivateRoutes = ({ roleRequired }) => {
    const auth = useAuth();
    if (auth.authenticated && roleRequired) {
        if (roleRequired === 'all') {
            return <Outlet />;
        }else if (roleRequired === auth.authorization) {
            return <Outlet />;
        } else {
            return <Navigate to="/denied" />;
        }
    } else {
        return <Navigate to="/" />;
    }
};

export default PrivateRoutes;
