import React, { useEffect } from "react";
import { Groups2Outlined, ReadMore } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import { filter, isEmpty, size } from "lodash";
import JobTopInfoDetails from "../../components/jobComponents/JobTopInfoDetails";
import PageLayout from "../layouts/PageLayout";
import JobDetailsSidebar from "../../components/sidebars/JobDetailsSidebar";
import CustomDialog from "../../components/Dialogs/CustomDialog";
import { useGetSingleJobORGQuery } from "../../api/job/jobAPI2";
import useRTK from "../../hooks/useRTK";
import {
    jobApplicationsSelector,
    jobsSelector,
} from "../../states/features/selectors";
import UpdateJob from "./UpdateJob";
import {
    addCheckPaymentStatus,
    setJobDetails,
} from "../../states/features/job/jobsSlice";
import ContentLoader from "../../components/ContentLoader";
import InnerDataFetchingError from "../../components/InnerDataFetchingError";
import CustomTabs from "../../components/tabs/CustomTabs";
import CustomCard2 from "../../components/cards/CustomCard2";
import { useLazyGetSingleJobApplicationsQuery } from "../../api/job/jobApplicationAPI";
import { setSingleJobApplications } from "../../states/features/job/jobApplicationsSlice";
import JobGeneralDetails from "./components/JobGeneralDetails";
import JobApplicantTable from "../../components/Tables/JobApplicantTable";

// !######################### MAIL FUNC #####################
const JobDetails = () => {
    // ################## CONST #####################
    const RTK = useRTK();
    const { job_id } = useParams();
    const dispatch = useDispatch();

    // ################## Comp state ################
    const [openJobDialog, setOpenJobDialogy] = useState(false);

    // ################## Redux state ##############
    const { jobDetails, checkPaymentStatus } = useSelector(jobsSelector);
    const { singleJobApplications } = useSelector(jobApplicationsSelector);
    const jobResponsibilities = jobDetails.responsibilities;
    const jobRequirements = jobDetails.requirements;
    const jobTags = jobDetails.tags ? JSON.parse(jobDetails.tags) : [];

    // ################## FUNC ################
    // -> Dialogy job open
    const handleJobDialogyOpen = () => {
        setOpenJobDialogy(true);
    };
    // -> Dialogy job closer
    const handleJobDialogClose = () => {
        setOpenJobDialogy(false);
    };

    // ############### RTK ####################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: jobData,
    } = useGetSingleJobORGQuery(job_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setJobDetails(data));
    };
    RTK.useRTKFetch(isSuccess, jobData, rtkAction);
    // => Fetch applicants
    const [
        getApplicants,
        {
            isLoading: applicantIsLoading,
            isSuccess: applicantIsSuccess,
            data: applicantData,
        },
    ] = useLazyGetSingleJobApplicationsQuery();
    // => Response
    const rtkApplicantAction = (data) => {
        dispatch(setSingleJobApplications(data));
    };
    RTK.useRTKFetch(applicantIsSuccess, applicantData, rtkApplicantAction);

    // ############# useEffect ############
    // => Get applicant
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setSingleJobApplications([]));
            getApplicants(job_id);
        }
        return () => {
            isSubscribed = false;
        };
    }, [job_id, getApplicants, dispatch]);

    // => Check payment status
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            let paymentStatus = jobDetails?.payment?.status;
            if (paymentStatus === "pending") {
                setTimeout(() => {
                    refetch();
                    dispatch(addCheckPaymentStatus(checkPaymentStatus + 1));
                }, 5000);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [jobDetails, checkPaymentStatus]);

    // ############ FUNC #################
    // => // => Job Applicants
    const getJobApplicantsByStatus = () => {
        let pendingApplicants = [];
        let savedApplicants = [];
        let shortlistedApplicants = [];
        let acceptedApplicants = [];
        // => Pending Job applications
        pendingApplicants = filter(
            singleJobApplications,
            ({ application }) => application.status === "pending"
        );
        // => Saved
        savedApplicants = filter(
            singleJobApplications,
            ({ application }) => application.isSaved === 1
        );
        // => Shortlisted
        shortlistedApplicants = filter(
            singleJobApplications,
            ({ application }) => application.status === "shortlisted"
        );
        // => Accepted
        acceptedApplicants = filter(
            singleJobApplications,
            ({ application }) => application.status === "accepted"
        );
        return {
            pendingApplicants,
            savedApplicants,
            shortlistedApplicants,
            acceptedApplicants,
        };
    };
    // => Job applicant
    const JobApplicants = getJobApplicantsByStatus();

    // ############ Tabs ##################
    const JobTabs = [
        {
            id: 1,
            title: "Job details",
            icon: <ReadMore />,
            tabsContent: (
                <JobGeneralDetails
                    jobID={job_id}
                    jobDetails={jobDetails}
                    jobResponsibilities={jobResponsibilities}
                    jobRequirements={jobRequirements}
                    jobTags={jobTags}
                />
            ),
        },
        {
            id: 2,
            title: "All app's",
            icon: <Groups2Outlined />,
            tabsContent: (
                <JobApplicantTable
                    height={"41.4vh"}
                    applicants={singleJobApplications}
                />
            ),
            total: size(singleJobApplications),
            chipColor: "primary",
            message: "No available application",
        },
        {
            id: 3,
            title: "Saved",
            icon: <Groups2Outlined />,
            tabsContent: (
                <JobApplicantTable
                    height={"41.4vh"}
                    applicants={JobApplicants.savedApplicants}
                />
            ),
            total: size(JobApplicants.savedApplicants),
            chipColor: "warning",
            message: "No saved application",
        },
        {
            id: 4,
            title: "Shortlisted",
            icon: <Groups2Outlined />,
            tabsContent: (
                <JobApplicantTable
                    height={"41.4vh"}
                    applicants={JobApplicants.shortlistedApplicants}
                />
            ),
            total: size(JobApplicants.shortlistedApplicants),
            chipColor: "info",
            message: "No shortlisted application",
        },
        {
            id: 5,
            title: "Accepted",
            icon: <Groups2Outlined />,
            tabsContent: (
                <JobApplicantTable
                    height={"41.4vh"}
                    applicants={JobApplicants.acceptedApplicants}
                />
            ),
            total: size(JobApplicants.acceptedApplicants),
            chipColor: "success",
            message: "No accepted application",
        },
    ];

    return (
        <React.Fragment>
            <>
                {/* ##################### POSTING DIALOGY ################## */}
                <CustomDialog
                    head={"Update job vacancy"}
                    openDialog={openJobDialog}
                    customWidth={"md"}
                    handleDialogClose={handleJobDialogClose}
                >
                    <UpdateJob
                        jobDetails={jobDetails}
                        closeDialog={handleJobDialogClose}
                    />
                </CustomDialog>

                {/* ##################### CONTENTS ################## */}
                {isLoading ? (
                    <PageLoader />
                ) : (
                    <PageLayout
                        head={"Job details"}
                        sidebar={
                            !isEmpty(jobDetails) && (
                                <JobDetailsSidebar
                                    jobDetails={jobDetails}
                                    handleOpenJobPostDialogy={
                                        handleJobDialogyOpen
                                    }
                                />
                            )
                        }
                    >
                        {/* ##################### TOP DETAILS ################## */}
                        {!isEmpty(jobDetails) && (
                            <JobTopInfoDetails
                                jobDetails={jobDetails}
                                applicantIsLoading={applicantIsLoading}
                            />
                        )}

                        {/* ##################### MAIN CONTENT ################## */}
                        {isLoading ? (
                            <CustomCard2 height={620}>
                                <ContentLoader />
                            </CustomCard2>
                        ) : isError ? (
                            <>
                                <CustomCard2 height={620}>
                                    <InnerDataFetchingError
                                        height={550}
                                        handleDataRefetch={refetch}
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <CustomTabs
                                tabs={JobTabs}
                                // height={'48vh'}
                                padding={`0 !important`}
                            />
                        )}
                    </PageLayout>
                )}
            </>
        </React.Fragment>
    );
};

export default JobDetails;
