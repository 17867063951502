import React, { useState } from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import TrainingApplicantDetails from '../../pages/practicalTraining/components/TrainingApplicantDetails';
import CustomDialog from '../Dialogs/CustomDialog';

const FieldApplicantWidget = ({ applicant }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############# Dialog ################ */}
            <CustomDialog
                head={'Practical training applicant details'}
                openDialog={openDialog}
                customWidth={'lg'}
                handleDialogClose={handleDialogClose}
            >
                <TrainingApplicantDetails
                    applicant={applicant}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <Tooltip title="Applicant details" arrow>
                        <Button
                            sx={{
                                bgcolor: colors.bgColor3,
                            }}
                            onClick={() => {
                                handleDialogOpen();
                            }}
                        >
                            <RemoveRedEye color="info" sx={{ fontSize: 20 }} />
                        </Button>
                    </Tooltip>
                }
                sx={{
                    'transition': '.3s all ease-in-out',
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    'position': 'relative',
                    'mb': 2,
                    'borderRadius': 2,
                    'transition': '.3s all ease-in-out',
                    'border': `1px solid ${colors.bgColor3}`,
                    ':hover': {
                        transition: '.3s all ease-in-out',
                        border: `1px solid ${colors.bgColor5}`,
                    },
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        alt="Remy Sharp"
                        src={applicant.profile_picture}
                        sx={{
                            height: 65,
                            width: 65,
                            mt: -0.5,
                            mr: 1.5,
                            border: `3px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                            }}
                        >
                            {`${applicant.first_name} ${applicant?.middle_name} ${applicant.last_name}`}
                        </Typography>
                    }
                    secondary={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                }}
                                component="span"
                            >
                                {applicant.institution.organization_name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                                component="span"
                            >
                                {applicant?.appliedTraining?.title}
                            </Typography>
                        </Box>
                    }
                />
            </ListItem>
        </>
    );
};

export default FieldApplicantWidget;
