import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import SmallCustomTextField from '../../components/Forms/SmallCustomTextField';
import SmallCustomSelectInput from '../../components/Forms/SmallCustomSelectInput';
import CustomMultlineInput from '../../components/Forms/CustomMultlineInput';
import { colors } from '../../assets/utils/constants';
import { ArrowRightAltOutlined, Update } from '@mui/icons-material';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import { settingsSelector } from '../../states/features/selectors';
import CustomRegionSelect from '../../components/Forms/CustomRegionSelect';
import { useUpdateJobMutation } from '../../api/job/jobAPI2';
import useRTK from '../../hooks/useRTK';
import { setJobDetails } from '../../states/features/job/jobsSlice';
import { useGetJobCategoriesQuery } from '../../api/settings/settingAPI';
import { setJobCategories } from '../../states/features/settings/settingsSlice';

// * ################### VALIDATION SCHEMA ####################
const jobPostingValidationSchema = Yup.object({
    title: Yup.string().required('Title is a required field'),
    description: Yup.string().required('Description is a required field'),
    location: Yup.string().required('Location is a required field'),
    salary: Yup.number().typeError('Salary must be a number').nullable(),
    open_date: Yup.string().required('Open date is a required field'),
    close_date: Yup.string().required('Close date is a required field'),
    isFor: Yup.string().required('Classfication is a required field'),
    job_category_id: Yup.string().required('Job category is a required field'),
    job_type: Yup.string().required('Job type is a required field'),
});

const UpdateJob = ({ jobDetails, closeDialog }) => {
    // ############ CONST ###################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############ Redux state #################
    const { jobCategories } = useSelector(settingsSelector);

    // ############# RTK ####################
    const {
        isSuccess: jobCategoriesSuccess,
        data: jobCategoriesData,
    } = useGetJobCategoriesQuery();
    const rtkJobCategoriesAction = (data) => {
        dispatch(setJobCategories(data));
    };
    RTK.useRTKFetch(
        jobCategoriesSuccess,
        jobCategoriesData,
        rtkJobCategoriesAction,
    );
    const [
        updateJob,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateJobMutation();
    // => Response
    const rtkActions = (data) => {
        closeDialog();
        dispatch(setJobDetails(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    job_id: jobDetails.id,
                    title: isEmpty(jobDetails) ? '' : jobDetails.title,
                    description: isEmpty(jobDetails)
                        ? ''
                        : jobDetails.description,
                    location: isEmpty(jobDetails) ? '' : jobDetails.location,
                    salary: isEmpty(jobDetails) ? '' : jobDetails.salary,
                    open_date: isEmpty(jobDetails) ? '' : jobDetails.open_date,
                    close_date: isEmpty(jobDetails)
                        ? ''
                        : jobDetails.close_date,
                    isFor: isEmpty(jobDetails) ? '' : jobDetails.isFor,
                    job_category_id: isEmpty(jobDetails)
                        ? ''
                        : jobDetails.job_category_id,
                    job_type: isEmpty(jobDetails) ? '' : jobDetails.job_type,
                }}
                validationSchema={jobPostingValidationSchema}
                onSubmit={(payload) => {
                    updateJob(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Box>
                            <Grid
                                container
                                spacing={2}
                                padding={2}
                                justifyContent={'center'}
                            >
                                {/* ################## JOB TITLE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Job title
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Job title'}
                                                    name={'title'}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## SALARY ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Job salary{' '}
                                            <strong className="error-text">
                                                ***optional***
                                            </strong>
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Job Salary'}
                                                    name={'salary'}
                                                    type="number"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Salary scale'}
                                                    name={'salary_scale'}
                                                    type="text"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## DATE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Application date
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Application start'}
                                                    name={'open_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={
                                                        'Application deadline'
                                                    }
                                                    name={'close_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## JOB TYPE & CATEGORY ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Job type and Category
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomSelectInput
                                                    label={'Job type'}
                                                    name={'job_type'}
                                                    formik={formik}
                                                    color={colors.primary}
                                                >
                                                    <MenuItem value="internship">
                                                        Intentship
                                                    </MenuItem>
                                                    <MenuItem value="part_time">
                                                        Part time
                                                    </MenuItem>
                                                    <MenuItem value="full_time">
                                                        Full time
                                                    </MenuItem>
                                                </SmallCustomSelectInput>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomSelectInput
                                                    label={'Job category'}
                                                    name={'job_category_id'}
                                                    formik={formik}
                                                    color={colors.primary}
                                                >
                                                    {jobCategories?.map(
                                                        (category) => (
                                                            <MenuItem
                                                                key={
                                                                    category.id
                                                                }
                                                                value={
                                                                    category.id
                                                                }
                                                            >
                                                                {category.name}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                                </SmallCustomSelectInput>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                {/* ################## DESCRIPTION ############ */}
                                <Grid item sm={9} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Job descriptions
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <CustomMultlineInput
                                                    label={'Job description'}
                                                    name={'description'}
                                                    rows={4}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## JOB LOCATION ############ */}
                                <Grid item sm={3} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Job location
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <CustomRegionSelect
                                                    label={'Job location'}
                                                    name={'location'}
                                                    formik={formik}
                                                    colors={colors.primary}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item sm={12}>
                                    <CustomSubmitButton
                                        startIcon={<Update />}
                                        endIcon={<ArrowRightAltOutlined />}
                                        btnColor="info"
                                        variant={'outlined'}
                                        loading={isLoading}
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                        sx={{ py: 2, fontSize: 16 }}
                                    >
                                        Update job
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateJob;
