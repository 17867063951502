import { Grid } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import StudentFilters from '../../components/filters/StudentFilters';
import AllocatedStudentTable from '../../components/Tables/AllocatedStudentTable';
import PageLayout from '../layouts/PageLayout';

const EnrolledStudents = () => {
    return (
        <>
            <PageLayout
                head={'Students allocated in practical training'}
                noSidebar
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Allocated students'}
                            action={<StudentFilters />}
                        >
                            <AllocatedStudentTable height={472} />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default EnrolledStudents;
