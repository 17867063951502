import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { colors } from '../../assets/utils/constants';
import PaymentAndPosting from '../../pages/jobs/CRUD/PaymentAndPosting';
import GeneralDetails from '../../pages/practicalTraining/CRUD/GeneralDetails';
import InstitutionSelection from '../../pages/practicalTraining/CRUD/InstitutionSelection';
import SectionSelection from '../../pages/practicalTraining/CRUD/SectionSelection';
import Confimation from '../../pages/practicalTraining/CRUD/Confimation';

const steps = [
    'General detals',
    'Institution selection',
    'Section selection',
    'Confirmation',
];

export default function CreatePracticalTrainingStep() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [formData, setFormData] = React.useState({
        title: '',
        open_date: '',
        end_date: '',
        max_applicant: '',
        positions: '',
        description: '',
        institutions: '',
        sector_id: '',
        depertiment_id: '',
        section_id: '',
    });

    const handleNext = () => {
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper
                activeStep={activeStep}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    py: 1.5,
                    borderRadius: 2,
                }}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ p: 1 }}>
                        {activeStep === 0 && (
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    mt: 2,
                                    borderRadius: 2,
                                    height: 510,
                                    position: 'relative',
                                    overflow: 'auto',
                                }}
                            >
                                <GeneralDetails
                                    formData={formData}
                                    setFormData={setFormData}
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    mt: 2,
                                    borderRadius: 2,
                                    height: 510,
                                    position: 'relative',
                                    overflow: 'auto',
                                }}
                            >
                                <InstitutionSelection
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    mt: 2,
                                    borderRadius: 2,
                                    height: 510,
                                    position: 'relative',
                                    overflow: 'auto',
                                }}
                            >
                                <SectionSelection
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    mt: 2,
                                    borderRadius: 2,
                                    height: 510,
                                    position: 'relative',
                                    overflow: 'auto',
                                }}
                            >
                                <Confimation
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                />
                            </Box>
                        )}
                        {activeStep === 4 && (
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                    mt: 2,
                                    borderRadius: 2,
                                    height: 510,
                                    position: 'relative',
                                    overflow: 'auto',
                                }}
                            >
                                <PaymentAndPosting
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleNext={handleNext}
                                    handleBack={handleBack}
                                />
                            </Box>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}
