import React from "react";
import { Box, Grid, List } from "@mui/material";
import JobForYouWidget2 from "../widgets/JobForYouWidget2";
import { isEmpty } from "lodash";
import NoContent from "../NoContent";
import { jobsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import JobForYouEmployerWidget from "../widgets/JobForYouEmployerWidget";

const DashJobForYou = () => {
    // ############## Redux state #################
    const { jobsForYouORG, jobsForYouEmployer } = useSelector(jobsSelector);
    const jobsForYou = [...jobsForYouORG, ...jobsForYouEmployer];

    return (
        <React.Fragment>
            <Box
                sx={{
                    overflow: "auto",
                    position: "relative",
                }}
            >
                <List
                    sx={{
                        width: "100%",
                        p: 0,
                    }}
                >
                    {isEmpty(jobsForYou) ? (
                        <>
                            <NoContent
                                height={120}
                                message={"No job for you for now !"}
                            />
                        </>
                    ) : (
                        <Grid container spacing={1}>
                            {jobsForYou.map((job) => (
                                <Grid item sm={12} xs={12} key={job.id}>
                                    {job?.organization ? (
                                        <JobForYouWidget2 job={job} />
                                    ) : (
                                        <JobForYouEmployerWidget job={job} />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </List>
            </Box>
        </React.Fragment>
    );
};

export default DashJobForYou;
