import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { colors } from '../../../assets/utils/constants';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import {
    AllInclusive,
    ArrowRightAltOutlined,
    BookmarkBorderOutlined,
    DoneAll,
    PinDrop,
    PublishOutlined,
    RemoveRedEye,
    Update,
    WorkOutline,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { filter, isEmpty } from 'lodash';
import CustomCard2 from '../../../components/cards/CustomCard2';
import {
    PTPostingSelector,
    settingsSelector,
} from '../../../states/features/selectors';
import CustomFileInput2 from '../../../components/Forms/CustomFileInput2';
import DocumentPreviewDialog from '../../../components/Dialogs/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';
import strHelper from '../../../helpers/strHelper';
import moment from 'moment/moment';
import { resetPTPostingDetails } from '../../../states/features/practicalTraining/PTPostingSlice';
import {
    useCreatePracticalTraingMutation,
    useUpdatePracticalTraingMutation,
} from '../../../api/practicalTraining/practicalTrainingsAPI';
import { useNavigate } from 'react-router-dom';
import useRTK from '../../../hooks/useRTK';
import ContentLoader from '../../../components/ContentLoader';
import { addORGPracticalTraining } from '../../../states/features/practicalTraining/practicalTrainingsSlice';

// * ################### VALIDATION SCHEMA ####################
const confimationValidationSchema = Yup.object({
    title: Yup.string().required('Title is a required field'),
    open_date: Yup.string().required('Open date is a required field'),
    end_date: Yup.string().required('End date is a required field'),
    max_applicants: Yup.number().nullable(),
    positions: Yup.number().nullable(),
    description: Yup.string().required('Description is a required field'),
});

const Confimation = ({
    handleBack,
    steps,
    activeStep,
    handleDialogClose: closePTDialog,
}) => {
    // ################### CONST #################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ################### Comp state #################
    const [dispatchType, setDispatchType] = useState('');
    const [filePreview, setFilePreview] = useState('');
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    const [PTDetails, setPTDetails] = useState({});

    // ################## Redux state ####################
    const {
        pt_id,
        title,
        description,
        max_applicants,
        location,
        positions,
        open_date,
        end_date,
        institutions,
        sector_id,
        department_id,
        sections,
        isUpdate,
    } = useSelector(PTPostingSelector);
    const { activeSectors, activeDepartments } = useSelector(settingsSelector);
    // => Slected sector
    const selectedSector = filter(activeSectors, ({ id }) => id === sector_id);
    // => Selected depart
    const selectedDepartment = filter(
        activeDepartments,
        ({ id }) => id === department_id,
    );

    // ################## FUNC ####################
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };
    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    // #################### RTK #####################
    const [
        createPT,
        { isLoading, isSuccess, isError, error, data: createdPT },
    ] = useCreatePracticalTraingMutation();
    const [
        updatePT,
        {
            isLoading: updateLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updatedPT,
        },
    ] = useUpdatePracticalTraingMutation();

    // => RTK action
    const rtkActions = (data) => {
        setPTDetails(data);
        dispatch(addORGPracticalTraining(data))
        dispatch(resetPTPostingDetails());
    };
    const rtkUpdateActions = (data) => {
        setPTDetails(data);
        dispatch(resetPTPostingDetails());
    };

    // => RTK Response
    RTK.useRTKResponse(isSuccess, isError, error, createdPT, rtkActions);
    RTK.useRTKResponse(
        updateSuccess,
        updateIsError,
        updateError,
        updatedPT,
        rtkUpdateActions,
    );

    // ############ SUCCESS HANDLER #################
    const handlePTDetails = () => {
        isUpdate
            ? closePTDialog()
            : navigate(`/practical-trainings/${PTDetails.id}`);
        dispatch(resetPTPostingDetails());
    };
    const handleNewPT = () => {
        navigate(`/practical-trainings/`);
        dispatch(resetPTPostingDetails());
    };

    return (
        <>
            {isLoading || updateLoading ? (
                <ContentLoader height={500} />
            ) : isSuccess || updateSuccess ? (
                <>
                    <Box
                        sx={{
                            height: 500,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={8} xs={12}>
                                <Box
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                        textAlign: 'center',
                                        p: 8,
                                        borderRadius: 3,
                                    }}
                                >
                                    <Box>
                                        <DoneAll
                                            color="success"
                                            sx={{ fontSize: 85 }}
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            color: colors.success,
                                        }}
                                    >
                                        Practical training created successfully
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={8} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        mt: 5,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className="app-btn"
                                        color="info"
                                        startIcon={<RemoveRedEye />}
                                        onClick={handlePTDetails}
                                        sx={{ mr: 2 }}
                                    >
                                        Training details
                                    </Button>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className="app-btn"
                                        color="secondary"
                                        startIcon={<WorkOutline />}
                                        onClick={handleNewPT}
                                        sx={{ ml: 2 }}
                                    >
                                        Training management
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                <>
                    {/* ##################### DIALOG ################# */}
                    <DocumentPreviewDialog
                        head={'Document preview'}
                        openDialog={openDocsDialog}
                        handleDialogClose={handleDialogClose}
                    >
                        <DocumentPreview document={filePreview} />
                    </DocumentPreviewDialog>

                    <Formik
                        initialValues={{
                            pt_id,
                            file: '',
                            title,
                            description,
                            max_applicants,
                            positions,
                            open_date,
                            end_date,
                            institutions,
                            sector_id,
                            department_id,
                            sections,
                            dispatchType,
                        }}
                        validationSchema={confimationValidationSchema}
                        onSubmit={(payload) => {
                            const Formdata = new FormData();
                            Formdata.append('attachmentFile', payload.file);
                            Formdata.append('pt_id', payload.pt_id);
                            Formdata.append('title', payload.title);
                            Formdata.append('description', payload.description);
                            Formdata.append('region', location);
                            Formdata.append(
                                'max_applicants',
                                payload.max_applicants,
                            );
                            Formdata.append('positions', payload.positions);
                            Formdata.append('open_date', payload.open_date);
                            Formdata.append('end_date', payload.end_date);
                            Formdata.append(
                                'institutions',
                                JSON.stringify(institutions),
                            );
                            Formdata.append(
                                'sections',
                                JSON.stringify(payload.sections),
                            );
                            Formdata.append('sector_id', payload.sector_id);
                            Formdata.append(
                                'department_id',
                                payload.department_id,
                            );
                            Formdata.append(
                                'type',
                                isEmpty(institutions) ? 'public' : 'private',
                            );
                            Formdata.append('dispatchType', dispatchType);

                            if (
                                dispatchType === 'save' ||
                                dispatchType === 'publish'
                            ) {
                                createPT(Formdata);
                            } else if (dispatchType === 'update') {
                                updatePT(Formdata);
                            }
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Box
                                    sx={{
                                        height: 450,
                                        position: 'relative',
                                        overflow: 'auto',
                                        p: 1,
                                    }}
                                >
                                    <CustomCard2>
                                        <Grid container spacing={2}>
                                            {/* ################ TITLE ################# */}
                                            <Grid item sm={12} xs={12}>
                                                <Box
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                        p: 1,
                                                        textAlign: 'center',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 25,
                                                            color: colors.info,
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                    >
                                                        {strHelper().strCapitalizeFirstChar(
                                                            title,
                                                        )}
                                                    </Typography>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    'bold',
                                                                opacity: 0.8,
                                                                color:
                                                                    colors.info,
                                                            }}
                                                        >
                                                            {
                                                                selectedSector[0]
                                                                    .name
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 14,
                                                                fontWeight:
                                                                    'bold',
                                                                opacity: 0.8,
                                                                color:
                                                                    colors.warning,
                                                            }}
                                                        >
                                                            {
                                                                selectedDepartment[0]
                                                                    .name
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 16,
                                                                opacity: 0.8,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                color:
                                                                    colors.primary,
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >
                                                            ({' '}
                                                            <PinDrop
                                                                sx={{
                                                                    fontSize: 18,
                                                                    mr: 1,
                                                                }}
                                                            />
                                                            {location} )
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ APPLICATION DATE ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            pb: 1,
                                                            color: colors.info,
                                                            borderBottom: `1px solid ${colors.bgColor3}`,
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                        }}
                                                    >
                                                        Application date
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            <strong>
                                                                <small>
                                                                    Start date
                                                                </small>
                                                            </strong>
                                                            <Typography>
                                                                {moment(
                                                                    open_date,
                                                                ).format('LL')}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            <strong>
                                                                <small>
                                                                    Deadline
                                                                    date
                                                                </small>
                                                            </strong>
                                                            <Typography>
                                                                {moment(
                                                                    end_date,
                                                                ).format('LL')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ APPLICANTS ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            pb: 1,
                                                            color: colors.info,
                                                            borderBottom: `1px solid ${colors.bgColor3}`,
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                                        }}
                                                    >
                                                        Application applicants
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            <strong>
                                                                <small>
                                                                    Max
                                                                    applicants
                                                                </small>
                                                            </strong>
                                                            <Typography>
                                                                {max_applicants ? (
                                                                    max_applicants
                                                                ) : (
                                                                    <AllInclusive color="warning" />
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                                opacity: 0.8,
                                                            }}
                                                        >
                                                            <strong>
                                                                <small>
                                                                    Positions
                                                                </small>
                                                            </strong>
                                                            <Typography>
                                                                {positions ? (
                                                                    positions
                                                                ) : (
                                                                    <AllInclusive color="warning" />
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ DESCRIPTION ################# */}
                                            <Grid item sm={12} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 1,
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        Description
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                {description}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ SECTORS ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 1,
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        Selected sector
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 16,
                                                                    opacity: 0.8,
                                                                }}
                                                            >
                                                                {
                                                                    selectedSector[0]
                                                                        .name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ DEPERTMENTS ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 1,
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        Selected department
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 16,
                                                                    opacity: 0.8,
                                                                }}
                                                            >
                                                                {
                                                                    selectedDepartment[0]
                                                                        .name
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ INSTITUTIONS ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 1,
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        Selected institutions
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                'width': '100%',
                                                                'mr': 1,
                                                                '& .chip': {
                                                                    border: `1px solid ${colors.bgColor5}`,
                                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                                                                    mr: 1,
                                                                },
                                                                'overflowX':
                                                                    'auto',
                                                                'display':
                                                                    'flex',
                                                                'pb': 2,
                                                            }}
                                                        >
                                                            {isEmpty(
                                                                institutions,
                                                            ) ? (
                                                                <Chip
                                                                    label="All institutions"
                                                                    size="small"
                                                                    className="chip"
                                                                />
                                                            ) : (
                                                                institutions?.map(
                                                                    (
                                                                        institution,
                                                                    ) => (
                                                                        <Chip
                                                                            key={
                                                                                institution.id
                                                                            }
                                                                            label={
                                                                                institution.title
                                                                            }
                                                                            size="small"
                                                                            className="chip"
                                                                        />
                                                                    ),
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ SECTIONS ################# */}
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 1,
                                                            color: colors.info,
                                                        }}
                                                    >
                                                        Selected sections
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                'width': '100%',
                                                                'mr': 1,
                                                                '& .chip': {
                                                                    border: `1px solid ${colors.bgColor5}`,
                                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                                                                    mr: 1,
                                                                },
                                                                'overflowX':
                                                                    'auto',
                                                                'display':
                                                                    'flex',
                                                                'pb': 2,
                                                            }}
                                                        >
                                                            {isEmpty(
                                                                sections,
                                                            ) ? (
                                                                <Chip
                                                                    label="All sections"
                                                                    size="small"
                                                                    className="chip"
                                                                />
                                                            ) : (
                                                                sections?.map(
                                                                    (
                                                                        section,
                                                                    ) => (
                                                                        <Chip
                                                                            key={
                                                                                section.id
                                                                            }
                                                                            label={
                                                                                section.title
                                                                            }
                                                                            size="small"
                                                                            className="chip"
                                                                        />
                                                                    ),
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ ATTACHMENT ################# */}
                                            <Grid item sm={12} xs={12}>
                                                <Box
                                                    sx={{
                                                        p: 1.5,
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mt: 3,
                                                        }}
                                                    >
                                                        <strong
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        >
                                                            Attachment (**
                                                            Optional **)
                                                        </strong>
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        Add an attachment which
                                                        will provide more
                                                        information about this
                                                        practical training.
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                ml: 1,
                                                            }}
                                                        >
                                                            <CustomFileInput2
                                                                label={
                                                                    'Attachment file'
                                                                }
                                                                name={
                                                                    'attachmentFile'
                                                                }
                                                                type={'file'}
                                                                accept="application/pdf"
                                                                formik={formik}
                                                                filePreview={
                                                                    filePreview
                                                                }
                                                                handleDocsPreview={
                                                                    handleDocsPreview
                                                                }
                                                                setFilePreview={
                                                                    setFilePreview
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {/* ################ ACTION BTN ################# */}
                                            <Grid item sm={12} xs={12}>
                                                <Box>
                                                    <Grid
                                                        container
                                                        padding={2}
                                                        spacing={5}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                    >
                                                        {isUpdate ? (
                                                            <Grid
                                                                item
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <CustomSubmitButton
                                                                    startIcon={
                                                                        <Update />
                                                                    }
                                                                    endIcon={
                                                                        <ArrowRightAltOutlined />
                                                                    }
                                                                    btnColor="info"
                                                                    loading={
                                                                        updateLoading
                                                                    }
                                                                    onClick={() => {
                                                                        let dispatchType =
                                                                            'update';
                                                                        setDispatchType(
                                                                            dispatchType,
                                                                        );
                                                                        formik.handleSubmit();
                                                                    }}
                                                                    sx={{
                                                                        py: 2,
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    Update
                                                                    practical
                                                                    training
                                                                </CustomSubmitButton>
                                                            </Grid>
                                                        ) : (
                                                            <>
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    xs={6}
                                                                >
                                                                    <CustomSubmitButton
                                                                        startIcon={
                                                                            <BookmarkBorderOutlined />
                                                                        }
                                                                        endIcon={
                                                                            <ArrowRightAltOutlined />
                                                                        }
                                                                        btnColor="info"
                                                                        loading={
                                                                            isLoading
                                                                        }
                                                                        onClick={() => {
                                                                            let dispatchType =
                                                                                'save';
                                                                            setDispatchType(
                                                                                dispatchType,
                                                                            );
                                                                            formik.handleSubmit();
                                                                        }}
                                                                        sx={{
                                                                            py: 2,
                                                                            fontSize: 16,
                                                                        }}
                                                                    >
                                                                        Save to
                                                                        draft
                                                                    </CustomSubmitButton>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    sm={6}
                                                                    xs={6}
                                                                >
                                                                    <CustomSubmitButton
                                                                        startIcon={
                                                                            <PublishOutlined />
                                                                        }
                                                                        endIcon={
                                                                            <ArrowRightAltOutlined />
                                                                        }
                                                                        btnColor="success"
                                                                        loading={
                                                                            isLoading
                                                                        }
                                                                        onClick={() => {
                                                                            let dispatchType =
                                                                                'publish';
                                                                            setDispatchType(
                                                                                dispatchType,
                                                                            );
                                                                            formik.handleSubmit();
                                                                        }}
                                                                        sx={{
                                                                            py: 2,
                                                                            fontSize: 16,
                                                                        }}
                                                                    >
                                                                        Save And
                                                                        Publish
                                                                    </CustomSubmitButton>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CustomCard2>
                                </Box>
                                {/* ################ ACTION BUTONS ###################### */}
                                <NextAndBackBtn
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleBack={handleBack}
                                    action={formik.handleSubmit}
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default Confimation;
