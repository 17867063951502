import React from 'react';
import { useSelector } from 'react-redux';
import LeftBar from './LeftBar';
import Banner from './Banner';
import MainContent from './MainContent';
import { Grid } from '@mui/material';
import { profileSelector } from '../../states/features/selectors';
import { useGetProfileQuery } from '../../api/profile/profileAPI';
import { isEmpty } from 'lodash';

// !################ MAIN FUNC ##################
const Profile = () => {
    // ############### Redux state ###############
    const { profile: user } = useSelector(profileSelector);

    // ############## RTK ######################
    // => Get profile
    const { refetch: refetchProfile } = useGetProfileQuery();

    return (
        <>
            {/* ################## bannner ####################### */}
            <Grid container>
                {!isEmpty(user) && (
                    <Banner user={user} refetchProfile={refetchProfile} />
                )}
            </Grid>

            {/* ################### main content ################ */}
            {!isEmpty(user) && (
                <Grid container justifyContent={'center'}>
                    <LeftBar user={user} refetchProfile={refetchProfile} />
                    <MainContent user={user} refetchProfile={refetchProfile} />
                </Grid>
            )}
        </>
    );
};
export default Profile;
