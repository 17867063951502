import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const InfoBoxCard = styled(Box)({
    'height': 90,
    'background': colors.primary,
    'position': 'relative',
    'display': 'flex',
    'borderRadius': 10,
    'padding': 20,
    // 'overflow': 'hidden',
    '.total': {
        'height': 40,
        'width': 40,
        'background': colors.secondary,
        'position': 'absolute',
        'top': -10,
        'right': 0,
        'borderTopLeftRadius': 10,
        'borderBottomLeftRadius': 10,
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        '.text': {
            fontSize: 14,
            fontWeight: 'bold',
        },
    },
    '.link': {
        'left': 0,
        'right': 0,
        'bottom': 0,
        'height': 35,
        'display': 'flex',
        'paddingLeft': 20,
        'paddingRight': 20,
        'position': 'absolute',
        'alignItems': 'center',
        'background': colors.secondary,
        'justifyContent': 'space-between',
        'borderBottomRightRadius': 10,
        'borderBottomLeftRadius': 10,
        '.icon': {
            transition: '.3s all ease-in-out',
        },
        ':hover': {
            '.icon': {
                marginRight: '-5px',
                transition: '.3s all ease-in-out',
            },
        },
    },
    '.content': {
        '.title': {
            fontSize: 14,
            color: colors.secondary,
            fontWeight: 'bold',
        },
    },
});

const InfoboxWidget = ({ name, url, urlName, total }) => {
    return (
        <>
            <InfoBoxCard className="neo-up">
                <Box className={'total'}>
                    <Typography className="text">{total}</Typography>
                </Box>
                <Box className="content">
                    <Typography className="title">{name}</Typography>
                </Box>
                <Link to={url}>
                    <Box className="link">
                        <Typography sx={{ fontSize: 14 }}>{urlName}</Typography>
                        <KeyboardArrowRight className="icon" />
                    </Box>
                </Link>
            </InfoBoxCard>
        </>
    );
};

export default InfoboxWidget;
