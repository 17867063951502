import { TextField } from '@mui/material';
import React from 'react';

const OccupationFilterForm = () => {
    return (
        <>
            <TextField fullWidth size="small" label="Search occupation" />
        </>
    );
};

export default OccupationFilterForm;
