import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { colors } from '../assets/utils/constants';
import { regionList } from '../assets/utils/regionList';
import { ListAlt } from '@mui/icons-material';

const RegionList = () => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100%',
            }}
        >
            <List component="nav" sx={{ py: 0 }}>
                <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                    sx={{
                        background:
                            selectedIndex === 0
                                ? `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`
                                : `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <ListItemIcon>
                        <ListAlt />
                    </ListItemIcon>
                    <ListItemText primary={'All'} />
                </ListItemButton>
                {regionList?.map((region) => (
                    <ListItemButton
                        key={region.id}
                        selected={selectedIndex === region.id}
                        onClick={(event) =>
                            handleListItemClick(event, region.id)
                        }
                        sx={{
                            background:
                                selectedIndex === region.id
                                    ? `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`
                                    : `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        <ListItemIcon>{region.id}</ListItemIcon>
                        <ListItemText primary={region.name} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default RegionList;
