// => Update array of obj
export const updateArrObj = (key, newData, arrData) => {
    let newArrData = arrData.map((currData) => {
        return currData[key] === newData[key] ? newData : currData;
    });
    return newArrData;
};

// => Remove obj from arr
export const removeArrObj = (key, val, arrData) => {
    let newArrData = arrData.filter((data) => data[key] != val);

    return newArrData;
};
