import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { colors } from '../../assets/utils/constants';

const OrganizationFilterForm = () => {
    const [organization, setOganization] = React.useState('');

    const handleChange = (event) => {
        setOganization(event.target.value);
    };

    const organizations = [
        {
            id: 1,
            name: "VETA Chang'ombe",
        },
        {
            id: 2,
            name: 'Wazawa Max company',
        },
        {
            id: 3,
            name: 'University of Dodoma',
        },
        {
            id: 4,
            name: 'J Fire intertaiment',
        },
        {
            id: 5,
            name: 'University of Dar es Salaam',
        },
    ];

    return (
        <FormControl fullWidth size="small">
            <InputLabel sx={{ borderRadius: 1 }}>
                Filter by organization
            </InputLabel>
            <Select
                value={organization}
                label="Regions"
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {organizations?.map((organization) => (
                    <MenuItem key={organization.id} value={organization.id}>
                        {organization.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default OrganizationFilterForm;
