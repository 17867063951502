import React, { useState } from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import TrainingApplicantDetails from '../../pages/practicalTraining/components/TrainingApplicantDetails';
import CustomDialog from '../Dialogs/CustomDialog';
import { isEmpty } from 'lodash';

const FieldApplicantWidget2 = ({ applicant }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############# Dialog ################ */}
            <CustomDialog
                head={'Practical training applicant details'}
                openDialog={openDialog}
                customWidth={'lg'}
                handleDialogClose={handleDialogClose}
            >
                <TrainingApplicantDetails
                    applicant={applicant}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <ListItem
                alignItems="flex-start"
                onClick={() => {
                    handleDialogOpen();
                }}
                sx={{
                    'transition': '.3s all ease-in-out',
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    'position': 'relative',
                    'mb': 1,
                    'borderRadius': 4,
                    'cursor': 'pointer',
                    'p': 0,
                    'pl': 1,
                    'py': 0.5,
                    'transition': '.3s all ease-in-out',
                    'border': `1px solid ${colors.bgColor3}`,
                    ':hover': {
                        transition: '.3s all ease-in-out',
                        border: `1px solid ${colors.bgColor5}`,
                    },
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        alt="Remy Sharp"
                        src={applicant.profile_picture}
                        sx={{
                            height: 60,
                            width: 60,
                            mt: -0.5,
                            mr: 1,
                            border: `3px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            {`${applicant.first_name} ${
                                !isEmpty(applicant?.middle_name)
                                    ? applicant?.middle_name
                                    : ''
                            } ${applicant.last_name}`}
                        </Typography>
                    }
                    secondary={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                }}
                                component="span"
                            >
                                {applicant.institution.organization_name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                                component="span"
                            >
                                {applicant?.appliedTraining?.title}
                            </Typography>
                        </Box>
                    }
                />
            </ListItem>
        </>
    );
};

export default FieldApplicantWidget2;
