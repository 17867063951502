import React, { useState } from 'react';
import { paginator } from '../../../../helpers/paginationHelper';
import NoContent from '../../../../components/NoContent';
import CountBadge from '../../../../components/CountBadge';
import { Box, Grid } from '@mui/material';
import CustomPagination from '../../../../components/paginations/CustomPagination';
import { isEmpty, size } from 'lodash';
import StudentWidget from '../../../../components/widgets/StudentWidget';

const EnrolledStudents = ({ students }) => {
    // ############## Comp state ##################
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedStudents = paginator(students, page, perPage);

    return (
        <div>
            <CustomPagination
                height={'39vh'}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                data={paginatedStudents}
                action={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <CountBadge
                            total={size(students)}
                            item={'Active PTs'}
                        />
                    </Box>
                }
            >
                {isEmpty(students) ? (
                    <>
                        <NoContent
                            height={250}
                            message={'No enrolled students'}
                        />
                    </>
                ) : (
                    <Grid container spacing={3}>
                        {paginatedStudents.data.map((student) => (
                            <Grid item sm={4} key={student.id}>
                                <StudentWidget student={student} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomPagination>
        </div>
    );
};

export default EnrolledStudents;
