import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    Avatar,
    Box,
    Chip,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';
import StudentMiniWidget from '../widgets/StudentMiniWidget';
import { LocationOn, RemoveRedEye } from '@mui/icons-material';

const columns = [
    { id: 's_n', label: 'S/N', minWidth: 0, align: 'center' },
    { id: 'student', label: 'Student', align: 'left' },
    { id: 'reg_id', label: 'Registration ID', minWidth: 15 },
    { id: 'section', label: 'Section' },
    {
        id: 'organization',
        label: 'Organization',
        align: 'left',
    },
    { id: 'start', label: 'Start', align: 'center' },
    { id: 'end', label: 'End', align: 'center' },
    {
        id: 'action',
        label: '',
    },
];

function createData(
    s_n,
    student,
    reg_id,
    section,
    organization,
    start,
    end,
    action,
) {
    return {
        s_n,
        student,
        reg_id,
        section,
        organization,
        start,
        end,
        action,
    };
}

const AllocatedStudentTable = ({ height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((index) => {
        return createData(
            <Typography sx={{ fontWeight: 'bold' }}>{index}</Typography>,
            <StudentMiniWidget />,
            'IMC/BIT/16/90798',
            'Bachelor of health system management',
            <Box>
                <ListItem alignItems="flex-start" sx={{ p: 0 }}>
                    <ListItemAvatar>
                        <Avatar
                            alt="Business Op"
                            src=""
                            variant="square"
                            sx={{ borderRadius: 2 }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        sx={{
                            '.MuiListItemText-primary': {
                                fontSize: 14,
                                fontWeight: 'bold',
                            },
                        }}
                        primaryTypographyProps={{
                            color: colors.primary,
                        }}
                        primary={"VETA Chang'ombe"}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    <LocationOn
                                        sx={{
                                            fontSize: 14,
                                            color: colors.secondary,
                                        }}
                                    />
                                    {'Dar-es-saalam'}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </Box>,
            <Typography
                sx={{ fontSize: 12, fontWeight: 'bold', color: colors.success }}
            >
                22Jan, 2023
            </Typography>,
            <Typography
                sx={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}
            >
                22Jan, 2023
            </Typography>,
            <Link to={`details`}>
                <Tooltip title="Student details" arrow>
                    <IconButton size="small">
                        <RemoveRedEye color="info" />
                    </IconButton>
                </Tooltip>
            </Link>,
        );
    });

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
            <TableContainer
                sx={{
                    height: height,
                    overflow: 'auto',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Table stickyHeader aria-label="sticky table" sx={{}}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        fontSize: 12,
                                        color: colors.primary,
                                        whiteSpace: 'nowrap',
                                    }}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                            )
                            .map((row) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.s_n}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    sx={{ py: 0 }}
                                                >
                                                    {column.format &&
                                                    typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                }}
            />
        </Paper>
    );
};

export default AllocatedStudentTable;
