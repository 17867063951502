import React, { useEffect, useState } from 'react';
import PageLayout from '../layouts/PageLayout';
import { Grid, Box } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import { jobApplicationsSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { filter } from 'lodash';
import NoContent from '../../components/NoContent';
import { isEmpty } from 'lodash';
import CountBadge from '../../components/CountBadge';
import { size } from 'lodash';
import ApplicantsSidebar from '../../components/sidebars/ApplicantsSidebar';
import JobApplicantTable from '../../components/Tables/JobApplicantTable';

const JobApplicantsByStatus = () => {
    // ############ Const #######################
    const { status } = useParams();

    // ############ Comp state ##################
    const [applicants, setApplicants] = useState([]);

    // ############ Redux state ##################
    const { companyJobApplications, individualJobApplications } = useSelector(
        jobApplicationsSelector,
    );
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
    ];

    // ############## FUNC ###################
    const getApplicantsByStatus = () => {
        let trainingApplicants = [];
        if (status === 'pending') {
            trainingApplicants = filter(
                allJobApplications,
                ({ application }) => application.status === 'pending',
            );
        }
        if (status === 'saved') {
            trainingApplicants = filter(
                allJobApplications,
                ({ application }) => application.isSaved === 1,
            );
        }
        if (status === 'shortlisted') {
            trainingApplicants = filter(
                allJobApplications,
                ({ application }) => application.status === 'shortlisted',
            );
        }
        if (status === 'accepted') {
            trainingApplicants = filter(
                allJobApplications,
                ({ application }) => application.status === 'accepted',
            );
        }
        if (status === 'declined') {
            trainingApplicants = filter(
                allJobApplications,
                ({ application }) => application.status === 'declined',
            );
        }
        setApplicants(trainingApplicants);
    };

    // ############### useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getApplicantsByStatus();
        }

        return () => {
            isSubscribed = false;
        };
    }, [allJobApplications]);

    return (
        <>
            <PageLayout
                head={`${status} job applications`}
                sidebar={<ApplicantsSidebar />}
            >
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CustomCard2
                            head={`All ${status} applications`}
                            padding={0}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(applicants)}
                                            item={
                                                status === 'pending' ||
                                                status === 'approved'
                                                    ? `${status}  applications`
                                                    : `${status}  students`
                                            }
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(applicants) ? (
                                <>
                                    <NoContent
                                        height={'72vh'}
                                        message={`No ${status}  applications`}
                                    />
                                </>
                            ) : (
                                <JobApplicantTable
                                    height={'65vh'}
                                    applicants={applicants}
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default JobApplicantsByStatus;
