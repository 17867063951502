import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import OTPInput, { ResendOTP } from 'otp-input-react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
    authSelector,
    settingsSelector,
} from '../../states/features/selectors';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import useRTK from '../../hooks/useRTK';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    useRequestORGEmailVerificationMutation,
    useRequestPasswordResetEmailMutation,
    useVerifyORGEmailMutation,
} from '../../api/auth/authAPI';
import { isEmpty } from 'lodash';
import { setEmailVerified } from '../../states/features/settings/settingsSlice';
import { ArrowRightAltOutlined, VerifiedUser } from '@mui/icons-material';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

// * -> verify schema
const otpValidationSchema = Yup.object({
    otp_code: Yup.number().required('Fill all field').min(6),
});

const RequestEmailVerification = () => {
    // ############## Comp State ################
    const navigate = useNavigate();
    const [OTP, setOTP] = useState('');

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Redux status ############
    const { registerAs, ORGEmail } = useSelector(settingsSelector);
    const { emailVerifiedFor } = useSelector(authSelector);
    const name = registerAs.name;

    // ############### RTK #####################
    // => Email verification Request
    const [
        requestORGEmailVerification,
        {
            isLoading: requestLoading,
            isSuccess: emailSuccess,
            isError: emailIsError,
            error: emailError,
            data: emailData,
        },
    ] = useRequestORGEmailVerificationMutation();

    // => Forgot password Request
    const [
        requestPasswordResetEmail,
        {
            isLoading: requestPasswordLoading,
            isSuccess: emailPasswordSuccess,
            isError: emailPasswordIsError,
            error: emailPasswordError,
            data: emailPasswordData,
        },
    ] = useRequestPasswordResetEmailMutation();

    // => Verify
    const [
        verifyORGEmail,
        { isLoading, isSuccess, isError, error, data: verifyData },
    ] = useVerifyORGEmailMutation();

    // ############### useEffect ################
    // => Request verification code
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (emailSuccess) {
                successNotify(getRTKSuccessMessage(emailData));
            }
            if (emailIsError) {
                errorNotify(getRTKErrorMessage(emailError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [emailSuccess, emailIsError, emailData, emailError]);

    // => Request password reset verification code
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (emailPasswordSuccess) {
                successNotify(getRTKSuccessMessage(emailPasswordData));
            }
            if (emailPasswordIsError) {
                errorNotify(getRTKErrorMessage(emailPasswordError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        emailPasswordSuccess,
        emailPasswordIsError,
        emailPasswordError,
        emailPasswordData,
    ]);

    // => RTK action
    const rtkActions = () => {
        dispatch(setEmailVerified(true));
        if (emailVerifiedFor === 'passwordReset') {
            navigate('/password-reset');
        } else {
            navigate('/register');
        }
    };
    RTK.useRTKResponse(isSuccess, isError, error, verifyData, rtkActions);
    // => Check ORG email
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isEmpty(ORGEmail)) {
                navigate('/email-verification');
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [ORGEmail, navigate]);

    return (
        <React.Fragment>
            <Grid item sm={10}>
                <FormBox>
                    <Typography className="head">Email verification</Typography>
                    <Typography className="sub-head">
                        Please Verify {name} email address
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        enableReinitialize
                        initialValues={{
                            otp_code: OTP,
                        }}
                        validationSchema={otpValidationSchema}
                        onSubmit={(payload) => {
                            verifyORGEmail(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                {requestLoading && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                        }}
                                    />
                                )}
                                {requestPasswordLoading && (
                                    <LinearProgress
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                        }}
                                    />
                                )}
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    sx={{ mt: 2 }}
                                >
                                    <Grid item sm={10}>
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                textAlign: 'center',
                                                color: colors.secondary,
                                            }}
                                        >
                                            An verification code has been sent
                                            to {name} email :{' '}
                                            <strong>{ORGEmail}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={9}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 2,
                                                marginBottom: 3,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <OTPInput
                                                value={OTP}
                                                onChange={setOTP}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                            />
                                            {isError ? (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 14,
                                                            color: 'red',
                                                            textAlign: 'center',
                                                            marginTop: 4,
                                                        }}
                                                    >
                                                        {getRTKErrorMessage(
                                                            error,
                                                        )}
                                                    </Typography>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Box>
                                        <ResendOTP
                                            style={{
                                                'color': colors.secondary,
                                                '& .button': {
                                                    backgroundColor:
                                                        colors.secondary,
                                                },
                                            }}
                                            onResendClick={() => {
                                                let payload = {
                                                    email: ORGEmail,
                                                };
                                                if (
                                                    emailVerifiedFor ===
                                                    'passwordReset'
                                                ) {
                                                    requestPasswordResetEmail(
                                                        payload,
                                                    );
                                                } else {
                                                    requestORGEmailVerification(
                                                        payload,
                                                    );
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={9}
                                        md={9}
                                        xs={9}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<VerifiedUser />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Verify sent email OTP
                                        </CustomSubmitButton>
                                    </Grid>
                                    {/* ######### Change email ######### */}
                                    <Grid
                                        item
                                        sm={9}
                                        md={9}
                                        xs={9}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/email-verification'}>
                                            <Typography className="forget">
                                                Change email ...
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default RequestEmailVerification;
