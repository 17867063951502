import React from "react";
import { Comment, GridOn, Subject } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Grid,
    Rating,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import PageLayout from "../layouts/PageLayout";
import useRTK from "../../hooks/useRTK";
import { jobsSelector } from "../../states/features/selectors";
import CustomCard from "../../components/cards/CustomCard";
import { isEmpty } from "lodash";
import CustomCard4 from "../../components/cards/CustomCard4";
import EmployerJobTopInfoWidget from "../../components/widgets/EmployerJobTopInfoWidget";
import JobDetailsSidebar2 from "../../components/sidebars/JobDetailsSidebar2";
import { useGetSingleJobEmployerQuery } from "../../api/job/jobAPI2";
import { setEmployerJobDetails } from "../../states/features/job/jobsSlice";
import JobDetailsSidebar3 from "../../components/sidebars/JobDetailsSidebar3";
import CustomDialog from "../../components/Dialogs/CustomDialog";
import RemarkAppliedJob from "./CRUD/RemarkAppliedJob";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        height: "100%",
                        overflow: "auto",
                        borderRadius: 2.5,
                        borderBottom: `15px solid ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

// !######################### MAIL FUNC #####################
const ForYouJobDetailsEmployer = () => {
    // ################## CONST #####################
    const RTK = useRTK();
    const theme = useTheme();
    const { job_id } = useParams();
    const dispatch = useDispatch();

    // ################## Comp state ################
    const [value, setValue] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);

    // ################## Redux state ##############
    const { employerJobDetails: jobDetails } = useSelector(jobsSelector);

    // ################## FUNC ################
    // -> Tab state
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    // => Remark dialog
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    // ############### RTK ####################
    const {
        isLoading,
        isSuccess,
        data: jobData,
    } = useGetSingleJobEmployerQuery(job_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setEmployerJobDetails(data));
    };
    RTK.useRTKFetch(isSuccess, jobData, rtkAction);

    const application = jobDetails?.application;

    return (
        <React.Fragment>
            <>
                <CustomDialog
                    isConfirm
                    noDropClose
                    openDialog={openDialog}
                    handleDialogClose={handleDialogClose}
                    head="Remark job (feedback)"
                >
                    <RemarkAppliedJob
                        job={jobDetails}
                        handleDialogClose={handleDialogClose}
                    />
                </CustomDialog>

                {/* ##################### CONTENTS ################## */}
                {isLoading ? (
                    <PageLoader />
                ) : (
                    <PageLayout
                        head={"Job details"}
                        sidebar={
                            !isEmpty(jobDetails) && (
                                <JobDetailsSidebar3 jobDetails={jobDetails} />
                            )
                        }
                    >
                        {/* ##################### TOP DETAILS ################## */}
                        {!isEmpty(jobDetails) && (
                            <EmployerJobTopInfoWidget jobDetails={jobDetails} />
                        )}

                        {/* ##################### MAIN CONTENT ################## */}
                        <CustomCard
                            head={
                                <AppBar
                                    position="static"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        position: "sticky",
                                        top: 64,
                                        zIndex: 999,
                                        ".MuiSvgIcon-root": {
                                            color: colors.primary,
                                        },
                                        boxShadow: `1px 1px 5px 0px ${colors.bgColor5}`,
                                    }}
                                >
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            sx={{
                                                color: colors.primary,
                                            }}
                                            label={
                                                <Typography
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: 13,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <GridOn
                                                        sx={{
                                                            fontSize: 16,
                                                            mr: 1,
                                                        }}
                                                    />
                                                    Job details
                                                </Typography>
                                            }
                                            {...a11yProps(0)}
                                        />
                                        {jobDetails.is_completed &&
                                            jobDetails.application && (
                                                <Tab
                                                    sx={{
                                                        color: colors.primary,
                                                    }}
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                fontSize: 13,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            <Comment
                                                                sx={{
                                                                    fontSize: 16,
                                                                    mr: 1,
                                                                }}
                                                            />
                                                            Job remark
                                                        </Typography>
                                                    }
                                                    {...a11yProps(1)}
                                                />
                                            )}
                                    </Tabs>
                                </AppBar>
                            }
                        >
                            {/* ######################## JOB DETAILS #################### */}
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                            >
                                <Grid container spacing={2} padding={2}>
                                    {/* #################### DESCRIPTION #################### */}
                                    <Grid item sm={12}>
                                        <CustomCard4>
                                            <Box sx={{ p: 2 }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 15,
                                                        fontWeight: "bold",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        pb: 0.5,
                                                        color: colors.warning,
                                                        opacity: 0.8,
                                                    }}
                                                >
                                                    <Subject
                                                        sx={{
                                                            mr: 1,
                                                            fontSize: 14,
                                                            color: colors.warning,
                                                        }}
                                                    />
                                                    Job description
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {jobDetails.description}
                                                </Typography>
                                            </Box>
                                        </CustomCard4>
                                    </Grid>

                                    {/* #################### RESPONSIBILITY #################### */}
                                    {/* <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobResponsibility
                                                job_id={job_id}
                                                jobResponsibilities={
                                                    jobResponsibilities
                                                }
                                            />
                                        </CustomCard4>
                                    </Grid> */}

                                    {/* #################### REQUIREMENT #################### */}
                                    {/* <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobRequirement
                                                job_id={job_id}
                                                jobRequirements={
                                                    jobRequirements
                                                }
                                            />
                                        </CustomCard4>
                                    </Grid> */}

                                    {/* #################### TAGS #################### */}
                                    {/* <Grid item sm={12}>
                                        <CustomCard4>
                                            <JobTags
                                                job_id={job_id}
                                                jobTags={jobTags}
                                            />
                                        </CustomCard4>
                                    </Grid> */}
                                </Grid>
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}
                                dir={theme.direction}
                            >
                                <Grid
                                    container
                                    padding={2}
                                    spacing={2}
                                    justifyContent={"center"}
                                >
                                    <Grid item sm={12}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: colors.info,
                                                }}
                                            >
                                                Employer remark
                                            </Typography>
                                            {!isEmpty(
                                                application?.employer_comment
                                            ) ? (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                        p: 1.5,
                                                    }}
                                                >
                                                    <Typography>
                                                        {
                                                            application?.employer_comment
                                                        }
                                                    </Typography>
                                                    <Rating
                                                        name="read-only"
                                                        value={
                                                            application?.employer_rate
                                                        }
                                                        readOnly
                                                    />
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                        p: 1.5,
                                                    }}
                                                >
                                                    <Typography>
                                                        Remark not updated{" "}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: colors.info,
                                                }}
                                            >
                                                Employee remark
                                            </Typography>
                                            <CustomCard4>
                                                {!isEmpty(
                                                    application?.employee_comment
                                                ) ? (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                            p: 1.5,
                                                        }}
                                                    >
                                                        <Typography>
                                                            {
                                                                application?.employee_comment
                                                            }
                                                        </Typography>
                                                        <Rating
                                                            name="read-only"
                                                            value={
                                                                application?.employee_rate
                                                            }
                                                            readOnly
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "space-between",
                                                            p: 1.5,
                                                        }}
                                                    >
                                                        <Typography>
                                                            Remark not updated{" "}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </CustomCard4>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        {jobDetails.is_completed &&
                                            !jobDetails.application
                                                ?.employer_rate && (
                                                <Box sx={{ mt: 2 }}>
                                                    <Box>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            color="info"
                                                            fullWidth
                                                            startIcon={
                                                                <Comment />
                                                            }
                                                            onClick={() => {
                                                                handleDialogOpen();
                                                            }}
                                                        >
                                                            Remark employer
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </CustomCard>
                    </PageLayout>
                )}
            </>
        </React.Fragment>
    );
};

export default ForYouJobDetailsEmployer;
