import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sectors: [],
    departments: [],
    sections: [],
};

const instituteProgramsSlice = createSlice({
    name: 'instituteProgramsSlice.js',
    initialState,
    reducers: {
        // => GET
        setSectors: (state, action) => {
            let sectors = action.payload;
            state.sectors = sectors;
        },
        setDepartments: (state, action) => {
            let departments = action.payload;
            state.departments = departments;
        },
        setSections: (state, action) => {
            let sections = action.payload;
            state.sections = sections;
        },
    },
});

export const {
    setSectors,
    setDepartments,
    setSections,
    addSector,
    addDepartment,
    addSection,
    removeSector,
    removeDepartment,
    removeSection,
} = instituteProgramsSlice.actions;

export default instituteProgramsSlice.reducer;
