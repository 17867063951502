import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { studentsEndpoints } from './studentsEndpoints';

export const studentsAPI = createApi({
    reducerPath: 'studentsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: orgBaseURL, prepareHeaders: headers }),
    tagTypes: ['students'],
    endpoints: (builder) => ({
        // => GET all
        getAllStudents: builder.query({
            query: () => `${studentsEndpoints.GET_ALL_STUDENTS}`,
            providesTags: ['students'],
        }),
        // => GET single
        getSingleStudent: builder.query({
            query: (payload) =>
                `${studentsEndpoints.GET_SINGLE_STUDENT}/${payload}`,
        }),
        // => CREATE student
        createStudent: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.CREATE_STUDENT}`,
                method: 'POST',
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(studentsAPI.util.invalidateTags(['students']));
            },
        }),
        // => IMPORT student
        importStudents: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.IMPORT_STUDENTS}`,
                method: 'POST',
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(studentsAPI.util.invalidateTags(['students']));
            },
        }),
        // => UPDATE student
        updateStudent: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.UPDATE_STUDENT}/${payload.student_id}`,
                method: 'PUT',
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(studentsAPI.util.invalidateTags(['students']));
            },
        }),
        // => DELETE student
        deleteStudent: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.DELETE_STUDENT}/${payload}`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(studentsAPI.util.invalidateTags(['students']));
            },
        }),
    }),
});

export const {
    useGetAllStudentsQuery,
    useGetSingleStudentQuery,
    useCreateStudentMutation,
    useUpdateStudentMutation,
    useDeleteStudentMutation,
    useImportStudentsMutation,

    // Lazy
    useLazyGetAllStudentsQuery,
} = studentsAPI;
