import React from 'react';
import { Subject } from '@mui/icons-material';
import { Box, Chip, styled, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { colors } from '../../assets/utils/constants';
import SmallCustomTextField from '../Forms/SmallCustomTextField';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import { isEmpty } from 'lodash';
import NoContent from '../NoContent';
import { useCreateTagMutation } from '../../api/job/jobAPI2';
import { setJobDetails } from '../../states/features/job/jobsSlice';
import useRTK from '../../hooks/useRTK';
import DeleteTag from './CRUD/DeleteTag';

// ########### STYLED COMPONENT #################
const JobTagCard = styled(Box)({
    'padding': 20,
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'pb': 0.5,
        'color': colors.warning,
        'opacity': 0.8,
        'marginBottom': 2,
        '.icon': {
            marginRight: 1,
            fontSize: 14,
            color: colors.warning,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
        },
    },
});

// ########## VALIDATION SCHEMA #################
const tagValidationSchema = Yup.object({
    tag_name: Yup.string().required('Tag is required field'),
});

// !################## MAIN FUNC ##################
const JobTags = ({ job_id, jobTags }) => {
    // ################### CONST ###################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################### RTK ###################
    const rtkActions = (data) => {
        dispatch(setJobDetails(data));
    };
    const [
        addTag,
        {
            isLoading: tagAddLoading,
            isSuccess: tagAddSuccess,
            isError: tagAddIsError,
            error: tagAddError,
            data: tagAddData,
        },
    ] = useCreateTagMutation();
    RTK.useRTKResponse(
        tagAddSuccess,
        tagAddIsError,
        tagAddError,
        tagAddData,
        rtkActions,
    );
    return (
        <>
            <JobTagCard>
                <Typography className="title">
                    <Subject className="icon" />
                    Job tags
                </Typography>
                {!isEmpty(jobTags) ? (
                    <>
                        {/* ######################### CONTENT #################### */}
                        <Box>
                            {jobTags?.map((tag) => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    variant="outlined"
                                    size="large"
                                    sx={{ m: 1, textTransform: 'capitalize' }}
                                    deleteIcon={
                                        <DeleteTag
                                            job_id={job_id}
                                            tag_name={tag}
                                        />
                                    }
                                    onDelete={() => {}}
                                />
                            ))}
                        </Box>
                    </>
                ) : (
                    <>
                        <NoContent height={100} message={'No tag added'} />
                    </>
                )}
                {/* ######################### FORM #################### */}
                <Box sx={{ mt: 2 }}>
                    <Formik
                        initialValues={{
                            tag_name: '',
                        }}
                        validationSchema={tagValidationSchema}
                        onSubmit={(payload, { resetForm }) => {
                            let newPayload = {
                                job_id,
                                ...payload,
                            };
                            addTag(newPayload);
                            resetForm();
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Box
                                    sx={{
                                        position: 'relative',
                                    }}
                                >
                                    <SmallCustomTextField
                                        label="Add job tag"
                                        name="tag_name"
                                        cuPadding={14}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            right: 3,
                                            bottom: 0,
                                        }}
                                    >
                                        <CustomSubmitButton
                                            color={'primary'}
                                            loading={tagAddLoading}
                                            onClick={formik.handleSubmit}
                                        >
                                            Add
                                        </CustomSubmitButton>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </JobTagCard>
        </>
    );
};

export default JobTags;
