import React from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';
import { RemoveRedEye } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';

const CustomInput = styled(TextField)({
    padding: 0,
});

const CustomFileInput2 = ({
    formik,
    setFilePreview,
    setFile,
    label,
    type,
    filePreview,
    handleDocsPreview,
    accept,
    ...props
}) => {
    const handleFileInputChange = (e) => {
        setFilePreview(URL.createObjectURL(e.target.files[0]));
        formik.setFieldValue('file', e.currentTarget.files[0]);
    };
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ borderRadius: 1.5, overflow: 'hidden' }}>
                <Box sx={{ display: 'flex' }}>
                    <CustomInput
                        error={meta.touched && meta.error ? true : false}
                        label={label}
                        variant="filled"
                        fullWidth
                        type={type}
                        size='small'
                        placeholder={field.label}
                        onChange={handleFileInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            accept: accept,
                        }}
                    />
                    {filePreview && (
                        <IconButton
                            onClick={() => {
                                handleDocsPreview();
                            }}
                            color="info"
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        >
                            <RemoveRedEye />
                        </IconButton>
                    )}
                </Box>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomFileInput2;
