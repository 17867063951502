import { Box, Typography } from '@mui/material';
import React from 'react';
import CustomCard2 from '../cards/CustomCard2';

const PTPostingSidebar = () => {
    return (
        <CustomCard2 head={'Quick tips'}>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {' '}
                    Field posting include four steps
                </Typography>
                <ol>
                    <li>General detals</li>
                    <li>Institution selection</li>
                    <li>Section selection</li>
                    <li>Confirmation and Posting</li>
                </ol>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    1: General detals
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    You have to provide general details and description of your
                    training post.
                </Typography>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    2: Institution selection
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    Is optional to select specificy institution. But you can
                    skip this step if you want to post for all available
                    institutions.
                </Typography>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    3: Section selection
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    Is option to select section but sector and departiment is
                    mandatory.
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    4: Confirmation and Posting
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    In this step you will confirm the details that you fill and
                    post field for approve.
                </Typography>
            </Box>
        </CustomCard2>
    );
};

export default PTPostingSidebar;
