import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Grid, Box } from '@mui/material';
import { Groups } from '@mui/icons-material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { useDispatch, useSelector } from 'react-redux';
import { trainingSeasonSelector } from '../../../states/features/selectors';
import useRTK from '../../../hooks/useRTK';
import { useGetSingleTrainingSeasonQuery } from '../../../api/students/trainingSeasonAPI';
import { setTrainingSeasonDetails } from '../../../states/features/students/trainingSeasonSlice';
import TrainingSeasonSidebar from '../../../components/sidebars/TrainingSeasonSidebar';
import { useParams } from 'react-router-dom';
import ContentLoader from '../../../components/ContentLoader';
import InnerDataFetchingError from '../../../components/InnerDataFetchingError';
import TrainingSeasonWidget from '../../../components/widgets/TrainingSeasonWidget';
import { isEmpty } from 'lodash';
import CustomTabs from '../../../components/tabs/CustomTabs';
import EnrolledStudents from './components/EnrolledStudents';

const TrainingSeasonDetails = () => {
    // ################## CONST #####################
    const RTK = useRTK();
    const { season_id } = useParams();
    const dispatch = useDispatch();

    // ############## Redux state ################
    const { trainingSeasonDetails } = useSelector(trainingSeasonSelector);
    const enrolled_studets = trainingSeasonDetails.enrolledStudets;

    // ############### RTK ####################
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: seasonData,
    } = useGetSingleTrainingSeasonQuery(season_id);
    // => Response
    const rtkAction = (data) => {
        dispatch(setTrainingSeasonDetails(data));
    };
    RTK.useRTKFetch(isSuccess, seasonData, rtkAction);

    // ############ Tabs ##################
    const SeasonTab = [
        {
            id: 1,
            title: 'Enrolled students',
            icon: <Groups />,
            tabsContent: (
                <EnrolledStudents
                    students={
                        !isEmpty(enrolled_studets) ? enrolled_studets : []
                    }
                />
            ),
        },
    ];

    return (
        <>
            <PageLayout
                head={'Practical training season details'}
                sidebar={<TrainingSeasonSidebar />}
            >
                {isLoading ? (
                    <Box sx={{ width: '100%' }}>
                        <CustomCard2 height={620}>
                            <ContentLoader />
                        </CustomCard2>
                    </Box>
                ) : isError ? (
                    <Box sx={{ width: '100%' }}>
                        <CustomCard2 height={620}>
                            <InnerDataFetchingError
                                height={550}
                                handleDataRefetch={refetch}
                            />
                        </CustomCard2>
                    </Box>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <CustomCard2
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <TrainingSeasonWidget
                                    season={trainingSeasonDetails}
                                />
                            </CustomCard2>
                        </Grid>
                        <Grid item sm={12}>
                            <CustomTabs
                                tabs={SeasonTab}
                                // height={'68.5vh'}
                                padding={`0 !important`}
                            />
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default TrainingSeasonDetails;
