import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { filter, isEmpty, size } from 'lodash';
import { useSelector } from 'react-redux';
import {
    PTSelector,
    trainingSeasonSelector,
} from '../../states/features/selectors';
import CustomCard2 from '../cards/CustomCard2';
import CurrPTSeason from '../widgets/CurrPTSeason';
import NoContent from '../NoContent';

const TrainingStudentsSidebar = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);
    const { trainingSeasons, currTrainingSeason } = useSelector(
        trainingSeasonSelector,
    );

    // ############## FUNC ###################
    // => Curr training season
    const getCurrTrainingSeason = () => {
        let currTraining = filter(
            trainingSeasons,
            ({ title }) => title === currTrainingSeason,
        );
        return currTraining[0];
    };

    // => Applicant by status
    const getApplicantsByStatus = () => {
        let pendingApplicants = [];
        let approvedApplicants = [];
        let onTrainingApplicants = [];
        let completedApplicants = [];
        // => Pending
        pendingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) => appliedTraining.status === 'pending',
        );
        // => Approved
        approvedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.status === 'approved' &&
                appliedTraining.onTrainingStatus === 'pending',
        );
        // => OnTraining
        onTrainingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'on-training',
        );
        // => Completed
        completedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'completed',
        );
        return {
            pendingApplicants,
            approvedApplicants,
            onTrainingApplicants,
            completedApplicants,
        };
    };

    return (
        <div>
            <>
                <Box sx={{ mb: 3 }}>
                    <CustomCard2 head={'Current practical training season'}>
                        {isEmpty(getCurrTrainingSeason()) ? (
                            <NoContent
                                message={'Training season not added'}
                                height={150}
                            />
                        ) : (
                            <CurrPTSeason season={getCurrTrainingSeason()} />
                        )}
                    </CustomCard2>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        px: 1,
                        pb: 2,
                        mb: 3,
                        borderRadius: 2,
                        // boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                        border: `1px dotted ${colors.info}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            mb: 2,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                opacity: 0.8,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            Practical training student stats
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            height: 150,
                            width: 150,
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: `5px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                color: colors.info,
                                mt: -2,
                            }}
                        >
                            {size(allPracticalTrainingApplicants)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                opacity: 0.8,
                                mt: 0,
                                textAlign: 'center',
                            }}
                        >
                            Training
                            <br />
                            student(s)
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.info}`,
                                    }}
                                >
                                    {size(
                                        getApplicantsByStatus()
                                            .onTrainingApplicants,
                                    )}{' '}
                                    <sup
                                        style={{
                                            color: colors.info,
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        On-training
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.success,
                                        borderBottom: `3px solid ${colors.success}`,
                                    }}
                                >
                                    {size(
                                        getApplicantsByStatus()
                                            .completedApplicants,
                                    )}{' '}
                                    <sup
                                        style={{
                                            color: colors.success,
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Completed
                                    </sup>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        </div>
    );
};

export default TrainingStudentsSidebar;
