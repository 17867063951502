import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomCard2 from "../../components/cards/CustomCard2";
import DepartmentTable from "../../components/Tables/DepartmentTable";
import ProgramsTable from "../../components/Tables/ProgramsTable";
import SectorsTable from "../../components/Tables/SectorsTable";
import PageLayout from "../layouts/PageLayout";
import CountBadge from "../../components/CountBadge copy";
import CustomDialog from "../../components/Dialogs/CustomDialog";
import AddSectors from "./CRUD/AddSectors";
import AddDepartments from "./CRUD/AddDepartments";
import AddSections from "./CRUD/AddSections";
import usePT from "../../hooks/usePT";
import { instituteProgramsSelector } from "../../states/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import NoContent from "../../components/NoContent";
import { isEmpty, size } from "lodash";
import {
    useGetDepartmentsQuery,
    useGetSectionsQuery,
    useGetSectorsQuery,
    useLazyGetDepartmentsQuery,
    useLazyGetSectionsQuery,
    useLazyGetSectorsQuery,
} from "../../api/students/instituteProgramsAPI";
import useRTK from "../../hooks/useRTK";
import {
    setDepartments,
    setSections,
    setSectors,
} from "../../states/features/students/instituteProgramsSlice.js";
import ContentLoader from "../../components/ContentLoader";

const InstitutePrograms = () => {
    // ############ CONST ################
    const ptHook = usePT();
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################ Comp state ############
    const [crudType, setCRUDType] = useState("");
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // ################ Redux state ###########
    const { sectors, departments, sections } = useSelector(
        instituteProgramsSelector
    );

    // ############# RTK #################
    ptHook.useMetaDataDispatch();
    const [
        getSectors,
        {
            isLoading: sectorLoading,
            isSuccess: sectorSuccess,
            isError: sectorIsError,
            error: sectorError,
            data: sectorData,
        },
    ] = useLazyGetSectorsQuery();
    const [
        getDepartments,
        {
            isLoading: departmentLoading,
            isSuccess: departmentSuccess,
            isError: departmentIsError,
            error: departmentError,
            data: departmentData,
        },
    ] = useLazyGetDepartmentsQuery();
    const [
        getSections,
        {
            isLoading: sectionLoading,
            isSuccess: sectionSuccess,
            isError: sectionIsError,
            error: sectionError,
            data: sectionData,
        },
    ] = useLazyGetSectionsQuery();
    // ############# Dispatch IP ##########
    // => Sector
    const rtkSectorAction = (data) => {
        dispatch(setSectors(data));
    };
    RTK.useRTKFetch(sectorSuccess, sectorData, rtkSectorAction);
    // => Department
    const rtkDepartmentAction = (data) => {
        dispatch(setDepartments(data));
    };
    RTK.useRTKFetch(departmentSuccess, departmentData, rtkDepartmentAction);
    // => Sections
    const rtkSectionsAction = (data) => {
        dispatch(setSections(data));
    };
    RTK.useRTKFetch(sectionSuccess, sectionData, rtkSectionsAction);

    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // ############## useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getSectors();
            getDepartments();
            getSections();
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <PageLayout head={"Institute programs"} noSidebar>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose
                customWidth={"md"}
            >
                {crudType === "sector" ? (
                    <AddSectors
                        addedSectors={sectors}
                        handleDialogClose={handleDialogClose}
                    />
                ) : crudType === "department" ? (
                    <AddDepartments
                        addedSectors={sectors}
                        addedDepartments={departments}
                        handleDialogClose={handleDialogClose}
                    />
                ) : crudType === "section" ? (
                    <AddSections
                        addedSections={sections}
                        addedDepartments={departments}
                        handleDialogClose={handleDialogClose}
                    />
                ) : (
                    ""
                )}
            </CustomDialog>

            <Grid container>
                <Grid item sm={4}>
                    <CustomCard2
                        head={"Institute Sectors"}
                        height={"72.5vh"}
                        action={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CountBadge
                                    total={size(sectors)}
                                    item={"Sectors"}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    size="small"
                                    className="app-btn"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        let dialogHead = "Add sectors";
                                        let crudType = "sector";
                                        handleDialogyOpen(crudType, dialogHead);
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                        }
                    >
                        {sectorLoading ? (
                            <ContentLoader />
                        ) : !isEmpty(sectors) ? (
                            <SectorsTable sectors={sectors} />
                        ) : (
                            <NoContent
                                height={"67vh"}
                                message={"No sector added"}
                            />
                        )}
                    </CustomCard2>
                </Grid>
                <Grid item sm={4}>
                    <CustomCard2
                        head={"Institute Departments"}
                        height={"72.5vh"}
                        action={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CountBadge
                                    total={size(departments)}
                                    item={"Depart"}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    size="small"
                                    className="app-btn"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        let dialogHead = "Add departments";
                                        let crudType = "department";
                                        handleDialogyOpen(crudType, dialogHead);
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                        }
                    >
                        {departmentLoading ? (
                            <ContentLoader />
                        ) : !isEmpty(departments) ? (
                            <DepartmentTable departments={departments} />
                        ) : (
                            <NoContent
                                height={"67vh"}
                                message={"No department added"}
                            />
                        )}
                    </CustomCard2>
                </Grid>
                <Grid item sm={4}>
                    <CustomCard2
                        head={"Programs"}
                        height={"72.5vh"}
                        action={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CountBadge
                                    total={size(sections)}
                                    item={"Programs"}
                                />
                                <Button
                                    variant="contained"
                                    startIcon={<Add />}
                                    size="small"
                                    className="app-btn"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        let dialogHead = "Add sections";
                                        let crudType = "section";
                                        handleDialogyOpen(crudType, dialogHead);
                                    }}
                                >
                                    Add
                                </Button>
                            </Box>
                        }
                    >
                        {sectionLoading ? (
                            <ContentLoader />
                        ) : !isEmpty(sections) ? (
                            <ProgramsTable sections={sections} />
                        ) : (
                            <NoContent
                                height={"67vh"}
                                message={"No section added"}
                            />
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </PageLayout>
    );
};

export default InstitutePrograms;
