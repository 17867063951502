import React, { useState } from 'react';
import {
    AppBar,
    Box,
    Grid,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from '@mui/material';
import RequredDetails from './RequredDetails';
import ContactsVerifications from './ContactsVerifications';
import ORGDescription from './ORGDescription';
import ChangePassword from './ChangePassword';
import ORGDocuments from './ORGDocuments';
import { colors } from '../../assets/utils/constants';
import {
    DocumentScannerOutlined,
    ManageAccounts,
    Security,
} from '@mui/icons-material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        'id': `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// !############## MAIN FUNC ################
const MainContent = ({ user, refetchProfile }) => {
    const [value, setValue] = useState(0);
    // -> Tabs change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const match = useMediaQuery('(min-width: 768px)');
    return (
        <>
            <Grid
                item
                md={9}
                sm={12}
                xs={12}
                sx={{
                    position: 'relative',
                }}
            >
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Box sx={{ maxWidth: '100vw' }}>
                            {/* ############ TABS NAV ############ */}
                            <AppBar sx={{ position: 'sticky', top: 64 }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant={match ? 'fullWidth' : 'scrollable'}
                                    scrollButtons
                                >
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    textTransform: 'uppercase',
                                                    color: colors.secondary,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <ManageAccounts
                                                    sx={{ mr: 1 }}
                                                />
                                                General settings
                                            </Typography>
                                        }
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    textTransform: 'uppercase',
                                                    color: colors.secondary,
                                                    display: 'flex',
                                                }}
                                            >
                                                <DocumentScannerOutlined
                                                    sx={{ mr: 1 }}
                                                />
                                                Organization documents
                                            </Typography>
                                        }
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    textTransform: 'uppercase',
                                                    color: colors.secondary,
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Security sx={{ mr: 1 }} />
                                                Security settings
                                            </Typography>
                                        }
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                            </AppBar>

                            {/* ############ TABS CONTENT (0) General ############### */}
                            <TabPanel value={value} index={0}>
                                <Grid
                                    container
                                    justifyContent={'space-between'}
                                    padding={1}
                                    rowGap={4}
                                    sx={{
                                        overflowY: 'auto',
                                        position: 'relative',
                                        // height: 530,
                                    }}
                                >
                                    {/* ######### Verification request ############ */}
                                    <RequredDetails
                                        user={user}
                                        refetchProfile={refetchProfile}
                                    />

                                    {/* ######### Contacts verification ############ */}
                                    <ContactsVerifications
                                        user={user}
                                        refetchProfile={refetchProfile}
                                    />

                                    {/* ######### Contacts verification ############ */}
                                    <ORGDescription
                                        user={user}
                                        refetchProfile={refetchProfile}
                                    />
                                </Grid>
                            </TabPanel>

                            {/* ############ TABS CONTENT (1) Documents ############### */}
                            <TabPanel value={value} index={1}>
                                <Grid
                                    container
                                    justifyContent={'space-between'}
                                    padding={1}
                                    rowGap={4}
                                    sx={{
                                        overflowY: 'auto',
                                        position: 'relative',
                                        // height: 530,
                                    }}
                                >
                                    <ORGDocuments
                                        refetchProfile={refetchProfile}
                                    />
                                </Grid>
                            </TabPanel>

                            {/* ############ TABS CONTENT (2) Security ############### */}
                            <TabPanel value={value} index={2}>
                                <Grid
                                    container
                                    justifyContent={'space-around'}
                                    padding={1}
                                    rowGap={4}
                                    sx={{
                                        overflowY: 'auto',
                                        position: 'relative',
                                        // height: 530,
                                    }}
                                >
                                    <ChangePassword />
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MainContent;
