import { Box, Chip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import { useSelector } from 'react-redux';
import { instituteProgramsSelector } from '../../states/features/selectors';
import { size } from 'lodash';

const InstituteProgramWidget = () => {
    // ############### Redux state ####################
    const { sectors, departments, sections } = useSelector(
        instituteProgramsSelector,
    );

    return (
        <>
            <Link to={'/students/institute-programs'}>
                <Box
                    sx={{
                        'background': `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                        'height': 90,
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'borderRadius': 2,
                        'overflow': 'hidden',
                        'boxShadow': `1px 1px 5px 0px ${colors.bgColor5}`,
                        'transition': `.3s all ease-in-out`,
                        ':hover': {
                            transition: `.3s all ease-in-out`,
                        },
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            height: 90,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            px: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 16,
                                color: colors.secondary,
                                fontWeight: 'bold',
                            }}
                        >
                            Institute Programs
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flexGrow: 1,
                            height: 90,
                            px: 1,
                        }}
                    >
                        <Typography
                            component={'div'}
                            sx={{
                                pl: 1,
                                mb: 0.4,
                                fontSize: 14,
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderLeft: `4px solid ${colors.primary}`,
                            }}
                        >
                            Sectors
                            <Chip
                                size="small"
                                label={size(sectors)}
                                sx={{ ml: 1 }}
                                color="primary"
                            />
                        </Typography>
                        <Typography
                            component={'div'}
                            sx={{
                                pl: 1,
                                mb: 0.4,
                                fontSize: 14,
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderLeft: `4px solid ${colors.success}`,
                            }}
                        >
                            Department
                            <Chip
                                size="small"
                                label={size(departments)}
                                sx={{ ml: 1 }}
                                color="success"
                            />
                        </Typography>
                        <Typography
                            component={'div'}
                            sx={{
                                pl: 1,
                                mb: 0.4,
                                fontSize: 14,
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderLeft: `4px solid ${colors.info}`,
                            }}
                        >
                            Programs
                            <Chip
                                size="small"
                                label={size(sections)}
                                sx={{ ml: 1 }}
                                color="info"
                            />
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default InstituteProgramWidget;
