import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

// component imports
import App from './App';

// custom sass files and css
import './assets/sass/app.scss';
import { Provider } from 'react-redux';
import { store } from './states/stores/rootStore';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
);
