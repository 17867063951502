import React from "react";
import ReportLayout from "../../layouts/ReportLayout";
import {
    CuTableHeader,
    CuTableRow,
    CuTableSN,
    CuTableColumn,
    CuTableHead,
    CuTableBody,
    CuReportTable,
} from "../../../components/Tables/ReportTableComp";
import { isEmpty } from "lodash";
import moment from "moment";
import ReportNoData from "../../../components/ReportNoData";
import { numFormater } from "../../../helpers/numFormater";

const TransactionReport = ({ transactions }) => {
    return (
        <ReportLayout
            head={"Wazawa Max organization report"}
            orientation={"landscape"}
        >
            {isEmpty(transactions) ? (
                <>
                    <ReportNoData />
                </>
            ) : (
                <CuReportTable>
                    <CuTableHeader>
                        <CuTableRow>
                            <CuTableSN value={"S/N"} />
                            <CuTableHead
                                value={"Payment Ref"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Posted job ID"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Payment method"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Amount"}
                                style={{
                                    width: "300px",
                                    whiteSpace: "nowrap",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"Date payed"}
                                style={{
                                    width: "420px",
                                    textAlign: "center",
                                }}
                            />
                            <CuTableHead
                                value={"status"}
                                style={{
                                    textAlign: "center",
                                    width: "300px",
                                }}
                            />
                        </CuTableRow>
                    </CuTableHeader>
                    {/* Table body */}
                    <CuTableBody>
                        {transactions.map((transaction, index) => (
                            <CuTableRow key={index}>
                                <CuTableSN value={++index} />
                                <CuTableColumn
                                    value={
                                        transaction.transaction.reference_number
                                    }
                                    style={{
                                        width: "420px",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={transaction.job.job_number}
                                    style={{
                                        width: "420px",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={
                                        transaction.transaction.payment_method
                                    }
                                    style={{
                                        width: "420px",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={numFormater(
                                        transaction.transaction.amount
                                    )}
                                    style={{
                                        width: "300px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={moment(
                                        transaction.transaction.updated_at
                                    ).format("LL")}
                                    style={{
                                        width: "420px",
                                        textAlign: "center",
                                    }}
                                />
                                <CuTableColumn
                                    value={transaction.transaction.status}
                                    style={{
                                        width: "300px",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                        color:
                                            transaction.transaction.status ===
                                            "not_paid"
                                                ? "brown"
                                                : transaction.transaction
                                                      .status === "pending"
                                                ? "brown"
                                                : transaction.transaction
                                                      .status === "complete" ||
                                                  transaction.transaction
                                                      .status === "success"
                                                ? "green"
                                                : transaction.transaction
                                                      .status === "failed" ||
                                                  transaction.transaction
                                                      .status === "failure"
                                                ? "red"
                                                : "black",
                                    }}
                                />
                            </CuTableRow>
                        ))}
                    </CuTableBody>
                </CuReportTable>
            )}
        </ReportLayout>
    );
};

export default TransactionReport;
