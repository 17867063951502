import React from 'react';
import ReportIcon from '@mui/icons-material/Report';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout';

// !###################### MAIN FUNC ########################
function ErrorPage404({ bgColor }) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <PageLayout noSidebar>
                <Box
                    sx={{
                        height: '89vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box>
                        <Box
                            className="warning-box"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Box>
                                <Typography>
                                    <ReportIcon sx={{ fontSize: 55, mr: 2 }} />
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: -1,
                                        fontSize: 22,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    404 content not found
                                </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <Typography>
                                    Ops! Sorry the content you try to access not
                                    found!
                                </Typography>
                                <Button
                                    startIcon={<ArrowLeft />}
                                    variant="outlined"
                                    color="warning"
                                    size="small"
                                    sx={{ mt: 2 }}
                                    onClick={() => navigate(-1)}
                                >
                                    Go to back
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PageLayout>
        </React.Fragment>
    );
}

export default ErrorPage404;
