import React from 'react';
import { SchoolOutlined, Subject } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import JobTableCard from '../../../components/cards/JobTableCard';
import JobResponsibilities from '../../../components/jobComponents/JobResponsibilities';
import JobRequirements from '../../../components/jobComponents/JobRequirements';
import JobTags from '../../../components/jobComponents/JobTags';

const JobGeneralDetails = ({
    jobID,
    jobDetails,
    jobResponsibilities,
    jobRequirements,
    jobTags,
}) => {
    const job_id = jobID;

    return (
        <div>
            <Grid container spacing={2} padding={2}>
                {/* #################### DESCRIPTION #################### */}
                <Grid item sm={12}>
                    <JobTableCard>
                        <Box sx={{ p: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    pb: 0.5,
                                    color: colors.warning,
                                    opacity: 0.8,
                                }}
                            >
                                <Subject
                                    sx={{
                                        mr: 1,
                                        fontSize: 14,
                                        color: colors.warning,
                                    }}
                                />
                                Job description
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                }}
                            >
                                {jobDetails.description}
                            </Typography>
                        </Box>
                    </JobTableCard>
                </Grid>

                {/* #################### QUALIFICATION #################### */}
                {jobDetails.isFor === 'individual' && (
                    <Grid item sm={12}>
                        <JobTableCard>
                            <Box sx={{ p: 2 }}>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        alignItems: 'center',
                                        pb: 0.5,
                                        color: colors.warning,
                                        opacity: 0.8,
                                    }}
                                >
                                    <SchoolOutlined
                                        sx={{
                                            mr: 1,
                                            fontSize: 14,
                                            color: colors.warning,
                                        }}
                                    />
                                    Job qualification
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                border: `1px dotted ${colors.bgColor5}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                    color: colors.info,
                                                }}
                                            >
                                                Education award qualification
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {
                                                            jobDetails
                                                                .educationLevel
                                                                .award.award
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                border: `1px dotted ${colors.bgColor5}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                    color: colors.info,
                                                }}
                                            >
                                                Education level qualification
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {
                                                            jobDetails
                                                                .educationLevel
                                                                .qualification
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                border: `1px dotted ${colors.bgColor5}`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                    color: colors.info,
                                                }}
                                            >
                                                Experience level qualification
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 16,
                                                        }}
                                                    >
                                                        {
                                                            jobDetails
                                                                .experienceLevel
                                                                .experience
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </JobTableCard>
                    </Grid>
                )}

                {/* #################### RESPONSIBILITY #################### */}
                <Grid item sm={12}>
                    <JobTableCard>
                        <JobResponsibilities
                            job_id={job_id}
                            jobResponsibilities={jobResponsibilities}
                        />
                    </JobTableCard>
                </Grid>
                {/* #################### REQUIREMENT #################### */}
                <Grid item sm={12}>
                    <JobTableCard>
                        <JobRequirements
                            job_id={job_id}
                            jobRequirements={jobRequirements}
                        />
                    </JobTableCard>
                </Grid>

                {/* #################### TAGS #################### */}
                <Grid item sm={12}>
                    <JobTableCard>
                        <JobTags job_id={job_id} jobTags={jobTags} />
                    </JobTableCard>
                </Grid>
            </Grid>
        </div>
    );
};

export default JobGeneralDetails;
