import React from 'react';
import { Select } from '@mui/material';
import { Box, FormControl, InputLabel } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

const CustomSelectInput = ({ formik, label, color, children, ...props }) => {
    const [field, meta] = useField(props);
    const [selectValue, setSelectValue] = React.useState(
        field.value ? field.value : '',
    );

    const handleChange = (e) => {
        setSelectValue(e.target.value);
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, e.target.value);
    };

    return (
        <>
            <Box sx={{ borderRadius: 1.5, overflow: "hidden" }}>
                <FormControl variant="filled" sx={{ minWidth: "100%" }}>
                    <InputLabel
                        id={label}
                        sx={{
                            color: `${color ? color : "none"} !important`,
                        }}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        className={`${meta.touched && meta.error && "error"}`}
                        {...field}
                        {...props}
                        minRows={3}
                        placeholder={label}
                        value={selectValue}
                        size='small'
                        onChange={handleChange}
                        sx={{ color: `${color ? color : "#eee"}` }}
                    >
                        {children}
                    </Select>
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomSelectInput;
