import React from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';

const StudentFilters = () => {
    const [occupation, setOccupation] = React.useState('');

    const handleChange = (event) => {
        setOccupation(event.target.value);
    };

    const availableSections = [
        { id: 1, title: 'Computer science' },
        { id: 2, title: 'Information technology' },
        { id: 3, title: 'Computer engeneering' },
        { id: 4, title: 'Infomation technology and communication' },
        { id: 5, title: 'Telecomunication Engeenering' },
    ];

    return (
        <>
            <Box
                sx={{
                    width: 450,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                    borderRadius: 2,
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            py: 1,
                            px: 2,
                            borderRadius: 2,
                            fontWeight: 'bold',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                        }}
                    >
                        80 <sup>Students</sup>
                    </Typography>
                </Box>
                <Box>
                    <TextField sx={{width: 150}} size="small" label="Registration ID" />
                </Box>
                <Box>
                    <FormControl sx={{width: 150}} size="small">
                        <InputLabel id="demo-select-small">Occupation</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={occupation}
                            label="occupation"
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {availableSections?.map((occupation) => (
                                <MenuItem
                                    value={occupation.id}
                                    key={occupation.id}
                                >
                                    {occupation.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </>
    );
};

export default StudentFilters;
