import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { colors } from "../../assets/utils/constants";

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingLeft: "50px",
        paddingRight: "50px",
        paddingTop: "50px",
        paddingBottom: "59px",
        backgroundColor: colors.bgColor2,
    },
    section: {},
    image: {
        height: "120px",
        width: "180px",
    },
    table: {
        display: "table",
        width: "auto",
    },
    tr: {
        display: "flex",
        flexDirection: "row",
        borderTop: `1px solid #4d4b4b`,
        borderBottom: `1px solid #4d4b4b`,
    },
    td: {
        paddingVertical: `6px`,
        paddingHorizontal: `8px`,
        flexGrow: 1,
        width: "100%",
        borderRight: `1px solid #4d4b4b`,
    },
    thead: {
        fontSize: `10px`,
        textTransform: "uppercase",
    },
    tText: { fontSize: `12px` },
    tSn: {
        borderLeft: `1px solid #4d4b4b`,
        borderRight: `1px solid #4d4b4b`,
        width: "90px !important",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export const CuReportTable = ({ children }) => {
    return (
        <>
            <View style={styles.table}>{children}</View>
        </>
    );
};

export const CuTableHeader = ({ children }) => {
    return (
        <>
            <View style={{ backgroundColor: colors.secondary }}>
                {children}
            </View>
        </>
    );
};

export const CuTableBody = ({ children }) => {
    return (
        <>
            <View>{children}</View>
        </>
    );
};

export const CuTableHead = ({ value, style }) => {
    return (
        <>
            <View
                style={{
                    ...styles.td,
                    ...style,
                }}
            >
                <Text style={styles.thead}>{value}</Text>
            </View>
        </>
    );
};

export const CuTableColumn = ({ value, subValue, style }) => {
    return (
        <>
            <View style={{ ...styles.td, ...style }}>
                <Text style={styles.tText}>{value}</Text>
                {subValue && (
                    <Text style={{ fontSize: `8px`, marginTop: "3px" }}>
                        {subValue}
                    </Text>
                )}
            </View>
        </>
    );
};

export const CuTableRow = ({ children }) => {
    return (
        <>
            <View style={styles.tr} wrap={false}>
                {children}
            </View>
        </>
    );
};

export const CuTableSN = ({ value }) => {
    return (
        <>
            <View style={styles.tSn}>
                <Text style={styles.thead}>{value}</Text>
            </View>
        </>
    );
};
