// => GET contacts
const GET_CONTACTS = `/help-center/contacts`;

// => Get Social nets
const GET_SOCIAL_NET = `/help-center/social-networks/active`;

// => Get Social nets
const SEND_US_EMAIL = `/help-center/send-email`;

export const helpCenterEndpoints = {
    GET_CONTACTS,
    GET_SOCIAL_NET,
    SEND_US_EMAIL,
};
