import React from 'react';
import { LocationOn } from '@mui/icons-material';
import {
    Avatar,
    Box,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';

const OnFieldStudentWidget = ({ student }) => {
    return (
        <>
            <Link to={`/students/${student.id}`}>
                <Box
                    sx={{
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        'px': 0.5,
                        'mb': 0.5,
                        'borderRadius': 2,
                        'transition': `.3s all ease-in-out`,
                        'border': `1px dotted ${colors.bgColor5}`,
                        ':hover': {
                            transition: `.3s all ease-in-out`,
                            border: `1px dotted ${colors.warning}`,
                        },
                    }}
                >
                    <ListItem alignItems="flex-start" sx={{ p: 0.5 }}>
                        <ListItemAvatar
                            sx={{
                                mb: 0.5,
                                borderRadius: 2,
                                border: `2px dotted ${colors.warning}`,
                            }}
                        >
                            <Avatar
                                alt="PP"
                                src={student.profile_picture}
                                variant="square"
                                sx={{
                                    borderRadius: 2,
                                    height: 65,
                                    width: 65,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                },
                            }}
                            disableTypography
                            primaryTypographyProps={{
                                color: colors.primary,
                                ml: 1.5,
                            }}
                            primary={
                                <Typography
                                    sx={{
                                        color: colors.primary,
                                        ml: 1.5,
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >{`${student.first_name} ${student.last_name}`}</Typography>
                            }
                            secondaryTypographyProps={{ ml: 1.5 }}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 13,
                                            ml: 1.5,
                                        }}
                                        component="span"
                                    >
                                        {student?.section?.section?.name}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            ml: 1.5,
                                        }}
                                    >
                                        <LocationOn
                                            sx={{
                                                fontSize: 14,
                                                ml: -0.3,
                                            }}
                                        />
                                        <Typography
                                            noWrap
                                            sx={{
                                                fontSize: 13,
                                                opacity: 0.7,
                                                width: 150,
                                            }}
                                            color={colors.info}
                                        >
                                            <strong>
                                                {`${student.currTraining?.institute?.organization_name} - ${student?.currTraining?.training?.region}`}
                                            </strong>
                                        </Typography>
                                    </Box>
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <Box
                        sx={{
                            display: 'flex',
                            textAlign: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                    color: colors.warning,
                                }}
                            >
                                Finish date
                            </Typography>
                            <Typography
                                className="error-message"
                                sx={{ fontSize: 12, fontWeight: 'bold' }}
                            >
                                {moment(
                                    student.currTraining.finish_date,
                                ).format('LL')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default OnFieldStudentWidget;
