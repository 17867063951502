import React, { useEffect, useState } from 'react';
import PageLayout from '../../layouts/PageLayout';
import { Grid, Box } from '@mui/material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { filter } from 'lodash';
import NoContent from '../../../components/NoContent';
import { isEmpty } from 'lodash';
import CountBadge from '../../../components/CountBadge';
import { size } from 'lodash';
import {
    studentsSelector,
    trainingSeasonSelector,
} from '../../../states/features/selectors';
import PTApplicantTable3 from '../../../components/Tables/PTApplicantTable3';
import PTApplicantTable4 from '../../../components/Tables/PTApplicantTable4';
import TrainingStudentsSidebar from '../../../components/sidebars/TrainingStudentsSidebar';

const TrainingStudentsByStatus = () => {
    // ############ Const #######################
    const { status } = useParams();

    // ############ Comp state ##################
    const [students, setStudents] = useState([]);

    // ############ Redux state ##################
    const { students: allStudents } = useSelector(studentsSelector);
    const { currTrainingSeason } = useSelector(trainingSeasonSelector);

    // ############## FUNC ###################
    const getStudentByStatus = () => {
        let trainingStudents = [];
        if (status === 'registered') {
            trainingStudents = allStudents;
        }
        if (status === 'enrolled') {
            trainingStudents = filter(allStudents, ({ enrolledSeasons }) => {
                let currSes = filter(
                    enrolledSeasons,
                    ({ title }) => title === currTrainingSeason,
                );
                return !isEmpty(currSes);
            });
        }
        if (status === 'on-training') {
            trainingStudents = filter(
                allStudents,
                ({ isOnTraining }) => isOnTraining === true,
            );
        }
        if (status === 'completed') {
            trainingStudents = filter(
                allStudents,
                ({ currTraining }) =>
                    currTraining?.onTrainingStatus === 'completed',
            );
        }
        setStudents(trainingStudents);
    };

    // ############### useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getStudentByStatus();
        }

        return () => {
            isSubscribed = false;
        };
    }, [allStudents]);

    return (
        <>
            <PageLayout
                head={`${status} practical training students`}
                sidebar={<TrainingStudentsSidebar />}
            >
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CustomCard2
                            head={`All ${status} students`}
                            padding={0}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(students)}
                                            item={`${status}  students`}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(students) ? (
                                <>
                                    <NoContent
                                        height={'68.5vh'}
                                        message={`No ${status} students`}
                                    />
                                </>
                            ) : status === 'on-training' ||
                              status === 'completed' ? (
                                <PTApplicantTable3
                                    height={'65.5vh'}
                                    applicants={students}
                                />
                            ) : (
                                <PTApplicantTable4
                                    height={'65.5vh'}
                                    applicants={students}
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default TrainingStudentsByStatus;
