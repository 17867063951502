import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import CustomTextField from '../../components/Forms/CustomTextField';
import logo from '../../assets/media/images/logo.png';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

// ======= MUI styled comp ===============
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
    styled,
} from '@mui/material';
import { ArrowRightAltOutlined, LoginOutlined } from '@mui/icons-material';

// ======= Custom MUI styled comp ===============
import CustomPasswordField from '../../components/Forms/CustomPasswordField';
import { useLoginMutation } from '../../api/auth/authAPI';
import useAuth from '../../hooks/useAuth';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import useRTK from '../../hooks/useRTK';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
        '& .icon': {
            color: `${colors.secondary}!important`,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 100,
            paddingRight: 100,
        },
    },
}));

// ========= validation ================
const validation = Yup.object({
    login_id: Yup.string().required('Reg ID or Email is required'),
    password: Yup.string().required(),
});

// !################## MAIN FUNC ##################
const Login = () => {
    // ############## CONST ##########
    const RTK = useRTK();
    const auth = useAuth();

    // ########## Reset Store ##########
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            auth.resetStoreStates();
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    // ############# RTK ##############
    const [
        login,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useLoginMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <React.Fragment>
            <Grid item sm={10} md={10}>
                <FormBox>
                    <Grid item sm={12} xs={12} sx={{ display: { md: 'none' } }}>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={2} xs={3}>
                                <img
                                    src={logo}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography className="head">Welcome Back</Typography>
                    <Typography className="sub-head">
                        Sign in to your account
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            login_id: '',
                            password: '',
                        }}
                        // initialValues={{
                        //     login_id: 'ORG-245964',
                        //     password: 'wmax@2023',
                        // }}
                        validationSchema={validation}
                        onSubmit={(payload) => {
                            login(payload);
                        }}
                    >
                        {(formik) => (
                            <Form style={{ marginTop: 25 }}>
                                <Grid container justifyContent={'center'}>
                                    {/* ######### username ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomTextField
                                            className="form-input"
                                            label="Registation ID or Email"
                                            type="text"
                                            name="login_id"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### password ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomPasswordField
                                            className="form-input"
                                            label="Password"
                                            type="password"
                                            name="password"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### remember ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormGroup>
                                                <FormControlLabel
                                                    name="remember"
                                                    control={
                                                        <Checkbox
                                                            color="secondary"
                                                            size="small"
                                                        />
                                                    }
                                                    label="Remember me ?"
                                                    sx={{
                                                        '& span': {
                                                            color: colors.gray,
                                                        },
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Link to={'/forget-password'}>
                                                    <Typography className="forget">
                                                        Forget password ?
                                                    </Typography>
                                                </Link>
                                            </FormGroup>
                                        </Box>
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={11}
                                        sx={{
                                            marginTop: 2,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<LoginOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Login
                                        </CustomSubmitButton>
                                    </Grid>

                                    {/* ######### SIGN UP ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={10}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.secondary,
                                            }}
                                        >
                                            OR
                                        </Typography>
                                        <Link to={'/register-as'}>
                                            <Typography
                                                className="forget"
                                                marginTop={2}
                                            >
                                                Don't have an acount? register
                                                now.
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default Login;
