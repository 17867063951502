import { Add, Leaderboard } from '@mui/icons-material';
import { Box, Button, Chip, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import LatestApplicants from '../jobComponents/LatestApplicants';
import StatsInfoBox from '../infoBoxes/StatsInfoBox';
import { filter, isEmpty, size } from 'lodash';
import {
    jobApplicationsSelector,
    jobsSelector,
} from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import NoContent from '../NoContent';

const CreateJobBtn = styled(Box)({
    'background': colors.gray,
    // 'padding': 20,
    'borderRadius': 15,
    'overflow': 'hidden',
    'boxShadow': `1px 2px 5px 10px  ${colors.bgColor3}`,
    // 'border': `1px solid ${colors.primary}`,
    '.btn': {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        fontSize: 16,
        display: 'flex',
        flexDirection: 'column',
    },
});

const JobsSidebar = () => {
    // ################# Redux state #################
    const { jobs } = useSelector(jobsSelector);
    const { companyJobApplications } = useSelector(jobApplicationsSelector);
    const allJobApplications = [...companyJobApplications];

    const jobStats = [
        {
            id: 1,
            title: 'Drafted jobs',
            stats: `${size(filter(jobs, ['status', 'draft']))} Jobs`,
            borderColor: colors.warning,
        },
        {
            id: 2,
            title: 'Published jobs',
            stats: `${size(filter(jobs, ['status', 'published']))} Jobs`,
            borderColor: colors.info,
        },
        {
            id: 3,
            title: 'Closed job',
            stats: `${size(filter(jobs, ['status', 'closed']))} Jobs`,
            borderColor: colors.success,
        },
        {
            id: 4,
            title: 'Expired job',
            stats: `${size(filter(jobs, ['status', 'expired']))} Jobs`,
            borderColor: 'red',
        },
    ];

    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <CreateJobBtn>
                        <Link to={'/jobs/create'}>
                            <Button
                                className="btn"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{
                                    'border': `1px solid ${colors.bgColor3}`,
                                    ':hover': {
                                        bgcolor: colors.secondary,
                                        opacity: 0.9,
                                        border: `1px solid ${colors.bgColor5}`,
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        opacity: 0.9,
                                    }}
                                >
                                    <Add sx={{ mr: 1 }} />
                                    Create
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 'bold', opacity: 0.9 }}
                                >
                                    new job vacancy
                                </Typography>
                            </Button>
                        </Link>
                    </CreateJobBtn>
                </Grid>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1, color: colors.info }} />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Job overview
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {jobStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomCard2
                        height={'33vh'}
                        head="Latest applicants"
                        action={
                            <Chip
                                label={`${size(allJobApplications)} Applicants`}
                                size="small"
                                color="info"
                                sx={{
                                    opacity: 0.8,
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    height: 18,
                                    px: 0.5,
                                }}
                            />
                        }
                    >
                        {isEmpty(allJobApplications) ? (
                            <>
                                <NoContent
                                    height={150}
                                    message={'No job applicants'}
                                />
                            </>
                        ) : (
                            <LatestApplicants applicants={allJobApplications} />
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default JobsSidebar;
