import React, { useState } from "react";
import { Comment, Update } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import CustomCard2 from "../cards/CustomCard2";
import JobInfoBoxDetails from "../jobComponents/JobInfoBoxDetails";
import OrganizationJobWidget from "../widgets/OrganizationJobWidget";
import CustomDialog from "../Dialogs/CustomDialog";
import ApplyJob from "../../pages/jobs/CRUD/ApplyJob";
import OrganizationFieldWidget from "../widgets/OrganizationFieldWidget";
import EmployerMiniWidget from "../widgets/EmployerMiniWidget";

const JobDetailsSidebar3 = ({ jobDetails }) => {
    // ############# Comp State ################
    const [openDialog, setOpenDialog] = useState(false);

    // ############## FUNC #####################
    // => Apply dialog
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <CustomDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                head="Confirm job application"
            >
                <ApplyJob
                    job={jobDetails}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <Box>
                <JobInfoBoxDetails jobDetails={jobDetails} />
            </Box>
            <Box sx={{ mt: 2 }}>
                <CustomCard2 head={"Posted by"}>
                    <EmployerMiniWidget employer={jobDetails?.employer} />
                </CustomCard2>
            </Box>
            {jobDetails.isApplied !== true && (
                <Box sx={{ mt: 2 }}>
                    <CustomCard2 head={"Actions links"}>
                        <Box>
                            <Button
                                size="large"
                                variant="contained"
                                color="info"
                                fullWidth
                                startIcon={<Update />}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                            >
                                Apply now
                            </Button>
                        </Box>
                    </CustomCard2>
                </Box>
            )}
        </>
    );
};

export default JobDetailsSidebar3;
