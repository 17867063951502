import React from 'react';
import { Leaderboard } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import StatsInfoBox from '../infoBoxes/StatsInfoBox';
import {
    jobsSelector,
    transactionSelector,
} from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { numFormater } from '../../helpers/numFormater';
import { filter, size } from 'lodash';

const TransactionSidebar = () => {
    // ################# Redux state #################
    const { jobTransactions } = useSelector(transactionSelector);
    const { jobs } = useSelector(jobsSelector);

    // ################ FUNC ##################
    const getTotalAmount = (status) => {
        let transactions = filter(
            jobTransactions,
            ({ transaction }) => transaction.status === status,
        );
        let totalAmount = 0;
        transactions.forEach((transaction) => {
            totalAmount += parseInt(transaction.transaction.amount);
        });
        return {
            transactions,
            total: size(transactions),
            amount: totalAmount,
        };
    };

    const jobStats = [
        {
            id: 1,
            title: 'Drafted jobs',
            stats: `${size(filter(jobs, ['status', 'draft']))} jobs`,
            borderColor: colors.warning,
        },
        {
            id: 2,
            title: 'Published jobs',
            stats: `${size(filter(jobs, ['status', 'published']))} jobs`,
            borderColor: colors.info,
        },
        {
            id: 3,
            title: 'Closed job',
            stats: `${size(filter(jobs, ['status', 'closed']))} jobs`,
            borderColor: colors.success,
        },
        {
            id: 4,
            title: 'Expired job',
            stats: `${size(filter(jobs, ['status', 'expired']))} jobs`,
            borderColor: 'red',
        },
    ];

    const transactionStats = [
        {
            id: 1,
            title: 'On-progress',
            stats: `${getTotalAmount('not_paid').total} payment`,
            borderColor: colors.info,
        },
        {
            id: 2,
            title: 'Pending',
            stats: `${getTotalAmount('pending').total} payment`,
            borderColor: colors.warning,
        },
        {
            id: 3,
            title: 'Completed',
            stats: `${getTotalAmount('success').total} payment`,
            borderColor: colors.success,
        },
        {
            id: 4,
            title: 'Failed',
            stats: `${getTotalAmount('failure').total} payment`,
            borderColor: 'red',
        },
    ];

    return (
        <>
            <Grid container spacing={8}>
                {/* ########## POSTING FEE HISTORY ############# */}
                <Grid item sm={12}>
                    <Box
                        sx={{
                            height: 100,
                            background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 4,
                            position: 'relative',
                            boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                        }}
                    >
                        <Box
                            sx={{
                                width: 15,
                                background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                position: 'absolute',
                                top: 20,
                                bottom: 20,
                                left: 0,
                                borderTopRightRadius: 100,
                                borderBottomRightRadius: 100,
                            }}
                        ></Box>
                        <Box
                            sx={{
                                width: 15,
                                background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                position: 'absolute',
                                top: 20,
                                bottom: 20,
                                right: 0,
                                borderTopLeftRadius: 100,
                                borderBottomLeftRadius: 100,
                            }}
                        ></Box>
                        <Typography
                            sx={{
                                fontSize: 25,
                                fontWeight: 'bold',
                                color: colors.success,
                            }}
                        >
                            {numFormater(getTotalAmount('success').amount)}
                            <sup style={{ fontSize: 16 }}>TZS</sup>
                        </Typography>
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                            Total completed payment
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1, color: colors.info }} />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Job overview
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {jobStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1, color: colors.info }} />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Payment overview
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {transactionStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default TransactionSidebar;
