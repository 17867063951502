import { DoneAll, TaskAltOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const SuccessFeedback = ({ message, height }) => {
    return (
        <>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 2,
                    height: height ? height : 200,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <TaskAltOutlined sx={{ fontSize: 80, color: colors.success, mb: 2 }} />
                <Typography
                    sx={{
                        fontSize: 18,
                        color: colors.success,
                        opacity: 0.8,
                    }}
                >
                    {message}
                </Typography>
            </Box>
        </>
    );
};

export default SuccessFeedback;
