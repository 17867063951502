import React from 'react';
import { Box, Button, MenuItem, Pagination, Typography } from '@mui/material';
import {
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import CustomSelectFilter from '../Forms/CustomSelectFilter';

const DataPagnation = ({
    data,
    currentPage,
    handleNext,
    handlePrev,
    perPage,
    handlePageSelect,
}) => {
    // => Page
    const total_pages = data?.total_pages;
    // => Per page
    const per_page = [
        {
            id: 1,
            value: 25,
        },
        {
            id: 2,
            value: 50,
        },
        {
            id: 3,
            value: 75,
        },
        {
            id: 4,
            value: 100,
        },
    ];
    // => Links
    const previousUrl = data?.pre_page;
    const nextUrl = data?.next_page;
    let links = [];
    for (let index = 1; index <= total_pages; index++) {
        links.push(index);
    }
    const handlePageChange = (event, value) => {
        handlePageSelect(value);
    };
    const handlePerPage = (value) => {
        perPage(value);
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 50,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    px: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                {/* PREVIOUS BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    startIcon={<KeyboardDoubleArrowLeft />}
                    disabled={previousUrl === null ? true : false}
                    onClick={() => {
                        handlePrev(previousUrl);
                    }}
                >
                    Previous
                </Button>
                {/* LINKS BTN */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Pagination
                        count={total_pages}
                        variant="outlined"
                        shape="rounded"
                        hidePrevButton
                        hideNextButton
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                    {/* PER PAGE */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                ml: 4,
                                color: colors.primary,
                            }}
                        >
                            Per page
                        </Typography>
                        <CustomSelectFilter
                            width={70}
                            handleFilter={handlePerPage}
                            compValue={per_page[0].value}
                        >
                            {per_page.map((perPage) => (
                                <MenuItem
                                    key={perPage.id}
                                    sx={{ fontSize: 12 }}
                                    value={perPage.value}
                                >
                                    {perPage.value}
                                </MenuItem>
                            ))}
                        </CustomSelectFilter>
                    </Box>
                </Box>
                {/* NEXT BTN */}
                <Button
                    size="small"
                    variant="outlined"
                    color="info"
                    disabled={nextUrl === null ? true : false}
                    endIcon={<KeyboardDoubleArrowRight />}
                    onClick={() => {
                        handleNext(nextUrl);
                    }}
                >
                    Next
                </Button>
            </Box>
        </>
    );
};

export default DataPagnation;
