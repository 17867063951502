import { Badge, Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const StatusInfoBox = ({ data }) => {
    return (
        <>
            <Link to={data.url}>
                <Box
                    sx={{
                        'width': '100%',
                        'border': `1px solid ${colors.bgColor5}`,
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'paddingLeft': 1,
                        'paddingRight': 3,
                        'paddingTop': 1,
                        'paddingBottom': 1,
                        'borderRadius': 2,
                        'color': colors.primary,
                        'alignItems': 'center',
                        'transition': `.3s all ease-in-out`,
                        'borderLeft': `5px solid ${data.color}`,
                        ':hover': {
                            transition: `.3s all ease-in-out`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: colors.primary,
                            opacity: 0.8,
                        }}
                    >
                        {data.label}
                    </Typography>
                    <Typography>
                        <Badge
                            badgeContent={data.total}
                            showZero
                            color="primary"
                            sx={{ opacity: 0.8 }}
                        />
                    </Typography>
                </Box>
            </Link>
        </>
    );
};

export default StatusInfoBox;
