import { useDispatch } from "react-redux";
import {
    useGetActiveRegionsQuery,
    useGetEducationLevelsQuery,
    useGetExperienceLevelsQuery,
    useGetJobCategoriesQuery,
    useGetJobPricingFrameworkQuery,
    useGetOrganizationCategoriesQuery,
    useGetQualificationAwardsQuery,
} from "../api/settings/settingAPI";
import {
    setActiveRegions,
    setEducationLevels,
    setExperienceLevels,
    setJobCategories,
    setOrganizationCategories,
    setPricingFramework,
    setQualificationLevels,
} from "../states/features/settings/settingsSlice";
import useRTK from "./useRTK";

const useJobs = () => {
    // => Job Posting Dependant data
    const useMetaDataDispatch = () => {
        // ############ CONST ################
        const RTK = useRTK();
        // -> redux state
        const dispatch = useDispatch();

        // ############# RTK #################
        const { isSuccess: ORGCategoriesSuccess, data: ORGCategoriesData } =
            useGetOrganizationCategoriesQuery();
        const { isSuccess: jobCategoriesSuccess, data: jobCategoriesData } =
            useGetJobCategoriesQuery();
        const { isSuccess: awardSuccess, data: awardData } =
            useGetQualificationAwardsQuery();
        const {
            isSuccess: edducationLevelsSuccess,
            data: edducationLevelsData,
        } = useGetEducationLevelsQuery();
        const {
            isSuccess: experienceLevelsSuccess,
            data: experienceLevelsData,
        } = useGetExperienceLevelsQuery();
        const {
            isSuccess: PricingFrameworkSuccess,
            data: PricingFrameworkData,
        } = useGetJobPricingFrameworkQuery();
        const { isSuccess: RegionSuccess, data: RegionData } =
            useGetActiveRegionsQuery();

        // ############# Dispatch settings ##########
        const rtkORGCategoriesAction = (data) => {
            dispatch(setOrganizationCategories(data));
        };
        RTK.useRTKFetch(
            ORGCategoriesSuccess,
            ORGCategoriesData,
            rtkORGCategoriesAction
        );
        const rtkJobCategoriesAction = (data) => {
            dispatch(setJobCategories(data));
        };
        RTK.useRTKFetch(
            jobCategoriesSuccess,
            jobCategoriesData,
            rtkJobCategoriesAction
        );
        const rtkAwardsAction = (data) => {
            dispatch(setQualificationLevels(data));
        };
        RTK.useRTKFetch(awardSuccess, awardData, rtkAwardsAction);
        const rtkEducationLevelsAction = (data) => {
            dispatch(setEducationLevels(data));
        };
        RTK.useRTKFetch(
            edducationLevelsSuccess,
            edducationLevelsData,
            rtkEducationLevelsAction
        );
        const rtkExpeerienceLevelsAction = (data) => {
            dispatch(setExperienceLevels(data));
        };
        RTK.useRTKFetch(
            experienceLevelsSuccess,
            experienceLevelsData,
            rtkExpeerienceLevelsAction
        );
        const rtkPricingFrameworkAction = (data) => {
            dispatch(setPricingFramework(data));
        };
        RTK.useRTKFetch(
            PricingFrameworkSuccess,
            PricingFrameworkData,
            rtkPricingFrameworkAction
        );
        const rtkRegionkAction = (data) => {
            dispatch(setActiveRegions(data));
        };
        RTK.useRTKFetch(RegionSuccess, RegionData, rtkRegionkAction);
    };

    return {
        useMetaDataDispatch,
    };
};

export default useJobs;
