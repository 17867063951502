import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import { colors } from "../../assets/utils/constants";
import FullAppLoader from "../../components/FullAppLoader";
import HotToastfy from "../../components/HotToastfy";
import useRTK from "../../hooks/useRTK";
import NoNetworkError from "../errors/NoNetworkError";
import useNetwork from "../../hooks/useNetwork";

const DrawerHeader = styled("div")(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const AppLayout = ({ children }) => {
    // ################# Comp state ###############
    const RTK = useRTK();
    const checkNetwork = useNetwork();

    // ################# Comp state ###############
    const [mobileOpen, setMobileOpen] = React.useState(false);

    // ################# FUNC ##################
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box
            sx={{
                display: "flex",
                position: "absolute",
                top: 0,
                bottom: "100%",
                right: 0,
                left: 0,
            }}
        >
            {RTK.useRTKMainData() ? (
                <FullAppLoader />
            ) : (
                <>
                    {/* ############## Tostfy ############## */}
                    <HotToastfy />

                    {/* ############## topbar ############## */}
                    <Topbar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                    />

                    {/* ############## sidebar ############## */}
                    <Sidebar mobileOpen={mobileOpen} />

                    {/* ############## PageBG ############## */}
                    <PageBgImageCon />

                    {/* ############## content ############## */}
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: colors.bgColor }}
                    >
                        <DrawerHeader />
                        {!checkNetwork ? (
                            <>
                                <NoNetworkError />
                            </>
                        ) : (
                            <>{children}</>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default AppLayout;
