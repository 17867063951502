import { filter, size } from "lodash";
import moment from "moment";

export const chatDataFilter = (chartData, year) => {
    // => Get date range from filter & labels
    const labels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Get date date range
    // => Jan
    let data1StartDate = year + "-01-01";
    let data1EndDate = year + "-01-31";
    // => Feb
    let data2StartDate = year + "-02-01";
    let data2EndDate = year + "-02-28";
    // => Mar
    let data3StartDate = year + "-03-01";
    let data3EndDate = year + "-03-30";
    // => Apr
    let data4StartDate = year + "-04-01";
    let data4EndDate = year + "-04-30";
    // => May
    let data5StartDate = year + "-05-01";
    let data5EndDate = year + "-05-30";
    // => Jun
    let data6StartDate = year + "-06-01";
    let data6EndDate = year + "-06-30";
    // => Jul
    let data7StartDate = year + "-07-01";
    let data7EndDate = year + "-07-30";
    // => Aug
    let data8StartDate = year + "-08-01";
    let data8EndDate = year + "-08-31";
    // => Sep
    let data9StartDate = year + "-09-01";
    let data9EndDate = year + "-09-30";
    // => Oct
    let data10StartDate = year + "-10-01";
    let data10EndDate = year + "-10-30";
    // => Nov
    let data11StartDate = year + "-11-01";
    let data11EndDate = year + "-11-30";
    // => Dec
    let data12StartDate = year + "-12-01";
    let data12EndDate = year + "-12-31";
    
    let data1 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data1StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data1EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data2 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data2StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data2EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data3 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data3StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data3EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data4 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data4StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data4EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data5 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data5StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data5EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data6 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data6StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data6EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data7 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data7StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data7EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data8 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data8StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data8EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data9 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data9StartDate, "YYYY-MM-DD").format("YYYY-MM-DD") &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data9EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data10 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data10StartDate, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                    ) &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data10EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data11 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data11StartDate, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                    ) &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data11EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    let data12 = size(
        filter(
            chartData,
            ({ created_at }) =>
                moment(created_at).format("YYYY-MM-DD") >=
                    moment(data12StartDate, "YYYY-MM-DD").format(
                        "YYYY-MM-DD"
                    ) &&
                moment(created_at).format("YYYY-MM-DD") <=
                    moment(data12EndDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        )
    );
    return {
        labels,
        data: [
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            data8,
            data9,
            data10,
            data11,
            data12,
        ],
    };
};
