// ################ Sectors ################
// => Get
const GET_SECTORS = '/institute-programs/sectors';
// => Add
const ADD_SECTOR = '/institute-programs/sectors';
// => Activate
const ACTIVATE_SECTOR = '/institute-programs/sectors/activate';
// => Deactivate
const DEACTIVATE_SECTOR = '/institute-programs/sectors/deactivate';
// => Remove
const REMOVE_SECTOR = '/institute-programs/sectors';

// ################ Departments ################
// => Get
const GET_DEPARTMENTS = '/institute-programs/departments';
// => Add
const ADD_DEPARTMENT = '/institute-programs/departments';
// => Activate
const ACTIVATE_DEPARTMENT = '/institute-programs/departments/activate';
// => Deactivate
const DEACTIVATE_DEPARTMENT = '/institute-programs/departments/deactivate';
// => Remove
const REMOVE_DEPARTMENT = '/institute-programs/departments';

// ################ Sections ################
// => Get
const GET_SECTIONS = '/institute-programs/sections';
// => Add
const ADD_SECTION = '/institute-programs/sections';
// => Activate
const ACTIVATE_SECTION = '/institute-programs/sections/activate';
// => Deactivate
const DEACTIVATE_SECTION = '/institute-programs/sections/deactivate';
// => Remove
const REMOVE_SECTION = '/institute-programs/sections';

// ################ Students ################
// => Get all student
const GET_ALL_STUDENTS = '/students';

// => Get single student
const GET_SINGLE_STUDENT = '/students';

// => Create student
const CREATE_STUDENT = '/students';

// => Import students
const IMPORT_STUDENTS = '/students/import';

// => Update student
const UPDATE_STUDENT = '/students';

// => Delete student
const DELETE_STUDENT = '/students';

// => Suspend student
const SUSPEND_STUDENT = '/students';

export const studentsEndpoints = {
    GET_SECTORS,
    GET_DEPARTMENTS,
    GET_SECTIONS,
    ADD_SECTOR,
    REMOVE_SECTOR,
    ACTIVATE_SECTOR,
    DEACTIVATE_SECTOR,
    ADD_DEPARTMENT,
    ACTIVATE_DEPARTMENT,
    DEACTIVATE_DEPARTMENT,
    REMOVE_DEPARTMENT,
    ADD_SECTION,
    ACTIVATE_SECTION,
    DEACTIVATE_SECTION,
    REMOVE_SECTION,
    GET_ALL_STUDENTS,
    GET_SINGLE_STUDENT,
    CREATE_STUDENT,
    IMPORT_STUDENTS,
    UPDATE_STUDENT,
    DELETE_STUDENT,
    SUSPEND_STUDENT,
};
