import React from 'react';
import { setJobDetails } from '../../../states/features/job/jobsSlice';
import { useDeleteTagMutation } from '../../../api/job/jobAPI2';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { Box, Button, IconButton } from '@mui/material';
import Loader from 'react-js-loader';
import { colors } from '../../../assets/utils/constants';
import { DeleteForever } from '@mui/icons-material';

const DeleteTag = ({ job_id, tag_name }) => {
    // ########### CONST #####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################### RTK ###################
    const rtkActions = (data) => {
        dispatch(setJobDetails(data));
    };
    // => Delete
    const [
        deleteTag,
        {
            isLoading: tagDeleteLoading,
            isSuccess: tagDeleteSuccess,
            isError: tagDeleteIsError,
            error: tagDeleteError,
            data: tagDeleteData,
        },
    ] = useDeleteTagMutation();
    RTK.useRTKResponse(
        tagDeleteSuccess,
        tagDeleteIsError,
        tagDeleteError,
        tagDeleteData,
        rtkActions,
    );

    return (
        <>
            <IconButton
                size="small"
                sx={{
                    mr: 1,
                    borderRadius: '50px !important',
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
                disabled={tagDeleteLoading ? true : false}
                onClick={() => {
                    let payload = {
                        job_id,
                        tag_name,
                    };
                    deleteTag(payload);
                }}
            >
                <DeleteForever color="error" sx={{ fontSize: 14 }} />
                {tagDeleteLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Loader
                            type="spinner-circle"
                            bgColor={colors.info}
                            color={colors.info}
                            size={30}
                        />
                    </Box>
                )}
            </IconButton>
        </>
    );
};

export default DeleteTag;
