import React from "react";
import Contacts from "./Contacts";
import MapLocation from "./MapLocation";
import SocialNetworks from "./SocialNetworks";
import { Box, Button, Grid } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import SendMessage from "./SendMessage";
import { Link } from "react-router-dom";
import CustomCard2 from "../../components/cards/CustomCard2";
import { useSelector } from "react-redux";
import { profileSelector } from "../../states/features/selectors";

const HelpCenter = () => {
    const { profile: user } = useSelector(profileSelector);

    // ########### Links ################
    const links = [
        {
            id: 1,
            link: "Job vacancy",
            url: "/jobs",
        },
        {
            id: 2,
            link: "Training vacancy",
            url: "/practical-trainings",
        },
        {
            id: 3,
            link: "Applicants",
            url: "/applicants",
        },
        {
            id: 4,
            link: "My Profile",
            url: "/profile",
        },
    ];

    return (
        <>
            <PageLayout head={"Help center"} noSidebar>
                <Grid container spacing={4}>
                    {/* ############## Contacts ############## */}
                    <Grid item sm={12}>
                        <Contacts />
                    </Grid>

                    {/* ############## Message ############## */}
                    <Grid item sm={6} xs={12}>
                        <CustomCard2
                            head={"Tell us, We are here to help"}
                            height={{ md: "40vh", xs: "100%" }}
                        >
                            <SendMessage />
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={6}>
                        <CustomCard2
                            head={"Our social networks"}
                            height={{ md: "40vh", xs: "100%" }}
                        >
                            <Grid container spacing={2}>
                                {/* ############## Social Nets ############## */}
                                <Grid item sm={6} xs={12}>
                                    <SocialNetworks />
                                </Grid>

                                {/* ############## Links ############## */}
                                {user.is_verified === "verified" && (
                                    <Grid item sm={12} sx={{ mt: 5 }}>
                                        <CustomCard2
                                            head={"Userful action links"}
                                        >
                                            <Box>
                                                {links.map((link) => (
                                                    <Link
                                                        to={link.url}
                                                        key={link.url}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            sx={{ m: 0.5 }}
                                                            className="app-btn"
                                                        >
                                                            {link.link}
                                                        </Button>
                                                    </Link>
                                                ))}
                                                <a
                                                    href="https://wazawamax.co.tz"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        sx={{ m: 0.5 }}
                                                        className="app-btn"
                                                    >
                                                        Wmax Website
                                                    </Button>
                                                </a>
                                            </Box>
                                        </CustomCard2>
                                    </Grid>
                                )}
                            </Grid>
                        </CustomCard2>
                    </Grid>

                    {/* ############## Map ############## */}
                    <Grid item sm={12} sx={{ mb: 10 }}>
                        <MapLocation />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default HelpCenter;
