import React from 'react';
import { VerifiedUserOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import {
    useApproveStudentArrivalMutation,
    useApproveStudentCompleteMutation,
} from '../../../api/practicalTraining/practicalTrainingApplicantsAPI';
import { updateApplicantDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import moment from 'moment';
import { colors } from '../../../assets/utils/constants';

// * ################### VALIDATION SCHEMA ####################
const approvalValidationSchema = Yup.object({
    start_date: Yup.date()
        .min(
            moment(new Date()).format('YYYY-MM-DD'),
            `start date must be later than today`,
        )
        .required('Start date is a required field'),
    finish_date: Yup.date()
        .min(Yup.ref('start_date'), `finish date must be later than open date`)
        .required('finish date is a required field'),
});

const ConfirmArrival = ({ applicant, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        arrivalAction,
        {
            isLoading: arrivalLoading,
            isSuccess: arrivalSuccess,
            isError: arrivalIsError,
            error: arrivalError,
            data: arrivalData,
        },
    ] = useApproveStudentArrivalMutation();
    const [
        completeAction,
        {
            isLoading: completeLoading,
            isSuccess: completeSuccess,
            isError: completeIsError,
            error: completeError,
            data: completeData,
        },
    ] = useApproveStudentCompleteMutation();

    // ############# useEffect ##################
    // => arrival res
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(updateApplicantDetails(data));
    };
    RTK.useRTKResponse(
        arrivalSuccess,
        arrivalIsError,
        arrivalError,
        arrivalData,
        rtkActions,
    );
    // => complete res
    RTK.useRTKResponse(
        completeSuccess,
        completeIsError,
        completeError,
        completeData,
        rtkActions,
    );
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    start_date: '',
                    finish_date: '',
                }}
                validationSchema={approvalValidationSchema}
                onSubmit={(values) => {
                    let payload = {
                        student_id: applicant?.appliedTraining?.student_id,
                        training_id: applicant?.appliedTraining?.training_id,
                        start_date: values.start_date,
                        finish_date: values.finish_date,
                    };
                    arrivalAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Box sx={{ mb: 2 }} className="warning-message">
                                    <Typography>
                                        Please verify and confirm student
                                        arrival and finish date of practical
                                        training.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        p: 1.5,
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            mb: 1,
                                        }}
                                    >
                                        Practical training duration
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mr: 1,
                                            }}
                                        >
                                            <SmallCustomTextField
                                                label={'Training start'}
                                                name={'start_date'}
                                                type={'date'}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                ml: 1,
                                            }}
                                        >
                                            <SmallCustomTextField
                                                label={'Training end'}
                                                name={'finish_date'}
                                                type={'date'}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<VerifiedUserOutlined />}
                                    btnColor={'success'}
                                    loading={arrivalLoading}
                                    variant={'outlined'}
                                    onClick={formik.handleSubmit}
                                    sx={{
                                        py: 1.5,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Confirm student arrival
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ConfirmArrival;
