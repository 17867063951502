import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import moment from 'moment';

const CurrPTSeason = ({ season }) => {
    return (
        <div>
            <Box>
                <Box
                    sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: colors.info,
                        }}
                    >
                        {season.title}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 12,
                                color: colors.success,
                            }}
                        >
                            Open date
                        </Typography>
                        <Typography
                            noWrap
                            sx={{
                                textAlign: 'center',
                                fontSize: 14,
                                color: colors.info,
                            }}
                        >
                            {moment(season.open_date).format('LL')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 12,
                                color: 'red',
                            }}
                        >
                            Close date
                        </Typography>
                        <Typography
                            noWrap
                            sx={{
                                textAlign: 'center',
                                fontSize: 14,
                                color: colors.info,
                            }}
                        >
                            {moment(season.close_date).format('LL')}
                        </Typography>
                    </Box>
                </Box>
                <Box></Box>
            </Box>
        </div>
    );
};

export default CurrPTSeason;
