import React from 'react';

import { colors } from '../../assets/utils/constants';
import {
    Box,
    Chip,
    Typography,
    styled,
    IconButton,
    Tooltip,
} from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DeleteNotification2 from '../../pages/notifications/CRUD/DeleteNotification2';

const NotCard = styled(Box)({
    'border': `1px solid ${colors.bgColor}`,
    'overflow': 'hidden',
    'marginBottom': 15,
    'position': 'relative',
    'transition': `.3s all ease-in-out`,
    ':hover': {
        borderBottom: `1px solid ${colors.primary} !important`,
        transition: `.3s all ease-in-out`,
    },
});

const NotHeader = styled(Box)({
    'paddingLeft': 15,
    'paddingRight': 10,
    'paddingTop': 10,
    '.head': {
        fontSize: 13,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    '.body': {
        fontSize: 14,
        color: 'gray',
        padding: 10,
    },
});

const ActionMenu = styled(Box)({
    'position': 'absolute',
    'right': 10,
    'top': 10,
    'cursor': 'pointer',
    '& .icon': {
        fontSize: 14,
    },
    '& .icon-btn': {
        marginRight: 5,
        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
    },
    '.container': {
        background: colors.bgColor,
        width: 120,
        position: 'absolute',
        right: 10,
        top: 20,
        borderRadius: 1,
        border: `2px solid ${colors.bgColor}`,
    },
    '.list-btn': {
        'paddingLeft': 10,
        'paddingTop': 0,
        'paddingBottom': 0,
        '.list-text': {
            ' .MuiListItemText-secondary': {
                fontSize: 12,
                fontWeight: 'bold',
            },
        },
        '.icon-con': {
            'position': 'absolute',
            'right': -33,
            '.icon': {
                fontSize: 14,
            },
        },
    },
});

const NotFooter = styled(Box)({
    'height': 25,
    'display': 'flex',
    'justifyContent': 'space-between',
    'paddingLeft': 20,
    'paddingRight': 20,
    'alignItems': 'center',
    'opacity': 0.8,
    '.text': {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.primary,
        opacity: 0.8,
    },
});

const NotificationWidget = ({ notification, closeNotDrawer }) => {
    return (
        <React.Fragment>
            <NotCard
                sx={{
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    border: `1px solid ${colors.bgColor5}`,
                }}
            >
                {/* ####################### HEAD AND BODY ######################### */}
                <NotHeader>
                    <Chip
                        variant="outlined"
                        color={notification.type}
                        label={notification.title}
                        className="head"
                        size="small"
                    />
                    {!notification.isSeen && (
                        <Typography
                            sx={{
                                fontSize: 11,
                                fontWeight: 'bold',
                                color: 'red',
                                position: 'absolute',
                                right: 10,
                                top: 50,
                                px: 1,
                                borderRadius: 2,
                                border: `2px dotted ${colors.danger}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        >
                            New
                        </Typography>
                    )}
                    {/* ######################### ACTION MENU ########################## */}
                    <ActionMenu>
                        <Tooltip
                            title="View notification"
                            arrow
                            onClick={closeNotDrawer}
                        >
                            <Link to={`/notifications/${notification.id}`}>
                                <IconButton
                                    size="small"
                                    className="icon-btn"
                                    color="info"
                                >
                                    <RemoveRedEye className="icon" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <DeleteNotification2 notification={notification} />
                    </ActionMenu>
                    {/* ########################## BODY ########################### */}
                    <Typography className="body" noWrap sx={{ width: 250 }}>
                        {notification.body}
                    </Typography>
                </NotHeader>
                {/* ########################## FOOTER ########################### */}
                <NotFooter>
                    <Typography className="text">
                        From: {notification.from}
                    </Typography>
                    <Typography className="text">
                        {moment(notification.created_at).fromNow()}
                    </Typography>
                </NotFooter>
            </NotCard>
        </React.Fragment>
    );
};

export default NotificationWidget;
