import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import StatusInfoBox from '../../../components/infoBoxes/StatusInfoBox';
import { colors } from '../../../assets/utils/constants';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../../components/cards/CustomCard2';
import {
    studentsSelector,
    trainingSeasonSelector,
} from '../../../states/features/selectors';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { filter } from 'lodash';
import NoContent from '../../../components/NoContent';
import { isEmpty } from 'lodash';
import PTApplicantTable3 from '../../../components/Tables/PTApplicantTable3';
import TrainingStudentsSidebar from '../../../components/sidebars/TrainingStudentsSidebar';

const TrainingsStudents = () => {
    // ############ Redux state ##################
    const { students: allStudents } = useSelector(studentsSelector);
    const { currTrainingSeason } = useSelector(trainingSeasonSelector);

    // ############## FUNC ###################
    const getApplicantsByStatus = () => {
        let enrolledStudents = [];
        let onTrainingStudents = [];
        let completedStudents = [];
        // => Pending
        enrolledStudents = filter(allStudents, ({ enrolledSeasons }) => {
            let currSes = filter(
                enrolledSeasons,
                ({ title }) => title === currTrainingSeason,
            );
            return !isEmpty(currSes);
        });
        // => OnTraining
        onTrainingStudents = filter(
            allStudents,
            ({ isOnTraining }) => isOnTraining === true,
        );
        // => Completed
        completedStudents = filter(
            allStudents,
            ({ currTraining }) =>
                currTraining?.onTrainingStatus === 'completed',
        );
        return {
            enrolledStudents,
            onTrainingStudents,
            completedStudents,
        };
    };
    const infoBoxData2 = [
        {
            id: 2,
            label: 'Enrolled students',
            total: size(getApplicantsByStatus().enrolledStudents),
            url: `enrolled`,
            color: colors.warning,
        },
        {
            id: 4,
            label: 'On-training students',
            total: size(getApplicantsByStatus().onTrainingStudents),
            url: `on-training`,
            color: colors.info,
        },
        {
            id: 5,
            label: 'Completed students',
            total: size(getApplicantsByStatus().completedStudents),
            url: `completed`,
            color: colors.success,
        },
    ];

    return (
        <>
            <PageLayout
                head={'Practical training students managements'}
                sidebar={<TrainingStudentsSidebar />}
            >
                <Box
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        mb: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Grid container spacing={2}>
                        {infoBoxData2.map((data) => (
                            <Grid item xs key={data.id}>
                                <StatusInfoBox data={data} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CustomCard2 head={'On training students'} padding={0}>
                            {isEmpty(
                                getApplicantsByStatus().onTrainingStudents,
                            ) ? (
                                <>
                                    <NoContent
                                        height={'61.8vh'}
                                        message={`No on-training students`}
                                    />
                                </>
                            ) : (
                                <PTApplicantTable3
                                    height={'55.5vh'}
                                    applicants={
                                        getApplicantsByStatus()
                                            .onTrainingStudents
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default TrainingsStudents;
