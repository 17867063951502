import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { studentsEndpoints } from './studentsEndpoints';

export const instituteProgramsAPI = createApi({
    reducerPath: 'instituteProgramsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: orgBaseURL, prepareHeaders: headers }),
    tagTypes: ['sectors', 'departmens', 'sections'],
    endpoints: (builder) => ({
        // => GET all sectors
        getSectors: builder.query({
            query: () => `${studentsEndpoints.GET_SECTORS}`,
            providesTags: ['sectors'],
        }),
        // => ADD  sector
        addSector: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ADD_SECTOR}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['sectors'],
        }),
        // => ACTIVATE  sector
        activateSector: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ACTIVATE_SECTOR}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => DEACTIVATE  sector
        deactivateSector: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.DEACTIVATE_SECTOR}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Remove sector
        removeSector: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.REMOVE_SECTOR}/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['sectors'],
        }),
        // => GET all departments
        getDepartments: builder.query({
            query: () => `${studentsEndpoints.GET_DEPARTMENTS}`,
            providesTags: ['departmens'],
        }),
        // => ADD  department
        addDepartment: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ADD_DEPARTMENT}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['departmens'],
        }),
        // => ACTIVATE  department
        activateDepartment: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ACTIVATE_DEPARTMENT}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => DEACTIVATE  department
        deactivateDepartment: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.DEACTIVATE_DEPARTMENT}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Remove department
        removeDepartment: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.REMOVE_DEPARTMENT}/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['departmens'],
        }),
        // => GET all sections
        getSections: builder.query({
            query: () => `${studentsEndpoints.GET_SECTIONS}`,
            providesTags: ['sections'],
        }),
        // => ADD  section
        addSection: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ADD_SECTION}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['sections'],
        }),
        // => ACTIVATE  section
        activateSection: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.ACTIVATE_SECTION}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => DEACTIVATE  section
        deactivateSection: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.DEACTIVATE_SECTION}/${payload}`,
                method: 'PUT',
            }),
        }),
        // => Remove section
        removeSection: builder.mutation({
            query: (payload) => ({
                url: `${studentsEndpoints.REMOVE_SECTION}/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['sections'],
        }),
    }),
});

export const {
    useGetSectorsQuery,
    useAddSectorMutation,
    useActivateSectorMutation,
    useDeactivateSectorMutation,
    useRemoveSectorMutation,
    useGetDepartmentsQuery,
    useAddDepartmentMutation,
    useActivateDepartmentMutation,
    useDeactivateDepartmentMutation,
    useRemoveDepartmentMutation,
    useGetSectionsQuery,
    useAddSectionMutation,
    useActivateSectionMutation,
    useDeactivateSectionMutation,
    useRemoveSectionMutation,

    // => Lazy
    useLazyGetSectorsQuery,
    useLazyGetDepartmentsQuery,
    useLazyGetSectionsQuery,
} = instituteProgramsAPI;
