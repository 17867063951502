import React from 'react';
import { Subject } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { isEmpty } from 'lodash';
import NoContent from '../NoContent';

// ########### STYLED COMPONENT #################
const JobResponsibilityCard = styled(Box)({
    'padding': 20,
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'pb': 0.5,
        'color': colors.warning,
        'opacity': 0.8,
        'marginBottom': 2,
        '.icon': {
            marginRight: 1,
            fontSize: 14,
            color: colors.warning,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
        },
    },
});

// !################## MAIN FUNC ##################
const ForYouJobResponsibility = ({ job_id, jobResponsibilities }) => {
    return (
        <>
            <JobResponsibilityCard>
                <Typography className="title">
                    <Subject className="icon" />
                    Job responsibility
                </Typography>
                {!isEmpty(jobResponsibilities) ? (
                    <>
                        {/* ######################### CONTENT #################### */}
                        <Box sx={{mt: 2}}>
                            {jobResponsibilities?.map(
                                (responsibility, index) => (
                                    <Box
                                        className="res-con"
                                        key={responsibility.id}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                    width: 40,
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mr: 1,
                                                }}
                                            >
                                                {++index} :
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }}>
                                                {
                                                    responsibility.job_responsibility
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                ),
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <NoContent
                            height={100}
                            message={'No responsibility added'}
                        />
                    </>
                )}
            </JobResponsibilityCard>
        </>
    );
};

export default ForYouJobResponsibility;
