import { Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/constants";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { profileSelector } from "../../states/features/selectors";

const FreeTrialWidgetOne = ({ freeTrial }) => {
    const { profile } = useSelector(profileSelector);

    // => Get remain dates
    const date1 = moment(moment().format("YYYY-MM-DD"));
    const date2 = moment(freeTrial.end_date);
    const daysDifference = date2.diff(date1, "days");

    return (
        <>
            {!isEmpty(freeTrial) && (
                <Box
                    sx={{
                        p: 2,
                        position: "relative",
                        bgcolor: "red",
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                {freeTrial.package.name}
                            </Typography>
                            <Typography
                                sx={{ fontSize: 14, textAlign: "center", p: 2 }}
                            >
                                {freeTrial.package.description}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center", width: "50%" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    borderBottomRightRadius: 12,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                }}
                            >
                                <Box sx={{ p: 1 }}>
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            color: colors.warning,
                                        }}
                                    >
                                        {daysDifference > 0
                                            ? daysDifference
                                            : 0}
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            textAlign: "left",
                                            color: colors.info,
                                        }}
                                    >
                                        Days
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            textAlign: "left",
                                            color: colors.info,
                                        }}
                                    >
                                        Remain
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                {profile.isFreeTrial === true ? (
                                    <>
                                        <Typography
                                            className={"info-message"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Your free trial is active
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <Typography
                                            className={"error-message"}
                                            sx={{ fontWeight: "bold" }}
                                        >
                                            Your free trial is expired
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default FreeTrialWidgetOne;
