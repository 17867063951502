import { authAPI } from '../../api/auth/authAPI';
import { jobAPI2 } from '../../api/job/jobAPI2';
import { practicalTraingsAPI } from '../../api/practicalTraining/practicalTrainingsAPI';
import { profileAPI } from '../../api/profile/profileAPI';
import { settingAPI } from '../../api/settings/settingAPI';
import { trainingSeasonAPI } from '../../api/students/trainingSeasonAPI';
import { instituteProgramsAPI } from '../../api/students/instituteProgramsAPI';
import { studentsAPI } from '../../api/students/studentsAPI';
import { transactionAPI } from '../../api/transactions/transactionAPI';
import { practicalTrainingApplicantsAPI } from '../../api/practicalTraining/practicalTrainingApplicantsAPI';
import { jobApplicationAPI } from '../../api/job/jobApplicationAPI';
import { helpCenterAPI } from '../../api/helpCenter/helpCenterAPI';
import { contactUsAPI } from '../../api/helpCenter/contactUsAPI';
import { notificationsAPI } from '../../api/notifications/notificationsAPI';

// => RTK middleware
export const RTKmiddleware = [
    authAPI.middleware,
    profileAPI.middleware,
    settingAPI.middleware,
    jobAPI2.middleware,
    jobApplicationAPI.middleware,
    practicalTraingsAPI.middleware,
    instituteProgramsAPI.middleware,
    studentsAPI.middleware,
    transactionAPI.middleware,
    trainingSeasonAPI.middleware,
    practicalTrainingApplicantsAPI.middleware,
    helpCenterAPI.middleware,
    contactUsAPI.middleware,
    notificationsAPI.middleware,
];
