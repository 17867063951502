import React from 'react';
import {
    Bookmark,
    BookmarkBorderOutlined,
    DeleteForeverOutlined,
    PersonAddAlt1Outlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import {
    useAcceptApplicationMutation,
    useSaveApplicationMutation,
    useShortlistApplicationMutation,
    useDeclineApplicationMutation,
} from '../../../api/job/jobApplicationAPI';
import { updateORGApplicantDetails } from '../../../states/features/job/jobApplicationsSlice';

const ApproveAndDecline = ({ applicant, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        saveAction,
        {
            isLoading: saveLoading,
            isSuccess: saveSuccess,
            isError: saveIsError,
            error: saveError,
            data: saveData,
        },
    ] = useSaveApplicationMutation();
    const [
        shortlistAction,
        {
            isLoading: shortlistLoading,
            isSuccess: shortlistSuccess,
            isError: shortlistIsError,
            error: shortlistError,
            data: shortlistData,
        },
    ] = useShortlistApplicationMutation();
    const [
        acceptAction,
        {
            isLoading: acceptLoading,
            isSuccess: acceptSuccess,
            isError: acceptIsError,
            error: acceptError,
            data: acceptData,
        },
    ] = useAcceptApplicationMutation();
    const [
        declineAction,
        {
            isLoading: declineLoading,
            isSuccess: declineSuccess,
            isError: declineIsError,
            error: declineError,
            data: declineData,
        },
    ] = useDeclineApplicationMutation();

    // ############# useEffect ##################
    // => Res Action
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(updateORGApplicantDetails(data));
    };
    // => Save
    RTK.useRTKResponse(
        saveSuccess,
        saveIsError,
        saveError,
        saveData,
        rtkActions,
    );
    // => Shortlist
    RTK.useRTKResponse(
        shortlistSuccess,
        shortlistIsError,
        shortlistError,
        shortlistData,
        rtkActions,
    );
    // => Accept
    RTK.useRTKResponse(
        acceptSuccess,
        acceptIsError,
        acceptError,
        acceptData,
        rtkActions,
    );
    // => decline
    RTK.useRTKResponse(
        declineSuccess,
        declineIsError,
        declineError,
        declineData,
        rtkActions,
    );
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    mt: 2,
                    justifyContent: 'space-between',
                }}
            >
                {applicant?.application?.status === 'pending' ||
                    (applicant?.application?.status === 'shortlisted' && (
                        <CustomSubmitButton
                            startIcon={<DeleteForeverOutlined />}
                            btnColor={'error'}
                            loading={declineLoading}
                            variant={'outlined'}
                            onClick={() => {
                                let payload = applicant?.application?.id;
                                declineAction(payload);
                            }}
                            sx={{
                                width: 200,
                                py: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Deny application
                        </CustomSubmitButton>
                    ))}
                {applicant?.application?.status === 'pending' && (
                    <CustomSubmitButton
                        startIcon={<PersonAddAlt1Outlined />}
                        btnColor={'info'}
                        loading={shortlistLoading}
                        variant={'outlined'}
                        onClick={() => {
                            let payload = applicant?.application?.id;
                            shortlistAction(payload);
                        }}
                        sx={{
                            width: 200,
                            py: 1,
                            fontWeight: 'bold',
                        }}
                    >
                        Add to shortlisted
                    </CustomSubmitButton>
                )}
                {applicant?.application?.status !== 'accepted' &&
                    applicant?.application?.status !== 'declined' && (
                        <CustomSubmitButton
                            startIcon={<VerifiedUserOutlined />}
                            btnColor={'success'}
                            loading={acceptLoading}
                            variant={'outlined'}
                            onClick={() => {
                                let payload = applicant?.application?.id;
                                acceptAction(payload);
                            }}
                            sx={{
                                width: 200,
                                py: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Accept application
                        </CustomSubmitButton>
                    )}
                <CustomSubmitButton
                    btnColor={'warning'}
                    loading={saveLoading}
                    variant={'outlined'}
                    onClick={() => {
                        let payload = applicant?.application?.id;
                        saveAction(payload);
                    }}
                    sx={{
                        width: 50,
                        py: 1,
                        fontWeight: 'bold',
                    }}
                >
                    <Tooltip
                        title={
                            applicant?.application?.isSaved
                                ? 'Unsave application'
                                : 'Save application'
                        }
                        arrow
                    >
                        {applicant?.application?.isSaved === 0 ? (
                            <BookmarkBorderOutlined />
                        ) : (
                            <Bookmark />
                        )}
                    </Tooltip>
                </CustomSubmitButton>
            </Box>
        </>
    );
};

export default ApproveAndDecline;
