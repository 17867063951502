import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { helpCenterSelector } from '../../states/features/selectors';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../../components/cards/CustomCard2';
import NoContent from '../../components/NoContent';

const MapLocation = () => {
    // ############ Redux state ##############
    const { contacts } = useSelector(helpCenterSelector);

    return (
        <div>
            <CustomCard2
                head={'Location and Address'}
                height={{ md: '50vh', xs: '100%' }}
            >
                {isEmpty(contacts) ? (
                    <NoContent message={'Location not added'} height={'30vh'} />
                ) : (
                    <Grid container spacing={4}>
                        <Grid item sm={7}>
                            <Box
                                sx={{
                                    height: 350,
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                    border: `15px solid ${colors.bgColor3}`,
                                }}
                            >
                                <iframe
                                    src={contacts[0]?.map_url}
                                    width="100%"
                                    height="100%"
                                    title="Location"
                                    style={{ border: 0 }}
                                    aria-hidden="false"
                                ></iframe>
                            </Box>
                        </Grid>
                        <Grid item sm={5} xs={12}>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: 20,
                                        color: colors.info,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        opacity: 0.8,
                                    }}
                                >
                                    <LocationOn
                                        sx={{
                                            fontSize: 100,
                                            color: colors.warning,
                                            opacity: 0.8,
                                        }}
                                    />
                                    {contacts[0]?.address}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </CustomCard2>
        </div>
    );
};

export default MapLocation;
