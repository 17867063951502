import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../../assets/utils/constants';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { AllInclusive, Groups2Outlined } from '@mui/icons-material';
import CustomDialog from '../../../components/Dialogs/CustomDialog';
import ApprovePTTOStudents from '../CRUD/ApprovePTTOStudents';
import { PTSelector } from '../../../states/features/selectors';
import { useSelector } from 'react-redux';
import { filter, isEmpty, size } from 'lodash';
import ContentLoader from '../../../components/ContentLoader';

const StudentApprovalStatus = ({ training, applicantIsLoading }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### Redux state #################
    const { singlePracticalTrainingApplicants } = useSelector(PTSelector);

    // ################ FUNC ####################
    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            <CustomDialog
                head={'Approve practical training to students'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <ApprovePTTOStudents
                    training={training}
                    handleClose={handleDialogClose}
                />
            </CustomDialog>

            <Box
                sx={{
                    mt: 4,
                    border: `1px dotted ${colors.info}`,
                    borderRadius: 4,
                    overflow: 'hidden',
                }}
            >
                <Grid container>
                    <Grid item sm={4}>
                        <Box
                            sx={{
                                height: `100%`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            className={
                                training.assignStatus === 'approved'
                                    ? 'success-message'
                                    : training.assignStatus === 'pending'
                                    ? 'warning-message'
                                    : training.assignStatus === 'declined'
                                    ? 'error-message'
                                    : ''
                            }
                        >
                            <Box>
                                <Typography>Student approval status</Typography>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {training.assignStatus}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={8}>
                        <Box sx={{ bgcolor: 'green' }}>
                            <Grid item sm={12}>
                                <Box
                                    sx={{
                                        pt: 1.5,
                                        // borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        textAlign: 'center',
                                        position: 'relative',
                                        height: 120,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            pb: 1,
                                            borderBottom: `1px solid ${colors.bgColor5}`,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            color: colors.info,
                                        }}
                                    >
                                        Student apply stats
                                    </Typography>
                                    {applicantIsLoading ? (
                                        <ContentLoader height={160} />
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                p: 1.5,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.warning,
                                                }}
                                            >
                                                <strong>
                                                    <small>Applied</small>
                                                </strong>
                                                <Typography>
                                                    {size(
                                                        singlePracticalTrainingApplicants,
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.success,
                                                }}
                                            >
                                                <strong>
                                                    <small>Approved</small>
                                                </strong>
                                                <Typography>
                                                    {size(
                                                        filter(
                                                            singlePracticalTrainingApplicants,
                                                            ({
                                                                appliedTraining,
                                                            }) =>
                                                                appliedTraining?.status ===
                                                                'approved',
                                                        ),
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    color: colors.info,
                                                }}
                                            >
                                                <strong>
                                                    <small>Available</small>
                                                </strong>
                                                <Typography>
                                                    {!isEmpty(
                                                        training.positions,
                                                    ) ||
                                                    training.positions > 0 ? (
                                                        <Typography>
                                                            {training.positions -
                                                                size(
                                                                    filter(
                                                                        singlePracticalTrainingApplicants,
                                                                        ({
                                                                            appliedTraining,
                                                                        }) =>
                                                                            appliedTraining?.status ===
                                                                            'approved',
                                                                    ),
                                                                )}
                                                        </Typography>
                                                    ) : (
                                                        <AllInclusive />
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {!training.studentApproval && training.assignStatus === 'pending' && (
                <Box>
                    <Grid container justifyContent={'center'}>
                        <Grid item sm={4}>
                            <CustomSubmitButton
                                startIcon={<Groups2Outlined />}
                                btnColor="info"
                                sx={{ py: 0.5 }}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                            >
                                Approve to student
                            </CustomSubmitButton>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default StudentApprovalStatus;
