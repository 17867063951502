import React, { useState } from 'react';
import { ManageAccounts } from '@mui/icons-material';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { colors } from '../../../assets/utils/constants';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import OTPInput, { ResendOTP } from 'otp-input-react';
import useRTK from '../../../hooks/useRTK';
import {
    useRequestORGEmailVerificationMutation,
    useVerifyORGEmailMutation,
} from '../../../api/profile/profileAPI';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../../helpers/RTKHelpers';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import { errorNotify, successNotify } from '../../../helpers/notify';
import { useEffect } from 'react';

// * -> verify schema
const otpValidationSchema = Yup.object({
    otp_code: Yup.number().required('Fill all field').min(6),
});

const EmailVerification = ({ user, closeDialogy, refetchProfile }) => {
    // ############## Comp State ################
    const [OTP, setOTP] = useState('');

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    // => Request
    const [
        requestORGEmailVerification,
        {
            isLoading: requestLoading,
            isSuccess: emailSuccess,
            isError: emailIsError,
            error: emailError,
            data: emailData,
        },
    ] = useRequestORGEmailVerificationMutation();
    // => Verify
    const [
        verifyORGEmail,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useVerifyORGEmailMutation();

    // ############### useEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (emailSuccess) {
                successNotify(getRTKSuccessMessage(emailData));
            }
            if (emailIsError) {
                errorNotify(getRTKErrorMessage(emailError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [emailSuccess, emailIsError, emailData, emailError]);
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    otp_code: OTP,
                }}
                validationSchema={otpValidationSchema}
                onSubmit={(payload) => {
                    verifyORGEmail(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {requestLoading && (
                            <LinearProgress
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                }}
                            />
                        )}
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={10}>
                                <Typography
                                    sx={{
                                        fontSize: 22,
                                        textAlign: 'center',
                                        color: colors.primary,
                                        fontWeight: 'bold',
                                        margin: 2,
                                    }}
                                >
                                    Verify {user.organization_type.name} email
                                    address
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        textAlign: 'center',
                                        color: colors.primary,
                                    }}
                                >
                                    An verification code has been sent to{' '}
                                    {user.organization_type.name} email :{' '}
                                    <strong>{user.email}</strong>
                                </Typography>
                            </Grid>
                            <Grid item sm={9}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: 2,
                                        marginBottom: 3,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <OTPInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                    />
                                    {isError ? (
                                        <>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    color: 'red',
                                                    textAlign: 'center',
                                                    marginTop: 4,
                                                }}
                                            >
                                                {getRTKErrorMessage(error)}
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                                <ResendOTP
                                    onResendClick={() =>
                                        requestORGEmailVerification()
                                    }
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<ManageAccounts />}
                                        onClick={formik.handleSubmit}
                                        loading={isLoading}
                                    >
                                        Verify email
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EmailVerification;
