import React, { useEffect, useState } from 'react';
import { Email, LocalPhone, Verified } from '@mui/icons-material';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    styled,
    Typography,
} from '@mui/material';
import CustomCard from '../../components/cards/CustomCard';
import { colors } from '../../assets/utils/constants';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import EmailVerification from "./CRUD's/EmailVerification";
import PhoneVerification from "./CRUD's/PhoneVerification";
import {
    useRequestORGEmailVerificationMutation,
    useRequestORGPhoneVerificationMutation,
} from '../../api/profile/profileAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { setPinId } from '../../states/features/auth/authSlice';

const ButtonCon = styled(Box)({
    '.btn': {
        marginTop: 15,
        fontSize: 12,
        paddingTop: 4,
        paddingBottom: 4,
    },
});

const ContactsVerifications = ({ user, refetchProfile }) => {
    const dispatch = useDispatch();
    // ############### COMPONENT STATE ##################
    const [crudType, setCRUDType] = useState('');
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);
    const handleEmailVerification = () => {
        setCRUDType('emailVerification');
        setDialogHead('Verify organization email');
        setOpenDialogy(true);
    };
    const handlePhoneVerification = () => {
        setCRUDType('phoneVerification');
        setDialogHead('Verify organization phone number');
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // ############### RTK #####################
    // => Email
    const [
        requestORGEmailVerification,
        {
            isLoading: emailLoading,
            isSuccess: emailSuccess,
            isError: emailIsError,
            error: emailError,
            data: emailData,
        },
    ] = useRequestORGEmailVerificationMutation();
    // => Phone
    const [
        requestORGPhoneVerification,
        {
            isLoading: phoneLoading,
            isSuccess: phoneSuccess,
            isError: phoneIsError,
            error: phoneError,
            data: phoneData,
        },
    ] = useRequestORGPhoneVerificationMutation();

    // ############### useEffect ##################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (emailSuccess) {
                handleEmailVerification();
                successNotify(getRTKSuccessMessage(emailData));
            }
            if (emailIsError) {
                errorNotify(getRTKErrorMessage(emailError));
            }
            if (phoneSuccess) {
                handlePhoneVerification();
                dispatch(setPinId(getRTKResponseData(phoneData)));
                successNotify(getRTKSuccessMessage(phoneData));
            }
            if (phoneIsError) {
                errorNotify(getRTKErrorMessage(phoneError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        emailSuccess,
        emailIsError,
        emailData,
        emailError,
        phoneSuccess,
        phoneIsError,
        phoneError,
        phoneData,
        dispatch,
    ]);

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose={handleDialogOpen}
            >
                {crudType === 'emailVerification' ? (
                    <>
                        <EmailVerification
                            user={user}
                            refetchProfile={refetchProfile}
                            closeDialogy={handleDialogClose}
                        />
                    </>
                ) : crudType === 'phoneVerification' ? (
                    <>
                        <PhoneVerification
                            user={user}
                            refetchProfile={refetchProfile}
                            closeDialogy={handleDialogClose}
                        />
                    </>
                ) : (
                    <></>
                )}
            </CustomDialog>
            <Grid item sm={12} xs={12} sx={{ p: 2 }}>
                <CustomCard
                    head={'Contacts verifications'}
                    bodyPadding={3}
                    action={
                        isEmpty(user.email_verified_at) ||
                        isEmpty(user.phone_number_verified_at) ? (
                            <Chip
                                label="Not verified"
                                color="error"
                                size="small"
                                sx={{ textTransform: 'capitalize' }}
                            />
                        ) : (
                            <Chip
                                label="Verified"
                                color="success"
                                size="small"
                                sx={{ textTransform: 'capitalize' }}
                            />
                        )
                    }
                >
                    <Grid container justifyContent={'space-between'}>
                        <Grid
                            item
                            sm={6}
                            xs={6}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Email verify
                            </Typography>
                            {isEmpty(user.email_verified_at) ? (
                                <>
                                    <ButtonCon>
                                        <Button
                                            disabled={
                                                emailLoading ? true : false
                                            }
                                            className="btn"
                                            variant="contained"
                                            startIcon={
                                                <Email className="icon" />
                                            }
                                            onClick={() => {
                                                requestORGEmailVerification();
                                            }}
                                        >
                                            request verification code
                                            {emailLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: colors.primary,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </ButtonCon>
                                </>
                            ) : (
                                <>
                                    <center>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: 1,
                                                color: 'green',
                                                fontSize: 13,
                                                border: `1px solid ${colors.success}`,
                                                width: { md: 200, xs: '100%' },
                                                py: 0.5,
                                            }}
                                        >
                                            <Verified
                                                sx={{
                                                    fontSize: 18,
                                                }}
                                            />{' '}
                                            &nbsp;&nbsp;Email verified
                                        </Typography>
                                    </center>
                                </>
                            )}
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={6}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Phone verify
                            </Typography>
                            {isEmpty(user.phone_number_verified_at) ? (
                                <>
                                    {isEmpty(user.phone_number) ? (
                                        <>
                                            <Typography sx={{ fontSize: 14 }} className='error-message'>
                                                <strong
                                                    style={{ color: 'red' }}
                                                >
                                                    NOTE:
                                                </strong>
                                                Please add organization phone
                                                number in order to request
                                                verification code.
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <ButtonCon>
                                                <Button
                                                    className="btn"
                                                    disabled={
                                                        phoneLoading
                                                            ? true
                                                            : false
                                                    }
                                                    variant="contained"
                                                    startIcon={
                                                        <LocalPhone className="icon" />
                                                    }
                                                    onClick={() => {
                                                        requestORGPhoneVerification();
                                                    }}
                                                >
                                                    request verification code
                                                    {phoneLoading && (
                                                        <CircularProgress
                                                            size={24}
                                                            sx={{
                                                                color:
                                                                    colors.primary,
                                                                position:
                                                                    'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                marginTop:
                                                                    '-12px',
                                                                marginLeft:
                                                                    '-12px',
                                                            }}
                                                        />
                                                    )}
                                                </Button>
                                            </ButtonCon>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <center>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: 1,
                                                color: 'green',
                                                fontSize: 13,
                                                border: `1px solid ${colors.success}`,
                                                width: { md: 200, xs: '100%' },
                                                py: 0.5,
                                            }}
                                        >
                                            <Verified
                                                sx={{
                                                    fontSize: 18,
                                                }}
                                            />{' '}
                                            &nbsp;&nbsp;Phone verified
                                        </Typography>
                                    </center>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CustomCard>
            </Grid>
        </>
    );
};

export default ContactsVerifications;
