import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import Loader from "react-js-loader";
import {
    Typography,
    Badge,
    Grid,
    Stack,
    Box,
    Avatar,
    Button,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../assets/media/images/logo.png";
import { colors } from "../assets/utils/constants";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    BarChart,
    Construction,
    Groups,
    Money,
    PostAddTwoTone,
    SchoolOutlined,
    WifiCalling3Outlined,
    Window,
    WorkOutline,
} from "@mui/icons-material";
import { isEmpty, size } from "lodash";
import SidebarUserCard from "./sidebars/SidebarUserCard";
import CustomDialog from "./Dialogs/CustomDialog";
import { useState } from "react";
import JobPostinReq from "./JobPostinReq";
import CreateJobInDialogy from "../pages/jobs/CreateJobInDialogy";
import {
    PTSelector,
    jobApplicationsSelector,
    jobsSelector,
    profileSelector,
    studentsSelector,
} from "../states/features/selectors";
import useAuth from "../hooks/useAuth";
import useRTK from "../hooks/useRTK";
import { useLogoutMutation } from "../api/profile/profileAPI";

//############# Sidebar Mui Proparty #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const AppBarHeight = styled("div")(({ theme }) => ({
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const { window } = "props";
const container =
    window !== undefined ? () => window().document.body : undefined;

//############# DrawerDesktop #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root": {
        background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor2}) !important`,
        border: "none !important",
        padding: "10px",
        paddingTop: 0,
        boxShadow: `0px 0px 4px 0px ${colors.bgColor5}`,
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: 0,
    transition: "all ease-in-out 0.3s",
    "&::before": {
        content: '""',
        position: "absolute",
        height: 0,
        bottom: "50%",
        width: 4,
        transition: "all ease-in-out 0.3s",
        backgroundColor: colors.primary,
    },
    "& .icon": {
        minWidth: 0,
        justifyContent: "center",
        color: colors.primary,
        opacity: 0.9,
    },
    "& .name": {
        color: colors.primary,
    },
}));

//############# SidebarBottom #################
const SidebarBottom = styled(Stack)(({ theme }) => ({
    // 'position': 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    marginTop: 15,
    backgroundColor: colors.gray,
    "& .create-job": {
        height: 100,
        background: colors.primary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderTopRightRadius: 15,
        borderTopLeftRadius: 15,
        "& .icon": {
            transition: `.3s ease-in-out all`,
        },
        ":hover": {
            "& .icon": {
                transform: `scale(1.3) rotate(360deg)`,
                transition: `.3s ease-in-out all`,
            },
        },
        "& .icon-con": {
            height: 45,
            width: 45,
            borderRadius: 50,
            border: `2px solid ${colors.secondary}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
    },
}));

// !############### MAIN FUNC ###################
const Sidebar = ({ handleDrawerToggle, mobileOpen }) => {
    const [open] = React.useState(true);
    const RTK = useRTK();
    const [openDialog, setOpenDialogy] = useState(false);

    // -> Dialogy closer
    const handleDialogOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    const [openJobDialog, setOpenJobDialogy] = useState(false);
    // -> Dialogy job open
    const handleJobDialogyOpen = () => {
        setOpenJobDialogy(true);
    };

    // -> Dialogy job closer
    const handleJobDialogClose = () => {
        setOpenJobDialogy(false);
    };

    // #################### redux state #####################
    const auth = useAuth();
    const { profile: user } = useSelector(profileSelector);
    const { jobs, appliedORGJobs, appliedEmployerJobs } =
        useSelector(jobsSelector);
    const appliedJobsForYou = [...appliedORGJobs, ...appliedEmployerJobs];
    const {
        ORGPracticalTrainings,
        specialPracticalTrainings,
        otherPracticalTrainings,
        allPracticalTrainingApplicants,
    } = useSelector(PTSelector);
    const { students } = useSelector(studentsSelector);

    // => All PTs
    const allPracticalTrainings = () => {
        let trainings =
            user.organization_type?.name === "Institution"
                ? [
                      ...ORGPracticalTrainings,
                      ...specialPracticalTrainings,
                      ...otherPracticalTrainings,
                  ]
                : [...ORGPracticalTrainings];
        return trainings;
    };
    const {
        companyJobApplications,
        individualJobApplications,
        groupJobApplications,
    } = useSelector(jobApplicationsSelector);
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
        ...groupJobApplications,
    ];

    // ############## RTK ######################
    const [
        logoutUser,
        { isSuccess, isLoading, isError, error, data: logoutData },
    ] = useLogoutMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.logout(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, logoutData, rtkActions);

    // -> Nav links
    let links = [
        {
            id: 1,
            name: "Job vacancy",
            icon: <WorkOutline />,
            url: "/jobs",
            tooltip: "Job vacancy",
            total: size(jobs) + size(appliedJobsForYou),
        },
        {
            id: 2,
            name: "Practical training",
            icon: <Construction />,
            url: "/practical-trainings",
            tooltip: "Practical training",
            total: size(allPracticalTrainings()),
        },
        {
            id: 3,
            name: "Applicants",
            icon: <Groups />,
            url: "/applicants",
            tooltip: "Applicants",
            total:
                size(allPracticalTrainingApplicants) + size(allJobApplications),
        },
    ];

    // ############## Company links ################
    const extraCompanyLinks = [
        {
            id: 1,
            name: "Transactions",
            icon: <Money />,
            url: "/transactions",
            tooltip: "Transactions",
            total: "",
        },
        {
            id: 2,
            name: "Analysis & Reports",
            icon: <BarChart />,
            url: "/analysis-and-reports",
            tooltip: "Analisis & Reports",
            total: "",
        },
    ];

    // ############## Institution links ################
    const extraInstitutionLinks = [
        {
            id: 1,
            name: "Students ",
            icon: <SchoolOutlined />,
            url: "/students",
            tooltip: "Students management",
            total: size(students),
            badge: true,
        },
        {
            id: 2,
            name: "Transactions",
            icon: <Money />,
            url: "/transactions",
            tooltip: "Transactions",
            total: "",
            badge: false,
        },
        {
            id: 3,
            name: "Analysis & Reports",
            icon: <BarChart />,
            url: "/analysis-and-reports",
            tooltip: "Analisis & Reports",
            total: "",
            badge: false,
        },
    ];

    // -> Drawer
    const drawer = (
        <Box>
            <List sx={{ marginTop: 1 }}>
                {/* ####################### USERCARD ####################### */}
                {!isEmpty(user) && <SidebarUserCard user={user} />}

                {/* ####################### LINKS ####################### */}
                <NavLink to="/dashboard">
                    {({ isActive }) => (
                        <SideNavListItem
                            disablePadding
                            sx={{
                                marginTop: 2,
                                "&::before": isActive && {
                                    content: '""',
                                    position: "absolute",
                                    height: "100%",
                                    bottom: 0,
                                    top: 0,
                                    width: 4,
                                    transition: "all ease-in-out 0.3s",
                                    backgroundColor: colors.primary,
                                },
                                "&:hover": !isActive && {
                                    transition: "all ease-in-out 0.3s",
                                    "&::before": {
                                        transition: "all ease-in-out 0.3s",
                                        height: "100%",
                                        bottom: 0,
                                    },
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    py: 0.5,
                                }}
                            >
                                <ListItemIcon
                                    className="icon"
                                    sx={{ mr: open ? 1 : "auto" }}
                                >
                                    <Window />
                                </ListItemIcon>
                                <ListItemText
                                    className="name"
                                    sx={{ opacity: open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        fontWeight: "bold",
                                        fontSize: 14,
                                    }}
                                    primary="Dashboard"
                                />
                            </ListItemButton>
                        </SideNavListItem>
                    )}
                </NavLink>
                {user.is_verified === "verified" &&
                    links.map((link) => (
                        <NavLink to={link.url} key={link.id}>
                            {({ isActive }) => (
                                <SideNavListItem
                                    disablePadding
                                    sx={{
                                        display: "block",
                                        "&::before": isActive && {
                                            content: '""',
                                            position: "absolute",
                                            height: "100%",
                                            bottom: 0,
                                            top: 0,
                                            width: 4,
                                            transition: "all ease-in-out 0.3s",
                                            backgroundColor: colors.primary,
                                        },
                                        "&:hover": !isActive && {
                                            transition: "all ease-in-out 0.3s",
                                            "&::before": {
                                                transition:
                                                    "all ease-in-out 0.3s",
                                                height: "100%",
                                                bottom: 0,
                                            },
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            py: 0.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            className="icon"
                                            sx={{ mr: open ? 1 : "auto" }}
                                        >
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            className="name"
                                            primary={link.name}
                                            sx={{ opacity: open ? 1 : 0 }}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                        />
                                        <Badge
                                            className="badge"
                                            badgeContent={link.total}
                                            showZero
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    bgcolor: colors.bgColor3,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    color: colors.warning,
                                                    fontWeight: "bold",
                                                },
                                            }}
                                        />
                                    </ListItemButton>
                                </SideNavListItem>
                            )}
                        </NavLink>
                    ))}
                {user.organization_type?.name === "Company" &&
                    user.is_verified === "verified" &&
                    extraCompanyLinks.map((link) => (
                        <NavLink to={link.url} key={link.id}>
                            {({ isActive }) => (
                                <SideNavListItem
                                    disablePadding
                                    sx={{
                                        display: "block",
                                        "&::before": isActive && {
                                            content: '""',
                                            position: "absolute",
                                            height: "100%",
                                            bottom: 0,
                                            top: 0,
                                            width: 4,
                                            transition: "all ease-in-out 0.3s",
                                            backgroundColor: colors.primary,
                                        },
                                        "&:hover": !isActive && {
                                            transition: "all ease-in-out 0.3s",
                                            "&::before": {
                                                transition:
                                                    "all ease-in-out 0.3s",
                                                height: "100%",
                                                bottom: 0,
                                            },
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            py: 0.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            className="icon"
                                            sx={{ mr: open ? 1 : "auto" }}
                                        >
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            className="name"
                                            primary={link.name}
                                            sx={{ opacity: open ? 1 : 0 }}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                        />
                                        {link.total && (
                                            <Badge
                                                className="badge"
                                                badgeContent={link.total}
                                                showZero
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        bgcolor:
                                                            colors.bgColor3,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                        color: colors.warning,
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                            />
                                        )}
                                    </ListItemButton>
                                </SideNavListItem>
                            )}
                        </NavLink>
                    ))}
                {user.organization_type?.name === "Institution" &&
                    user.is_verified === "verified" &&
                    extraInstitutionLinks.map((link) => (
                        <NavLink to={link.url} key={link.id}>
                            {({ isActive }) => (
                                <SideNavListItem
                                    disablePadding
                                    sx={{
                                        display: "block",
                                        "&::before": isActive && {
                                            content: '""',
                                            position: "absolute",
                                            height: "100%",
                                            bottom: 0,
                                            top: 0,
                                            width: 4,
                                            transition: "all ease-in-out 0.3s",
                                            backgroundColor: colors.primary,
                                        },
                                        "&:hover": !isActive && {
                                            transition: "all ease-in-out 0.3s",
                                            "&::before": {
                                                transition:
                                                    "all ease-in-out 0.3s",
                                                height: "100%",
                                                bottom: 0,
                                            },
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            py: 0.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            className="icon"
                                            sx={{ mr: open ? 1 : "auto" }}
                                        >
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            className="name"
                                            primary={link.name}
                                            sx={{ opacity: open ? 1 : 0 }}
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                        />
                                        {link.badge && (
                                            <Badge
                                                className="badge"
                                                badgeContent={link.total}
                                                showZero
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        bgcolor:
                                                            colors.bgColor3,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                        color: colors.warning,
                                                        fontWeight: "bold",
                                                    },
                                                }}
                                            />
                                        )}
                                    </ListItemButton>
                                </SideNavListItem>
                            )}
                        </NavLink>
                    ))}
            </List>
            {/* ####################### HELP CENTER ####################### */}
            <Box sx={{ mt: 1 }}>
                <Link to={"/help-center"}>
                    <Button
                        startIcon={<WifiCalling3Outlined />}
                        variant="contained"
                        fullWidth
                        color="secondary"
                        sx={{
                            height: 45,
                            borderRadius: `150px !important`,
                            fontWeight: "bold !important",
                            border: `1px solid ${colors.bgColor3}`,
                            ":hover": {
                                bgcolor: colors.secondary,
                                opacity: 0.9,
                                border: `1px solid ${colors.bgColor5}`,
                            },
                        }}
                    >
                        Help center
                    </Button>
                </Link>
            </Box>

            {/* ####################### bottom ####################### */}
            <SidebarBottom
                sx={{
                    position:
                        user.is_verified === "verified"
                            ? "relative"
                            : "absolute",
                }}
            >
                <Box className="create-job">
                    <Button
                        fullWidth
                        sx={{ display: "flex", flexDirection: "column" }}
                        onClick={() => {
                            user.is_verified === "verified"
                                ? handleJobDialogyOpen()
                                : handleDialogOpen();
                        }}
                    >
                        <Box className="icon-con">
                            <PostAddTwoTone
                                sx={{ color: colors.secondary }}
                                className="icon"
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 16,
                                fontWeight: "bold",
                                color: colors.secondary,
                                mt: 1,
                            }}
                        >
                            New job vacancy
                        </Typography>
                    </Button>
                </Box>
                <List sx={{ padding: 0 }}>
                    <Grid container>
                        <Grid item sm={12} xs={12}>
                            <ListItem
                                disablePadding
                                style={{
                                    backgroundColor: colors.bgColor1,
                                    color: colors.primary,
                                    justifyContent: "center",
                                }}
                            >
                                <ListItemButton
                                    style={{
                                        justifyContent: "center",
                                        height: 50,
                                        overflow: "hidden",
                                    }}
                                    onClick={() => {
                                        logoutUser();
                                    }}
                                >
                                    {isLoading ? (
                                        <>
                                            <Box>
                                                <Loader
                                                    type="bubble-loop"
                                                    bgColor={colors.danger}
                                                    color={colors.danger}
                                                    size={50}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <LogoutIcon
                                                className="icon"
                                                sx={{
                                                    color: colors.danger,
                                                }}
                                            />
                                            <Typography
                                                component={"span"}
                                                sx={{
                                                    color: colors.danger,
                                                    ml: 2,
                                                }}
                                            >
                                                Sign out
                                            </Typography>
                                        </>
                                    )}
                                </ListItemButton>
                            </ListItem>
                        </Grid>
                    </Grid>
                </List>
            </SidebarBottom>
        </Box>
    );

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={"Create new job vacancy"}
                openDialog={openJobDialog}
                customWidth={"lg"}
                handleDialogClose={handleJobDialogClose}
            >
                <CreateJobInDialogy />
            </CustomDialog>

            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={"Job posting requirement"}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose={handleDialogOpen}
            >
                <JobPostinReq />
            </CustomDialog>

            {/* ########################### mobile ########################## */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        px: 2,
                        boxSizing: "border-box",
                        width: "80%",
                        backgroundColor: colors.bgColor3,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ########################### desktop ########################## */}
            <DrawerDesktop
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: colors.primary,
                        bgcolor: colors.bgColor5,
                        height: 64,
                        position: "absolute",
                        left: 0,
                        width: 250,
                    }}
                >
                    <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
                        Wazawa Max
                    </Typography>
                    <Box
                        sx={{
                            bgcolor: colors.primary,
                            borderRadius: 2,
                            ml: 1,
                        }}
                    >
                        <Avatar
                            src={logo}
                            sx={{ p: 0.5, img: { objectFit: "contain" } }}
                        />
                    </Box>
                </Box>
                <AppBarHeight />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default Sidebar;
