import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    Clear,
    DangerousRounded,
    Done,
    Pending,
    Publish,
    Verified,
} from '@mui/icons-material';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { colors } from '../../assets/utils/constants';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import VerificationRequest from "./CRUD's/VerificationRequest";
import { useSelector } from 'react-redux';
import { profileSelector } from '../../states/features/selectors';

// !############## MAIN FUNC ################
const RequredDetails = ({ user, refetchProfile }) => {
    const [openDialog, setOpenDialogy] = useState(false);
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // *======== PROFILE_REDUCER ========
    const { orgDocs, orgKeyPerson } = useSelector(profileSelector);

    const profileReqDetails = [
        {
            label: `${user.organization_type.name} logo`,
            status: user.org_logo ? true : false,
        },
        {
            label: `${user.organization_type.name} description`,
            status: user.description ? true : false,
        },
        {
            label: `${user.organization_type.name} phone number verification`,
            status: user.phone_number_verified_at ? true : false,
        },
        {
            label: `${user.organization_type.name} email verification`,
            status: user.email_verified_at ? true : false,
        },
        {
            label: `${user.organization_type.name} verification document`,
            status:
                !isEmpty(orgDocs) && orgDocs.verification_status !== 'denied'
                    ? true
                    : false,
        },
        {
            label: `${user.organization_type.name} key person and verification document`,
            status:
                !isEmpty(orgKeyPerson) &&
                orgKeyPerson.documents &&
                orgKeyPerson.documents?.verification_status !== 'denied'
                    ? true
                    : false,
        },
    ];

    // ############### REDUCER STATE ##################
    const { isSuccess } = useSelector((state) => state.profileReducer);

    // -> closeDialog useEffect
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                handleDialogClose();
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess]);

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Request organization verifications'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <VerificationRequest
                    user={user}
                    orgDocs={orgDocs}
                    orgKeyPerson={orgKeyPerson}
                    refetchProfile={refetchProfile}
                    closeDialogy={handleDialogClose}
                />
            </CustomDialog>

            {/* ##################### CONTENT ################# */}
            <Grid item sm={12} xs={12} sx={{ p: 2 }}>
                <Typography>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Corporis illum omnis, quaerat sapiente, animi, consequatur
                    dolorum facere accusantium facilis accusamus eveniet.
                </Typography>
                <Box sx={{ textAlign: 'left', pb: 2 }}>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            my: 1,
                            fontSize: 16,
                            color: colors.success,
                        }}
                    >
                        Important required details
                    </Typography>
                    {profileReqDetails?.map((detail, index) => (
                        <Box component={'span'} key={index} sx={{ mr: 2 }}>
                            <Chip
                                label={detail.label}
                                sx={{
                                    mb: 2,
                                    bgcolor: colors.bgColor3,
                                    border: `1px solid ${colors.bgColor5}`,
                                }}
                                icon={
                                    detail.status ? (
                                        <Done
                                            sx={{
                                                color: 'green !important',
                                                fontSize: 18,
                                            }}
                                        />
                                    ) : (
                                        <Clear
                                            sx={{
                                                color: 'red !important',
                                                fontSize: 18,
                                            }}
                                        />
                                    )
                                }
                            />
                        </Box>
                    ))}
                </Box>
                <center>
                    {user.is_verified === 'pending' ? (
                        <>
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                color="info"
                                startIcon={<Publish />}
                                onClick={() => handleDialogyOpen()}
                            >
                                Submit profile for verification
                            </Button>
                        </>
                    ) : user.is_verified === 'verified' ? (
                        <>
                            <Typography className="success-box">
                                <Verified sx={{ mr: 2 }} />
                                Congratulation! your profile has been verified
                            </Typography>
                        </>
                    ) : orgDocs?.verification_status === 'denied' ||
                      orgKeyPerson.documents?.verification_status ===
                          'denied' ? (
                        <>
                            <Typography className="error-box">
                                <DangerousRounded sx={{ mr: 2 }} />
                                Sorry document has been denied . Please check it
                                and make sure you re-upload the right
                                information.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography className="info-box">
                                <Pending sx={{ mr: 2 }} />
                                Please wait for you profile to be verified
                            </Typography>
                        </>
                    )}
                </center>
            </Grid>
        </>
    );
};

export default RequredDetails;
