import React from "react";
import { Box, DialogContent, Grid, Rating, Typography } from "@mui/material";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import { useApplyORGJobMutation } from "../../../api/job/jobAPI2";
import {
    setEmployerJobDetails,
    setJobDetails,
    updateJobForYouDetailsEmployer,
    updateJobForYouDetailsORG,
} from "../../../states/features/job/jobsSlice";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import CustomTextArea from "../../../components/Forms/CustomTextArea";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ArrowRightAltOutlined, CommentOutlined } from "@mui/icons-material";
import CustomRating from "../../../components/Forms/CustomRating";
import { useRemarkAppliedJobMutation } from "../../../api/job/jobApplicationAPI";
import { isEmpty } from "lodash";

// ################## VALIDATION #################
const remarkValidationSchema = Yup.object({
    employee_rate: Yup.number()
        .required("Employee rate is required field")
        .max(5)
        .min(1),
    employee_comment: Yup.string()
        .required("Please provide comment")
        .max(500, "Reason must be at most 500 characters"),
});

const RemarkAppliedJob = ({ job, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        remarkAppliedJob,
        {
            isLoading: remarkAppliedLoading,
            isSuccess: remarkAppliedSuccess,
            isError: remarkAppliedIsError,
            error: remarkAppliedError,
            data: remarkAppliedData,
        },
    ] = useRemarkAppliedJobMutation();
    // ############# useEffect ##################
    const rtkORGActions = (data) => {
        handleDialogClose();
        isEmpty(job.employer)
            ? dispatch(setJobDetails(data))
            : dispatch(setEmployerJobDetails(data));
    };
    RTK.useRTKResponse(
        remarkAppliedSuccess,
        remarkAppliedIsError,
        remarkAppliedError,
        remarkAppliedData,
        rtkORGActions
    );

    return (
        <>
            <Formik
                initialValues={{
                    job_id: job.application.job_id,
                    type: job.employer ? "employer" : "company",
                    employee_rate: "",
                    employee_comment: "",
                }}
                validationSchema={remarkValidationSchema}
                onSubmit={(payload) => {
                    remarkAppliedJob(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Grid container>
                                    <Grid item sm={12}>
                                        <CustomRating
                                            formik={formik}
                                            name={"employee_rate"}
                                            label={"Employee rate"}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography
                                            sx={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                opacity: 0.8,
                                                mb: -1,
                                            }}
                                        >
                                            Remark comment
                                        </Typography>
                                        <CustomTextArea
                                            maxChar={500}
                                            rows={3}
                                            name={"employee_comment"}
                                            label={"Employee comment"}
                                        />
                                    </Grid>
                                    <Grid item sm={12}>
                                        <CustomSubmitButton
                                            startIcon={<CommentOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor="success"
                                            variant={"contained"}
                                            loading={remarkAppliedLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 2, fontSize: 16 }}
                                        >
                                            Submit your remark
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default RemarkAppliedJob;
