import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { adminBaseURL } from "../baseUrl/baseURL";
import { settingEndpoints } from "./settingEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const settingAPI = createApi({
    reducerPath: "settingAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
    }),
    endpoints: (builder) => ({
        // => Organization types
        getOrganizationTypes: builder.query({
            query: () => `${settingEndpoints.GET_ORG_TYPES}`,
        }),
        // => Organization categories
        getOrganizationCategories: builder.query({
            query: () => `${settingEndpoints.GET_ORG_CATEGORIES}`,
        }),
        // => Verification types
        getVerificationTypes: builder.query({
            query: () => `${settingEndpoints.GET_VERIFICATION_TYPE}`,
        }),
        // => Job categories
        getJobCategories: builder.query({
            query: () => `${settingEndpoints.GET_JOB_CATEGORIES}`,
        }),
        // => Qualification awards
        getQualificationAwards: builder.query({
            query: () => `${settingEndpoints.GET_QUALIFICATION_AWARDS}`,
        }),
        // => Education levels
        getEducationLevels: builder.query({
            query: () => `${settingEndpoints.GET_EDUCATION_LEVELS}`,
        }),
        // => Experies levels
        getExperienceLevels: builder.query({
            query: () => `${settingEndpoints.GET_EXPERIENCE_LEVELS}`,
        }),
        // => Experies levels
        getJobPricingFramework: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_POSTING_FEE_FRAMWORK}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => Payment methods
        getPaymentMethods: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_PAYMENT_METHODS}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => Verified organizations
        getPublicORG: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_PUBLIC_ORG}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => get active sectors
        getActiveSectors: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_ACTIVE_SECTORS}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => get active departments
        getActiveDepartments: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_ACTIVE_DEPARTMENTS}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => get active sections
        getActiveSections: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_ACTIVE_SECTIONS}`,
                method: "GET",
                headers: headers,
            }),
        }),
        // => get active regions
        getActiveRegions: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_ACTIVE_REGIONS}`,
                method: "GET",
            }),
        }),
        // => get active regions
        getActiveORGFreeTrial: builder.query({
            query: () => ({
                url: `${settingEndpoints.GET_ACTIVE_FREE_TRIALS}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetOrganizationTypesQuery,
    useGetOrganizationCategoriesQuery,
    useGetVerificationTypesQuery,
    useGetJobCategoriesQuery,
    useGetQualificationAwardsQuery,
    useGetEducationLevelsQuery,
    useGetExperienceLevelsQuery,
    useGetJobPricingFrameworkQuery,
    useGetPaymentMethodsQuery,
    useGetPublicORGQuery,
    useGetActiveSectorsQuery,
    useGetActiveDepartmentsQuery,
    useGetActiveSectionsQuery,
    useGetActiveRegionsQuery,
    useGetActiveORGFreeTrialQuery,
} = settingAPI;
