import { Box, Grid } from "@mui/material";
import { filter, size } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/constants";
import JobInfoCard from "../../components/cards/JobInfoCard";
import JobsSidebar from "../../components/sidebars/JobsSidebar";
import PageLayout from "../layouts/PageLayout";
import { jobsSelector } from "../../states/features/selectors";
import { WorkHistory } from "@mui/icons-material";
import CustomTabs from "../../components/tabs/CustomTabs";
import JobPagination from "../../components/paginations/JobPagination";

const JobsForYou = () => {
    // ############## Redux state #################
    const {
        jobsForYouORG,
        jobsForYouEmployer,
        appliedORGJobs,
        appliedEmployerJobs,
    } = useSelector(jobsSelector);
    const appliedJobsForYou = [...appliedORGJobs, ...appliedEmployerJobs];

    // ############## FUNC ####################
    // => Get job by application status
    const getJobByApplicationStatus = () => {
        let appliedJobs = filter(
            appliedJobsForYou,
            ({ isApplied }) => isApplied === true
        );
        let shortlistedJobs = filter(
            appliedJobsForYou,
            ({ application }) => application.status === "shortlisted"
        );
        let acceptedJobs = filter(
            appliedJobsForYou,
            ({ application }) => application.status === "accepted"
        );
        let onGoingJobs = filter(
            appliedJobsForYou,
            ({ is_on_going }) => is_on_going === 1
        );
        let completedJobs = filter(
            appliedJobsForYou,
            ({ is_completed }) => is_completed === 1
        );
        let declinedJobs = filter(
            appliedJobsForYou,
            ({ application }) => application.status === "declined"
        );

        return {
            appliedJobs,
            shortlistedJobs,
            acceptedJobs,
            onGoingJobs,
            completedJobs,
            declinedJobs,
        };
    };

    const appliedJobs = getJobByApplicationStatus().appliedJobs;
    const shortlistedJobs = getJobByApplicationStatus().shortlistedJobs;
    const acceptedJobs = getJobByApplicationStatus().acceptedJobs;
    const onGoingJobs = getJobByApplicationStatus().onGoingJobs;
    const completedJobs = getJobByApplicationStatus().completedJobs;
    const declinedJobs = getJobByApplicationStatus().declinedJobs;

    const infoBoxData = [
        {
            id: 1,
            name: "Applied",
            total: size(appliedJobs),
            color: colors.warning,
            url: "status/applied",
        },
        {
            id: 2,
            name: "Shortlisted",
            total: size(shortlistedJobs),
            color: colors.info,
            url: "status/shortlisted",
        },
        {
            id: 3,
            name: "Accepted",
            total: size(acceptedJobs),
            color: colors.success,
            url: "status/accepted",
        },
        {
            id: 4,
            name: "On-going",
            total: size(onGoingJobs),
            color: colors.info,
            url: "status/on-going",
        },
        {
            id: 5,
            name: "Completed",
            total: size(completedJobs),
            color: colors.success,
            url: "status/completed",
        },
        {
            id: 6,
            name: "Declined",
            total: size(declinedJobs),
            color: colors.danger,
            url: "status/declined",
        },
    ];

    const tabs = [
        {
            id: 1,
            title: "Organization jobs for you",
            icon: <WorkHistory />,
            chipColor: "success",
            total: size(jobsForYouORG),
            tabsContent: (
                <JobPagination
                    jobs={jobsForYouORG}
                    height={"50vh"}
                    type={"org"}
                />
            ),
        },
        {
            id: 2,
            title: "Employers jobs for you",
            icon: <WorkHistory />,
            chipColor: "info",
            total: size(jobsForYouEmployer),
            tabsContent: (
                <JobPagination
                    jobs={jobsForYouEmployer}
                    height={"50vh"}
                    type={"employer"}
                />
            ),
        },
    ];

    return (
        <PageLayout head="Jobs for you" sidebar={<JobsSidebar />}>
            <Grid container spacing={2}>
                {infoBoxData.map((data) => (
                    <Grid item sm={4} key={data.id}>
                        <Link to={data.url}>
                            <JobInfoCard info={data} />
                        </Link>
                    </Grid>
                ))}
                <Grid item sm={12}>
                    <CustomTabs
                        head={"Active jobs for you"}
                        tabs={tabs}
                        noHeight={"60vh"}
                        padding={"0 !important"}
                        borderBottom={false}
                    />
                </Grid>
            </Grid>
        </PageLayout>
    );
};

export default JobsForYou;
