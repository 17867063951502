import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
    Avatar,
    AvatarGroup,
    Button,
    Chip,
    Tooltip,
    Typography,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Box } from "@mui/system";
import {
    Bookmark,
    BookmarkBorderOutlined,
    CategoryOutlined,
    PinDropOutlined,
    Preview,
} from "@mui/icons-material";
import { useState } from "react";
import moment from "moment";
import CustomDialog from "../Dialogs/CustomDialog";
import { isEmpty, take } from "lodash";
import ORGApplicantDetails from "../../pages/jobs/components/ORGApplicantDetails";
import IndividualApplicantDetails from "../../pages/jobs/components/IndividualApplicantDetails";
import GroupApplicantDetails from "../../pages/jobs/components/GroupApplicantDetails";

// ######################## Table column #######################
const columns = [
    { id: "s_n", label: "S/N", minWidth: 1, align: "center" },
    { id: "name", label: "Applicant name" },
    {
        id: "date",
        label: "Date applied",
        align: "left",
    },
    {
        id: "type",
        label: "Type",
        align: "center",
    },
    {
        id: "status",
        label: "Status",
        align: "center",
    },
    {
        id: "action",
        label: "Action",
        align: "center",
    },
];
function createData(s_n, name, date, type, status, action) {
    return {
        s_n,
        name,
        date,
        type,
        status,
        action,
    };
}

// ######################## USER CARD #######################
const UserCardORG = ({ applicant }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 3,
            position: "relative",
            overflow: "hidden",
        }}
    >
        <Avatar
            src={applicant.org_logo}
            sx={{
                width: 65,
                height: 65,
                mr: 2,
                p: 0.5,
                border: `1px dotted ${colors.info}`,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                img: {
                    objectFit: "contain",
                },
            }}
        />
        <Box>
            <Typography
                sx={{
                    fontSize: 16,
                    color: colors.info,
                    fontWeight: "bold",
                }}
                noWrap
            >
                {`${applicant.organization_name}`}
            </Typography>
            <Typography
                sx={{
                    fontSize: 13,
                    display: "flex",
                    alignItems: "center",
                    color: colors.primary,
                    mt: 1,
                    fontWeight: "bold",
                    opacity: 0.8,
                }}
            >
                <PinDropOutlined color="warning" sx={{ mr: 1, fontSize: 15 }} />
                {applicant.location}
            </Typography>
        </Box>
    </Box>
);
const UserCardIndividual = ({ applicant }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 3,
            position: "relative",
            overflow: "hidden",
        }}
    >
        <Avatar
            src={applicant.profile_picture}
            sx={{
                width: 65,
                height: 65,
                mr: 2,
                p: 0.5,
                border: `1px dotted ${colors.info}`,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                img: {
                    borderRadius: 50,
                    objectFit: "cover",
                },
            }}
        />
        <Box>
            <Typography
                sx={{
                    fontSize: 16,
                    color: colors.info,
                    fontWeight: "bold",
                }}
                noWrap
            >
                {`${applicant.first_name} ${applicant?.middle_name} ${applicant.last_name}`}
            </Typography>
            <Typography
                sx={{
                    fontSize: 13,
                    display: "flex",
                    alignItems: "center",
                    color: colors.primary,
                    mt: 1,
                    fontWeight: "bold",
                    opacity: 0.8,
                }}
            >
                <PinDropOutlined color="warning" sx={{ mr: 1, fontSize: 15 }} />
                {`${applicant.region}, ${applicant.district}`}
            </Typography>
        </Box>
    </Box>
);

const UserCardGroup = ({ applicant }) => (
    <Box
        sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: 3,
            position: "relative",
            overflow: "hidden",
        }}
    >
        <AvatarGroup>
            <Avatar
                src={applicant.banner}
                sx={{
                    width: 65,
                    height: 65,
                    mr: -1,
                    p: 0.5,
                    border: `1px dotted ${colors.info} !important`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    img: {
                        borderRadius: 50,
                        objectFit: "cover",
                    },
                }}
            />
            {take(applicant?.members, 3).map((member) => (
                <Avatar
                    alt="DP"
                    src={member.profile_picture}
                    sx={{
                        width: 18,
                        height: 18,
                        mr: 1,
                        p: 0.5,
                        border: `1px dotted ${colors.info} !important`,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        img: {
                            borderRadius: 50,
                            objectFit: "cover",
                        },
                    }}
                />
            ))}
        </AvatarGroup>
        <Box>
            <Typography
                sx={{
                    fontSize: 16,
                    color: colors.info,
                    fontWeight: "bold",
                }}
                noWrap
            >
                {`${applicant.group_name} (${applicant.group_short_name})`}
            </Typography>
            <Typography
                sx={{
                    fontSize: 13,
                    display: "flex",
                    alignItems: "center",
                    color: colors.primary,
                    mt: 1,
                    fontWeight: "bold",
                    opacity: 0.8,
                }}
                noWrap
            >
                {/* <CategoryOutlined
                    color="warning"
                    sx={{ mr: 1, fontSize: 15 }}
                /> */}
                {`${applicant.group_reg_number}`}
            </Typography>
        </Box>
    </Box>
);

// !###################### MAIN FUNC ########################
const JobApplicantTable = ({ applicants, height }) => {
    // ################# Comp State ###################
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [applicantDetails, setApplicantDetails] = useState({});

    // ################# FUNC ###############
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // -> Dialogy state
    const [openApplicantDialogy, setopenApplicantDialogy] = useState(false);
    const handleOpenApplicantDialogy = (applicant) => {
        setApplicantDetails(applicant);
        setopenApplicantDialogy(true);
    };
    const handleCloseDialogy = () => {
        setopenApplicantDialogy(false);
    };

    // ######################## TABLE ROW #######################
    const rows = applicants?.map((applicant, index) => {
        return createData(
            <Typography
                sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    opacity: 0.6,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {++index}
                {applicant?.application?.isSaved === 0 ? (
                    <BookmarkBorderOutlined color="warning" />
                ) : (
                    <Bookmark color="warning" />
                )}
            </Typography>,
            applicant?.type === "company" ? (
                <UserCardORG applicant={applicant} />
            ) : applicant?.type === "group" ? (
                <UserCardGroup applicant={applicant} />
            ) : (
                <UserCardIndividual applicant={applicant} />
            ),
            <Typography
                noWrap
                sx={{ fontSize: 13, fontWeight: "bold", opacity: 0.8 }}
            >
                {moment(applicant.application.created_at).format("MMM Do YYYY")}
            </Typography>,
            <Chip
                size="small"
                sx={{
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "bold",
                    px: 1,
                    opacity: 0.8,
                }}
                color={
                    applicant?.type === "secondary"
                        ? "secondary"
                        : applicant?.type === "company"
                        ? "info"
                        : applicant?.type === "individual"
                        ? "secondary"
                        : applicant?.type === "group"
                        ? "primary"
                        : "default"
                }
                label={applicant?.type}
            />,
            <Chip
                size="small"
                sx={{
                    textTransform: "capitalize",
                    fontSize: 12,
                    fontWeight: "bold",
                    px: 1,
                    opacity: 0.8,
                }}
                color={
                    applicant?.application.status === "pending"
                        ? "warning"
                        : applicant?.application.status === "accepted"
                        ? "success"
                        : applicant?.application.status === "declined"
                        ? "error"
                        : applicant?.application.status === "dropped"
                        ? "primary"
                        : "secondary"
                }
                label={applicant?.application?.status}
            />,
            // //? #################### ACTION BUTTON #######################
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "end",
                    textAlign: "center",
                }}
            >
                <Tooltip title="View applicant profile" arrow>
                    <Button
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            py: 0.5,
                            m: 0.4,
                        }}
                        color="info"
                        variant="outlined"
                        onClick={() => {
                            handleOpenApplicantDialogy(applicant);
                        }}
                    >
                        <Preview sx={{ fontSize: 12, mr: 0.4 }} />
                        More details
                    </Button>
                </Tooltip>
            </Box>
        );
    });

    return (
        <React.Fragment>
            {/* ############# Dialog ################ */}
            <CustomDialog
                head={"Job applicant details"}
                openDialog={openApplicantDialogy}
                customWidth={"md"}
                handleDialogClose={handleCloseDialogy}
            >
                {!isEmpty(applicantDetails) &&
                    (applicantDetails.type === "company" ? (
                        <ORGApplicantDetails
                            applicant={applicantDetails}
                            handleDialogClose={handleCloseDialogy}
                        />
                    ) : applicantDetails.type === "individual" ? (
                        <IndividualApplicantDetails
                            applicant={applicantDetails}
                            handleDialogClose={handleCloseDialogy}
                        />
                    ) : (
                        <GroupApplicantDetails
                            applicant={applicantDetails}
                            handleDialogClose={handleCloseDialogy}
                        />
                    ))}
            </CustomDialog>

            <Paper
                sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}
            >
                <TableContainer
                    sx={{
                        height: height ? height : "61vh",
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: "bold",
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            fontSize: 12,
                                            color: colors.primary,
                                            whiteSpace: "nowrap",
                                            opacity: 0.8,
                                        }}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            "number"
                                                            ? column.format(
                                                                  value
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[1, 2, 5, 10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};

export default JobApplicantTable;
