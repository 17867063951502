// => Get all
const GET_ALL = '/practical-trainings';

// => Get other organization pts
const GET_OTHER_ORG_PTs = '/practical-trainings/organizations/other';

// => Get intenal organization pts
const GET_ORG_PTs = '/practical-trainings/organizations/intenal';

// => Get special organization pts
const GET_SPECIAL_PTs = '/practical-trainings/organizations/special';

// => Get single
const GET_SINGLE = '/practical-trainings';

// => Create PT
const CREATE_PT = '/practical-trainings';

// => Publish PT
const PUBLIS_PT = '/practical-trainings/publish';

// => Close PT
const CLOSE_PT = '/practical-trainings/close';

// => Approve PT to students
const APPROVE_PT_TO_STUDENT = '/practical-trainings/approve';

// => Decline PT
const DECLINE_SPECIAL_PT = '/practical-trainings/decline';

// => Update PT
const UPDATE_PT = '/practical-trainings';

// => Delete PT
const DELETE_PT = '/practical-trainings';

// => ALL PT applicants
const ALL_PT_APPLICANTS = '/practical-trainings/applicants/all';

// => SINGLE PT applicants
const SINGLE_PT_APPLICANTS = '/practical-trainings/applicants/single';

// => APPROVE Application
const APPROVE_APPLICATION = '/practical-trainings/applicants/approve';

// => DECLINE Application
const DECLINE_APPLICATION = '/practical-trainings/applicants/decline';

// => STUDENT arrrival
const APPROVE_STUDENT_ARRIVAL = '/practical-trainings/applicants/arrival';

// => STUDENT complete
const APPROVE_STUDENT_COMPLETE = '/practical-trainings/applicants/complete';

export const practicalTrainingsEndpoints = {
    GET_ALL,
    GET_ORG_PTs,
    GET_SPECIAL_PTs,
    GET_OTHER_ORG_PTs,
    GET_SINGLE,
    CREATE_PT,
    PUBLIS_PT,
    CLOSE_PT,
    APPROVE_PT_TO_STUDENT,
    DECLINE_SPECIAL_PT,
    UPDATE_PT,
    DELETE_PT,
    ALL_PT_APPLICANTS,
    SINGLE_PT_APPLICANTS,
    APPROVE_APPLICATION,
    DECLINE_APPLICATION,
    APPROVE_STUDENT_ARRIVAL,
    APPROVE_STUDENT_COMPLETE,
};
