import { createSlice } from '@reduxjs/toolkit';
import { removeArrObj, updateArrObj } from '../../../helpers/arrayHelpers';
import { isEmpty } from 'lodash';

const initialState = {
    practicalTrainings: [],
    ORGPracticalTrainings: [],
    specialPracticalTrainings: [],
    otherPracticalTrainings: [],
    practicalTrainingDetails: {},
    allPracticalTrainingApplicants: [],
    singlePracticalTrainingApplicants: [],
};

const practicalTrainingsSlice = createSlice({
    name: 'practicalTrainingsSlice',
    initialState,
    reducers: {
        // => Set practical trainings
        setPracticalTrainings: (state, action) => {
            let practicalTrainings = action.payload;
            state.practicalTrainings = practicalTrainings;
        },
        // => Set ORG practical trainings
        setORGPracticalTrainings: (state, action) => {
            let practicalTrainings = action.payload;
            state.ORGPracticalTrainings = practicalTrainings;
        },
        // => Set special ORG practical trainings
        setSpecialORGPracticalTrainings: (state, action) => {
            let practicalTrainings = action.payload;
            state.specialPracticalTrainings = practicalTrainings;
        },
        // => Set other practical trainings
        setOtherPracticalTrainings: (state, action) => {
            let practicalTrainings = action.payload;
            state.otherPracticalTrainings = practicalTrainings;
        },
        // => Set all practical training applicants
        setAllPracticalTrainingApplicants: (state, action) => {
            let applicants = action.payload;
            state.allPracticalTrainingApplicants = applicants;
        },
        // => Set single practical training applicants
        setSinglePracticalTrainingApplicants: (state, action) => {
            let applicants = action.payload;
            state.singlePracticalTrainingApplicants = applicants;
        },
        // => Add practical trainings
        addPracticalTraining: (state, action) => {
            let practicalTraining = action.payload;
            state.practicalTrainings = [
                ...practicalTraining,
                state.practicalTrainings,
            ];
        },
        // => Add ORG practical trainings
        addORGPracticalTraining: (state, action) => {
            let practicalTraining = action.payload;
            isEmpty(state.ORGPracticalTrainings)
                ? (state.ORGPracticalTrainings = [practicalTraining, ...[]])
                : (state.ORGPracticalTrainings = [
                      practicalTraining,
                      ...state.ORGPracticalTrainings,
                  ]);
        },
        // => Set practical training details
        setPracticalTrainingDetails: (state, action) => {
            let details = action.payload;
            state.practicalTrainingDetails = details;
        },
        removePracticalTraining: (state, actions) => {
            let payload = actions.payload;
            let practicalTrainings = removeArrObj(
                'id',
                payload,
                state.ORGPracticalTrainings,
            );
            state.ORGPracticalTrainings = practicalTrainings;
        },
        updateApplicantDetails: (state, actions) => {
            let newApplicant = actions.payload;
            let singleTrainingApplicants = updateArrObj(
                'student_id',
                newApplicant,
                state.singlePracticalTrainingApplicants,
            );
            state.singlePracticalTrainingApplicants = singleTrainingApplicants;
            let allApplicants = updateArrObj(
                'student_id',
                newApplicant,
                state.allPracticalTrainingApplicants,
            );
            state.allPracticalTrainingApplicants = allApplicants;
        },
        resetPTStates: (state) => {
            state.practicalTrainings = [];
            state.ORGPracticalTrainings = [];
            state.specialPracticalTrainings = [];
            state.otherPracticalTrainings = [];
            state.practicalTrainingDetails = {};
            state.allPracticalTrainingApplicants = [];
            state.singlePracticalTrainingApplicants = [];
        },
    },
});

export const {
    setPracticalTrainings,
    setORGPracticalTrainings,
    setSpecialORGPracticalTrainings,
    setOtherPracticalTrainings,
    addPracticalTraining,
    addORGPracticalTraining,
    setPracticalTrainingDetails,
    removePracticalTraining,
    setAllPracticalTrainingApplicants,
    setSinglePracticalTrainingApplicants,
    updateApplicantDetails,
    resetPTStates,
} = practicalTrainingsSlice.actions;

export default practicalTrainingsSlice.reducer;
