import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { colors } from '../../assets/utils/constants';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge copy';
import PTStatusInfobox from './components/PTStatusInfobox';
import PracticalTrainingSidebarCompany from '../../components/sidebars/PracticalTrainingSidebarCompany';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import CustomPagination from '../../components/paginations/CustomPagination';
import TrainingFilters from '../../components/filters/TrainingFilters';
import { paginator } from '../../helpers/paginationHelper';

const OrganizationPT = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);
    const [trainings, setTraining] = useState([]);

    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ############## CONST ####################
    // => Intenal PTs
    const intenalPTs = filter(
        ORGPracticalTrainings,
        ({ organization_id }) => organization_id === user.id,
    );
    // => Active PTs
    const activePTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'published',
    );

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Filter by training name
    const filterByPTName = (search) => {
        let filteredTraining = [];
        if (search) {
            filteredTraining = activePTs.filter((training) => {
                return training.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setTraining(filteredTraining);
        }
        return setTraining(activePTs);
    };

    // const infoBoxData = [
    //     {
    //         id: 1,
    //         title1: 'Approved',
    //         title2: 'Practical training',
    //         total: 2,
    //         colorSign: colors.secondary,
    //         url: 'organization',
    //     },
    //     {
    //         id: 2,
    //         title1: 'Allocated',
    //         title2: 'Practical training',
    //         total: 45,
    //         colorSign: colors.secondary,
    //         url: 'other-organizations',
    //     },
    //     {
    //         id: 3,
    //         title1: 'On-Going',
    //         title2: 'Practical training',
    //         total: 45,
    //         colorSign: colors.secondary,
    //         url: 'other-organizations',
    //     },
    // ];

    // => Pagination data
    const paginatedActivePTs = paginator(trainings, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setTraining(activePTs);
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <PageLayout
                head={'Practical trainings'}
                sidebar={
                    user?.organization_type?.name === 'Institution' ? (
                        <PracticalTrainingSidebar />
                    ) : (
                        <PracticalTrainingSidebarCompany />
                    )
                }
            >
                <PTStatusInfobox />
                <Grid container spacing={2}>
                    {/* {infoBoxData.map((data) => (
                        <Grid item sm={4} key={data.id}>
                            <InfoboxWidget2
                                data={data}
                                colorSign={data.colorSign}
                            />
                        </Grid>
                    ))} */}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={'Active internal practical trainings'}
                            height={'53vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TrainingFilters
                                        filterByJobName={filterByPTName}
                                        // filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(trainings)}
                                        item={'Active PTs'}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(trainings) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default OrganizationPT;
