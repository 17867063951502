import React from 'react';
import { Autocomplete, Popper, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import { regionList } from '../../assets/utils/regionList';

const SmallCustomSelectInput = ({
    formik,
    label,
    color,
    children,
    handler,
    popperPlacement,
    popperWidth,
    ...props
}) => {
    const regions = regionList;
    const [field, meta] = useField(props);
    const [value, setValue] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');

    const handleChange = (payload) => {
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, payload);
        // -> Handler
        handler && handler(payload?.label);
    };

    const PopperMy = function (props) {
        return (
            <Popper
                {...props}
                style={{ width: popperWidth ? popperWidth : 250 }}
                placement={popperPlacement ? popperPlacement : 'left-start'}
            />
        );
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: 1.5,
                }}
            >
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        handleChange(newInputValue);
                        setInputValue(newInputValue);
                    }}
                    options={regions}
                    size={'small'}
                    sx={{ color: `${color ? color : '#eee'}` }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            {...props}
                            label={label}
                            variant="filled"
                        />
                    )}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default SmallCustomSelectInput;
