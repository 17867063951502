import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Box, Grid, Typography } from '@mui/material';
import CustomTextField from '../../../components/Forms/CustomTextField';
import { useDispatch } from 'react-redux';
import { ManageAccounts } from '@mui/icons-material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { useUpdateORGNameMutation } from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import useRTK from '../../../hooks/useRTK';

// ################## VALIDATION ####################
// * -> name
const nameValidationSchema = Yup.object({
    organization_name: Yup.string().required(
        'Organization name is required field',
    ),
    org_short_name: Yup.string()
        .required('Organization short name is required field')
        .max(10, 'Organization short name must be at most 10 characters')
        .nullable(),
});

// !############## MAIN FUNC ###############
const UpdateOrgNames = ({ user, refetchProfile, closeDialogy }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        updateORGNames,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateORGNameMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    organization_name: user.organization_name,
                    org_short_name: user.org_short_name
                        ? user.org_short_name
                        : '',
                }}
                validationSchema={nameValidationSchema}
                onSubmit={(payload) => {
                    let id = user.id;
                    payload = {
                        id,
                        ...payload,
                    };
                    updateORGNames(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container>
                                    <Grid item sm={12} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`${user.organization_type.name} name`}
                                            type="text"
                                            name="organization_name"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`${user.organization_type.name} short name`}
                                            type="text"
                                            name="org_short_name"
                                        />
                                        <Typography
                                            sx={{
                                                position: 'relative',
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Maxmum 10 characters
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<ManageAccounts />}
                                        loading={isLoading}
                                        btnColor={'primary'}
                                        onClick={formik.handleSubmit}
                                    >
                                        Update name
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateOrgNames;
