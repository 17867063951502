import React from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

// ======= MUI styled comp ===============
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import {
    ArrowRightAltOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import useRTK from '../../hooks/useRTK';
import { useSelector } from 'react-redux';
import {
    registerSelector,
    settingsSelector,
} from '../../states/features/selectors';
import CustomPasswordField from '../../components/Forms/CustomPasswordField';
import { useResetPasswordMutation } from '../../api/auth/authAPI';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

// ############## validation ###############
const validation = Yup.object({
    new_password: Yup.string().required().min(8),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('confirm password is required field')
        .min(8, 'Password must be at least 8 characters'),
});

const ResetPassword = () => {
    // ################# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();

    // ############## Redux status ############
    const { ORGEmail } = useSelector(settingsSelector);

    // ############### RTK #####################
    // => Request OTP
    const [
        resetPassword,
        { isLoading, isSuccess, isError, error, data: otpData },
    ] = useResetPasswordMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        setTimeout(() => {
            navigate('/');
        }, 5);
    };
    RTK.useRTKResponse(isSuccess, isError, error, otpData, rtkActions);

    return (
        <React.Fragment>
            <Grid item sm={10}>
                <FormBox>
                    <Typography className="head">Password reset</Typography>
                    <Typography className="sub-head">
                        Provide your new password
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            email: ORGEmail,
                            new_password: '',
                        }}
                        validationSchema={validation}
                        onSubmit={(payload) => {
                            resetPassword(payload);
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    padding={2}
                                >
                                    {/* ######### phone ######### */}
                                    <Grid item sm={11} xs={12}>
                                        <CustomPasswordField
                                            id="password"
                                            className="form-input"
                                            label={'New password'}
                                            type="password"
                                            name="new_password"
                                            bBottom={colors.secondary}
                                        />
                                        <Typography
                                            sx={{
                                                marign: 0,
                                                padding: 0,
                                                fontSize: 12,
                                                color: colors.warning,
                                            }}
                                        >
                                            Note :- password should contain
                                            atleast 8 characters
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={11} xs={12} sx={{ mb: 5 }}>
                                        <CustomPasswordField
                                            id="confirm_password"
                                            className="form-input"
                                            label={'Confirm password'}
                                            type="password"
                                            name="confirm_password"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={11}
                                        xs={12}
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<VerifiedUserOutlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Reset password
                                        </CustomSubmitButton>
                                    </Grid>

                                    {/* ######### forget ######### */}
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/'}>
                                            <Typography
                                                className="sign_in"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: colors.secondary,
                                                }}
                                            >
                                                Sign in here
                                                <ArrowRightAltOutlined color="secondary" />
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default ResetPassword;
