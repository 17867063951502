import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { colors } from '../../assets/utils/constants';
import CustomTextField from '../../components/Forms/CustomTextField';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

// ======= MUI styled comp ===============
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from '../../states/features/selectors';
import { isEmpty } from 'lodash';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import {
    getRTKErrorMessage,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import { errorNotify, successNotify } from '../../helpers/notify';
import { setORGEmail } from '../../states/features/settings/settingsSlice';
import { useRequestORGEmailVerificationMutation } from '../../api/auth/authAPI';

// ======== form box ==============
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '50px 25px',
    '& .head': {
        fontSize: 50,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 20,
        textAlign: 'center',
        marginTop: -10,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .forget': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        'padding': '50px 5px',
        '& .head': {
            fontSize: 35,
        },
        '& .sub-head': {
            fontSize: 15,
        },
        '& .sigin-btn': {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
}));

// ========= validation ================
const validation = Yup.object({
    email: Yup.string().required(),
});

const RequestEmailVerification = () => {
    // ############## CONST ############
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############## Comp states ############
    const [reqEmail, setReqEmail] = useState('');

    // ############## Redux states ############
    const { registerAs } = useSelector(settingsSelector);
    const name = registerAs.name;

    // ############### RTK #####################
    // => Request
    const [
        requestORGEmailVerification,
        {
            isLoading: requestLoading,
            isSuccess: emailSuccess,
            isError: emailIsError,
            error: emailError,
            data: emailData,
        },
    ] = useRequestORGEmailVerificationMutation();

    // ############## useEffect ############
    // => Request verification code
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (emailSuccess) {
                navigate('/verify-email');
                dispatch(setORGEmail(reqEmail));
                setTimeout(() => {
                    successNotify(getRTKSuccessMessage(emailData));
                }, 1000);
            }
            if (emailIsError) {
                errorNotify(getRTKErrorMessage(emailError));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [
        emailSuccess,
        emailIsError,
        emailData,
        emailError,
        reqEmail,
        navigate,
        dispatch,
    ]);
    // => Check register as
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isEmpty(registerAs)) {
                navigate('/register-as');
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [registerAs, navigate]);

    return (
        <React.Fragment>
            <Grid item sm={10}>
                <FormBox>
                    <Typography className="head">Email verification</Typography>
                    <Typography className="sub-head">
                        Please provide {name} email to be verified
                    </Typography>
                    {/* ######### Form ######## */}
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={validation}
                        onSubmit={(payload) => {
                            setReqEmail(payload.email);
                            requestORGEmailVerification(payload);
                        }}
                    >
                        {(formik) => (
                            <Form style={{ marginTop: 25 }}>
                                <Grid container justifyContent={'center'}>
                                    {/* ######### phone ######### */}
                                    <Grid item sm={10} xs={11}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`${name} email`}
                                            type="email"
                                            name="email"
                                        />
                                    </Grid>

                                    {/* ######### sign btn ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={10}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            loading={requestLoading}
                                            variant={'contained'}
                                            btnColor={'secondary'}
                                            sx={{ py: 1.3, fontSize: 15 }}
                                            onClick={formik.handleSubmit}
                                        >
                                            Request email verification CODE
                                        </CustomSubmitButton>
                                    </Grid>

                                    {/* ######### forget ######### */}
                                    <Grid
                                        item
                                        sm={10}
                                        md={10}
                                        xs={10}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/'}>
                                            <Typography className="forget">
                                                Sign in here ...
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Grid>
        </React.Fragment>
    );
};

export default RequestEmailVerification;
