import { Box, Typography } from '@mui/material';
import React from 'react';
import CustomCard2 from '../cards/CustomCard2';

const JobPostingSidebar = () => {
    return (
        <CustomCard2 head={'Quick tips'}>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {' '}
                    Job posting include four steps
                </Typography>
                <ol>
                    <li>Job detals</li>
                    <li>Job responsibilities</li>
                    <li>Skills & Requirements</li>
                    <li>Applicants & Posting fee</li>
                </ol>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    1: Job detals
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    All field required to be filled except{' '}
                    <strong>Salary & Salary scale</strong>, Which is optional.
                </Typography>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    2: Job responsibilities
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    Is optional to provide job responsibilities.
                </Typography>
            </Box>
            <Box sx={{ mb: 1.5 }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    3: Skills & Requirements
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    Is option to provide job skills and requirements.
                </Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                    4: Applicants & Posting fee
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                    Posting fee calculated based on number of
                    applicants and positions.You can save job and pay
                    latter or you can save and pay instatly.
                </Typography>
            </Box>
        </CustomCard2>
    );
};

export default JobPostingSidebar;
