import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../../components/cards/CustomCard2';
import PracticalTrainingFilter from '../../components/filters/PracticalTrainingFilter';
import RegionList from '../../components/RegionList';
import FieldWidget from '../../components/widgets/FieldWidget';
import PageLayout from '../layouts/PageLayout';

const ApprovedPT = () => {
    return (
        <>
            <PageLayout
                head={'Approved practical trainings'}
                noSidebar
            >
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        sm={3}
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'block',
                            },
                        }}
                    >
                        <CustomCard2
                            head={'Filter by region'}
                            height={560}
                            borderBottom
                        >
                            <RegionList />
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={9}>
                        <Box
                            sx={{
                                height: 90,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                borderRadius: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative',
                                overflow: 'hidden',
                                px: 3,
                                mb: 2,
                            }}
                        >
                            <PracticalTrainingFilter />
                        </Box>
                        <CustomCard2
                            head={'Active practical trainings'}
                            height={455}
                            borderBottom
                        >
                            <Grid container spacing={2}>
                                {[1, 2, 3, 4, 5, 6].map((index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <FieldWidget />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default ApprovedPT;
