import React, { useState } from "react";
import { Leaderboard, PostAddTwoTone, Settings } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colors } from "../../assets/utils/constants";
import CustomCard2 from "../cards/CustomCard2";
import CustomDialog from "../Dialogs/CustomDialog";
import CreatePracticalTrainingStep from "../steppers/CreatePracticalTrainingStep";
import StatsInfoBox from "../infoBoxes/StatsInfoBox";
import {
    PTSelector,
    jobsSelector,
    transactionSelector,
} from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { filter, size } from "lodash";
import { numFormater } from "../../helpers/numFormater";
import { Link } from "react-router-dom";

const DashSidebar = () => {
    const [openDialog, setOpenDialogy] = useState(false);

    // ################# Redux state #################
    const { jobTransactions } = useSelector(transactionSelector);
    const { jobs } = useSelector(jobsSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ################ FUNC ##################
    const getTotalAmount = (status) => {
        let transactions = filter(
            jobTransactions,
            ({ transaction }) => transaction.status === status
        );
        let totalAmount = 0;
        transactions.forEach((transaction) => {
            totalAmount += parseInt(transaction.transaction.amount);
        });
        return {
            transactions,
            total: size(transactions),
            amount: totalAmount,
        };
    };
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    const dashStats = [
        {
            id: 1,
            title: "Posted jobs",
            stats: `${size(jobs)} Jobs`,
            borderColor: colors.info,
        },
        {
            id: 2,
            title: "Posted trainings",
            stats: `${size(ORGPracticalTrainings)} Training`,
            borderColor: colors.info,
        },
        {
            id: 3,
            title: "Active jobs",
            stats: `${size(filter(jobs, ["status", "published"]))} Jobs`,
            borderColor: colors.success,
        },
        {
            id: 4,
            title: "Active trainings",
            stats: `${size(
                filter(ORGPracticalTrainings, ["status", "published"])
            )} Training`,
            borderColor: colors.success,
        },
    ];
    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={"Create new practical training vacancy"}
                openDialog={openDialog}
                customWidth={"lg"}
                handleDialogClose={handleDialogClose}
            >
                <CreatePracticalTrainingStep />
            </CustomDialog>

            <CustomCard2 head={"Overview"} height={620}>
                {/* ########## POSTING FEE HISTORY ############# */}
                <Box
                    sx={{
                        height: 100,
                        background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4,
                        position: "relative",
                        boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                    }}
                >
                    <Box
                        sx={{
                            width: 15,
                            background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                            position: "absolute",
                            top: 20,
                            bottom: 20,
                            left: 0,
                            borderTopRightRadius: 100,
                            borderBottomRightRadius: 100,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 15,
                            background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                            position: "absolute",
                            top: 20,
                            bottom: 20,
                            right: 0,
                            borderTopLeftRadius: 100,
                            borderBottomLeftRadius: 100,
                        }}
                    ></Box>
                    <Tooltip title={"Show history"}>
                        <Link to={"/transactions"}>
                            <IconButton
                                size="small"
                                color="info"
                                sx={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: 15,
                                    bgcolor: colors.bgColor5,
                                }}
                            >
                                <Settings sx={{ fontSize: 14 }} />
                            </IconButton>
                        </Link>
                    </Tooltip>
                    <Typography
                        sx={{
                            fontSize: 25,
                            fontWeight: "bold",
                            color: colors.success,
                        }}
                    >
                        {numFormater(getTotalAmount("success").amount)}
                        <sup style={{ fontSize: 16 }}>TZS</sup>
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                        Posting fee history
                    </Typography>
                </Box>

                {/* ########## STATISTICS ############# */}
                <Box sx={{ mt: 5 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            opacity: 0.8,
                        }}
                    >
                        <Leaderboard sx={{ mr: 1, color: colors.info }} />
                        <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                            {" "}
                            Statistics
                        </Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                        {dashStats.map((stats) => (
                            <Grid item sm={6} key={stats.id}>
                                <StatsInfoBox stats={stats} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* ########## NEW FIELD VACANCY ############# */}
                <Box
                    sx={{
                        mt: 4,
                        "& .create-field": {
                            background: `linear-gradient(180deg, ${colors.secondary},  ${colors.secondary}) !important`,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 15,
                            borderTopLeftRadius: 15,
                            boxShadow: `1px 1px 5px 0px  ${colors.bgColor5}`,
                            p: 2,
                            "& .icon": {
                                transition: `.3s ease-in-out all`,
                            },
                            ":hover": {
                                "& .icon": {
                                    transform: `scale(1.3) rotate(360deg)`,
                                    transition: `.3s ease-in-out all`,
                                },
                            },
                            "& .icon-con": {
                                height: 45,
                                width: 45,
                                borderRadius: 80,
                                border: `3px solid ${colors.primary}`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            },
                        },
                    }}
                >
                    <Box className="create-field">
                        <Typography
                            sx={{ color: colors.primary, fontSize: 14 }}
                        >
                            In learning you will teach, and in teaching you will
                            learn.
                        </Typography>
                        <Button
                            fullWidth
                            onClick={handleDialogyOpen}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                background: `linear-gradient(180deg, ${colors.secondary},  ${colors.secondary}) !important`,
                            }}
                        >
                            <Box className="icon-con">
                                <PostAddTwoTone
                                    sx={{ color: colors.primary }}
                                    className="icon"
                                />
                            </Box>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    mt: 2,
                                }}
                            >
                                New field vacancy
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </CustomCard2>
        </>
    );
};

export default DashSidebar;
