import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Switch,
    Typography,
    styled,
} from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import {
    Add,
    ArrowCircleRightOutlined,
    ImportExportOutlined,
} from '@mui/icons-material';
import CustomCard2 from '../cards/CustomCard2';
import OnTrainingStudentsTable from '../Tables/OnTrainingStudentsTable';
import { Link } from 'react-router-dom';
import InnerContentLoader from '../InnerContentLoader';
import { useSelector } from 'react-redux';
import {
    studentsSelector,
    trainingSeasonSelector,
} from '../../states/features/selectors';
import { filter, isEmpty, size, take } from 'lodash';
import NoContent from '../NoContent';
import moment from 'moment';
import CurrentTrainingSeasonWidget from '../widgets/CurrentTrainingSeasonWidget';
import CustomPagination from '../paginations/CustomPagination';
import { paginator } from '../../helpers/paginationHelper';
import OnFieldStudentWidget from '../widgets/OnFieldStudentWidget';

const CustomBtn = styled(Box)({
    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
    'padding': 5,
    'borderRadius': 15,
    'overflow': 'hidden',
    'boxShadow': `1px 2px 5px 10px  ${colors.bgColor3}`,
    '.btn': {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        fontSize: 16,
        display: 'flex',
        flexDirection: 'column',
    },
});

const StudentSidebar = ({ handleDialogyOpen, currSeasonLoading }) => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############## Redux state ###############
    const { trainingSeasons } = useSelector(trainingSeasonSelector);
    const { students } = useSelector(studentsSelector);

    // ############## FUNC ####################
    // => Get on-training students
    const getOnTrainingStudents = () => {
        let onTrainingStudents = filter(
            students,
            ({ isOnTraining }) => isOnTraining === true,
        );
        return onTrainingStudents;
    };
    const onTrainingStudents = getOnTrainingStudents();

    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Get current season
    const getCurrSeason = () => {
        let currDate = new Date();
        let currMonth = moment(currDate).format('MM');
        let currYear = parseInt(moment(currDate).format('YYYY'));
        let currSeason = '';
        if (currMonth > 6) {
            let startYear = currYear;
            let lastYear = currYear + 1;
            currSeason = startYear + '/' + lastYear;
        } else {
            let startYear = currYear - 1;
            let lastYear = currYear;
            currSeason = startYear + '/' + lastYear;
        }
        return 'Academic year ' + currSeason;
    };
    const currSeason = getCurrSeason();

    const getCurrAddedSeason = (season) => {
        let currSeason = filter(
            trainingSeasons,
            ({ title }) => title === season,
        );
        return currSeason[0];
    };
    const currAddedSeason = getCurrAddedSeason(currSeason);

    // => Pagination data
    const paginatedOnTrainingStudents = paginator(
        take(onTrainingStudents, 10),
        page,
        perPage,
    );

    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item sm={6}>
                    <CustomBtn>
                        <Button
                            className="btn"
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{
                                'border': `1px solid ${colors.bgColor3}`,
                                ':hover': {
                                    bgcolor: colors.secondary,
                                    opacity: 0.9,
                                    border: `1px solid ${colors.bgColor5}`,
                                },
                            }}
                            onClick={() => {
                                let crudType = 'ImportStudents';
                                let dialogHead =
                                    'Import students ready for practical training';
                                handleDialogyOpen(crudType, dialogHead);
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    opacity: 0.9,
                                }}
                            >
                                <ImportExportOutlined sx={{ mr: 1 }} />
                                Import student
                            </Typography>
                        </Button>
                    </CustomBtn>
                </Grid>
                <Grid item sm={6}>
                    <CustomBtn>
                        <Button
                            className="btn"
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{
                                'border': `1px solid ${colors.bgColor3}`,
                                ':hover': {
                                    bgcolor: colors.secondary,
                                    opacity: 0.9,
                                    border: `1px solid ${colors.bgColor5}`,
                                },
                            }}
                            onClick={() => {
                                let crudType = 'AddStudent';
                                let dialogHead =
                                    'Add student ready for practical training';
                                handleDialogyOpen(crudType, dialogHead);
                            }}
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    opacity: 0.9,
                                }}
                            >
                                <Add sx={{ mr: 1 }} />
                                Add student
                            </Typography>
                        </Button>
                    </CustomBtn>
                </Grid>
                <Grid item sm={12}>
                    {currSeasonLoading ? (
                        <InnerContentLoader height={109} />
                    ) : isEmpty(currAddedSeason) ? (
                        <NoContent
                            message={'Training season not added'}
                            height={109}
                        />
                    ) : (
                        <CurrentTrainingSeasonWidget
                            currAddedSeason={currAddedSeason}
                        />
                    )}
                </Grid>
                <Grid item sm={12}>
                    <CustomPagination
                        head={'On training students'}
                        height={{ md: '40.5vh', xs: '100%' }}
                        borderBottom
                        handlePageChange={handlePageChange}
                        handlePerPageChage={handlePerPageChage}
                        data={paginatedOnTrainingStudents}
                        action={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        py: 0.5,
                                        px: 2,
                                        borderRadius: 2,
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        mr: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                    }}
                                >
                                    {size(onTrainingStudents)}{' '}
                                    <sup>Students</sup>
                                </Typography>
                                <Box>
                                    <Link
                                        to={
                                            '/students/trainings/on-training'
                                        }
                                    >
                                        <Button
                                            variant="contained"
                                            endIcon={
                                                <ArrowCircleRightOutlined />
                                            }
                                            size="small"
                                            className="app-btn"
                                        >
                                            all
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        }
                    >
                        {isEmpty(onTrainingStudents) ? (
                            <>
                                <NoContent
                                    height={'35vh'}
                                    message={'No on training student'}
                                />
                            </>
                        ) : (
                            <Grid container spacing={1}>
                                {paginatedOnTrainingStudents.data.map(
                                    (student) => (
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            key={student.id}
                                        >
                                            <OnFieldStudentWidget
                                                student={student}
                                            />
                                        </Grid>
                                    ),
                                )}
                            </Grid>
                        )}
                    </CustomPagination>
                </Grid>
            </Grid>
        </>
    );
};

export default StudentSidebar;
