import React from 'react';
import {
    Article,
    Cached,
    CloudUpload,
    DoDisturb,
    ManageAccounts,
    NotStarted,
    Pending,
    Preview,
    QuestionMark,
    UploadFile,
    Verified,
} from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { colors } from '../../assets/utils/constants';
import CustomCard from '../../components/cards/CustomCard';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import UploadKeyPerson from "./CRUD's/UploadKeyPerson";
import UploadKeyPersonDocs from "./CRUD's/UploadKeyPersonDocs";
import DocumentPreviewDialog from '../../components/Dialogs/DocumentPreviewDialog';
import DocumentPreview from '../../components/DocumentPreview';
import { useState } from 'react';

const ButtonCon = styled(Box)({
    '.btn': {
        marginTop: 15,
        fontSize: 12,
        paddingTop: 4,
        paddingBottom: 4,
    },
});

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

// -> docs status
const DocsStatus = styled(Box)({
    'height': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '.text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.icon': {
        fontSize: 35,
        margin: 10,
    },
});

const ORGKeyPerson = ({ user, orgKeyPerson, refetchProfile }) => {
    const [crudType, setCRUDType] = useState('');
    const [openDialog, setOpenDialogy] = useState(false);
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // => Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setDialogHead(dialogHead);
        setCRUDType(crudType);
        setOpenDialogy(true);
    };

    // => Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Organization key person document'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview
                    document={orgKeyPerson.documents?.verification_attachment}
                />
            </DocumentPreviewDialog>
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                {crudType === 'addKeyPerson' ? (
                    <UploadKeyPerson
                        user={user}
                        orgKeyPerson={orgKeyPerson}
                        closeDialogy={handleDialogClose}
                        refetchProfile={refetchProfile}
                    />
                ) : crudType === 'addKeyPersonDocs' ? (
                    <UploadKeyPersonDocs
                        user={user}
                        orgKeyPerson={orgKeyPerson}
                        closeDialogy={handleDialogClose}
                        refetchProfile={refetchProfile}
                    />
                ) : (
                    <></>
                )}
            </CustomDialog>

            {/* ##################### CONTENTS ################# */}
            <CustomCard head={`Company key person`} bodyPadding={2}>
                <Grid container justifyContent={'center'} spacing={1}>
                    {!isEmpty(orgKeyPerson) ? (
                        <Grid container padding={2}>
                            <Grid item sm={8}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                        color: colors.info,
                                    }}
                                >
                                    Personal details
                                </Typography>
                                <Grid container>
                                    <Grid item sm={8}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Full name
                                            </Typography>
                                            <Typography className="value">{`${orgKeyPerson.first_name} ${orgKeyPerson.middle_name} ${orgKeyPerson.last_name}`}</Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Gender
                                            </Typography>
                                            <Typography className="value">
                                                {orgKeyPerson.gender}
                                            </Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Phone
                                            </Typography>
                                            <Typography className="value">
                                                {orgKeyPerson.phone_number}
                                            </Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <center>
                                            {user.is_verified === 'pending' && (
                                                <>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={
                                                            <ManageAccounts />
                                                        }
                                                        color="success"
                                                        size="small"
                                                        sx={{
                                                            mt: 2,
                                                        }}
                                                        onClick={() => {
                                                            let dialogHead =
                                                                'Update key person details';
                                                            let crudType =
                                                                'addKeyPerson';
                                                            handleDialogyOpen(
                                                                crudType,
                                                                dialogHead,
                                                            );
                                                        }}
                                                    >
                                                        Update details
                                                    </Button>
                                                </>
                                            )}
                                        </center>
                                    </Grid>
                                </Grid>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                        color: colors.info,
                                        mt: 2,
                                    }}
                                >
                                    Attachment
                                </Typography>
                                <Box
                                    sx={{
                                        py: 2,
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    }}
                                >
                                    {!isEmpty(orgKeyPerson.documents) ? (
                                        <>
                                            <Grid item sm={12} marginTop={1}>
                                                <Grid
                                                    container
                                                    sx={{
                                                        borderRadius: 2,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                >
                                                    <>
                                                        <Grid item sm={12}>
                                                            <BoxTable>
                                                                <Typography className="title">
                                                                    Verification
                                                                    type
                                                                </Typography>
                                                                <Typography className="value">
                                                                    {
                                                                        orgKeyPerson
                                                                            .documents
                                                                            .verification_type
                                                                            .name
                                                                    }
                                                                </Typography>
                                                            </BoxTable>
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <BoxTable>
                                                                <Typography className="title">
                                                                    Verification
                                                                    number
                                                                </Typography>
                                                                <Typography className="value">
                                                                    {
                                                                        orgKeyPerson
                                                                            .documents
                                                                            .verification_number
                                                                    }
                                                                </Typography>
                                                            </BoxTable>
                                                        </Grid>
                                                    </>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <ButtonCon>
                                                    <center>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={
                                                                <Preview />
                                                            }
                                                            color="info"
                                                            size="small"
                                                            sx={{
                                                                m: 2,
                                                            }}
                                                            onClick={() =>
                                                                handleDocsPreview()
                                                            }
                                                        >
                                                            Preview document
                                                        </Button>
                                                        {user.is_verified ===
                                                            'pending' &&
                                                            orgKeyPerson
                                                                .documents
                                                                .verification_status !==
                                                                'verified' &&
                                                            orgKeyPerson
                                                                .documents
                                                                .verification_status !==
                                                                'on_progress' && (
                                                                <>
                                                                    <Button
                                                                        variant="outlined"
                                                                        startIcon={
                                                                            <ManageAccounts />
                                                                        }
                                                                        color="success"
                                                                        size="small"
                                                                        sx={{
                                                                            m: 2,
                                                                        }}
                                                                        onClick={() => {
                                                                            let dialogHead =
                                                                                'Update key person document';
                                                                            let crudType =
                                                                                'addKeyPersonDocs';
                                                                            handleDialogyOpen(
                                                                                crudType,
                                                                                dialogHead,
                                                                            );
                                                                        }}
                                                                    >
                                                                        Update
                                                                        document
                                                                    </Button>
                                                                </>
                                                            )}
                                                        {user.is_verified ===
                                                            'denied' &&
                                                            orgKeyPerson
                                                                .documents
                                                                ?.verification_status ===
                                                                'denied' && (
                                                                <>
                                                                    <Button
                                                                        variant="outlined"
                                                                        startIcon={
                                                                            <UploadFile />
                                                                        }
                                                                        color="success"
                                                                        size="small"
                                                                        sx={{
                                                                            m: 2,
                                                                        }}
                                                                        onClick={() => {
                                                                            let dialogHead =
                                                                                'Update key person document';
                                                                            let crudType =
                                                                                'addKeyPersonDocs';
                                                                            handleDialogyOpen(
                                                                                crudType,
                                                                                dialogHead,
                                                                            );
                                                                        }}
                                                                    >
                                                                        Re-upload
                                                                        document
                                                                    </Button>
                                                                </>
                                                            )}
                                                    </center>
                                                </ButtonCon>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid
                                            item
                                            sm={12}
                                            xs={12}
                                            sx={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Typography>
                                                <Article
                                                    sx={{
                                                        fontSize: 44,
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                />
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    fontSize: 12,
                                                    paddingTop: 0.4,
                                                    paddingBottom: 0.4,
                                                }}
                                                startIcon={<CloudUpload />}
                                                onClick={() => {
                                                    let dialogHead =
                                                        'Upload key person document';
                                                    let crudType =
                                                        'addKeyPersonDocs';
                                                    handleDialogyOpen(
                                                        crudType,
                                                        dialogHead,
                                                    );
                                                }}
                                            >
                                                Upload document
                                            </Button>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item md={4}>
                                {orgKeyPerson.documents.verification_status ===
                                'pending' ? (
                                    <>
                                        <DocsStatus className="warning-message">
                                            <Typography className="text">
                                                <Pending className="icon" />
                                                Wait for submission
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : orgKeyPerson.documents
                                      .verification_status === 'on_progress' ? (
                                    <>
                                        <DocsStatus className="info-message">
                                            <Typography className="text">
                                                <Cached className="icon" />
                                                Verification requested
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : orgKeyPerson.documents
                                      .verification_status === 'verified' ? (
                                    <>
                                        <DocsStatus className="success-message">
                                            <Typography className="text">
                                                <Verified className="icon" />
                                                Document verified
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : isEmpty(orgKeyPerson.documents) ? (
                                    <>
                                        <DocsStatus className="error-message">
                                            <Typography className="text">
                                                <NotStarted className="icon" />
                                                Document not uploaded
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : (
                                    <>
                                        <DocsStatus className="error-message">
                                            <Typography className="text">
                                                <DoDisturb className="icon" />
                                                Document denied
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography>
                                                        {
                                                            orgKeyPerson
                                                                .documents
                                                                ?.deny_reason
                                                        }
                                                    </Typography>
                                                }
                                                arrow
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="error"
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                >
                                                    <QuestionMark />
                                                </IconButton>
                                            </Tooltip>
                                        </DocsStatus>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid
                                item
                                sm={12}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                <Typography>
                                    <Article
                                        sx={{
                                            fontSize: 44,
                                        }}
                                    />
                                </Typography>
                                <Typography>
                                    Organization key person do not added.
                                </Typography>
                                <ButtonCon>
                                    <Button
                                        className="btn"
                                        variant="contained"
                                        startIcon={
                                            <ManageAccounts className="icon" />
                                        }
                                        onClick={() => {
                                            let dialogHead =
                                                'Add key person details';
                                            let crudType = 'addKeyPerson';
                                            handleDialogyOpen(
                                                crudType,
                                                dialogHead,
                                            );
                                        }}
                                    >
                                        Add key person
                                    </Button>
                                </ButtonCon>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CustomCard>
        </>
    );
};

export default ORGKeyPerson;
