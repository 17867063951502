import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { practicalTrainingsEndpoints } from './practicalTrainingsEndpoints';

export const practicalTraingsAPI = createApi({
    reducerPath: 'practicalTraingsAPI',
    baseQuery: fetchBaseQuery({ baseUrl: orgBaseURL, prepareHeaders: headers }),
    tagTypes: ['ORGPTs', 'SpecialPTs', 'OtherPTs', 'PTDetails', 'allPTApplicants', 'singlePTApplicants'],
    endpoints: (builder) => ({
        // => GET all
        getPracticalTraings: builder.query({
            query: () => `${practicalTrainingsEndpoints.GET_ALL}`,
        }),
        // => GET org PTS
        getORGPracticalTraings: builder.query({
            query: () => `${practicalTrainingsEndpoints.GET_ORG_PTs}`,
            providesTags: ['ORGPTs'],
        }),
        // => GET org PTS
        getSpecialORGPracticalTraings: builder.query({
            query: () => `${practicalTrainingsEndpoints.GET_SPECIAL_PTs}`,
            providesTags: ['ORGPTs'],
        }),
        // => GET other org PTS
        getOtherORGPracticalTraings: builder.query({
            query: () => `${practicalTrainingsEndpoints.GET_OTHER_ORG_PTs}`,
            providesTags: ['ORGPTs'],
        }),
        // => GET single
        getSinglePracticalTraing: builder.query({
            query: (payload) =>
                `${practicalTrainingsEndpoints.GET_SINGLE}/${payload}`,
            providesTags: ['PTDetails'],
        }),
        // => CREATE PT
        createPracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.CREATE_PT}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['ORGPTs'],
        }),
        // => PUBLISH PT
        publishPracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.PUBLIS_PT}/${payload.pt_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['ORGPTs', 'PTDetails'],
        }),
        // => CLOSE PT
        closePracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.CLOSE_PT}/${payload.pt_id}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['ORGPTs', 'PTDetails'],
        }),
        // => APPROVE PT
        approvePracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.APPROVE_PT_TO_STUDENT}/${payload}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['ORGPTs', 'SpecialPTs', 'OtherPTs', 'PTDetails'],
        }),
        // => DECLINE PT
        declinePracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.DECLINE_SPECIAL_PT}/${payload.pt_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['SpecialPTs', 'PTDetails'],
        }),
        // => UPDATE PT
        updatePracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.UPDATE_PT}/${payload.get(
                    'pt_id',
                )}`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error) =>
                error ? [] : ['ORGPTs', 'PTDetails'],
        }),
        // => DELETE PT
        deletePracticalTraing: builder.mutation({
            query: (payload) => ({
                url: `${practicalTrainingsEndpoints.DELETE_PT}/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error) => (error ? [] : ['ORGPTs']),
        }),
    }),
});

export const {
    // => useGet
    useGetPracticalTraingsQuery,
    useGetORGPracticalTraingsQuery,
    useGetSinglePracticalTraingQuery,
    useCreatePracticalTraingMutation,
    usePublishPracticalTraingMutation,
    useClosePracticalTraingMutation,
    useUpdatePracticalTraingMutation,
    useDeletePracticalTraingMutation,
    useApprovePracticalTraingMutation,
    useDeclinePracticalTraingMutation,

    // => useLazy
    useLazyGetORGPracticalTraingsQuery,
    useLazyGetSpecialORGPracticalTraingsQuery,
    useLazyGetOtherORGPracticalTraingsQuery,
} = practicalTraingsAPI;
