import {
    ArrowRight,
    DeleteForever,
    Edit,
    LocalAtm,
    PublishOutlined,
    StopCircle,
} from "@mui/icons-material";
import {
    Box,
    Chip,
    Icon,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../assets/utils/constants";
import JobInfoBoxDetails from "../jobComponents/JobInfoBoxDetails";
import { numFormater } from "../../helpers/numFormater";
import CustomDialog from "../Dialogs/CustomDialog";
import {
    addJobPostingFee,
    setSaveAndPay,
} from "../../states/features/job/JobPostingSlice";
import { useDispatch } from "react-redux";
import JobPostPayment from "../../pages/jobs/CRUD/JobPostPayment";
import { removeSpclChar } from "../../helpers/strHelper";
import PublishJob from "../../pages/jobs/CRUD/PublishJob";
import DeleteJob from "../../pages/jobs/CRUD/DeleteJob";
import CustomCard2 from "../cards/CustomCard2";
import UnpublishJob from "../../pages/jobs/CRUD/UnpublishJob";
import CloseJob from "../../pages/jobs/CRUD/CloseJob";
import OpenJob from "../../pages/jobs/CRUD/OpenJob";

const JobDetailsSidebar = ({ jobDetails, handleOpenJobPostDialogy }) => {
    // ############ CONST #####################
    const jobPayment = jobDetails.payment;
    const dispatch = useDispatch();

    // ############ Comp states ###################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionType, setActionType] = useState("");

    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
        setActionType(actionType);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };
    return (
        <>
            <CustomDialog
                head={
                    actionType === "payment"
                        ? "Job posting fee payment"
                        : actionType === "publish"
                        ? "Publish job confimation"
                        : actionType === "close"
                        ? "Close job application confimation"
                        : actionType === "open"
                        ? "Open job application confimation"
                        : actionType === "delete"
                        ? "Delete job confimation"
                        : ""
                }
                openDialog={openDialog}
                customWidth={actionType === "payment" ? "md" : "sm"}
                handleDialogClose={handleDialogClose}
            >
                {actionType === "payment" ? (
                    <JobPostPayment handleDialogClose={handleDialogClose} />
                ) : actionType === "close" ? (
                    <CloseJob
                        job={jobDetails}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === "open" ? (
                    <OpenJob
                        job={jobDetails}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === "publish" ? (
                    <PublishJob
                        job={jobDetails}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === "delete" ? (
                    <DeleteJob
                        job={jobDetails}
                        handleDialogClose={handleDialogClose}
                    />
                ) : (
                    ""
                )}
            </CustomDialog>

            <Box
                sx={{
                    height: 90,
                    mb: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 4,
                    position: "relative",
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                }}
            >
                <Box sx={{ position: "absolute", bottom: 0, right: 5 }}>
                    <Chip
                        label={removeSpclChar(jobDetails.payment.status)}
                        // icon={<Payment />}
                        size="small"
                        color={
                            jobDetails.payment.status === "pending"
                                ? "info"
                                : jobDetails.payment.status === "completed" ||
                                  jobDetails.payment.status === "success"
                                ? "success"
                                : jobDetails.payment.status === "not_paid"
                                ? "warning"
                                : jobDetails.payment.status === "failed" ||
                                  jobDetails.payment.status === "failure"
                                ? "error"
                                : "default"
                        }
                        sx={{
                            fontSize: 10,
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            px: 0.6,
                            height: 18,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: "absolute",
                        top: 20,
                        bottom: 20,
                        left: 0,
                        borderTopRightRadius: 100,
                        borderBottomRightRadius: 100,
                    }}
                ></Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: "absolute",
                        top: 20,
                        bottom: 20,
                        right: 0,
                        borderTopLeftRadius: 100,
                        borderBottomLeftRadius: 100,
                    }}
                ></Box>
                <Typography
                    sx={{
                        fontSize: 25,
                        fontWeight: "bold",
                        color: colors.success,
                    }}
                >
                    {numFormater(jobDetails.payment.amount)}
                    <sup style={{ fontSize: 16 }}>TZS</sup>
                </Typography>
                <Typography
                    sx={{ fontSize: 16, fontWeight: "bold", opacity: 0.8 }}
                >
                    Posting fee
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <JobInfoBoxDetails jobDetails={jobDetails} />
            </Box>
            <Box sx={{ mt: 2 }}>
                <CustomCard2 head={"Actions links"} bodyPadding={1}>
                    <List sx={{ p: 0 }}>
                        {jobDetails.payment.status === "not_paid" && (
                            <ListItemButton
                                sx={{ p: 0, mb: 1 }}
                                onClick={() => {
                                    let actionType = "payment";
                                    handleDialogOpen(actionType);
                                    dispatch(setSaveAndPay(false));
                                    dispatch(
                                        addJobPostingFee(jobPayment.amount)
                                    );
                                }}
                            >
                                <Box
                                    className="info-box"
                                    sx={{
                                        width: "100%",
                                        textAlign: "left",
                                        p: 0,
                                        borderLeft: 0,
                                        border: "1px solid silver",
                                    }}
                                >
                                    <Icon
                                        sx={{
                                            mx: 1,
                                        }}
                                    >
                                        <LocalAtm />
                                    </Icon>
                                    <ListItemText
                                        sx={{
                                            p: 0,
                                        }}
                                        primary="Pay posting fee"
                                    />
                                    <ArrowRight />
                                </Box>
                            </ListItemButton>
                        )}
                        {jobDetails.payment.status === "failure" && (
                            <ListItemButton
                                sx={{ p: 0, mb: 1 }}
                                onClick={() => {
                                    let actionType = "payment";
                                    handleDialogOpen(actionType);
                                    dispatch(setSaveAndPay(false));
                                    dispatch(
                                        addJobPostingFee(jobPayment.amount)
                                    );
                                }}
                            >
                                <Box
                                    className="info-box"
                                    sx={{
                                        width: "100%",
                                        textAlign: "left",
                                        p: 0,
                                        borderLeft: 0,
                                        border: "1px solid silver",
                                    }}
                                >
                                    <Icon
                                        sx={{
                                            mx: 1,
                                        }}
                                    >
                                        <LocalAtm />
                                    </Icon>
                                    <ListItemText
                                        sx={{
                                            p: 0,
                                        }}
                                        primary="Pay posting fee"
                                    />
                                    <ArrowRight />
                                </Box>
                            </ListItemButton>
                        )}
                        <ListItemButton
                            sx={{ p: 0, mb: 1 }}
                            onClick={() => {
                                let type =
                                    jobDetails.status === "published"
                                        ? "close"
                                        : jobDetails.status === "closed"
                                        ? "open"
                                        : "publish";
                                handleDialogOpen(type);
                            }}
                        >
                            <Box
                                className={
                                    jobDetails.status === "published"
                                        ? "warning-box"
                                        : jobDetails.status === "closed"
                                        ? "success-box"
                                        : "success-box"
                                }
                                sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    p: 0,
                                    borderLeft: 0,
                                    border: "1px solid silver",
                                }}
                            >
                                {jobDetails.status === "published" ? (
                                    <Icon
                                        sx={{
                                            mx: 1,
                                        }}
                                    >
                                        <StopCircle />
                                    </Icon>
                                ) : (
                                    <Icon
                                        sx={{
                                            mx: 1,
                                        }}
                                    >
                                        <PublishOutlined />
                                    </Icon>
                                )}

                                <ListItemText
                                    sx={{
                                        p: 0,
                                    }}
                                    primary={
                                        jobDetails.status === "published"
                                            ? "Close job"
                                            : jobDetails.status === "closed"
                                            ? "Open job"
                                            : "Publish job"
                                    }
                                />
                                <ArrowRight />
                            </Box>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ p: 0, mb: 1 }}
                            onClick={handleOpenJobPostDialogy}
                        >
                            <Box
                                className="warning-box"
                                sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    p: 0,
                                    borderLeft: 0,
                                    border: "1px solid silver",
                                }}
                            >
                                <Icon
                                    sx={{
                                        mx: 1,
                                    }}
                                >
                                    <Edit />
                                </Icon>
                                <ListItemText
                                    sx={{
                                        p: 0,
                                    }}
                                    primary="Edit job"
                                />
                                <ArrowRight />
                            </Box>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ p: 0, mb: 1 }}
                            onClick={() => {
                                let type = "delete";
                                handleDialogOpen(type);
                            }}
                        >
                            <Box
                                className="error-box"
                                sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    p: 0,
                                    borderLeft: 0,
                                    border: "1px solid silver",
                                }}
                            >
                                <Icon
                                    sx={{
                                        mx: 1,
                                    }}
                                >
                                    <DeleteForever />
                                </Icon>
                                <ListItemText
                                    sx={{
                                        p: 0,
                                    }}
                                    primary="Delete job"
                                />
                                <ArrowRight />
                            </Box>
                        </ListItemButton>
                    </List>
                </CustomCard2>
            </Box>
        </>
    );
};

export default JobDetailsSidebar;
