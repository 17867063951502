import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

const NextAndBackBtn = ({ steps, activeStep, action, handleBack, noNext }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 5,
                    justifyContent: 'space-between',
                    p: 1,
                    borderTop: `1px solid ${colors.bgColor5}`,
                }}
            >
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="contained"
                    className="app-btn"
                    sx={{ px: 2 }}
                    startIcon={<ArrowLeft />}
                >
                    Back
                </Button>
                {activeStep !== steps.length - 1 ? (
                    noNext ? (
                        <></>
                    ) : (
                        <Button
                            onClick={action}
                            variant="contained"
                            className="app-btn"
                            color="secondary"
                            sx={{ px: 2 }}
                            endIcon={<ArrowRight />}
                        >
                            {activeStep === steps.length - 1
                                ? 'Finish'
                                : 'Next'}
                        </Button>
                    )
                ) : (
                    ''
                )}
            </Box>
        </>
    );
};

export default NextAndBackBtn;
