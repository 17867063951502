import React from 'react';
import { Toaster } from 'react-hot-toast';
import { colors } from '../assets/utils/constants';

const HotToastfy = () => {
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 5000,
                    style: {
                        fontSize: '13px',
                        border: `4px solid ${colors.bgColor1}`,
                    },
                }}
            />
        </>
    );
};

export default HotToastfy;
