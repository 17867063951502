import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AppRoutes from "./routes/App.routes";
import useAuth from "./hooks/useAuth";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { authSelector } from "./states/features/selectors";
import { primaryTheme } from "./assets/utils/constants";
import ErrorBoundary from "./components/HCO/ErrorBoundary";

// !#################### MAIN FUNC ##################
const App = () => {
    // ######### Redux states #############
    const { user } = useSelector(authSelector);

    // ######### Get user profile #########
    const auth = useAuth();
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isEmpty(user)) {
                auth.persistAuthenticatedUser();
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [user, auth]);

    // ######### Main return #########
    return (
        <React.Fragment>
            <ThemeProvider theme={primaryTheme}>
                <CssBaseline />
                <ErrorBoundary>
                    <AppRoutes role={auth.authorization} user={user} />
                </ErrorBoundary>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default App;
