import React from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

const checkedIcon = <CheckBox fontSize="small" />;
const icon = <CheckBoxOutlineBlank fontSize="small" />;

const CustomAutocomplete = ({
    label,
    placeholder,
    options,
    defaultOption,
    getSelectedValues,
    ...props
}) => {
    const [value, setValue] = React.useState(defaultOption);
    const [inputValue, setInputValue] = React.useState();
    const [field, meta] = useField(props);

    return (
        <>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    getSelectedValues(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                multiple
                options={options}
                disableCloseOnSelect
                fullWidth
                getOptionLabel={(option) => option.title}
                defaultValue={defaultOption ? defaultOption : undefined}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{
                                marginRight: 8,
                            }}
                            checked={selected}
                        />
                        {option.title}
                    </li>
                )}
                renderInput={(params) => (
                    <Box>
                        <TextField
                            {...params}
                            label={label}
                            placeholder={placeholder}
                        />
                        <ErrorMessage
                            component="span"
                            name={field.name}
                            className="error-text"
                        />
                    </Box>
                )}
            />
        </>
    );
};

export default CustomAutocomplete;
