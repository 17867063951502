import React, { useState } from 'react';
import { Edit, Subject } from '@mui/icons-material';
import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { colors } from '../../assets/utils/constants';
import SmallCustomTextField from '../Forms/SmallCustomTextField';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import { isEmpty } from 'lodash';
import NoContent from '../NoContent';
import {
    useCreateResponsibilityMutation,
    useUpdateResponsibilityMutation,
} from '../../api/job/jobAPI2';
import useRTK from '../../hooks/useRTK';
import { setJobDetails } from '../../states/features/job/jobsSlice';
import DeleteResponsibility from './CRUD/DeleteResponsibility';

// ########### STYLED COMPONENT #################
const JobResponsibilityCard = styled(Box)({
    'padding': 20,
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'pb': 0.5,
        'color': colors.warning,
        'opacity': 0.8,
        'marginBottom': 2,
        '.icon': {
            marginRight: 1,
            fontSize: 14,
            color: colors.warning,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
        },
    },
});

// ########## VALIDATION SCHEMA #################
const responsibilityValidationSchema = Yup.object({
    job_responsibility: Yup.string().required(
        'Responsibility is required field',
    ),
});

// !################## MAIN FUNC ##################
const JobResponsibilities = ({ job_id, jobResponsibilities }) => {
    // ########### CONST #####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ########## IN COMP STATE #################
    const [responsibility, setResponsibility] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [responsibilityId, setResponsibilityId] = useState('');
    const handleEdit = (data) => {
        setIsEdit(true);
        setResponsibility(data.responsibility);
        setResponsibilityId(data.responsibility_id);
    };
    // -> reset comp state
    const resetCompState = () => {
        setIsEdit(false);
        setResponsibility('');
        setResponsibilityId('');
    };

    // ################### RTK ###################
    const rtkActions = (data) => {
        resetCompState();
        dispatch(setJobDetails(data));
    };
    const [
        addResponsibility,
        {
            isLoading: responsibilityAddLoading,
            isSuccess: responsibilityAddSuccess,
            isError: responsibilityAddIsError,
            error: responsibilityAddError,
            data: responsibilityAddData,
        },
    ] = useCreateResponsibilityMutation();
    RTK.useRTKResponse(
        responsibilityAddSuccess,
        responsibilityAddIsError,
        responsibilityAddError,
        responsibilityAddData,
        rtkActions,
    );
    // => Update
    const [
        updateResponsibility,
        {
            isLoading: responsibilityUpdateLoading,
            isSuccess: responsibilityUpdateSuccess,
            isError: responsibilityUpdateIsError,
            error: responsibilityUpdateError,
            data: responsibilityUpdateData,
        },
    ] = useUpdateResponsibilityMutation();
    RTK.useRTKResponse(
        responsibilityUpdateSuccess,
        responsibilityUpdateIsError,
        responsibilityUpdateError,
        responsibilityUpdateData,
        rtkActions,
    );
    return (
        <>
            <JobResponsibilityCard>
                <Typography className="title">
                    <Subject className="icon" />
                    Job responsibility
                </Typography>
                {!isEmpty(jobResponsibilities) ? (
                    <>
                        {/* ######################### CONTENT #################### */}
                        <Box>
                            {jobResponsibilities?.map(
                                (responsibility, index) => (
                                    <Box
                                        className="res-con"
                                        key={responsibility.id}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 'bold',
                                                    width: 40,
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    mr: 1,
                                                }}
                                            >
                                                {++index} :
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }}>
                                                {
                                                    responsibility.job_responsibility
                                                }
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                ml: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    p: 0.4,
                                                    m: 0.4,
                                                }}
                                                onClick={() => {
                                                    let data = {
                                                        responsibility_id:
                                                            responsibility.id,
                                                        responsibility:
                                                            responsibility.job_responsibility,
                                                    };
                                                    handleEdit(data);
                                                }}
                                            >
                                                <Edit
                                                    color="warning"
                                                    sx={{
                                                        fontSize: 14,
                                                    }}
                                                />
                                            </Button>

                                            <DeleteResponsibility
                                                responsibility={responsibility}
                                            />
                                        </Box>
                                    </Box>
                                ),
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <NoContent
                            height={100}
                            message={'No responsibility added'}
                        />
                    </>
                )}
                {/* ######################### FORM #################### */}
                <Box sx={{ mt: 2 }}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            job_responsibility: responsibility,
                        }}
                        validationSchema={responsibilityValidationSchema}
                        onSubmit={(payload, { resetForm }) => {
                            let newPayload = {
                                job_id,
                                ...payload,
                            };
                            let editPayload = {
                                responsibilityId,
                                ...payload,
                            };
                            !isEdit
                                ? addResponsibility(newPayload)
                                : updateResponsibility(editPayload);
                            resetForm();
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <Box
                                    sx={{
                                        position: 'relative',
                                    }}
                                >
                                    <SmallCustomTextField
                                        label="Add responsibility"
                                        name="job_responsibility"
                                        cuPadding={14}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -10,
                                            right: 3,
                                            bottom: 0,
                                        }}
                                    >
                                        <CustomSubmitButton
                                            loading={
                                                responsibilityAddLoading
                                                    ? responsibilityAddLoading
                                                    : responsibilityUpdateLoading
                                            }
                                            btnColor={
                                                isEdit ? 'secondary' : 'primary'
                                            }
                                            // loading={isSubmitRes}
                                            onClick={formik.handleSubmit}
                                        >
                                            {isEdit ? 'Update' : 'Add'}
                                        </CustomSubmitButton>
                                    </Box>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </JobResponsibilityCard>
        </>
    );
};

export default JobResponsibilities;
