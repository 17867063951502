import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { helpCenterEndpoints } from './helpCenterEndpoints';

export const helpCenterAPI = createApi({
    reducerPath: 'helpCenterAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
    }),
    tagTypes: ['Contacts', 'SocialNets'],
    endpoints: (builder) => ({
        // => GET contacts
        getContacts: builder.query({
            query: () => `${helpCenterEndpoints.GET_CONTACTS}`,
            providesTags: ['Contacts'],
        }),

        // => GET social net's
        getSocialNetworks: builder.query({
            query: () => `${helpCenterEndpoints.GET_SOCIAL_NET}`,
            providesTags: ['SocialNets'],
        }),

        // => Send us  email
        sendUsEmail: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.SEND_US_EMAIL}`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetContactsQuery,
    useGetSocialNetworksQuery,
    useSendUsEmailMutation,
} = helpCenterAPI;
