import React from 'react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import CustomCard from '../../components/cards/CustomCard';
import UploadORGDocs from "./CRUD's/UploadORGDocs";
import {
    Article,
    Cached,
    CloudUpload,
    DoDisturb,
    ManageAccounts,
    Pending,
    Preview,
    QuestionMark,
    UploadFile,
    Verified,
} from '@mui/icons-material';
import { useState } from 'react';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import { colors } from '../../assets/utils/constants';
import DocumentPreviewDialog from '../../components/Dialogs/DocumentPreviewDialog';
import DocumentPreview from '../../components/DocumentPreview';

const ButtonCon = styled(Box)({
    '.btn': {
        marginTop: 15,
        fontSize: 12,
        paddingTop: 4,
        paddingBottom: 4,
    },
});

// -> BoxTable
const BoxTable = styled(Box)({
    'border': `1px solid ${colors.bgColor5}`,
    'paddingTop': 5,
    'paddingBottom': 5,
    'paddingLeft': 15,
    'borderRadius': 2,
    '.title': {
        fontSize: 15,
        fontWeight: 'bold',
    },
    '.value': {
        fontSize: 14,
    },
});

// -> docs status
const DocsStatus = styled(Box)({
    'height': '100%',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '.text': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.icon': {
        fontSize: 35,
        margin: 10,
    },
});

const ORGDocs = ({ user, orgDocs, refetchProfile }) => {
    const [openDialog, setOpenDialogy] = useState(false);
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);

    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Organization verification document'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={orgDocs.attachment} />
            </DocumentPreviewDialog>
            <CustomDialog
                head={'Upload organization document'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <UploadORGDocs
                    orgDocs={orgDocs}
                    closeDialogy={handleDialogClose}
                    refetchProfile={refetchProfile}
                />
            </CustomDialog>
            <CustomCard head={'Verification document'} bodyPadding={2}>
                <Grid container justifyContent={'center'}>
                    {!isEmpty(orgDocs) ? (
                        <>
                            <Grid item md={8} sm={12}>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Verification type
                                            </Typography>
                                            <Typography className="value">
                                                {orgDocs.verification_type.name}
                                            </Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <BoxTable>
                                            <Typography className="title">
                                                Verification number
                                            </Typography>
                                            <Typography className="value">
                                                {orgDocs.verification_number}
                                            </Typography>
                                        </BoxTable>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <ButtonCon>
                                            <center>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<Preview />}
                                                    color="info"
                                                    size="small"
                                                    sx={{
                                                        m: 2,
                                                    }}
                                                    onClick={() => {
                                                        handleDocsPreview();
                                                    }}
                                                >
                                                    Preview document
                                                </Button>
                                                {user.is_verified ===
                                                    'pending' &&
                                                    orgDocs.verification_status !==
                                                        'verified' &&
                                                    orgDocs.verification_status !==
                                                        'on_pogress' && (
                                                        <>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    <ManageAccounts />
                                                                }
                                                                color="success"
                                                                size="small"
                                                                sx={{
                                                                    m: 2,
                                                                }}
                                                                onClick={() => {
                                                                    handleDialogyOpen();
                                                                }}
                                                            >
                                                                Update document
                                                            </Button>
                                                        </>
                                                    )}
                                                {user.is_verified ===
                                                    'denied' &&
                                                    orgDocs.verification_status ===
                                                        'denied' && (
                                                        <>
                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    <UploadFile />
                                                                }
                                                                color="success"
                                                                size="small"
                                                                sx={{
                                                                    m: 2,
                                                                }}
                                                                onClick={() => {
                                                                    handleDialogyOpen();
                                                                }}
                                                            >
                                                                Re-upload
                                                                document
                                                            </Button>
                                                        </>
                                                    )}
                                            </center>
                                        </ButtonCon>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4}>
                                {orgDocs.verification_status === 'pending' ? (
                                    <>
                                        <DocsStatus className="warning-message">
                                            <Typography className="text">
                                                <Pending className="icon" />
                                                Wait for submission
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : orgDocs.verification_status ===
                                  'on_progress' ? (
                                    <>
                                        <DocsStatus className="info-message">
                                            <Typography className="text">
                                                <Cached className="icon" />
                                                Verification requested
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : orgDocs.verification_status ===
                                  'verified' ? (
                                    <>
                                        <DocsStatus className="success-message">
                                            <Typography className="text">
                                                <Verified className="icon" />
                                                Document verified
                                            </Typography>
                                        </DocsStatus>
                                    </>
                                ) : (
                                    <>
                                        <DocsStatus className="error-message">
                                            <Typography className="text">
                                                <DoDisturb className="icon" />
                                                Document denied
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    <Typography>
                                                        {orgDocs.deny_reason}
                                                    </Typography>
                                                }
                                                arrow
                                                placement="top"
                                            >
                                                <IconButton
                                                    color="error"
                                                    sx={{
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                    }}
                                                >
                                                    <QuestionMark />
                                                </IconButton>
                                            </Tooltip>
                                        </DocsStatus>
                                    </>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                sm={12}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                <Typography>
                                    <Article
                                        sx={{
                                            fontSize: 44,
                                            color: colors.bgColor5,
                                        }}
                                    />
                                </Typography>
                                <Typography>
                                    Organization document do not uploaded.
                                </Typography>
                                <ButtonCon>
                                    <Button
                                        className="btn"
                                        variant="contained"
                                        startIcon={
                                            <CloudUpload className="icon" />
                                        }
                                        onClick={handleDialogyOpen}
                                    >
                                        Upload verification document
                                    </Button>
                                </ButtonCon>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CustomCard>
        </>
    );
};

export default ORGDocs;
