import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Divider, Grid, Tooltip } from "@mui/material";
import { Email, LocationOn, Phone } from "@mui/icons-material";
import { colors } from "../../../assets/utils/constants";
import { isEmpty } from "lodash";

// ##################### MUI STYLED ########################
const InfoBox = styled(Box)({
    background: colors.bgColor,
    borderRadius: 10,
    boxShadow: "3px 3px 3px 3px rgba(46, 119, 174,0.2)",
    ".title": {
        fontSize: 13,
        fontWeight: "bold",
        color: colors.primary,
        textAlign: "center",
        padding: 8,
        opacity: 0.8,
    },
    ".detail": {
        fontSize: 13,
        textAlign: "center",
        padding: 8,
    },
});

// -> BoxTable
const BoxTable = styled(Box)({
    border: `1px solid ${colors.bgColor5}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    borderRadius: 2,
    position: "relative",
    display: "flex",
    height: 50,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-between",
    ".title": {
        fontSize: 15,
        fontWeight: "bold",
        opacity: 0.8,
    },
    ".value": {
        fontSize: 14,
        opacity: 0.8,
    },
});

const GroupMemberDetails = ({ applicant }) => {
    return (
        <Grid container padding={2} spacing={2}>
            <Grid item sm={12}>
                <Box
                    className="neo-up"
                    sx={{
                        display: "flex",
                        position: "relative",
                        background: colors.bgColor,
                        p: 2,
                        borderRadius: 5,
                        borderBottom: `4px solid ${colors.secondary}`,
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Avatar
                            src={applicant.profile_picture}
                            sx={{
                                height: 80,
                                width: 80,
                                borderRadius: 50,
                                border: `2px dotted ${colors.info}`,
                                p: 0.2,
                                img: {
                                    borderRadius: 50,
                                    objectFit: "cover",
                                },
                            }}
                            variant="square"
                        />
                    </Box>
                    <Box
                        sx={{
                            height: 100,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            pl: 3,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: colors.info,
                                }}
                            >
                                {`${applicant.first_name} ${applicant?.middle_name} ${applicant.last_name}`}
                            </Typography>
                            <Typography
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: colors.warning,
                                    ml: -0.5,
                                }}
                            >
                                <LocationOn sx={{ fontSize: 14 }} />{" "}
                                {`${applicant?.region}, ${applicant?.district}`}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.info,
                                    opacity: 0.8,
                                }}
                            >
                                {`${applicant.phone_number}`}
                            </Typography>
                        </Box>
                        <Box
                            className="neo-up"
                            sx={{
                                bgcolor: colors.bgColor,
                                right: 20,
                                textAlign: "center",
                                borderRadius: 4,
                                p: 2,
                            }}
                        >
                            <InfoBox>
                                <Typography className="title">
                                    Applicant Contacts
                                </Typography>
                                <Divider />
                                <Box
                                    sx={{
                                        p: 0.5,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Tooltip
                                        title={applicant.phone_number}
                                        placement="top"
                                        arrow
                                        sx={{ mr: 1 }}
                                    >
                                        <Button color="info" variant="outlined">
                                            <Phone
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title={applicant.email}
                                        placement="top"
                                        arrow
                                        sx={{ ml: 1 }}
                                    >
                                        <Button color="info" variant="outlined">
                                            <Email
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </InfoBox>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            {!isEmpty(applicant.user_bio) && (
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Applicant bio
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    mr: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        opacity: 0.8,
                                    }}
                                >
                                    {applicant.user_bio}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            )}
            {applicant?.contactPerson && (
                <Grid item sm={12} xs={12}>
                    <Box
                        sx={{
                            p: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: "bold",
                                mb: 1,
                                color: colors.info,
                            }}
                        >
                            Applicant contact person
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Grid container>
                                <Grid item sm={4}>
                                    <BoxTable>
                                        <Typography className="title">
                                            Full name
                                        </Typography>
                                        <Typography className="value">{`${applicant.contactPerson.first_name} ${applicant.contactPerson.middle_name} ${applicant.contactPerson.last_name}`}</Typography>
                                    </BoxTable>
                                </Grid>
                                <Grid item sm={4}>
                                    <BoxTable>
                                        <Typography className="title">
                                            Location
                                        </Typography>
                                        <Typography className="value">
                                            {applicant.contactPerson.region}
                                        </Typography>
                                    </BoxTable>
                                </Grid>
                                <Grid item sm={4}>
                                    <BoxTable>
                                        <Typography className="title">
                                            Gender
                                        </Typography>
                                        <Typography className="value">
                                            {applicant.contactPerson.gender}
                                        </Typography>
                                    </BoxTable>
                                </Grid>
                                <Grid item sm={4}>
                                    <BoxTable>
                                        <Typography className="title">
                                            Relationship
                                        </Typography>
                                        <Typography className="value">
                                            {
                                                applicant.contactPerson
                                                    .relationType.name
                                            }
                                        </Typography>
                                    </BoxTable>
                                </Grid>
                                <Grid item sm={8}>
                                    <BoxTable>
                                        <Typography className="title">
                                            Phone
                                        </Typography>
                                        <Typography className="value">
                                            {
                                                applicant.contactPerson
                                                    .phone_number
                                            }
                                        </Typography>
                                        <Tooltip
                                            title={
                                                applicant.contactPerson
                                                    .phone_number
                                            }
                                            placement="top"
                                            arrow
                                            sx={{
                                                mr: 1,
                                                position: "absolute",
                                                right: 0,
                                                top: "25%",
                                            }}
                                        >
                                            <Button
                                                color="info"
                                                variant="outlined"
                                            >
                                                <Phone
                                                    sx={{
                                                        fontSize: 16,
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </BoxTable>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default GroupMemberDetails;
