import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    students: [],
    studentDetails: {},
};

const studentsSlice = createSlice({
    name: 'studentsSlice',
    initialState,
    reducers: {
        setStudents: (state, action) => {
            let practicalTrainings = action.payload;
            state.students = practicalTrainings;
        },
        addStudent: (state, actions) => {
            let student = actions.payload;
            isEmpty(state.students)
                ? (state.students = [student, ...[]])
                : (state.students = [student, ...state.students]);
        },
        removeStudent: (state, actions) => {
            let payload = actions.payload;
            let students = removeArrObj('id', payload, state.students);
            state.students = students;
        },
        setStudentDetails: (state, action) => {
            let details = action.payload;
            state.studentDetails = details;
        },
        resetStudentStates: (state) => {
            state.students = [];
            state.studentDetails = {};
        },
    },
});

export const {
    setStudents,
    addStudent,
    removeStudent,
    setStudentDetails,
    resetStudentStates,
} = studentsSlice.actions;

export default studentsSlice.reducer;
