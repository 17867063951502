import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import NoContent from '../../components/NoContent';
import { isEmpty, size } from 'lodash';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import PracticalTrainingSidebarCompany from '../../components/sidebars/PracticalTrainingSidebarCompany';
import { paginator } from '../../helpers/paginationHelper';
import CustomPagination from '../../components/paginations/CustomPagination';
import CountBadge from '../../components/CountBadge copy';

const OtherOragnizationPT = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { otherPracticalTrainings } = useSelector(PTSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedActivePTs = paginator(
        otherPracticalTrainings,
        page,
        perPage,
    );

    return (
        <>
            <PageLayout
                head={'Other organizations practical trainings'}
                sidebar={
                    user?.organization_type?.name === 'Institution' ? (
                        <PracticalTrainingSidebar />
                    ) : (
                        <PracticalTrainingSidebarCompany />
                    )
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <CustomPagination
                                    head={
                                        'Other organizations practical trainings'
                                    }
                                    height={'64.5vh'}
                                    borderBottom
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                    data={paginatedActivePTs}
                                    action={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ ml: 1 }}>
                                                <CountBadge
                                                    total={size(
                                                        paginatedActivePTs.data,
                                                    )}
                                                    item={'Active PTs'}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                >
                                    {isEmpty(paginatedActivePTs.data) ? (
                                        <>
                                            <NoContent
                                                height={300}
                                                message={
                                                    'No active practical trainings'
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Grid container spacing={3}>
                                            {paginatedActivePTs.data.map(
                                                (training) => (
                                                    <Grid
                                                        item
                                                        sm={6}
                                                        key={training.id}
                                                    >
                                                        <FieldWidgetOne
                                                            training={training}
                                                        />
                                                    </Grid>
                                                ),
                                            )}
                                        </Grid>
                                    )}
                                </CustomPagination>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default OtherOragnizationPT;
