import { isEmpty } from "lodash";
import { Navigate, Outlet } from "react-router-dom";

const VerifiedUserRoutes = ({ user }) => {
    return <Outlet />;
    if (isEmpty(user)) {
        return <Navigate to="/" />;
    } else {
        if (user.is_verified !== "verified") {
            return <Navigate to="/" />;
        }
        return <Outlet />;
    }
};

export default VerifiedUserRoutes;
