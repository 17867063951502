import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { orgBaseURL } from "../baseUrl/baseURL";
import { headers } from "../baseUrl/setHeaders";
import { jobEndpoints } from "./jobEndpoints2";
import { transactionAPI } from "../transactions/transactionAPI";

export const jobAPI2 = createApi({
    reducerPath: "jobAPI2",
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: [
        "Jobs",
        "Job",
        "JobsForYou",
        "singleJobApplications",
        "companyApplications",
        "individualApplications",
        "groupApplications",
    ],
    endpoints: (builder) => ({
        // ###################### General ######################
        // => Get all jobs
        getAllJobs: builder.query({
            query: () => `${jobEndpoints.GET_ALL_JOBS}`,
            providesTags: ["Jobs"],
        }),
        // => Get jobs for you
        getJobsForYouORG: builder.query({
            query: () => `${jobEndpoints.GET_JOBS_FOR_YOU_ORG}`,
            providesTags: ["JobsForYou"],
        }),

        // => Get jobs for you
        getJobsForYouEmployer: builder.query({
            query: () => `${jobEndpoints.GET_JOBS_FOR_YOU_EMPLOYERS}`,
            providesTags: ["JobsForYou"],
        }),
        // => Get single job organization
        getSingleJobORG: builder.query({
            query: (payload) =>
                `${jobEndpoints.GET_SINGLE_JOB_ORGANIZATION}/${payload}`,
            providesTags: ["Job"],
            invalidatesTags: (result, error) => (error ? [] : ["Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionAPI.util.invalidateTags(["jobTransactions"])
                    );
                } catch (e) {}
            },
        }),
        // => Get single job employer
        getSingleJobEmployer: builder.query({
            query: (payload) =>
                `${jobEndpoints.GET_SINGLE_JOB_EMPLOYER}/${payload}`,
            providesTags: ["Job"],
            invalidatesTags: (result, error) => (error ? [] : ["Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionAPI.util.invalidateTags(["jobTransactions"])
                    );
                } catch (e) {}
            },
        }),
        // => Create job
        createJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.CREATE_JOB}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionAPI.util.invalidateTags(["jobTransactions"])
                    );
                } catch (e) {}
            },
        }),
        // => Create job and Pay
        createJobAndPay: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.CREATE_JOB_AND_PAY}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionAPI.util.invalidateTags(["jobTransactions"])
                    );
                } catch (e) {}
            },
        }),
        // => Update job
        updateJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.CREATE_JOB}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
        }),
        // => Publish job
        publishJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.PUBLISH_JOB}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(jobAPI2.util.invalidateTags(["Jobs", "Job"]));
                } catch (e) {}
            },
        }),
        // => Un Publishjob
        unpublishJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.UNPUBLISH_JOB}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
        }),
        // => Close job
        closeJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.CLOSE_JOB}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
        }),
        // => Open job
        openJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.OPEN_JOB}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
        }),
        // => Pay posting fee
        payJobPostingFee: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.PAY_JOB_POSTING_FEE}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(
                        transactionAPI.util.invalidateTags(["jobTransactions"])
                    );
                } catch (e) {}
            },
        }),
        // => Delete job
        deleteJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.DELETE_JOB}/${payload}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error) => (error ? [] : ["Job", "Jobs"]),
        }),

        // ###################### Responsibilities ######################
        // => Add
        createResponsibility: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.ADD_RESPONSIBILITY}/${payload.job_id}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // => Update
        updateResponsibility: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.UPDATE_RESPONSIBILITY}/${payload.responsibilityId}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // => Delete
        deleteResponsibility: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.DELETE_RESPONSIBILITY}/${payload}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Job"],
        }),
        // ###################### Requirements ######################
        // => Add
        createRequirement: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.ADD_REQUIREMENT}/${payload.job_id}`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // => Update
        updateRequirement: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.UPDATE_REQUIREMENT}/${payload.requirementId}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // => Delete
        deleteRequirement: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.DELETE_REQUIREMENT}/${payload}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Job"],
        }),
        // ###################### Tags ######################
        // => Add
        createTag: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.ADD_TAG}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // => Delete
        deleteTag: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.DELETE_TAG}/${payload.job_id}`,
                method: "DELETE",
                body: payload,
            }),
            invalidatesTags: ["Job"],
        }),
        // ###################### Application ######################
        // => Apply
        applyORGJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.APPLY_ORG_JOB}/${payload}`,
                method: "POST",
            }),
            invalidatesTags: (res, error) => (error ? [] : ["Job"]),
        }),

        // => Apply
        applyEmployerJob: builder.mutation({
            query: (payload) => ({
                url: `${jobEndpoints.APPLY_EMPLOYER_JOB}/${payload}`,
                method: "POST",
            }),
            invalidatesTags: (res, error) => (error ? [] : ["Job"]),
        }),
    }),
});

export const {
    // => useGet
    useGetAllJobsQuery,
    useGetSingleJobORGQuery,
    useGetSingleJobEmployerQuery,
    useCreateJobMutation,
    useCreateJobAndPayMutation,
    usePublishJobMutation,
    useUnpublishJobMutation,
    useCloseJobMutation,
    useOpenJobMutation,
    useUpdateJobMutation,
    useDeleteJobMutation,
    useCreateResponsibilityMutation,
    useUpdateResponsibilityMutation,
    useDeleteResponsibilityMutation,
    useCreateRequirementMutation,
    useUpdateRequirementMutation,
    useDeleteRequirementMutation,
    useCreateTagMutation,
    useDeleteTagMutation,
    useApplyORGJobMutation,
    useApplyEmployerJobMutation,
    usePayJobPostingFeeMutation,
    // => useLazy
    useLazyGetAllJobsQuery,
    useLazyGetJobsForYouORGQuery,
    useLazyGetJobsForYouEmployerQuery,
} = jobAPI2;
