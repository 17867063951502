import { Grid } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import StudentFilters from '../../../components/filters/StudentFilters';
import StudentsTable from '../../../components/Tables/StudentsTable';
import PageLayout from '../../layouts/PageLayout';

const EnrolledStudents = () => {
    return (
        <>
            <PageLayout
                head={'Students for practical training application'}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Enrolled students'}
                            action={<StudentFilters />}
                        >
                            <StudentsTable height={472} />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default EnrolledStudents;
