import React from 'react';
import { DeleteForever } from '@mui/icons-material';
import { Box, Button, Grid, styled, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../assets/utils/constants';
import CustomCard2 from '../../../components/cards/CustomCard2';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import NoContent from '../../../components/NoContent';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';
import {
    addJobRequirement,
    removeJobRequirement,
} from '../../../states/features/job/JobPostingSlice';
import { jobPostingSelector } from '../../../states/features/selectors';

// ########### STYLED COMPONENT #################
const JobRequirementsCard = styled(Box)({
    'position': 'relative',
    '.title': {
        'fontSize': 15,
        'fontWeight': 'bold',
        'display': 'flex',
        'alignItems': 'center',
        'paddingBottom': 5,
        '.icon': {
            marginRight: 10,
            fontSize: 14,
        },
    },
    '.res-con': {
        'display': 'flex',
        'border': `1px solid ${colors.bgColor5}`,
        'justifyContent': 'space-between',
        'padding': 5,
        'borderRadius': 5,
        ':hover': {
            background: colors.bgColor3,
        },
    },
    '.number': {
        fontSize: 16,
        fontWeight: 'bold',
        width: 40,
        bgcolor: colors.gray,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mr: 1,
    },
});

const JobSkillsAndRequirements = ({
    steps,
    activeStep,
    handleNext,
    handleBack,
}) => {
    // -> redux state
    const dispatch = useDispatch();
    const { jobRequirements } = useSelector(jobPostingSelector);

    return (
        <>
            <Box
                sx={{
                    height: 450,
                    position: 'relative',
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={2} padding={2}>
                    <Grid item sm={12}>
                        <CustomCard2>
                            {!isEmpty(jobRequirements) ? (
                                <>
                                    <CustomCard2
                                        head={'Added job requirements'}
                                        height={270}
                                    >
                                        <JobRequirementsCard>
                                            {jobRequirements?.map(
                                                (requirement, index) => (
                                                    <Box
                                                        className="res-con"
                                                        key={index}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Typography className="number">
                                                                {++index} :
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                {requirement}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ml: 2,
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    'Delete requirement'
                                                                }
                                                            >
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        p: 0.4,
                                                                        m: 0.4,
                                                                    }}
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            removeJobRequirement(
                                                                                --index,
                                                                            ),
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteForever
                                                                        color="error"
                                                                        sx={{
                                                                            fontSize: 14,
                                                                        }}
                                                                    />
                                                                </Button>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                ),
                                            )}
                                        </JobRequirementsCard>
                                    </CustomCard2>
                                </>
                            ) : (
                                <>
                                    <NoContent
                                        height={320}
                                        message={
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography>
                                                    No Skills | Requirements
                                                    added
                                                </Typography>
                                                <Typography
                                                    className="error-message"
                                                    sx={{ mt: 3 }}
                                                >
                                                    <strong>Note: </strong>
                                                    Job Skills & Requirements
                                                    optional
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </>
                            )}
                            {/* ######################### FORM #################### */}
                            <Box sx={{ mt: 2 }}>
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        job_requirement: '',
                                    }}
                                    onSubmit={(payload, { resetForm }) => {
                                        if (payload.job_requirement !== '') {
                                            dispatch(
                                                addJobRequirement(
                                                    payload.job_requirement,
                                                ),
                                            );
                                            resetForm();
                                        }
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label="Add requirement"
                                                    name="job_requirement"
                                                    cuPadding={14}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -10,
                                                        right: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <CustomSubmitButton
                                                        onClick={
                                                            formik.handleSubmit
                                                        }
                                                    >
                                                        Add
                                                    </CustomSubmitButton>
                                                </Box>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </Box>
            <NextAndBackBtn
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                action={handleNext}
            />
        </>
    );
};

export default JobSkillsAndRequirements;
