import { createSlice } from "@reduxjs/toolkit";
import { filter } from "lodash";

const initialState = {
    isMainFetch: true,
    registerAs: {},
    ORGEmail: "",
    isEmailVerified: false,
    organizationTypes: [],
    verificationTypes: [],
    organizationCategories: [],
    jobCategories: [],
    qualificationAwards: [],
    educationLevels: [],
    experienceLevels: [],
    pricingFramework: {},
    paymentMethods: [],
    publicORG: [],
    publicInstitutions: [],
    publicCompanies: [],
    activeSectors: [],
    activeDepartments: [],
    activeRegions: [],
    activeSections: [],
    activeFreeTrials: [],
};

// !########## SLICE #############
const settingsSlice = createSlice({
    name: "settingsSlice",
    initialState,
    reducers: {
        // => Organization types
        setOrganizationTypes: (state, actions) => {
            state.organizationTypes = actions.payload;
        },
        // => Organization categories
        setOrganizationCategories: (state, actions) => {
            state.organizationCategories = actions.payload;
        },
        // => Register as
        setRegisterAs: (state, actions) => {
            state.registerAs = actions.payload;
        },
        // => Set ORG email
        setORGEmail: (state, actions) => {
            state.ORGEmail = actions.payload;
        },
        // => Set ORG email verified
        setEmailVerified: (state, action) => {
            state.isEmailVerified = action.payload;
        },
        // => Verification types
        setVerificationTypes: (state, actions) => {
            state.verificationTypes = actions.payload;
        },
        // => Job categories
        setJobCategories: (state, actions) => {
            state.jobCategories = actions.payload;
        },
        // => Qualification awards
        setQualificationLevels: (state, actions) => {
            state.qualificationAwards = actions.payload;
        },
        // => Education levels
        setEducationLevels: (state, actions) => {
            state.educationLevels = actions.payload;
        },
        // => Experience levels
        setExperienceLevels: (state, actions) => {
            state.experienceLevels = actions.payload;
        },
        // => Pricing framewark
        setPricingFramework: (state, actions) => {
            state.pricingFramework = actions.payload;
        },
        // => Payment methods
        setPaymentMethod: (state, actions) => {
            state.paymentMethods = actions.payload;
        },
        // => Public org
        setPublicORG: (state, actions) => {
            let publicORG = actions.payload;
            let publicInstitution = filter(
                publicORG,
                ({ organization_type }) =>
                    organization_type.name === "Institution"
            );
            let publicCompanies = filter(
                publicORG,
                ({ organization_type }) => organization_type.name === "Company"
            );
            state.publicORG = actions.payload;
            state.publicInstitutions = publicInstitution;
            state.publicCompanies = publicCompanies;
        },
        // => Active sectors
        setActiveSectors: (state, actions) => {
            state.activeSectors = actions.payload;
        },
        // => Active departments
        setActiveDepartments: (state, actions) => {
            state.activeDepartments = actions.payload;
        },
        // => Active regions
        setActiveRegions: (state, actions) => {
            state.activeRegions = actions.payload;
        },
        // => Active sections
        setActiveSections: (state, actions) => {
            state.activeSections = actions.payload;
        },
        // => Active free trial
        setActiveFreeTrials: (state, actions) => {
            state.activeFreeTrials = actions.payload[0];
        },
        // => Deactivate main fetch
        disableMainFetch: (state, actions) => {
            state.isMainFetch = false;
        },
        resetSettings: (state) => {
            state.isMainFetch = true;
            state.registerAs = {};
            state.ORGEmail = "";
            state.isEmailVerified = false;
            state.organizationTypes = [];
            state.verificationTypes = [];
            state.organizationCategories = [];
            state.jobCategories = [];
            state.qualificationAwards = [];
            state.educationLevels = [];
            state.experienceLevels = [];
            state.pricingFramework = {};
            state.paymentMethods = [];
            state.publicORG = [];
            state.publicInstitutions = [];
            state.publicCompanies = [];
            state.activeSectors = [];
            state.activeDepartments = [];
            state.activeSections = [];
            state.activeRegions = [];
            state.activeFreeTrials = [];
        },
    },
});

export const {
    setOrganizationTypes,
    setOrganizationCategories,
    setRegisterAs,
    setORGEmail,
    setEmailVerified,
    setVerificationTypes,
    setJobCategories,
    setQualificationLevels,
    setEducationLevels,
    setExperienceLevels,
    setPricingFramework,
    setPaymentMethod,
    setPublicORG,
    setActiveSectors,
    setActiveDepartments,
    setActiveSections,
    disableMainFetch,
    setActiveRegions,
    setActiveFreeTrials,
    resetSettings,
} = settingsSlice.actions;
export default settingsSlice.reducer;
