import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/utils/constants";
import {
    AppRegistration,
    ArrowRight,
    BookmarkBorderRounded,
    Height,
    ManageAccounts,
} from "@mui/icons-material";
import CustomDialog from "../Dialogs/CustomDialog";
import CustomSubmitButton from "../Forms/CustomSubmitButton";
import { useFreeTrialActivationMutation } from "../../api/profile/profileAPI";
import { setUserProfile } from "../../states/features/profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { Form, Formik } from "formik";
import { settingsSelector } from "../../states/features/selectors";
import { isEmpty } from "lodash";

const FreeTrialWidget = ({ freeTrial }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp state ###################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############## FUNC ###################
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // ############## RTK ##################
    const [
        activateFreeTrial,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useFreeTrialActivationMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={"Free trial activation confimation"}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <Grid container>
                    <Grid item sm={12}>
                        <Box className="warning-message">
                            <Typography
                                sx={{ fontSize: 18, fontWeight: "bold" }}
                            >
                                ⚠️ Please confirm the activation of your free
                                trial ⚠️
                            </Typography>
                        </Box>
                        <Box className="warning-message">
                            <Typography
                                sx={{
                                    m: 2,
                                    fontSize: 16,
                                    opacity: 0.8,
                                    color: colors.primary,
                                    textAlign: "left",
                                    textAlign: "center",
                                }}
                            >
                                “The trial will begin today and last for the
                                duration provided. After activating your free
                                trial, you can enjoy the free services offered
                                by WAZAWA Max Platform.”
                            </Typography>
                        </Box>
                    </Grid>
                    {!isEmpty(freeTrial) && (
                        <Grid item sm={12} xs={12}>
                            <Formik
                                initialValues={{
                                    free_trial_id: freeTrial.id,
                                }}
                                onSubmit={(payload) => {
                                    activateFreeTrial(payload);
                                }}
                            >
                                {(formik) => (
                                    <Form>
                                        <CustomSubmitButton
                                            startIcon={<ManageAccounts />}
                                            onClick={formik.handleSubmit}
                                            loading={isLoading}
                                            btnColor={"info"}
                                            sx={{ py: 1.5 }}
                                        >
                                            Activate free trial
                                        </CustomSubmitButton>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>
            {!isEmpty(freeTrial) && (
                <Box
                    sx={{
                        p: 2,
                        position: "relative",
                        bgcolor: "red",
                        width: "100%",
                        overflow: "hidden",
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                {freeTrial.name}
                            </Typography>
                            <Typography
                                sx={{ fontSize: 14, textAlign: "center", p: 2 }}
                            >
                                {freeTrial.description}
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: "center", width: "50%" }}>
                            <Button
                                size="large"
                                color="warning"
                                variant="contained"
                                startIcon={<AppRegistration />}
                                endIcon={<ArrowRight />}
                                onClick={() => {
                                    handleDialogyOpen();
                                }}
                            >
                                Try Now !
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default FreeTrialWidget;
