import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import CustomSubmitButton from '../../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, SchoolOutlined } from '@mui/icons-material';
import CustomCard2 from '../../../../components/cards/CustomCard2';
import SmallCustomTextField from '../../../../components/Forms/SmallCustomTextField';
import { colors } from '../../../../assets/utils/constants';
import useRTK from '../../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useAddTrainingSeasonMutation } from '../../../../api/students/trainingSeasonAPI';
import { addTrainingSeason } from '../../../../states/features/students/trainingSeasonSlice';
import moment from 'moment';

// * ################### VALIDATION SCHEMA ####################
const validationSchema = Yup.object({
    title: Yup.string().required(),
    open_date: Yup.date()
        .min(
            moment(new Date()).format('YYYY-MM-DD'),
            `Open date must be later than today`,
        )
        .required('Open date is a required field'),
    close_date: Yup.date()
        .min(Yup.ref('open_date'), `Close date must be later than open date`)
        .required('Close date is a required field'),
});

const AddTrainingSeason = ({ handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        addTrainingSeasonAction,
        { isLoading, isSuccess, isError, error, data: TSData },
    ] = useAddTrainingSeasonMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(addTrainingSeason(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, TSData, rtkActions);

    // => Get current season
    const getCurrSeason = () => {
        let currDate = new Date();
        let currMonth = moment(currDate).format('MM');
        let currYear = parseInt(moment(currDate).format('YYYY'));
        let currSeason = '';
        if (currMonth > 6) {
            let startYear = currYear;
            let lastYear = currYear + 1;
            currSeason = startYear + '/' + lastYear;
        } else {
            let startYear = currYear - 1;
            let lastYear = currYear;
            currSeason = startYear + '/' + lastYear;
        }
        return 'Academic year ' + currSeason;
    };

    const currSeason = getCurrSeason();

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    title: currSeason,
                    open_date: '',
                    close_date: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(payload) => {
                    addTrainingSeasonAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomCard2 height={'40vh'}>
                            <Grid container>
                                <Grid item sm={12} xs={12}></Grid>

                                <Grid item sm={12}>
                                    <Typography
                                        className="success-message"
                                        sx={{ mt: 3, fontSize: 22 }}
                                    >
                                        {currSeason}
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{ my: 2 }}
                                        className="warning-message"
                                    >
                                        <Typography>
                                            Please provide start and end date of
                                            the training season.
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Training season date
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Open date'}
                                                    name={'open_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Close date'}
                                                    name={'close_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CustomCard2>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<SchoolOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={'info'}
                                    variant={'contained'}
                                    loading={isLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Add training season
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddTrainingSeason;
