import React from 'react';
import { School } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { useApproveStudentCompleteMutation } from '../../../api/practicalTraining/practicalTrainingApplicantsAPI';
import { updateApplicantDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { Form, Formik } from 'formik';

const ConfirmCompletion = ({ applicant, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        completeAction,
        {
            isLoading: completeLoading,
            isSuccess: completeSuccess,
            isError: completeIsError,
            error: completeError,
            data: completeData,
        },
    ] = useApproveStudentCompleteMutation();

    // ############# useEffect ##################
    // => arrival res
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(updateApplicantDetails(data));
    };
    // => complete res
    RTK.useRTKResponse(
        completeSuccess,
        completeIsError,
        completeError,
        completeData,
        rtkActions,
    );
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    student_id: '',
                    training_id: '',
                }}
                onSubmit={() => {
                    let payload = {
                        student_id: applicant?.appliedTraining?.student_id,
                        training_id: applicant?.appliedTraining?.training_id,
                    };
                    completeAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Box sx={{ mb: 2 }} className="warning-message">
                                    <Typography>
                                        Please verify and confirm student
                                        practical training completion.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<School />}
                                    btnColor={'success'}
                                    loading={completeLoading}
                                    variant={'outlined'}
                                    onClick={formik.handleSubmit}
                                    sx={{
                                        py: 1.5,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Confirm training completion
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ConfirmCompletion;
