import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import PageHeader from '../../components/PageHeader';

const DashboardLayout = ({ sidebar, children, head }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                padding={2}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
                }}
            >
                {head && (
                    <Grid item sm={12}>
                        <PageHeader head={head} />
                    </Grid>
                )}
                <Grid item sm={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={9}>
                            <Box>{children}</Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box
                                sx={{
                                    height: `87.8vh`,
                                    position: 'sticky',
                                    top: 80,
                                    py: 1.5,
                                    p: 1.5,
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4}) !important`,
                                    borderRadius: 4,
                                }}
                            >
                                {sidebar}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardLayout;
