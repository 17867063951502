import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { transactionEndpoints } from './transactionEndpoint';
import { headers } from '../baseUrl/setHeaders';

export const transactionAPI = createApi({
    reducerPath: 'transactionAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['jobTransactions'],
    endpoints: (builder) => ({
        getJobTransactions: builder.query({
            query: () => `${transactionEndpoints.GET_JOB_TRANSACTION}`,
            providesTags: ['jobTransactions'],
        }),
    }),
});

export const { useGetJobTransactionsQuery } = transactionAPI;
