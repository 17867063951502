import React, { useState } from "react";
import { colors } from "../../assets/utils/constants";
import {
    Avatar,
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    ArrowCircleRightOutlined,
    LockClock,
    Person,
    Place,
    RequestQuote,
    SupervisedUserCircleOutlined,
    Update,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import useCurrency from "../../hooks/useCurrency";
import strHelper from "../../helpers/strHelper";
import ApplyJob from "../../pages/jobs/CRUD/ApplyJob";
import CustomDialog from "../Dialogs/CustomDialog";
import { size } from "lodash";

// !############### MAIN FUNC #################
const JobForYouEmployerWidget = ({ job }) => {
    // ############# CONST ################
    const STR = strHelper();
    const currency = useCurrency();

    // ############# Comp State ################
    const [openDialog, setOpenDialog] = useState(false);

    // ############## FUNC #####################
    // => Apply dialog
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <React.Fragment>
            <Box
                className="neo-buldge"
                sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    marginBottom: 2,
                    transition: ".2s all ease-in-out",
                    border: `2px solid ${colors.secondary}`,
                    borderLeft: `4px solid ${
                        job.isApplied !== true
                            ? colors.warning
                            : job?.application.status === "applied"
                            ? colors.info
                            : job?.application.status === "accepted"
                            ? colors.success
                            : job?.application.status === "shortlisted"
                            ? colors.secondary
                            : job?.application.status === "declined"
                            ? "red"
                            : job?.application.status === "dropped"
                            ? colors.warning
                            : colors.primary
                    }`,
                    boxShadow: `2px 5px 10px 0px ${colors.bgColor3}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                    "& .more": {
                        transition: ".2s all ease-in-out",
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    },
                    ":hover": {
                        boxShadow: "4p 4px 4px 4px rgba(46, 119, 174, .8)",
                        transition: ".2s all ease-in-out",
                        "& .more": {
                            marginLeft: 1,
                            transition: ".2s all ease-in-out",
                        },
                    },
                }}
            >
                <Box>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Grid item sm={10.5}>
                            <List
                                sx={{
                                    width: "100%",
                                    mb: 0,
                                    pb: 0,
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="logo"
                                            src={job.employer.profile_picture}
                                            variant="square"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                                borderRadius: 2,
                                                border: `1px dotted ${colors.warning}`,
                                                padding: 0.2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                img: {
                                                    objectFit: "contain",
                                                },
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        sx={{
                                            ".MuiListItemText-primary": {
                                                fontSize: 17,
                                                fontWeight: "bold",
                                            },
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                    opacity: 0.7,
                                                    ml: 1,
                                                }}
                                            >
                                                {STR.strCapitalizeFirstChar(
                                                    job.title
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: "inline",
                                                        fontWeight: "bold",
                                                        ml: 1,
                                                        fontSize: 14,
                                                        color: colors.info,
                                                    }}
                                                    component="span"
                                                >
                                                    {job.jobCategory.name.toLowerCase() ===
                                                    "other"
                                                        ? job.other_job_category_name
                                                        : job.jobCategory.name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={1.5}>
                            <Box
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                <Link to={`/jobs/for-you/employers/${job.id}`}>
                                    <IconButton className="more" color="info">
                                        <Tooltip title={"Job details"} arrow>
                                            <ArrowCircleRightOutlined />
                                        </Tooltip>
                                    </IconButton>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ p: 1, ml: 1 }}>
                        {job.isFor === "company"
                            ? `For company`
                            : job.isFor === "group"
                            ? "For (groups)"
                            : "For (individuals)"}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        p: 1,
                        "& .chip": {
                            border: `1px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                        },
                    }}
                >
                    <Chip
                        icon={<Place />}
                        label={`${job?.region?.name} , 
                                ${job?.district?.name}`}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    {/* <Chip
                        icon={<LockClock />}
                        label={job.job_type.replace(/[^a-zA-Z ]/g, " ")}
                        sx={{ mr: 1, textTransform: "capitalize" }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    /> */}
                    <Chip
                        icon={<SupervisedUserCircleOutlined />}
                        label={
                            job.isApplied !== true
                                ? "Not applied"
                                : job?.application.status
                        }
                        sx={{
                            textTransform: "capitalize",
                            fontSize: 12,
                            fontWeight: "bold",
                            px: 1,
                            opacity: 0.8,
                        }}
                        size="small"
                        color={
                            job.isApplied !== true
                                ? "warning"
                                : job?.application.status === "applied" ||
                                  job?.application.status === "pending"
                                ? "info"
                                : job?.application.status === "accepted"
                                ? "success"
                                : job?.application.status === "shortlisted"
                                ? "secondary"
                                : job?.application.status === "declined"
                                ? "error"
                                : job?.application.status === "dropped"
                                ? "warning"
                                : "default"
                        }
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default JobForYouEmployerWidget;
