import React from "react";
import {
    Box,
    InputAdornment,
    Rating,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";

const CustomInput = styled(TextField)({});

const CustomRating = ({
    label,
    type,
    prepend,
    readOnly,
    bBottom,
    formik,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [selectValue, setSelectValue] = React.useState(
        field.value ? field.value : ""
    );

    const handleChange = (value) => {
        setSelectValue(value);
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, value);
    };

    return (
        <>
            <Box
                sx={{
                    mb: 2,
                    borderRadius: 1.5,
                    overflow: "hidden",
                    textAlign: "left",
                }}
            >
                <Typography
                    sx={{ fontSize: 17, fontWeight: "bold", opacity: 0.8 }}
                >
                    {label}
                </Typography>
                <Rating
                    value={selectValue}
                    onChange={(event, newValue) => {
                        handleChange(newValue);
                        setSelectValue(newValue);
                    }}
                    color="warning"
                />
                <Typography sx={{ m: 0, p: 0, mt: -1 }}>
                    <ErrorMessage
                        component="span"
                        name={field.name}
                        className="error-text"
                    />
                </Typography>
            </Box>
        </>
    );
};

export default CustomRating;
