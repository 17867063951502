import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    jobTransactions: [],
};

const transactionsSlice = createSlice({
    name: 'transactionsSlice',
    initialState,
    reducers: {
        setJobTransactions: (state, action) => {
            let transactions = action.payload;
            state.jobTransactions = transactions;
        },
        resetTransactionStates: (state) => {
            state.jobTransactions = [];
        },
    },
});

export const {
    setJobTransactions,
    resetTransactionStates,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
