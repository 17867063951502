import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Facebook, Instagram, Twitter, WhatsApp } from '@mui/icons-material';

const InfoCard = styled(Box)({
    'height': 50,
    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'padding': 5,
    'borderRadius': 12,
    'border': `1px solid ${colors.bgColor4}`,
    'borderLeft': `4px solid ${colors.bgColor5}`,
    'transition': '.3s all ease-in-out',
    ':hover': {
        border: `1px solid ${colors.success}`,
        borderLeft: `4px solid ${colors.success}`,
        transition: '.3s all ease-in-out',
    },
    '& .total': {
        width: 35,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 40,
        marginRight: 10,
        fontWeight: 'bold',
        fontSize: 20,
        background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
        boxShadow: `1px 1px 5px 0px ${colors.bgColor1}`,
    },
});

const SocialMediaWidget = ({ media }) => {
    return (
        <>
            <a href={media.url}>
                <InfoCard
                    sx={{
                        height: 50,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 0.5,
                        borderRadius: 2,
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography className="total">
                            {media.name.toLowerCase() === 'whatsapp' ? (
                                <WhatsApp color="success" />
                            ) : media.name.toLowerCase() === 'facebook' ? (
                                <Facebook color="info" />
                            ) : media.name.toLowerCase() === 'instagram' ? (
                                <Instagram color="error" />
                            ) : media.name.toLowerCase() === 'twitter' ? (
                                <Twitter color="info" />
                            ) : (
                                ''
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                            }}
                        >
                            {media.name}
                        </Typography>
                    </Box>
                </InfoCard>
            </a>
        </>
    );
};

export default SocialMediaWidget;
