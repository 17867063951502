import React from 'react';
import { Box, Typography, Button, Chip } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import moment from 'moment';
import CustomDialog from '../Dialogs/CustomDialog';
import OpenCloseTrainingSeason from '../../pages/studentsManagement/trainingSeason/CRUD/OpenCloseTrainingSeason';
import { useState } from 'react';

const TrainingSeasonWidget = ({ season }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionHead, setActionHead] = useState('');

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionHead) => {
        setOpenDialogy(true);
        setActionHead(actionHead);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <div>
            <CustomDialog
                head={actionHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <OpenCloseTrainingSeason
                    trainingSeason={season}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <Box>
                <Box
                    sx={{
                        p: 2,
                        mb: 2,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            color: colors.info,
                        }}
                    >
                        {season.title}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{}}>
                        <Chip
                            label={`Training season ${season.status}`}
                            color={
                                season.status === 'opened'
                                    ? 'success'
                                    : season.status === 'closed'
                                    ? 'error'
                                    : 'warning'
                            }
                            sx={{ fontWeight: 'bold', opacity: 0.8 }}
                        />
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 12,
                                color: colors.success,
                            }}
                        >
                            Open date
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: 15,
                                color: colors.info,
                            }}
                        >
                            {moment(season.open_date).format('LL')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            width: '100%',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: 12,
                                color: 'red',
                            }}
                        >
                            Close date
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: 15,
                                color: colors.info,
                            }}
                        >
                            {moment(season.close_date).format('LL')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }}
                    >
                        {season.status !== 'closed' ? (
                            <Button
                                size="small"
                                variant="outlined"
                                color={
                                    season.status === 'opened'
                                        ? 'warning'
                                        : season.status === 'inactive'
                                        ? 'success'
                                        : 'primary'
                                }
                                sx={{ mt: 0.5, width: 120 }}
                                onClick={() => {
                                    let head =
                                        season.status === 'opened'
                                            ? 'Close training season'
                                            : 'Open traning season';
                                    handleDialogOpen(head);
                                }}
                            >
                                {season.status === 'opened'
                                    ? 'Close training season'
                                    : season.status === 'closed'
                                    ? 'default'
                                    : season.status === 'inactive'
                                    ? 'Open training season'
                                    : ''}
                            </Button>
                        ) : (
                            <Button
                                disabled={true}
                                variant="outlined"
                                color="info"
                                size="small"
                                sx={{ width: 120 }}
                            >
                                Training Season closed
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default TrainingSeasonWidget;
