import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../../assets/utils/constants";
import SmallCustomTextField from "../../../components/Forms/SmallCustomTextField";
import { isEmpty } from "lodash";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import { ArrowRightAltOutlined, Upload } from "@mui/icons-material";
import {
    usePublishJobMutation,
    useUnpublishJobMutation,
} from "../../../api/job/jobAPI2";
import { setJobDetails } from "../../../states/features/job/jobsSlice";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import moment from "moment";

// * ################### VALIDATION SCHEMA ####################
const jobPublishValidationSchema = Yup.object({
    open_date: Yup.date()
        .min(
            moment(new Date()).format("YYYY-MM-DD"),
            `open date must be later than today`
        )
        .required("Open date is a required field"),
    close_date: Yup.date()
        .min(Yup.ref("open_date"), `close date must be later than open date`)
        .test({
            name: "max",
            exclusive: false,
            params: {},
            message: "The date range must be within 30 days",
            test: function (value) {
                return (
                    value <=
                    moment(this.parent.open_date, "DD-MM-YYYY").add(30, "days")
                );
            },
        })
        .required("close date is a required field"),
});

const PublishJob = ({ job: jobDetails, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        publishAction,
        {
            isLoading: publishLoading,
            isSuccess: publishSuccess,
            isError: publishIsError,
            error: publishError,
            data: publishData,
        },
    ] = usePublishJobMutation();
    const [
        unPublishAction,
        {
            isLoading: unPublishLoading,
            isSuccess: unPublishSuccess,
            isError: unPublishIsError,
            error: unPublishError,
            data: unPublishData,
        },
    ] = useUnpublishJobMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setJobDetails(data));
    };
    RTK.useRTKResponse(
        publishSuccess,
        publishIsError,
        publishError,
        publishData,
        rtkActions
    );
    RTK.useRTKResponse(
        unPublishSuccess,
        unPublishIsError,
        unPublishError,
        unPublishData,
        rtkActions
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    job_id: jobDetails.id,
                    open_date: isEmpty(jobDetails) ? "" : jobDetails.open_date,
                    close_date: isEmpty(jobDetails)
                        ? ""
                        : jobDetails.close_date,
                }}
                validationSchema={jobPublishValidationSchema}
                onSubmit={(payload) => {
                    if (
                        jobDetails.status === "draft" ||
                        jobDetails.status === "unpublished"
                    ) {
                        publishAction(payload);
                    } else if (jobDetails.status === "published") {
                        unPublishAction(payload);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Box sx={{ mb: 2 }} className="warning-message">
                                    <Typography>
                                        Please verify application date before
                                        publishing job.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        p: 1.5,
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            mb: 1,
                                        }}
                                    >
                                        Application date
                                        <Typography
                                            component={"span"}
                                            sx={{
                                                float: "right",
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                opacity: 0.8,
                                                color: "red",
                                            }}
                                        >
                                            ** Max post duration 30days **
                                        </Typography>
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "100%",
                                                mr: 1,
                                            }}
                                        >
                                            <SmallCustomTextField
                                                label={"Application start"}
                                                name={"open_date"}
                                                type={"date"}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                ml: 1,
                                            }}
                                        >
                                            <SmallCustomTextField
                                                label={"Application deadline"}
                                                name={"close_date"}
                                                type={"date"}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<Upload />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor="success"
                                    variant={"contained"}
                                    loading={publishLoading || unPublishLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Publish job
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PublishJob;
