import React from 'react';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import {
    useActivateDepartmentMutation,
    useDeactivateDepartmentMutation,
    useRemoveDepartmentMutation,
} from '../../../api/students/instituteProgramsAPI';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import { Delete } from '@mui/icons-material';
import { setDepartments } from '../../../states/features/students/instituteProgramsSlice.js';

const DepartmentActions = ({ department }) => {
    // ############ CONST ################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############ RTK #################
    const [
        activateAction,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateDepartmentMutation();
    const [
        deactivateAction,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateDepartmentMutation();
    const [
        removeDepartment,
        {
            isLoading: removeLoading,
            isSuccess: removeSuccess,
            isError: removeIsError,
            error: removeError,
            data: removeData,
        },
    ] = useRemoveDepartmentMutation();
    // ############# useEffect ##################
    // => Action res
    const rtkActions = (data) => {
        dispatch(setDepartments(data));
    };
    // => Activate
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    // => Deactivate res
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    // => Remove
    RTK.useRTKResponse(
        removeSuccess,
        removeIsError,
        removeError,
        removeData,
        rtkActions,
    );

    return (
        <>
            <Tooltip
                title={
                    department.status
                        ? 'Deactivate department'
                        : 'Activate department'
                }
                arrow
            >
                <IconButton
                    size="small"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    onClick={() =>
                        department.status
                            ? deactivateAction(department.id)
                            : activateAction(department.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                color="primary"
                                sx={{
                                    mx: 1.2,
                                    my: 0.3,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            color={department.status ? 'success' : 'error'}
                            checked={department.status ? true : false}
                        />
                    )}
                </IconButton>
            </Tooltip>
            <Tooltip title="Remove department" arrow>
                <IconButton
                    size="small"
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                    }}
                    onClick={() => removeDepartment(department.id)}
                    disabled={removeLoading ? true : false}
                >
                    {removeLoading ? (
                        <CircularProgress
                            size={15}
                            color="primary"
                            sx={{
                                mx: 0.5,
                            }}
                        />
                    ) : (
                        <Delete
                            sx={{
                                fontSize: 16,
                                mx: 0.5,
                            }}
                            color="error"
                        />
                    )}
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DepartmentActions;
