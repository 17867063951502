import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj } from '../../../helpers/arrayHelpers';
import moment from 'moment';

// => Get current season
const getCurrSeason = () => {
    let currDate = new Date();
    let currMonth = moment(currDate).format('MM');
    let currYear = parseInt(moment(currDate).format('YYYY'));
    let currSeason = '';
    if (currMonth > 6) {
        let startYear = currYear;
        let lastYear = currYear + 1;
        currSeason = startYear + '/' + lastYear;
    } else {
        let startYear = currYear - 1;
        let lastYear = currYear;
        currSeason = startYear + '/' + lastYear;
    }
    return 'Academic year ' + currSeason;
};

const currSeason = getCurrSeason();

const initialState = {
    trainingSeasons: [],
    currTrainingSeason: currSeason,
    trainingSeasonDetails: {},
};

const trainingSeasonSlice = createSlice({
    name: 'trainingSeasonSlice',
    initialState,
    reducers: {
        setTrainingSeason: (state, action) => {
            let practicalTrainings = action.payload;
            state.trainingSeasons = practicalTrainings;
        },
        addTrainingSeason: (state, actions) => {
            let payload = actions.payload;
            isEmpty(state.trainingSeasons)
                ? (state.trainingSeasons = [payload, ...[]])
                : (state.trainingSeasons = [payload, ...state.trainingSeasons]);
        },
        removeTrainingSeason: (state, actions) => {
            let payload = actions.payload;
            let students = removeArrObj('id', payload, state.trainingSeasons);
            state.trainingSeasons = students;
        },
        setTrainingSeasonDetails: (state, action) => {
            let details = action.payload;
            state.trainingSeasonDetails = details;
        },
    },
});

export const {
    setTrainingSeason,
    addTrainingSeason,
    removeTrainingSeason,
    setTrainingSeasonDetails,
} = trainingSeasonSlice.actions;

export default trainingSeasonSlice.reducer;
