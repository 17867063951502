import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const InfoboxWidget2 = ({ data, colorSign }) => {
    return (
        <>
            <Link to={data.url}>
                <Box
                    sx={{
                        'height': 90,
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                        'borderRadius': 4,
                        'display': 'flex',
                        'flexDirection': 'column',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'position': 'relative',
                        'overflow': 'hidden',
                        ':hover': {
                            '& .total': {
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                transition: `.3s all ease-in-out`,
                            },
                            '& .border': {
                                background: `linear-gradient(90deg, ${colors.bgColor1}, ${colors.secondary})`,
                                transition: `.3s all ease-in-out`,
                            },
                        },
                    }}
                >
                    <Box
                        className="total"
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            height: 50,
                            width: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottomRightRadius: 20,
                            boxShadow: `1px 1px 10px 0px ${colors.bgColor5}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 'bold',
                                color: colors.primary,
                                opacity: 0.8,
                            }}
                        >
                            {data.total}
                        </Typography>
                    </Box>
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: 16, opacity: 0.8 }}
                    >
                        {data.title1}
                    </Typography>
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: 13, opacity: 0.8 }}
                    >
                        {data.title2}
                    </Typography>
                    <Box
                        className="border"
                        sx={{
                            width: 10,
                            background: `linear-gradient(90deg, ${colors.bgColor1}, ${colorSign?colorSign:colors.bgColor5})`,
                            position: 'absolute',
                            top: 10,
                            bottom: 10,
                            right: 0,
                            borderTopLeftRadius: 15,
                            borderBottomLeftRadius: 15,
                        }}
                    ></Box>
                </Box>
            </Link>
        </>
    );
};

export default InfoboxWidget2;
