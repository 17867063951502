import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { colors } from '../../../assets/utils/constants';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
    sectionSelection,
    sectorDepartimentSelection,
} from '../../../states/features/practicalTraining/PTPostingSlice';
import CustomAutocomplete from '../../../components/Forms/CustomAutocomplete';
import {
    PTPostingSelector,
    settingsSelector,
} from '../../../states/features/selectors';
import CustomMonoIdAutocomplete from '../../../components/Forms/CustomMonoIdAutocomplete';
import { filter } from 'lodash';

// * ################### VALIDATION SCHEMA ####################
const sectionSelectionValidationSchema = Yup.object({
    sector_id: Yup.string().required('Sector is a required field'),
    department_id: Yup.string().required('Department is a required field'),
});

const SectionSelection = ({ handleNext, handleBack, steps, activeStep }) => {
    // ############## Comp state ############
    const [filteredDepartment, setFilteredDepartment] = useState([]);
    const [filteredSections, setFilteredSections] = useState([]);

    // ############## CONST ###############
    const dispatch = useDispatch();

    // ############# Redux state ################
    const { activeSectors, activeDepartments, activeSections } = useSelector(
        settingsSelector,
    );
    const { sector_id, department_id, sections } = useSelector(
        PTPostingSelector,
    );

    // ########## FUNC ###############
    // => Restructure sector obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                name: obj.name,
                label: obj.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    const availableSectors = restructureArray(activeSectors);

    // => Restructure sections obj
    const restructureSections = () => {
        let restructured = [];
        activeSections?.forEach((section) => {
            let newObj = {
                id: section.id,
                department_id: section.department_id,
                title: section.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    const availableSections = restructureSections();

    // => handle sector change
    const handleSectorChange = (selectedSector) => {
        let fiteredDepartment = filter(
            activeDepartments,
            ({ sector_id }) => sector_id === selectedSector,
        );
        const availableDepartments = restructureArray(fiteredDepartment);
        setFilteredDepartment(availableDepartments);
        return availableDepartments;
    };

    // => handle department change
    const handleDepartmentChange = (selectedDepartment) => {
        let fiteredSections = filter(
            availableSections,
            ({ department_id }) => department_id === selectedDepartment,
        );
        setFilteredSections(fiteredSections);
    };

    // => Handle selected sections
    const handleSelectedSections = (payload) => {
        dispatch(sectionSelection(payload));
    };

    return (
        <>
            <Formik
                initialValues={{
                    sector_id,
                    department_id,
                    section_id: '',
                }}
                validationSchema={sectionSelectionValidationSchema}
                onSubmit={(payload) => {
                    dispatch(sectorDepartimentSelection(payload));
                    handleNext();
                }}
            >
                {(formik) => (
                    <Form>
                        <Box
                            sx={{
                                height: 450,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                {/* ################## JOB TITLE ############ */}
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            p: 2,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Academic information
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={4}
                                            sx={{ mt: 0 }}
                                        >
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CustomMonoIdAutocomplete
                                                        label={'Sector'}
                                                        name={'sector_id'}
                                                        formik={formik}
                                                        options={
                                                            availableSectors
                                                        }
                                                        colors={colors.primary}
                                                        defaultOption={
                                                            sector_id
                                                        }
                                                        handler={
                                                            handleSectorChange
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={6} xs={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CustomMonoIdAutocomplete
                                                        label={'Departments'}
                                                        name={'department_id'}
                                                        formik={formik}
                                                        options={
                                                            filteredDepartment
                                                        }
                                                        colors={colors.primary}
                                                        defaultOption={
                                                            department_id
                                                        }
                                                        handler={
                                                            handleDepartmentChange
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CustomAutocomplete
                                                        label={
                                                            'Select sections'
                                                        }
                                                        placeholder={'Sections'}
                                                        options={
                                                            filteredSections
                                                        }
                                                        name="sections"
                                                        getSelectedValues={
                                                            handleSelectedSections
                                                        }
                                                        defaultOption={sections}
                                                    />
                                                    <Box
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography
                                                            className="error-message"
                                                            sx={{ mt: 3 }}
                                                        >
                                                            <strong>
                                                                Note:{' '}
                                                            </strong>
                                                            Selecting section is
                                                            an optional. Leave
                                                            empty for all
                                                            sections from
                                                            selected department
                                                            to view it.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* ################ ACTION BUTONS ###################### */}
                        <NextAndBackBtn
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            action={formik.handleSubmit}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SectionSelection;
