import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { differenceWith, isEmpty } from 'lodash';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { useAddSectorMutation } from '../../../api/students/instituteProgramsAPI';
import { setSectors } from '../../../states/features/students/instituteProgramsSlice.js';
import CustomAutocomplete from '../../../components/Forms/CustomAutocomplete';
import { settingsSelector } from '../../../states/features/selectors';
import CustomCard2 from '../../../components/cards/CustomCard2';

// * ################### VALIDATION SCHEMA ####################
const jobPublishValidationSchema = Yup.object({
    sectors: Yup.array().required('Please select atleast one sector'),
});

const AddSectors = ({ addedSectors, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp State #############
    const [sectorsIds, setSectorsIds] = useState('');

    // ############# Redux state ################
    const { activeSectors } = useSelector(settingsSelector);

    // ############# RTK ##################
    const [
        addSectorAction,
        { isLoading, isSuccess, isError, error, data: sectorData },
    ] = useAddSectorMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setSectors(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, sectorData, rtkActions);

    // ########## FUNC ###############
    // => Restructure sector obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                title: obj.name,
                label: obj.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    // => Get unadded sectors
    const getPendingAddedSectors = () => {
        let pendingSectors = differenceWith(
            activeSectors,
            addedSectors,
            (a, b) => a.id === b.sector_id,
        );
        return pendingSectors;
    };
    const availableSectors = restructureArray(getPendingAddedSectors());

    const handleSelectedValues = (payload) => {
        isEmpty(payload) ? setSectorsIds('') : setSectorsIds(payload);
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    sectors: sectorsIds,
                }}
                validationSchema={jobPublishValidationSchema}
                onSubmit={(payload) => {
                    addSectorAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomCard2 height={'50vh'}>
                            <Grid container>
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{ mb: 2 }}
                                        className="warning-message"
                                    >
                                        <Typography>
                                            <strong>NOTE : </strong>
                                            Select sectors which available on
                                            your institute.
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item sm={12}>
                                    <CustomAutocomplete
                                        label={'Select sectors'}
                                        placeholder={'Institution'}
                                        name="sectors"
                                        options={availableSectors}
                                        getSelectedValues={handleSelectedValues}
                                        defaultOption={undefined}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard2>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<PublishOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={'info'}
                                    variant={'contained'}
                                    loading={isLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Add sector(s)
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddSectors;
