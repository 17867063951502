import { jobAPI2 } from "./jobAPI2";
import { jobApplicationEndpoints } from "./jobApplicationEndpoints";

export const jobApplicationAPI = jobAPI2.injectEndpoints({
    endpoints: (builder) => ({
        // => Single job applications
        getSingleJobApplications: builder.query({
            query: (payload) =>
                `${jobApplicationEndpoints.SINGLE_JOB_APPLICATIONS}/${payload}`,
            providesTags: ["singleJobApplications"],
        }),

        // => GET applied org jobs
        getAppliedORGJobs: builder.query({
            query: () => `${jobApplicationEndpoints.GET_APPLIED_ORG_JOBS}`,
            providesTags: ["singleJobApplications"],
        }),

        // => GET applied employer jobs
        getAppliedEmployerJobs: builder.query({
            query: () => `${jobApplicationEndpoints.GET_APPLIED_EMPLOYER_JOBS}`,
            providesTags: ["singleJobApplications"],
        }),

        // => Companies applications
        getCompaniesApplications: builder.query({
            query: () => `${jobApplicationEndpoints.COMPANIES_APPLICATIONS}`,
            providesTags: ["companyApplications"],
        }),

        // => Individual applications
        getIndividualsApplications: builder.query({
            query: () => `${jobApplicationEndpoints.INDIVIDUAL_APPLICATIONS}`,
            providesTags: ["individualApplications"],
        }),

        // => Group applications
        getGroupsApplications: builder.query({
            query: () => `${jobApplicationEndpoints.GROUP_APPLICATIONS}`,
            providesTags: ["groupApplications"],
        }),

        // => Save application
        saveApplication: builder.mutation({
            query: (payload) => ({
                url: `${jobApplicationEndpoints.SAVE_APPLICATION}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (arg, error) =>
                error
                    ? []
                    : [
                          "singleJobApplications",
                          "companyApplications",
                          "individualApplications",
                          "groupApplications",
                      ],
        }),

        // => Shortlist application
        shortlistApplication: builder.mutation({
            query: (payload) => ({
                url: `${jobApplicationEndpoints.SHORTLIST_APPLICATION}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (arg, error) =>
                error
                    ? []
                    : [
                          "singleJobApplications",
                          "companyApplications",
                          "individualApplications",
                          "groupApplications",
                      ],
        }),

        // => Accept application
        acceptApplication: builder.mutation({
            query: (payload) => ({
                url: `${jobApplicationEndpoints.ACCEPT_APPLICATION}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (arg, error) =>
                error
                    ? []
                    : [
                          "singleJobApplications",
                          "companyApplications",
                          "individualApplications",
                          "groupApplications",
                      ],
        }),

        // => Decline application
        declineApplication: builder.mutation({
            query: (payload) => ({
                url: `${jobApplicationEndpoints.DECLINE_APPLICATION}/${payload}`,
                method: "PUT",
            }),
            invalidatesTags: (arg, error) =>
                error
                    ? []
                    : [
                          "singleJobApplications",
                          "companyApplications",
                          "individualApplications",
                          "groupApplications",
                      ],
        }),

        // => Remark applied job
        remarkAppliedJob: builder.mutation({
            query: (payload) => ({
                url:
                    payload.type === "company"
                        ? `${jobApplicationEndpoints.REMARK_APPLIED_ORG_JOB}/${payload.job_id}`
                        : `${jobApplicationEndpoints.REMARK_APPLIED_EMPLOYER_JOB}/${payload.job_id}`,
                method: "PUT",
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    jobAPI2.util.invalidateTags(["Job", "Jobs", "JobsForYou"])
                );
            },
        }),
    }),
});

export const {
    useGetSingleJobApplicationsQuery,
    useSaveApplicationMutation,
    useShortlistApplicationMutation,
    useAcceptApplicationMutation,
    useDeclineApplicationMutation,
    useRemarkAppliedJobMutation,

    // lazy
    useLazyGetSingleJobApplicationsQuery,
    useLazyGetCompaniesApplicationsQuery,
    useLazyGetIndividualsApplicationsQuery,
    useLazyGetGroupsApplicationsQuery,
    useLazyGetAppliedORGJobsQuery,
    useLazyGetAppliedEmployerJobsQuery,
} = jobApplicationAPI;
