import { Box, Card, CardHeader, styled } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';

// -> styled MUI
const CustomCardStyled = styled(Card)(({ theme }) => ({
    'color': 'gray',
    'borderRadius': 15,
    'boxShadow': `1px 1px 10px 0px ${colors.bgColor5}`,
    '& .card-header': {
        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
        'padding': 12,
        '.MuiCardHeader-avatar': {
            position: 'relative',
            right: 20,
        },
        '.MuiCardHeader-subheader': {
            color: colors.primary,
            fontWeight: 'bold',
            fontSize: 16,
            marginLeft: 1.5,
            opacity: 0.8,
        },
    },
}));

function CustomCard({
    head,
    children,
    height,
    action,
    bodyPadding,
    borderRadius,
    borderBottom,
}) {
    return (
        <React.Fragment>
            <CustomCardStyled
                sx={{
                    borderBottom: borderBottom
                        ? `15px solid ${colors.bgColor4}`
                        : `1px solid ${colors.bgColor4}`,
                }}
            >
                <CardHeader
                    className="card-header"
                    subheader={head}
                    action={action ? action : ''}
                ></CardHeader>
                <Box
                    sx={{
                        overflow: 'auto',
                        height: height ? height : '100%',
                        p: bodyPadding,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    {children}
                </Box>
            </CustomCardStyled>
        </React.Fragment>
    );
}

export default CustomCard;
