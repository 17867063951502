import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { ArrowRight } from "@mui/icons-material";

const JobInfoCardCon = styled(Box)({
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-betwee",
    padding: 5,
    position: "relative",
    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3}) !important`,
    color: colors.primary,
    ".total": {
        borderRadius: "50%",
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        fontWeight: "bold",
        fontStyle: "italic",
        borderBottom: `4px solid ${colors.bgColor5}`,
        transition: ".3s all ease-in-out",
        background: `${colors.secondary}`,
    },
    ":hover": {
        transition: ".3s all ease-in-out",
        "& .total": {
            transform: `scale(1.1)`,
            transition: ".3s all ease-in-out",
            borderBottom: `4px solid ${colors.primary}`,
        },
        ".arrow-con": {
            right: 2.5,
            transition: ".3s all ease-in-out",
        },
    },
    ".arrow-con": {
        position: "absolute",
        right: 5,
        transition: ".3s all ease-in-out",
    },
});

const JobInfoCard = ({ info }) => {
    return (
        <>
            <JobInfoCardCon
                sx={{
                    borderBottom: `4px solid ${
                        info.color ? info.color : colors.secondary
                    }`,
                }}
            >
                <Box className="total">{info.total}</Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 14,
                        }}
                    >
                        {info.name}
                    </Typography>
                </Box>
                <Box className="arrow-con">
                    <ArrowRight sx={{ mt: 1 }} />
                </Box>
            </JobInfoCardCon>
        </>
    );
};

export default JobInfoCard;
