import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { filter, isEmpty, size } from 'lodash';
import { useSelector } from 'react-redux';
import {
    PTSelector,
    trainingSeasonSelector,
} from '../../states/features/selectors';
import CustomCard2 from '../cards/CustomCard2';
import CurrPTSeason from '../widgets/CurrPTSeason';
import NoContent from '../NoContent';

const TrainingSeasonSidebar = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);
    const { trainingSeasons, currTrainingSeason } = useSelector(
        trainingSeasonSelector,
    );

    // ############## FUNC ###################
    // => Curr training season
    const getCurrTrainingSeason = () => {
        let currTraining = filter(
            trainingSeasons,
            ({ title }) => title === currTrainingSeason,
        );
        return currTraining[0];
    };

    // => Applicant by status
    const getApplicantsByStatus = () => {
        let pendingApplicants = [];
        let approvedApplicants = [];
        let onTrainingApplicants = [];
        let completedApplicants = [];
        // => Pending
        pendingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) => appliedTraining.status === 'pending',
        );
        // => Approved
        approvedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.status === 'approved' &&
                appliedTraining.onTrainingStatus === 'pending',
        );
        // => OnTraining
        onTrainingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'on-training',
        );
        // => Completed
        completedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'completed',
        );
        return {
            pendingApplicants,
            approvedApplicants,
            onTrainingApplicants,
            completedApplicants,
        };
    };

    return (
        <div>
            <>
                <Box sx={{ mb: 3 }}>
                    <CustomCard2 head={'Current practical training season'}>
                        {isEmpty(getCurrTrainingSeason()) ? (
                            <NoContent
                                message={'Training season not added'}
                                height={150}
                            />
                        ) : (
                            <CurrPTSeason season={getCurrTrainingSeason()} />
                        )}
                    </CustomCard2>
                </Box>
            </>
        </div>
    );
};

export default TrainingSeasonSidebar;
