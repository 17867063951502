import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { colors } from "../../assets/utils/constants";
import { AllInclusiveOutlined } from "@mui/icons-material";
import { jobApplicationsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { filter, size } from "lodash";

const JobInfoBoxDetails = ({ jobDetails }) => {
    // ################## Redux state ##############
    const { singleJobApplications } = useSelector(jobApplicationsSelector);

    // ################## CONST ##############
    const acceptedApplications = filter(
        singleJobApplications,
        ({ application }) => application.status === "accepted"
    );
    return (
        <>
            <Grid container spacing={2}>
                {!jobDetails.isBelongTo && (
                    <Grid item sm={12}>
                        <Box
                            className={
                                jobDetails.isApplied !== true
                                    ? "warning-box"
                                    : jobDetails?.application.status ===
                                          "applied" ||
                                      jobDetails?.application.status ===
                                          "pending"
                                    ? "info-box"
                                    : jobDetails?.application.status ===
                                      "accepted"
                                    ? "success-box"
                                    : jobDetails?.application.status ===
                                      "employed"
                                    ? "success-box"
                                    : jobDetails?.application.status ===
                                      "employed"
                                    ? "success-box"
                                    : jobDetails?.application.status ===
                                      "shortlisted"
                                    ? "info-box"
                                    : jobDetails?.application.status ===
                                      "declined"
                                    ? "error-box"
                                    : jobDetails?.application.status ===
                                      "dropped"
                                    ? "warning"
                                    : colors.primary
                            }
                            sx={{
                                borderRadius: 2,
                                flexDirection: "column",
                                p: 0.4,
                                boxShadow:
                                    "3px 3px 3px 3px rgba(46, 119, 174,0.2)",
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    p: 0.5,
                                    fontSize: 13,
                                    color: colors.primary,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                            >
                                Job application status
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    p: 0.5,
                                    textTransform: "capitalize",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                {jobDetails.isApplied !== true
                                    ? "Not applied"
                                    : jobDetails?.application.status ===
                                      "pending"
                                    ? "Applied"
                                    : jobDetails?.application.status}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item sm={12}>
                    <Box
                        sx={{
                            bgcolor: colors.bgColor,
                            borderRadius: 2,
                            boxShadow: "3px 3px 3px 3px rgba(46, 119, 174,0.2)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Position
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    opacity: 0.8,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: "bold",
                                }}
                            >
                                {`${
                                    jobDetails.positions -
                                    size(acceptedApplications)
                                } out of ${jobDetails.positions}`}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Max applicant
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: "bold",
                                }}
                            >
                                {jobDetails.max_applicants ? (
                                    jobDetails.max_applicants
                                ) : (
                                    <AllInclusiveOutlined
                                        color="warning"
                                        sx={{ fontSize: 12 }}
                                    />
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            bgcolor: colors.bgColor,
                            borderRadius: 2,
                            boxShadow: "3px 3px 3px 3px rgba(46, 119, 174,0.2)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Open date
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: "bold",
                                }}
                            >
                                {moment(jobDetails.open_date).format("ll")}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Close date
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: "bold",
                                }}
                            >
                                {moment(jobDetails.close_date).format("ll")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default JobInfoBoxDetails;
