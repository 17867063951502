import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { colors } from "../../assets/utils/constants";
import CustomPasswordField from "../../components/Forms/CustomPasswordField";
import CustomSubmitButton from "../../components/Forms/CustomSubmitButton";
import { Lock } from "@mui/icons-material";
import { useChangePasswordMutation } from "../../api/profile/profileAPI";
import useRTK from "../../hooks/useRTK";

// ####################### VALIDATION #######################
const validation = Yup.object({
    current_password: Yup.string()
        .required("This field is required")
        .min(8, "Password must be at least 8 characters"),
    new_password: Yup.string()
        .required("This field is required")
        .min(8, "Password must be at least 8 characters"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "New passwords must match")
        .required("This field is required")
        .min(8),
});

const ChangePassword = () => {
    // ############## CONST ##################
    const RTK = useRTK();

    // ############# RTK ##################
    const [
        changePassword,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useChangePasswordMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {};
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Grid item md={10} sm={10} xs={11}>
                <Formik
                    initialValues={{
                        current_password: "",
                        new_password: "",
                        passwordConfirmation: "",
                    }}
                    validationSchema={validation}
                    onSubmit={(payload, { resetForm }) => {
                        resetForm();
                        changePassword(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid container>
                                <Grid
                                    item
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        marginTop: 4,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Change login password
                                    </Typography>
                                    {/* ######### old password ######### */}
                                    <CustomPasswordField
                                        className="form-input"
                                        label="Current password"
                                        labelColor={colors.primary}
                                        type="password"
                                        name="current_password"
                                    />
                                    {/* ######### new password ######### */}
                                    <CustomPasswordField
                                        className="form-input"
                                        label="New password"
                                        labelColor={colors.primary}
                                        type="password"
                                        name="new_password"
                                    />
                                    {/* ######### confirm password ######### */}
                                    <CustomPasswordField
                                        className="form-input"
                                        label="Confirm new password"
                                        labelColor={colors.primary}
                                        type="password"
                                        name="passwordConfirmation"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        marginBottom: 3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <CustomSubmitButton
                                            sx={{ py: 1.5 }}
                                            startIcon={<Lock />}
                                            loading={isLoading}
                                            onClick={formik.handleSubmit}
                                        >
                                            Update password
                                        </CustomSubmitButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </>
    );
};

export default ChangePassword;
