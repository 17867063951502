import React from 'react';
import { ManageAccounts } from '@mui/icons-material';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { colors } from '../../../assets/utils/constants';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import CustomTextField from '../../../components/Forms/CustomTextField';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import {
    useAddORGKeyPersonMutation,
    useUpdateORGKeyPersonMutation,
} from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';

// * -> key person
const keyPersonValidationSchema = Yup.object({
    first_name: Yup.string().required('First name is required field'),
    middle_name: Yup.string().nullable(),
    last_name: Yup.string().required('Last name is required field'),
    phone_number: Yup.string()
        .required('phone number is required field')
        .nullable()
        .max(10, 'phone number must be at most 10 characters')
        .min(10, 'phone number must be at least 10 characters'),
    gender: Yup.string().required('Gender is required field'),
});

const UploadKeyPerson = ({ orgKeyPerson, closeDialogy, refetchProfile }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        addORGKeyPerson,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useAddORGKeyPersonMutation();
    const [
        updateORGKeyPerson,
        {
            isLoading: updateLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updateUpdateddData,
        },
    ] = useUpdateORGKeyPersonMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    RTK.useRTKResponse(
        updateSuccess,
        updateIsError,
        updateError,
        updateUpdateddData,
        rtkActions,
    );

    return (
        <>
            <Formik
                initialValues={{
                    first_name: orgKeyPerson ? orgKeyPerson.first_name : '',
                    last_name: orgKeyPerson ? orgKeyPerson.last_name : '',
                    middle_name: orgKeyPerson ? orgKeyPerson.middle_name : '',
                    phone_number: orgKeyPerson
                        ? 0 + orgKeyPerson.phone_number.substring(3)
                        : '',
                    gender: orgKeyPerson ? orgKeyPerson.gender : '',
                }}
                validationSchema={keyPersonValidationSchema}
                onSubmit={(payload) => {
                    if (isEmpty(orgKeyPerson)) {
                        addORGKeyPerson(payload);
                    } else {
                        updateORGKeyPerson(payload);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container>
                                    <Grid item sm={4} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`First name`}
                                            type="text"
                                            name="first_name"
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Middle name`}
                                            type="text"
                                            name="middle_name"
                                        />
                                    </Grid>
                                    <Grid item sm={4} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Last name`}
                                            type="text"
                                            name="last_name"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} padding={1}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`Phone`}
                                            type="text"
                                            name="phone_number"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} padding={1}>
                                        <CustomSelectInput
                                            label="Gender"
                                            type="text"
                                            name="gender"
                                            color={colors.primary}
                                            formik={formik}
                                        >
                                            <MenuItem value={'male'}>
                                                Male
                                            </MenuItem>
                                            <MenuItem value={'female'}>
                                                Female
                                            </MenuItem>
                                        </CustomSelectInput>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<ManageAccounts />}
                                        onClick={formik.handleSubmit}
                                        loading={
                                            isLoading
                                                ? isLoading
                                                : updateLoading
                                        }
                                    >
                                        {!isEmpty(orgKeyPerson)
                                            ? 'Update'
                                            : 'Add'}{' '}
                                        key person
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UploadKeyPerson;
