import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { colors } from '../../assets/utils/constants';
import { useState } from 'react';
import { TablePagination } from '@mui/material';
import OnFieldStudentWidget from '../widgets/OnFieldStudentWidget';

// ######################## Table column #######################
const columns = [{ id: 's_n', label: 'S/N', minWidth: 1 }];
function createData(s_n) {
    return {
        s_n,
    };
}

// !###################### MAIN FUNC ########################
const OnTrainingStudentsTable = () => {
    // -> Table state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // ######################## TABLE ROW #######################
    const rows = [1, 2, 3, 4, 5]?.map((index) => {
        return createData(<OnFieldStudentWidget />);
    });

    return (
        <React.Fragment>
            <Paper
                sx={{
                    'width': '100%',
                    'overflow': 'hidden',
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    'boxShadow': 'none',
                    ':hover': {
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    },
                }}
            >
                <TableContainer sx={{ height: '42.5vh' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{ p: 0 }}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            'number'
                                                            ? column.format(
                                                                  value,
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};

export default OnTrainingStudentsTable;
