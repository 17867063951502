import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { AddPhotoAlternate, Image } from '@mui/icons-material';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { colors } from '../../../assets/utils/constants';
import { useUpdateORGLogoMutation } from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import useRTK from '../../../hooks/useRTK';

// ################## VALIDATION ####################
// * -> logo
const logoValidationSchema = Yup.object().shape({
    file: Yup.mixed().required('File field is required'),
});

// !############## MAIN FUNC ################
const UpdateLogo = ({ user, closeDialogy, refetchProfile }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##########
    // -> handle file preview
    const [filePreview, setFilePreview] = useState('');

    // ############## RTK ##################
    const [
        updateORGLogo,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateORGLogoMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    file: '',
                }}
                validationSchema={logoValidationSchema}
                onSubmit={(payload) => {
                    let id = user.id;
                    const data = new FormData();
                    data.append('id', id);
                    data.append('org_logo', payload.file);
                    updateORGLogo(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12}>
                                {filePreview ? (
                                    <>
                                        <img
                                            src={filePreview}
                                            alt="img"
                                            style={{
                                                width: '100%',
                                                height: '10em',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            sx={{
                                                width: '100%',
                                                height: '7em',
                                                objectFit: 'contain',
                                                opacity: 0.7,
                                                color: colors.bgColor5,
                                            }}
                                        />
                                    </>
                                )}
                            </Grid>
                            <Grid item sm={12}>
                                <CustomFileInput
                                    name="org_logo"
                                    type="file"
                                    formik={formik}
                                    accept="image/*"
                                    setFilePreview={setFilePreview}
                                />
                            </Grid>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <CustomSubmitButton
                                    loading={isLoading}
                                    btnColor={'primary'}
                                    startIcon={<AddPhotoAlternate />}
                                    onClick={formik.handleSubmit}
                                >
                                    Update logo
                                </CustomSubmitButton>
                            </Box>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateLogo;
