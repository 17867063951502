import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { helpCenterEndpoints } from './helpCenterEndpoints';
import { headers } from '../baseUrl/setHeaders';
import { orgBaseURL } from '../baseUrl/baseURL';

export const contactUsAPI = createApi({
    reducerPath: 'contactUsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
        prepareHeaders: headers,
    }),
    tagTypes: ['Contacts', 'SocialNets'],
    endpoints: (builder) => ({
        // => Send us  email
        sendUsEmail: builder.mutation({
            query: (payload) => ({
                url: `${helpCenterEndpoints.SEND_US_EMAIL}`,
                method: 'POST',
                body: payload,
            }),
        }),
    }),
});

export const { useSendUsEmailMutation } = contactUsAPI;
