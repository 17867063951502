import { useEffect, useState } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../states/features/profile/profileSlice";
import { useGetProfileQuery } from "../api/profile/profileAPI";
import {
    useLazyGetAllJobsQuery,
    useLazyGetJobsForYouEmployerQuery,
    useLazyGetJobsForYouORGQuery,
} from "../api/job/jobAPI2";
import {
    setJobs,
    setJobsForYouORG,
    setJobsForYouEmployer,
    setAppliedORGJobs,
    setAppliedEmployerJobs,
} from "../states/features/job/jobsSlice";
import {
    useGetActiveORGFreeTrialQuery,
    useGetVerificationTypesQuery,
} from "../api/settings/settingAPI";
import {
    disableMainFetch,
    setActiveFreeTrials,
    setVerificationTypes,
} from "../states/features/settings/settingsSlice";
import {
    setAllPracticalTrainingApplicants,
    setORGPracticalTrainings,
    setOtherPracticalTrainings,
    setSpecialORGPracticalTrainings,
} from "../states/features/practicalTraining/practicalTrainingsSlice";
import {
    useLazyGetORGPracticalTraingsQuery,
    useLazyGetOtherORGPracticalTraingsQuery,
    useLazyGetSpecialORGPracticalTraingsQuery,
} from "../api/practicalTraining/practicalTrainingsAPI";
import { authSelector, settingsSelector } from "../states/features/selectors";
import { useGetJobTransactionsQuery } from "../api/transactions/transactionAPI";
import { setJobTransactions } from "../states/features/transactions/transactionsSlice";
import {
    useLazyGetDepartmentsQuery,
    useLazyGetSectionsQuery,
    useLazyGetSectorsQuery,
} from "../api/students/instituteProgramsAPI";
import {
    setDepartments,
    setSections,
    setSectors,
} from "../states/features/students/instituteProgramsSlice.js";
import { setStudents } from "../states/features/students/studentsSlice";
import { useLazyGetAllStudentsQuery } from "../api/students/studentsAPI";
import { useLazyGetAllTrainingApplicantsQuery } from "../api/practicalTraining/practicalTrainingApplicantsAPI";
import { setTrainingSeason } from "../states/features/students/trainingSeasonSlice";
import { useLazyGetTrainingSeasonsQuery } from "../api/students/trainingSeasonAPI";
import {
    useLazyGetAppliedEmployerJobsQuery,
    useLazyGetAppliedORGJobsQuery,
    useLazyGetCompaniesApplicationsQuery,
    useLazyGetGroupsApplicationsQuery,
    useLazyGetIndividualsApplicationsQuery,
} from "../api/job/jobApplicationAPI";
import {
    setCompanyJobApplications,
    setGroupsJobApplications,
    setIndividualJobApplications,
} from "../states/features/job/jobApplicationsSlice";
import { useLazyGetAllNotificationsQuery } from "../api/notifications/notificationsAPI";
import { setNotifications } from "../states/features/notifications/notificationsSlice";

const useRTK = () => {
    // ######### CONST #############
    const { user, authorizationType } = useSelector(authSelector);

    // ######### Redux states #############
    const { isMainFetch } = useSelector(settingsSelector);
    const [isToast, setIsToast] = useState(false);

    // => Use RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                setIsToast(true);
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    if (isToast) {
                        successNotify(getRTKSuccessMessage(data));
                        setIsToast(true);
                    }
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : "Some error occur. Please try again!"
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => Use RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    // => RTK main data
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Profile #################
        const {
            isLoading: profileLoading,
            isSuccess: profileSuccess,
            isError: profileIsError,
            error: profileError,
            data: profileData,
        } = useGetProfileQuery();
        const rtkPofileActions = (data) => {
            dispatch(setUserProfile(data));
        };
        // if (profileIsError) {
        //     if (profileError.data.code) {
        //         dispatch(resetAuthStates());
        //     }
        // }
        useRTKFetch(profileSuccess, profileData, rtkPofileActions);
        // ################# Jobs #################
        const [
            getJobs,
            { isLoading: jobLoading, isSuccess: jobSuccess, data: jobData },
        ] = useLazyGetAllJobsQuery();
        const rtkJobActions = (data) => {
            dispatch(setJobs(data));
        };
        useRTKFetch(jobSuccess, jobData, rtkJobActions);
        // ################# Jobs applied #################
        // => ORG
        const [
            getAppliedORGJobs,
            {
                isLoading: appliedORGJobsLoading,
                isSuccess: appliedORGJobsSuccess,
                data: appliedORGJobsData,
            },
        ] = useLazyGetAppliedORGJobsQuery();
        const rtkAppliedORGJobsActions = (data) => {
            dispatch(setAppliedORGJobs(data));
        };
        useRTKFetch(
            appliedORGJobsSuccess,
            appliedORGJobsData,
            rtkAppliedORGJobsActions
        );
        // => Employer
        const [
            getAppliedEmployerJobs,
            {
                isLoading: appliedEmployerJobsLoading,
                isSuccess: appliedEmployerJobsSuccess,
                data: appliedEmployerJobsData,
            },
        ] = useLazyGetAppliedEmployerJobsQuery();
        const rtkAppliedEmployerJobsActions = (data) => {
            dispatch(setAppliedEmployerJobs(data));
        };
        useRTKFetch(
            appliedEmployerJobsSuccess,
            appliedEmployerJobsData,
            rtkAppliedEmployerJobsActions
        );
        // ################# Jobs For you #################
        // => ORG
        const [
            getJobsForYouORG,
            {
                isLoading: jobForYouORGLoading,
                isSuccess: jobForYouORGSuccess,
                data: jobForYouORGData,
            },
        ] = useLazyGetJobsForYouORGQuery();
        const rtkJobForYouORGActions = (data) => {
            dispatch(setJobsForYouORG(data));
        };
        useRTKFetch(
            jobForYouORGSuccess,
            jobForYouORGData,
            rtkJobForYouORGActions
        );
        // => Employer
        const [
            getJobsForYouEmployer,
            {
                isLoading: jobForYouEmployerLoading,
                isSuccess: jobForYouEmployerSuccess,
                data: jobForYouEmployerData,
            },
        ] = useLazyGetJobsForYouEmployerQuery();
        const rtkJobForYouEmployerActions = (data) => {
            dispatch(setJobsForYouEmployer(data));
        };
        useRTKFetch(
            jobForYouEmployerSuccess,
            jobForYouEmployerData,
            rtkJobForYouEmployerActions
        );
        // ################# Transactions #################
        const { isSuccess: jobTransactionSuccess, data: jobTransactionData } =
            useGetJobTransactionsQuery();
        const rtkJobTransactionActions = (data) => {
            dispatch(setJobTransactions(data));
        };
        useRTKFetch(
            jobTransactionSuccess,
            jobTransactionData,
            rtkJobTransactionActions
        );
        // ################# Students #################
        const [
            getStudents,
            {
                isLoading: studentsLoading,
                isSuccess: studentsSuccess,
                data: studentsData,
            },
        ] = useLazyGetAllStudentsQuery();
        const rtkStudentsActions = (data) => {
            dispatch(setStudents(data));
        };
        useRTKFetch(studentsSuccess, studentsData, rtkStudentsActions);
        // ################# Practical training #################
        // => ORG PTs
        const [
            getORGPT,
            {
                isLoading: ORGPTLoading,
                isSuccess: ORGPTSuccess,
                data: ORGPTData,
            },
        ] = useLazyGetORGPracticalTraingsQuery();
        const rtkORGPTActions = (data) => {
            dispatch(setORGPracticalTrainings(data));
        };
        useRTKFetch(ORGPTSuccess, ORGPTData, rtkORGPTActions);
        // => Special PTs
        const [
            getSpecialPTs,
            {
                isLoading: SpecialORGPTLoading,
                isSuccess: SpecialORGPTSuccess,
                data: SpecialORGPTData,
            },
        ] = useLazyGetSpecialORGPracticalTraingsQuery();
        const rtkSpecialORGPTActions = (data) => {
            dispatch(setSpecialORGPracticalTrainings(data));
        };
        useRTKFetch(
            SpecialORGPTSuccess,
            SpecialORGPTData,
            rtkSpecialORGPTActions
        );
        // => Other PTs
        const [
            getOtherPTs,
            {
                isLoading: otherPTLoading,
                isSuccess: otherPTSuccess,
                data: otherPTData,
            },
        ] = useLazyGetOtherORGPracticalTraingsQuery();
        const rtkOtherPTActions = (data) => {
            dispatch(setOtherPracticalTrainings(data));
        };
        useRTKFetch(otherPTSuccess, otherPTData, rtkOtherPTActions);
        // => All PTs applicants
        const [
            getAllPTsApplicants,
            {
                isLoading: allPTApplicantsLoading,
                isSuccess: allPTApplicantsSuccess,
                data: allPTApplicantsData,
            },
        ] = useLazyGetAllTrainingApplicantsQuery();
        const rtkAllPTApplicantsActions = (data) => {
            dispatch(setAllPracticalTrainingApplicants(data));
        };
        useRTKFetch(
            allPTApplicantsSuccess,
            allPTApplicantsData,
            rtkAllPTApplicantsActions
        );

        // ####################### Job applications ####################
        // => Company applications
        const [
            getCompaniesApplications,
            {
                isLoading: companiesAppLoading,
                isSuccess: companiesAppSuccess,
                data: companiesAppData,
            },
        ] = useLazyGetCompaniesApplicationsQuery();
        const rtkCompaniesAppActions = (data) => {
            dispatch(setCompanyJobApplications(data));
        };
        useRTKFetch(
            companiesAppSuccess,
            companiesAppData,
            rtkCompaniesAppActions
        );
        // => Individual applications
        const [
            getIndividualApplications,
            {
                // isLoading: individualAppLoading,
                isSuccess: individualAppSuccess,
                data: individualAppData,
            },
        ] = useLazyGetIndividualsApplicationsQuery();
        const rtkIndividualAppActions = (data) => {
            dispatch(setIndividualJobApplications(data));
        };
        useRTKFetch(
            individualAppSuccess,
            individualAppData,
            rtkIndividualAppActions
        );
        // => Group applications
        const [
            getGroupApplications,
            {
                // isLoading: groupAppLoading,
                isSuccess: groupAppSuccess,
                data: groupAppData,
            },
        ] = useLazyGetGroupsApplicationsQuery();
        const rtkGroupAppActions = (data) => {
            dispatch(setGroupsJobApplications(data));
        };
        useRTKFetch(groupAppSuccess, groupAppData, rtkGroupAppActions);

        // ############# Training season ##################
        const [getTrainingSeason, { isSuccess, data: TSData }] =
            useLazyGetTrainingSeasonsQuery();
        const rtkTSActions = (data) => {
            dispatch(setTrainingSeason(data));
        };
        useRTKFetch(isSuccess, TSData, rtkTSActions);

        // ################# Verification Types #################
        const { isSuccess: verTypesSuccess, data: verTypesData } =
            useGetVerificationTypesQuery();
        const rtkVerificationTypesActions = (data) => {
            dispatch(setVerificationTypes(data));
        };
        useRTKFetch(verTypesSuccess, verTypesData, rtkVerificationTypesActions);

        // ################# Verification Types #################
        const { isSuccess: freeTrialSuccess, data: freeTrialData } =
            useGetActiveORGFreeTrialQuery();
        const rtkFreeTrialActions = (data) => {
            dispatch(setActiveFreeTrials(data));
        };
        useRTKFetch(freeTrialSuccess, freeTrialData, rtkFreeTrialActions);

        // ################# Institute programs #################
        const [
            getSectors,
            {
                isLoading: sectorLoading,
                isSuccess: sectorSuccess,
                data: sectorData,
            },
        ] = useLazyGetSectorsQuery();
        const [
            getDepartments,
            {
                isLoading: departmentLoading,
                isSuccess: departmentSuccess,
                data: departmentData,
            },
        ] = useLazyGetDepartmentsQuery();
        const [
            getSections,
            {
                isLoading: sectionLoading,
                isSuccess: sectionSuccess,
                data: sectionData,
            },
        ] = useLazyGetSectionsQuery();
        // ############# Dispatch IP ##########
        // => Sector
        const rtkSectorAction = (data) => {
            dispatch(setSectors(data));
        };
        useRTKFetch(sectorSuccess, sectorData, rtkSectorAction);
        // => Department
        const rtkDepartmentAction = (data) => {
            dispatch(setDepartments(data));
        };
        useRTKFetch(departmentSuccess, departmentData, rtkDepartmentAction);
        // => Sections
        const rtkSectionsAction = (data) => {
            dispatch(setSections(data));
        };
        useRTKFetch(sectionSuccess, sectionData, rtkSectionsAction);

        // ################# Notifications #################
        const [
            getNotifications,
            {
                isLoading: notificationsLoading,
                isSuccess: notificationsSuccess,
                data: notificationsData,
            },
        ] = useLazyGetAllNotificationsQuery();
        const rtkNotificationsAction = (data) => {
            dispatch(setNotifications(data));
        };
        useRTKFetch(
            notificationsSuccess,
            notificationsData,
            rtkNotificationsAction
        );

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (profileSuccess) {
                    if (isMainFetch) {
                        if (authorizationType === "Institution") {
                            getSectors();
                            getSections();
                            getDepartments();
                            getTrainingSeason();
                            getStudents();
                        }
                        getORGPT();
                        getOtherPTs();
                        getSpecialPTs();
                        getJobs();
                        getAppliedORGJobs();
                        getAppliedEmployerJobs();
                        getJobsForYouORG();
                        getJobsForYouEmployer();
                        getAllPTsApplicants();
                        getCompaniesApplications();
                        getIndividualApplications();
                        getGroupApplications();
                    }
                    getNotifications();
                    setTimeout(() => {
                        dispatch(disableMainFetch());
                    }, 2000);
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [user, profileSuccess]);

        return jobLoading
            ? jobLoading
            : profileLoading
            ? profileLoading
            : studentsLoading
            ? studentsLoading
            : jobForYouORGLoading
            ? jobForYouORGLoading
            : jobForYouEmployerLoading
            ? jobForYouEmployerLoading
            : ORGPTLoading
            ? ORGPTLoading
            : SpecialORGPTLoading
            ? SpecialORGPTLoading
            : otherPTLoading
            ? otherPTLoading
            : // : sectorLoading
            // ? sectorLoading
            // : departmentLoading
            // ? departmentLoading
            // : sectionLoading
            // ? sectionLoading
            allPTApplicantsLoading
            ? allPTApplicantsLoading
            : companiesAppLoading
            ? companiesAppLoading
            : notificationsLoading
            ? notificationsLoading
            : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
