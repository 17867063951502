import React, { Component } from "react";
import ErrorPage400 from "../../pages/errors/ErrorPage400";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        let resetError = () => {
            this.setState({
                hasError: false,
            });
        };
        if (this.state.hasError) {
            return <ErrorPage400 error={"error"} resetError={resetError} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
