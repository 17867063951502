import React from "react";
import { ArrowRightAltOutlined, DoneAllOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import {
    useApplyORGJobMutation,
    useApplyEmployerJobMutation,
} from "../../../api/job/jobAPI2";
import {
    updateJobForYouDetailsORG,
    updateJobForYouDetailsEmployer,
} from "../../../states/features/job/jobsSlice";

const ApplyJob = ({ job, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        applyORGAction,
        {
            isLoading: applyORGLoading,
            isSuccess: applyORGSuccess,
            isError: applyORGIsError,
            error: applyORGError,
            data: applyORGData,
        },
    ] = useApplyORGJobMutation();
    const [
        applyEmployerAction,
        {
            isLoading: applyEmployerLoading,
            isSuccess: applyEmployerSuccess,
            isError: applyEmployerIsError,
            error: applyEmployerError,
            data: applyEmployerData,
        },
    ] = useApplyEmployerJobMutation();
    // ############# useEffect ##################
    // => apply res
    const rtkORGActions = (data) => {
        handleDialogClose();
        dispatch(updateJobForYouDetailsORG(data));
    };
    RTK.useRTKResponse(
        applyORGSuccess,
        applyORGIsError,
        applyORGError,
        applyORGData,
        rtkORGActions
    );
    // => apply res
    const rtkEmployerActions = (data) => {
        handleDialogClose();
        dispatch(updateJobForYouDetailsEmployer(data));
    };
    RTK.useRTKResponse(
        applyEmployerSuccess,
        applyEmployerIsError,
        applyEmployerError,
        applyEmployerData,
        rtkEmployerActions
    );

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box className="warning-message">
                    <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                        ⚠️ Attention: Job Application Submission ⚠️
                    </Typography>
                </Box>
                <Box sx={{ py: 5 }}>
                    <Typography sx={{ fontSize: 15, textAlign: "center" }}>
                        Before you proceed, please review your job application
                        carefully. Ensure that all the details are accurate and
                        complete. Once you're confident, go ahead and submit
                        your application. Good luck! 🌟 If you have any
                        questions, feel free to reach out to our support team.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        py: 0.5,
                        width: "100%",
                    }}
                >
                    <CustomSubmitButton
                        startIcon={<DoneAllOutlined color="success" />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor={`secondary`}
                        variant={"contained"}
                        onClick={() => {
                            if (job.employer) {
                                applyEmployerAction(job.id);
                            } else {
                                applyORGAction(job.id);
                            }
                        }}
                        loading={applyORGLoading || applyEmployerLoading}
                        sx={{ py: 1.5, fontSize: 16 }}
                    >
                        Submit application
                    </CustomSubmitButton>
                </Box>
            </Box>
        </>
    );
};

export default ApplyJob;
