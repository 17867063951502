import React from 'react';

const useCurrency = () => {
    // #################### FORMAT CURRENCY ####################
    const formatCurrency = (currency, amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
        });
        return formatter.format(amount);
    };

    // #################### RETURN ####################
    const currency = {
        formatCurrency,
    };
    return currency;
};

export default useCurrency;
