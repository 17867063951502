import { Box, Grid } from '@mui/material';
import React from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import PaymentOptions from '../../../components/payments/PaymentOptions';

const JobPostPayment = ({ handleDialogClose }) => {
    return (
        <>
            <Box
                sx={{
                    height: '100%',
                    position: 'relative',
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2>
                            <PaymentOptions handleDialogClose={handleDialogClose} />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default JobPostPayment;
