import {
    Avatar,
    Box,
    Chip,
    Grid,
    IconButton,
    Button,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';
import { colors } from '../../../assets/utils/constants';
import { DeleteForever, ManageAccounts, Remove } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import moment from 'moment';

const GeneralDetails = ({ student, handleDialogyOpen }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                    overflow: 'hidden',
                    borderRadius: 2,
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: 230,
                    }}
                >
                    <Box
                        sx={{
                            height: 150,
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            width: '100%',
                        }}
                    >
                        <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                            <Tooltip title="Delete student" arrow>
                                <IconButton
                                    color="error"
                                    sx={{
                                        ml: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor5})`,
                                    }}
                                    onClick={() => {
                                        let type = 'delete';
                                        let head = 'Delete student account';
                                        handleDialogyOpen(type, head);
                                    }}
                                >
                                    <DeleteForever />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: 100,
                            width: '100%',
                        }}
                    ></Box>
                    <Box
                        sx={{
                            background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                            position: 'absolute',
                            borderRadius: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            p: 1,
                        }}
                    >
                        <Avatar
                            src={student.profile_picture}
                            sx={{
                                height: 150,
                                width: 150,
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                // right: 20,
                                bottom: -60,
                                textAlign: 'center',
                            }}
                        >
                            <Chip
                                label={student.status}
                                color={
                                    student.status === 'active'
                                        ? 'success'
                                        : student.status === 'inactive'
                                        ? 'warning'
                                        : 'default'
                                }
                                size="small"
                            />
                            <Typography
                                sx={{
                                    mt: 0.5,
                                    fontWeight: 'bold',
                                    color: colors.info,
                                }}
                            >
                                {`${student.reg_id}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        'width': '100%',
                        'px': 2,
                        '.head': {
                            fontWeight: 'bold',
                            color: colors.primary,
                            fontSize: 16,
                            mb: 1,
                            opacity: 0.8,
                        },
                        '.info-box': {
                            'width': '100%',
                            'p': 1,
                            'borderRadius': 2,
                            'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            '.title': {
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.9,
                            },
                        },
                    }}
                >
                    <Box sx={{ mt: 3 }}>
                        <Typography className="head">Personal infos</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <Box
                                    className="info-box"
                                    sx={{
                                        mr: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography className="title">
                                        Full name
                                    </Typography>
                                    <Typography
                                        noWrap
                                        sx={{
                                            width: '100%',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {`${student.first_name} `}
                                        {!isEmpty(student.middle_name) &&
                                            student.middle_name}
                                        {` ${student.last_name}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Box
                                    className="info-box"
                                    sx={{
                                        ml: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography className="title">
                                        Address
                                    </Typography>
                                    <Typography noWrap sx={{ width: '100%' }}>
                                        {!isEmpty(student.address) ? (
                                            student.address
                                        ) : (
                                            <Remove
                                                sx={{
                                                    fontSize: 10,
                                                }}
                                            />
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Box
                                    className="info-box"
                                    sx={{
                                        mr: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography className="title">
                                        Gender
                                    </Typography>
                                    <Typography>
                                        {!isEmpty(student.gender) ? (
                                            student.gender
                                        ) : (
                                            <Remove
                                                sx={{
                                                    fontSize: 10,
                                                }}
                                            />
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Box
                                    className="info-box"
                                    sx={{
                                        ml: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography className="title" noWrap>
                                        Birth date
                                    </Typography>
                                    <Typography noWrap sx={{ width: '100%' }}>
                                        {!isEmpty(student.dob) ? (
                                            moment(student.dob).format('LL')
                                        ) : (
                                            <Remove
                                                sx={{
                                                    fontSize: 10,
                                                }}
                                            />
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Typography className="head">Contact infos</Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                className="info-box"
                                sx={{
                                    mr: 1,
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography className="title">
                                    Phone number
                                </Typography>
                                <Typography noWrap sx={{ width: '100%' }}>
                                    {!isEmpty(student.phone_number) ? (
                                        student.phone_number
                                    ) : (
                                        <Remove
                                            sx={{
                                                fontSize: 10,
                                            }}
                                        />
                                    )}
                                </Typography>
                            </Box>
                            <Box
                                className="info-box"
                                sx={{
                                    ml: 1,
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography className="title">
                                    Email address
                                </Typography>
                                <Typography noWrap sx={{ width: '100%' }}>
                                    {!isEmpty(student.email) ? (
                                        student.email
                                    ) : (
                                        <Remove
                                            sx={{
                                                fontSize: 10,
                                            }}
                                        />
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ my: 2.8, textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="info"
                            startIcon={<ManageAccounts />}
                            className="app-btn"
                            onClick={() => {
                                let type = 'update';
                                let head = 'Update your profile details';
                                handleDialogyOpen(type, head);
                            }}
                        >
                            Update student details
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default GeneralDetails;
