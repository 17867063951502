import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../assets/utils/constants';

const OccupationCard = ({ student: user }) => {
    // ########### FUNC ###################
    const getCurrYear = () => {
        let currYear = 0;
        currYear =
            new Date().getFullYear() - user.start_year >
            user.finish_year - user.start_year
                ? user.finish_year - user.start_year
                : new Date().getFullYear() - user.start_year;
        return currYear;
    };

    const currYear = getCurrYear();

    return (
        <>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    p: 2,
                    flexDirection: 'column',
                    background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor5})`,
                }}
            >
                <Box
                    sx={{
                        p: 1,
                        textAlign: 'center',
                        borderRadius: 2,
                        width: '100%',
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 15,
                            p: 1,
                            opacity: 0.9,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                        }}
                    >
                        Occupation
                    </Typography>
                    <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                        {user?.section?.section?.name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        border: `1px solid ${colors.bgColor5}`,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            border: `1px solid ${colors.bgColor5}`,
                            p: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            From
                        </Typography>
                        <Typography sx={{ opacity: 0.8 }}>
                            {user.start_year}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            border: `1px solid ${colors.bgColor5}`,
                            p: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            To
                        </Typography>
                        <Typography sx={{ opacity: 0.8 }}>
                            {user.finish_year}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                        border: `1px solid ${colors.bgColor5}`,
                        p: 1,
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            Current level
                        </Typography>
                        <Typography sx={{ opacity: 0.8 }}>
                            {currYear && currYear}{' '}
                            <sup>
                                {currYear === 1
                                    ? 'st'
                                    : currYear === 2
                                    ? 'nd'
                                    : currYear === 3
                                    ? 'rd'
                                    : 'th'}
                            </sup>{' '}
                            year
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default OccupationCard;
