import React from 'react';
import {
    Email,
    PinDropOutlined,
    Phone,
    Remove,
} from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import { Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import ContactWidget from '../../components/widgets/ContactWidget';
import { useDispatch, useSelector } from 'react-redux';
import { helpCenterSelector } from '../../states/features/selectors';
import { isEmpty } from 'lodash';
import ContentLoader from '../../components/ContentLoader';
import { useGetContactsQuery } from '../../api/helpCenter/helpCenterAPI';
import useRTK from '../../hooks/useRTK';
import { setContacts } from '../../states/features/helpCenter/helpCenterSlice';

const Contacts = () => {
    // ############ CONST ###############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############ Redux state ##############
    const { contacts } = useSelector(helpCenterSelector);

    // => Convet contacts to array
    const restructureContacts = () => {
        let contactsObj = contacts[0];
        let contactsArr = [
            {
                id: 1,
                url: `tel:${contactsObj?.phone}`,
                icon: (
                    <Phone
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'Call us',
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.phone,
            },
            {
                id: 4,
                url: `mailTo:${contactsObj?.email}`,
                icon: (
                    <Email
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'Send us an email',
                contact: isEmpty(contactsObj) ? <Remove /> : contactsObj?.email,
            },
            {
                id: 3,
                url: `${contactsObj?.map_url}`,
                icon: (
                    <PinDropOutlined
                        sx={{ fontSize: 25, mr: 1, color: colors.warning }}
                    />
                ),
                title: 'We are located',
                contact: isEmpty(contactsObj) ? (
                    <Remove />
                ) : (
                    contactsObj?.address
                ),
            },
        ];
        return contactsArr;
    };
    const contactsArr = restructureContacts();

    // ############## RTK ##############
    const { isLoading, isSuccess, data: contacstData } = useGetContactsQuery();
    // => Action
    const rtkAction = (data) => {
        dispatch(setContacts(data));
    };
    // => Response
    RTK.useRTKFetch(isSuccess, contacstData, rtkAction);

    return (
        <>
            <CustomCard2
                head={'Phone number, Email and address'}
                height={isLoading ? 100 : '100%'}
            >
                <Grid container spacing={4}>
                    {isLoading ? (
                        <Grid item xs>
                            <ContentLoader height={100} />
                        </Grid>
                    ) : (
                        contactsArr?.map((contact) => (
                            <Grid item xs key={contact.id}>
                                <ContactWidget contact={contact} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomCard2>
        </>
    );
};

export default Contacts;
