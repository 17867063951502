import React from "react";
import { Routes, Route } from "react-router-dom";

// custom components imports
import Login from "../pages/auth/Login";
import ForgetPassword from "../pages/auth/ForgetPassword";
import Register from "../pages/auth/Register";
import RegisterAs from "../pages/auth/RegisterAs";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes.js";
import Profile from "../pages/profile/Profile";
import ErrorPage404 from "../pages/errors/ErrorPage404";
import Jobs from "../pages/jobs/Jobs";
import JobDetails from "../pages/jobs/JobDetails";
import JobByStatus from "../pages/jobs/JobByStatus";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import PracticalTraining from "../pages/practicalTraining/PracticalTraining";
import StudentsManagement from "../pages/studentsManagement/StudentsManagement";
import HelpCenter from "../pages/helpCenter/HelpCenter";
import AnalysisAndReports from "../pages/analysisAndReports/AnalysisAndReports";
import Transactions from "../pages/transactions/Transactions";
import UpdateJob from "../pages/jobs/UpdateJob";
import CreateJob from "../pages/jobs/CreateJob";
import PracticalTrainingDetails from "../pages/practicalTraining/PracticalTrainingDetails";
import CreatePracticalTraining from "../pages/practicalTraining/CreatePracticalTraining";
import UpdatePracticalTraining from "../pages/practicalTraining/UpdatePracticalTraining";
import OrganizationPT from "../pages/practicalTraining/OrganizationPT";
import OtherOragnizationPT from "../pages/practicalTraining/OtherOragnizationPT";
import SpecialOrganizationPT from "../pages/practicalTraining/SpecialOrganizationPT";
import StudentDetails from "../pages/studentsManagement/StudentDetails";
import InternalOrganizationPT from "../pages/practicalTraining/InternalOrganizationPT";
import AllocatedPT from "../pages/practicalTraining/AllocatedPT";
import ApprovedPT from "../pages/practicalTraining/ApprovedPT";
import AllocatedStudents from "../pages/studentsManagement/AllocatedStudents";
import EnrolledStudents from "../pages/studentsManagement/trainingStudents/EnrolledStudents";
import InstitutePrograms from "../pages/studentsManagement/InstitutePrograms";
import OnTrainingStudents from "../pages/studentsManagement/trainingStudents/OnTrainingStudents";
import JobsForYou from "../pages/jobs/JobsForYou";
import ForYouJobDetailsORG from "../pages/jobs/ForYouJobDetailsORG";
import ForYouJobDetailsEmployer from "../pages/jobs/ForYouJobDetailsEmployer";
import AuthLayout from "../pages/layouts/AuthLayout";
import Notifications from "../pages/notifications/Notifications";
import NotificationDetails from "../pages/notifications/NotificationDetails";
import RequestEmailVerification from "../pages/auth/RequestEmailVerification";
import EmailVerification from "../pages/auth/EmailVerification";
import IntenalPTByStatus from "../pages/practicalTraining/IntenalPTByStatus";
import ForStudents from "../pages/practicalTraining/ForStudents";
import TransactionByStatus from "../pages/transactions/TransactionByStatus";
import TrainingManagement from "../pages/studentsManagement/trainingSeason/TrainingManagement";
import TrainingApplicants from "../pages/applicants/TrainingApplicants";
import JobApplicants from "../pages/applicants/JobApplicants";
import Applicants from "../pages/applicants/Applicants";
import TrainingApplicantsByStatus from "../pages/applicants/TrainingApplicantsByStatus";
import TrainingsStudents from "../pages/studentsManagement/trainingStudents/TrainingsStudents";
import TrainingStudentsByStatus from "../pages/studentsManagement/trainingStudents/TrainingStudentsByStatus";
import JobApplicantsByStatus from "../pages/applicants/JobApplicantsByStatus";
import ResetPassword from "../pages/auth/ResetPassword.jsx";
import JobForYouByStatus from "../pages/jobs/JobForYouByStatus.jsx";
import TrainingSeasonDetails from "../pages/studentsManagement/trainingSeason/TrainingSeasonDetails.jsx";
import VerifiedUserRoutes from "./VerifiedUserRoutes.js";
import FreeTrialPage from "../pages/FreeTrial/FreeTrialPage.jsx";

//! ######################## PUBLIC ELEMENTS #########################
// -> login
const LoginElement = () => (
    <AuthLayout>
        <Login />
    </AuthLayout>
);

// -> register
const RegisterElement = () => (
    <AuthLayout>
        <Register />
    </AuthLayout>
);

// -> register as
const RegisterAsElement = () => (
    <AuthLayout>
        <RegisterAs />
    </AuthLayout>
);

// -> Request email verification
const RequestEmailVerificationElement = () => (
    <AuthLayout>
        <RequestEmailVerification />
    </AuthLayout>
);

// -> Email verification
const EmailVerificationElement = () => (
    <AuthLayout>
        <EmailVerification />
    </AuthLayout>
);

// -> forget password
const ForgetElement = () => (
    <AuthLayout>
        <ForgetPassword />
    </AuthLayout>
);

// -> Reset password
const PasswordResetElement = () => (
    <AuthLayout>
        <ResetPassword />
    </AuthLayout>
);

//! ######################## GENERAL ELEMENTS #########################
// -> dashboard
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);

// -> Profile
const ProfileElement = () => (
    <AppLayout>
        <Profile />
    </AppLayout>
);

// -> manage jobs
const JobElement = () => (
    <AppLayout>
        <Jobs />
    </AppLayout>
);

// -> manage jobs by status
const JobByStatusElement = () => (
    <AppLayout>
        <JobByStatus />
    </AppLayout>
);

// -> manage jobs for you by status
const JobForYouByStatusElement = () => (
    <AppLayout>
        <JobForYouByStatus />
    </AppLayout>
);

// -> create create
const CreateJobElement = () => (
    <AppLayout>
        <CreateJob />
    </AppLayout>
);

// -> update job
const UpdateJobElement = () => (
    <AppLayout>
        <UpdateJob />
    </AppLayout>
);

// -> jobs details
const JobDetailsElement = () => (
    <AppLayout>
        <JobDetails />
    </AppLayout>
);

// -> jobs for you
const JobsForYouElement = () => (
    <AppLayout>
        <JobsForYou />
    </AppLayout>
);

// -> jobs for you details org
const ForYouJobDetailsORGElement = () => (
    <AppLayout>
        <ForYouJobDetailsORG />
    </AppLayout>
);

// -> jobs for you details employer
const ForYouJobDetailsEmployerElement = () => (
    <AppLayout>
        <ForYouJobDetailsEmployer />
    </AppLayout>
);

// -> Transaction
const TransactionsElement = () => (
    <AppLayout>
        <Transactions />
    </AppLayout>
);

// -> Transaction by status
const TransactionsByStatuElement = () => (
    <AppLayout>
        <TransactionByStatus />
    </AppLayout>
);

// -> Analysis and reports
const AnalysisAndReportsElement = () => (
    <AppLayout>
        <AnalysisAndReports />
    </AppLayout>
);

// -> Help center
const HelpCenterElement = () => (
    <AppLayout>
        <HelpCenter />
    </AppLayout>
);

// -> Free trial
const FreeTrialElement = () => (
    <AppLayout>
        <FreeTrialPage />
    </AppLayout>
);

//! ###################### PRACTICAL TRAINING ELEMENTS #######################
const PracticalTrainingElement = () => (
    <AppLayout>
        <PracticalTraining />
    </AppLayout>
);
const PracticalTrainingDetailsElement = () => (
    <AppLayout>
        <PracticalTrainingDetails />
    </AppLayout>
);
const CreatePracticalTrainingElement = () => (
    <AppLayout>
        <CreatePracticalTraining />
    </AppLayout>
);
const UpdatePracticalTrainingElement = () => (
    <AppLayout>
        <UpdatePracticalTraining />
    </AppLayout>
);
const OrganizationPTElement = () => (
    <AppLayout>
        <OrganizationPT />
    </AppLayout>
);
const SpecialOrganizationPTElement = () => (
    <AppLayout>
        <SpecialOrganizationPT />
    </AppLayout>
);
const ForStudentsPTElement = () => (
    <AppLayout>
        <ForStudents />
    </AppLayout>
);
const InternalOrganizationPTElement = () => (
    <AppLayout>
        <InternalOrganizationPT />
    </AppLayout>
);
const InternalPTByStatusElement = () => (
    <AppLayout>
        <IntenalPTByStatus />
    </AppLayout>
);
const OtherOragnizationPTElement = () => (
    <AppLayout>
        <OtherOragnizationPT />
    </AppLayout>
);
const ApprovedPTElement = () => (
    <AppLayout>
        <ApprovedPT />
    </AppLayout>
);
const AllocatedPTElement = () => (
    <AppLayout>
        <AllocatedPT />
    </AppLayout>
);

//! ###################### NOTFICATION ELEMENTS #######################
const NotificationsElement = () => (
    <AppLayout>
        <Notifications />
    </AppLayout>
);
const NotificationDetailsElement = () => (
    <AppLayout>
        <NotificationDetails />
    </AppLayout>
);
const ErrorPage404Element = () => (
    <AppLayout>
        <ErrorPage404 />
    </AppLayout>
);

//! ###################### INSTITUTION ELEMENTS #######################
const StudentsManagementElement = () => (
    <AppLayout>
        <StudentsManagement />
    </AppLayout>
);
const TrainingManagementElement = () => (
    <AppLayout>
        <TrainingManagement />
    </AppLayout>
);
const TrainingSeasonDetailsElement = () => (
    <AppLayout>
        <TrainingSeasonDetails />
    </AppLayout>
);
const StudentsDetailsElement = () => (
    <AppLayout>
        <StudentDetails />
    </AppLayout>
);
const InstituteProgramsElement = () => (
    <AppLayout>
        <InstitutePrograms />
    </AppLayout>
);
const TrainingsStudentElement = () => (
    <AppLayout>
        <TrainingsStudents />
    </AppLayout>
);
const TrainingStudentsByStatusElement = () => (
    <AppLayout>
        <TrainingStudentsByStatus />
    </AppLayout>
);
const EnrolledStudentElement = () => (
    <AppLayout>
        <EnrolledStudents />
    </AppLayout>
);
const AllocatedStudentsElement = () => (
    <AppLayout>
        <AllocatedStudents />
    </AppLayout>
);
const OnTrainingStudentsElement = () => (
    <AppLayout>
        <OnTrainingStudents />
    </AppLayout>
);

//! ###################### APPLICANTS ELEMENTS #######################
const ApplicantsElement = () => (
    <AppLayout>
        <Applicants />
    </AppLayout>
);
const JobApplicantsElement = () => (
    <AppLayout>
        <JobApplicants />
    </AppLayout>
);
const JobApplicantsByStatusElement = () => (
    <AppLayout>
        <JobApplicantsByStatus />
    </AppLayout>
);
const TrainingApplicantsElement = () => (
    <AppLayout>
        <TrainingApplicants />
    </AppLayout>
);
const TrainingApplicantsByStatusElement = () => (
    <AppLayout>
        <TrainingApplicantsByStatus />
    </AppLayout>
);

const App = ({ role, user }) => {
    return (
        <React.Fragment>
            <Routes>
                {/* ##################### GUEST ROUTES ################## */}
                <Route path="/" element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                    <Route
                        path="/register-as"
                        element={<RegisterAsElement />}
                    />
                    <Route path="/register" element={<RegisterElement />} />
                    <Route
                        path="/email-verification"
                        element={<RequestEmailVerificationElement />}
                    />
                    <Route
                        path="/verify-email"
                        element={<EmailVerificationElement />}
                    />
                    <Route
                        path="/forget-password"
                        element={<ForgetElement />}
                    />
                    <Route
                        path="/password-reset"
                        element={<PasswordResetElement />}
                    />
                </Route>
                <Route path="/" element={<PrivateRoutes roleRequired="all" />}>
                    <Route path="profile" element={<ProfileElement />} />
                    <Route path="dashboard" element={<DashboardElement />} />

                    {/* -> ##################### JOB ROUTES ################## */}
                    <Route
                        path="/"
                        element={<VerifiedUserRoutes user={user} />}
                    >
                        <Route path="jobs" element={<JobElement />} />
                        <Route
                            path="jobs/create"
                            element={<CreateJobElement />}
                        />
                        <Route
                            path="jobs/update"
                            element={<UpdateJobElement />}
                        />
                        <Route
                            path="jobs/for-you"
                            element={<JobsForYouElement />}
                        />
                        <Route
                            path="jobs/for-you/organizations/:job_id"
                            element={<ForYouJobDetailsORGElement />}
                        />
                        <Route
                            path="jobs/for-you/employers/:job_id"
                            element={<ForYouJobDetailsEmployerElement />}
                        />
                        <Route
                            path="jobs/status/:status"
                            element={<JobByStatusElement />}
                        />
                        <Route
                            path="jobs/for-you/status/:status"
                            element={<JobForYouByStatusElement />}
                        />
                        <Route
                            path="/jobs/:job_id"
                            element={<JobDetailsElement />}
                        />
                    </Route>

                    {/* ##################### PT ROUTES ################## */}
                    <Route
                        path="/"
                        element={<VerifiedUserRoutes user={user} />}
                    >
                        <Route
                            path="/practical-trainings"
                            element={<PracticalTrainingElement />}
                        />
                        <Route
                            path="/practical-trainings/:pt_id"
                            element={<PracticalTrainingDetailsElement />}
                        />
                        <Route
                            path="/practical-trainings/create"
                            element={<CreatePracticalTrainingElement />}
                        />
                        <Route
                            path="/practical-trainings/update"
                            element={<UpdatePracticalTrainingElement />}
                        />
                        <Route
                            path="/practical-trainings/organization"
                            element={<OrganizationPTElement />}
                        />
                        <Route
                            path="/practical-trainings/internal"
                            element={<InternalOrganizationPTElement />}
                        />
                        <Route
                            path="/practical-trainings/:org_type/:status"
                            element={<InternalPTByStatusElement />}
                        />
                        <Route
                            path="/practical-trainings/special"
                            element={<SpecialOrganizationPTElement />}
                        />
                        <Route
                            path="/practical-trainings/for-students"
                            element={<ForStudentsPTElement />}
                        />
                        <Route
                            path="/practical-trainings/approved"
                            element={<ApprovedPTElement />}
                        />
                        <Route
                            path="/practical-trainings/allocated"
                            element={<AllocatedPTElement />}
                        />
                        <Route
                            path="/practical-trainings/other"
                            element={<OtherOragnizationPTElement />}
                        />
                        <Route
                            path="/transactions"
                            element={<TransactionsElement />}
                        />
                        <Route
                            path="/transactions/:status"
                            element={<TransactionsByStatuElement />}
                        />
                    </Route>

                    {/* ##################### Applicants ROUTES ########### */}
                    <Route
                        path="/"
                        element={<VerifiedUserRoutes user={user} />}
                    >
                        <Route
                            path="/applicants"
                            element={<ApplicantsElement />}
                        />
                        <Route
                            path="/applicants/jobs"
                            element={<JobApplicantsElement />}
                        />
                        <Route
                            path="/applicants/jobs/:status"
                            element={<JobApplicantsByStatusElement />}
                        />
                        <Route
                            path="/applicants/practical-trainings"
                            element={<TrainingApplicantsElement />}
                        />
                        <Route
                            path="/applicants/practical-trainings/:status"
                            element={<TrainingApplicantsByStatusElement />}
                        />
                    </Route>

                    {/* ##################### Notifications ROUTES ########### */}
                    <Route
                        path="/notifications"
                        element={<NotificationsElement />}
                    />
                    <Route
                        path="/notifications/:notification_id"
                        element={<NotificationDetailsElement />}
                    />

                    {/* ##################### Help and Analysis ROUTES ########### */}
                    <Route
                        path="/analysis-and-reports"
                        element={<AnalysisAndReportsElement />}
                    />
                    <Route
                        path="/help-center"
                        element={<HelpCenterElement />}
                    />
                    <Route path="/free-trial" element={<FreeTrialElement />} />

                    {/* ##################### COMPANY ROUTES ################## */}
                    {role === "Company" && (
                        <Route
                            path="/"
                            element={<PrivateRoutes roleRequired="Company" />}
                        ></Route>
                    )}
                    {/* ##################### INSTITUTION ROUTES ################## */}
                    {role === "Institution" && (
                        <Route
                            path="/"
                            element={
                                <PrivateRoutes roleRequired="Institution" />
                            }
                        >
                            <Route
                                path="/"
                                element={<VerifiedUserRoutes user={user} />}
                            >
                                <Route
                                    path="/students"
                                    element={<StudentsManagementElement />}
                                />
                                <Route
                                    path="/students/training-managements"
                                    element={<TrainingManagementElement />}
                                />
                                <Route
                                    path="/students/training-season/:season_id"
                                    element={<TrainingSeasonDetailsElement />}
                                />
                                <Route
                                    path="/students/:student_id"
                                    element={<StudentsDetailsElement />}
                                />
                                <Route
                                    path="/students/institute-programs"
                                    element={<InstituteProgramsElement />}
                                />
                                <Route
                                    path="/students/trainings"
                                    element={<TrainingsStudentElement />}
                                />
                                <Route
                                    path="/students/trainings/:status"
                                    element={
                                        <TrainingStudentsByStatusElement />
                                    }
                                />
                                <Route
                                    path="/students/enrolled-students"
                                    element={<EnrolledStudentElement />}
                                />
                                <Route
                                    path="/students/allocated-students"
                                    element={<AllocatedStudentsElement />}
                                />
                                <Route
                                    path="/students/on-training-students"
                                    element={<OnTrainingStudentsElement />}
                                />
                            </Route>
                        </Route>
                    )}
                </Route>
                {/* ##################### 404 ROUTES ################## */}
                <Route path="*" element={<ErrorPage404Element />} />
            </Routes>
        </React.Fragment>
    );
};

export default App;
