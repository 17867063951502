import React from "react";
import PageLayout from "../layouts/PageLayout";
import InfoboxWidget2 from "../../components/widgets/InfoboxWidget2";
import { colors } from "../../assets/utils/constants";
import { Grid } from "@mui/material";
import CustomTabs from "../../components/tabs/CustomTabs";
import { EngineeringOutlined, WorkHistoryOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
    PTSelector,
    jobApplicationsSelector,
} from "../../states/features/selectors";
import { size } from "lodash";
import PTApplicantTable from "../../components/Tables/PTApplicantTable";
import { filter } from "lodash";
import ApplicantsSidebar from "../../components/sidebars/ApplicantsSidebar";
import JobApplicantTable from "../../components/Tables/JobApplicantTable";

const Applicants = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);
    const {
        companyJobApplications,
        individualJobApplications,
        groupJobApplications,
    } = useSelector(jobApplicationsSelector);
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
        ...groupJobApplications,
    ];

    // => Pending PT applications
    const pendingTrainingApplicants = filter(
        allPracticalTrainingApplicants,
        ({ appliedTraining }) => appliedTraining.status === "pending"
    );

    // => Pending Job applications
    const pendingJobApplicants = filter(
        allJobApplications,
        ({ application }) => application.status === "pending"
    );

    // ############ InfoBox ##################
    const infoBoxData = [
        {
            id: 2,
            title1: "Job vacancy",
            title2: "Applicants management",
            total: size(allJobApplications),
            colorSign: colors.secondary,
            url: "jobs",
        },
        {
            id: 1,
            title1: "Practical training",
            title2: "Applicants management",
            total: size(allPracticalTrainingApplicants),
            colorSign: colors.secondary,
            url: "practical-trainings",
        },
    ];

    // ############ Tabs ##################
    const ApplicantsTabs = [
        {
            id: 1,
            title: "Pending job applications",
            icon: <WorkHistoryOutlined />,
            tabsContent: true ? (
                <>
                    <JobApplicantTable
                        height={"49vh"}
                        applicants={pendingJobApplicants}
                    />
                </>
            ) : (
                ""
            ),
            total: size(pendingJobApplicants),
            chipColor: "info",
        },
        {
            id: 2,
            title: "Pending field applications",
            icon: <EngineeringOutlined />,
            tabsContent: true ? (
                <>
                    <PTApplicantTable
                        height={"49vh"}
                        applicants={pendingTrainingApplicants}
                    />
                </>
            ) : (
                ""
            ),
            total: size(pendingTrainingApplicants),
            chipColor: "info",
        },
    ];
    return (
        <>
            <PageLayout
                head={"Applicants managements"}
                sidebar={<ApplicantsSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={6} key={data.id}>
                            <InfoboxWidget2
                                data={data}
                                colorSign={data.colorSign}
                            />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomTabs
                            height={"56.7vh"}
                            padding={`0 !important`}
                            tabs={ApplicantsTabs}
                        />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Applicants;
