import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import { isEmpty } from 'lodash';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import {
    useClosePracticalTraingMutation,
    usePublishPracticalTraingMutation,
} from '../../../api/practicalTraining/practicalTrainingsAPI';
import { setPracticalTrainingDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import moment from 'moment';

// * ################### VALIDATION SCHEMA ####################
const PTPublishValidationSchema = Yup.object({
    max_applicants: Yup.string().nullable(),
    positions: Yup.number()
        .nullable()
        .when('max_applicants', {
            is: (applicants) => applicants.length > 0,
            then: Yup.number().max(Yup.ref('max_applicants')),
        }),
    open_date: Yup.date()
        .min(
            moment(new Date()).format('YYYY-MM-DD'),
            `open date must be later than today`,
        )
        .required('Open date is a required field'),
    end_date: Yup.date()
        .min(Yup.ref('open_date'), `close date must be later than open date`)
        .required('close date is a required field'),
});
const closeValidationSchema = Yup.object({
    positions: Yup.number().nullable(),
    max_applicants: Yup.string().nullable(),
    open_date: Yup.date(),
    end_date: Yup.date(),
});

const PublishPT = ({ training, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        publishAction,
        {
            isLoading: publishLoading,
            isSuccess: publishSuccess,
            isError: publishIsError,
            error: publishError,
            data: publishData,
        },
    ] = usePublishPracticalTraingMutation();
    const [
        closeAction,
        {
            isLoading: closeLoading,
            isSuccess: closeSuccess,
            isError: closeIsError,
            error: closeError,
            data: closeData,
        },
    ] = useClosePracticalTraingMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setPracticalTrainingDetails(data));
    };
    RTK.useRTKResponse(
        publishSuccess,
        publishIsError,
        publishError,
        publishData,
        rtkActions,
    );
    RTK.useRTKResponse(
        closeSuccess,
        closeIsError,
        closeError,
        closeData,
        rtkActions,
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    pt_id: training.id,
                    positions: isEmpty(training)
                        ? ''
                        // : isEmpty(training.positions)
                        // ? ''
                        : training.positions,
                    max_applicants: isEmpty(training)
                        ? ''
                        // : isEmpty(training.max_applicants)
                        // ? ''
                        : training.max_applicants,

                    open_date: isEmpty(training) ? '' : training.open_date,
                    end_date: isEmpty(training) ? '' : training.end_date,
                }}
                validationSchema={
                    training.status === 'published'
                        ? closeValidationSchema
                        : PTPublishValidationSchema
                }
                onSubmit={(payload) => {
                    if (
                        training.status === 'draft' ||
                        training.status === 'closed'
                    ) {
                        publishAction(payload);
                    } else if (training.status === 'published') {
                        closeAction(payload);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container spacing={2}>
                            {training.status === 'closed' ||
                            training.status === 'exired' ||
                            training.status === 'draft' ? (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{ mb: 2 }}
                                            className="warning-message"
                                        >
                                            <Typography>
                                                Please verify application date
                                                before publishing practical
                                                training.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/* ################## APPLICANTS ############ */}
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                }}
                                            >
                                                Applicants ( 0 for unlimited
                                                applications)
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            'Number of position'
                                                        }
                                                        name={'positions'}
                                                        type="number"
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        ml: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={'Max applicant'}
                                                        name={'max_applicants'}
                                                        type="number"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {/* ################## APPLICANTS ############ */}
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                }}
                                            >
                                                Application date
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            'Application start'
                                                        }
                                                        name={'open_date'}
                                                        type={'date'}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        ml: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            'Application deadline'
                                                        }
                                                        name={'end_date'}
                                                        type={'date'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{ mb: 2 }}
                                            className="warning-message"
                                        >
                                            <Typography>
                                                <strong>NOTE : </strong>
                                                You'r about to close this
                                                practical training which will
                                                prevent student from appliying
                                                for this training.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<PublishOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={
                                        training.status === 'published'
                                            ? 'warning'
                                            : training.status === 'closed' ||
                                              training.status === 'draft'
                                            ? 'success'
                                            : 'primary'
                                    }
                                    variant={'contained'}
                                    loading={publishLoading || closeLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    {training.status === 'published'
                                        ? 'Close practical training'
                                        : training.status === 'closed'
                                        ? 'Re-Publish practical training'
                                        : training.status === 'draft'
                                        ? 'Publish practical training'
                                        : ''}
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default PublishPT;
