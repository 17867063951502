import { useSelector } from "react-redux";
import { transactionSelector } from "../../../states/features/selectors";
import { filter, isEmpty } from "lodash";
import moment from "moment";

export const useTransactionReportFilter = () => {
    const { jobTransactions } = useSelector(transactionSelector);
    const filterTransaction = (payload) => {
        let filteredTransactions = jobTransactions;
        let transaction_status = payload?.transaction_status;
        let dateFrom = payload?.from;
        let dateTo = payload?.to;

        // => Filter by date
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (!isEmpty(dateFrom) || !isEmpty(dateTo)) {
            filteredTransactions = filter(
                filteredTransactions,
                ({ created_at }) =>
                    moment(created_at).format("YYYY-MM-DD") >= startDate &&
                    moment(created_at).format("YYYY-MM-DD") <= endDate
            );
        }

        // => Filter by status
        if (!isEmpty(transaction_status)) {
            filteredTransactions = filter(
                filteredTransactions,
                ({ transaction }) => transaction.status === transaction_status
            );
        }

        // => Return result
        if (
            !isEmpty(dateFrom) ||
            !isEmpty(dateTo) ||
            !isEmpty(transaction_status)
        ) {
            return filteredTransactions;
        } else {
            return jobTransactions;
        }
    };

    return {
        filterTransaction,
    };
};
