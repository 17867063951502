import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../../components/cards/CustomCard2';
import PracticalTrainingFilter from '../../components/filters/PracticalTrainingFilter';
import FieldWidget from '../../components/widgets/FieldWidget';
import PageLayout from '../layouts/PageLayout';
import StatusInfoBox from '../../components/infoBoxes/StatusInfoBox';
import { useSelector } from 'react-redux';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge copy';
import { paginator } from '../../helpers/paginationHelper';
import CustomPagination from '../../components/paginations/CustomPagination';
import TrainingFilters from '../../components/filters/TrainingFilters';

const InternalOrganizationPT = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);
    const [trainings, setTraining] = useState([]);

    // ################# Redux state ###############
    const { profile: user } = useSelector(profileSelector);
    const { practicalTrainings } = useSelector(PTSelector);

    // ############## CONST ####################
    // => Intenal PTs
    const intenalPTs = filter(
        practicalTrainings,
        ({ organization_id }) => organization_id === user.id,
    );

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Filter by training name
    const filterByPTName = (search) => {
        let filteredTraining = [];
        if (search) {
            filteredTraining = activePTs.filter((training) => {
                return training.title
                    .toLowerCase()
                    .includes(search.toLowerCase());
            });
            return setTraining(filteredTraining);
        }
        return setTraining(activePTs);
    };

    // => Active PTs
    const activePTs = filter(intenalPTs, ({ status }) => status === 'open');

    // => Pagination data
    const paginatedActivePTs = paginator(activePTs, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setTraining(activePTs);
        }

        return () => {
            isSubscribed = false;
        };
    }, []);

    const infoBoxData = [
        {
            id: 1,
            label: 'Drafted',
            total: 2,
            url: 'drafted',
        },
        {
            id: 2,
            label: 'Published',
            total: 45,
            url: 'published',
        },
        {
            id: 3,
            label: 'Closed',
            total: 45,
            url: 'closed',
        },
        {
            id: 4,
            label: 'Approved',
            total: 45,
            url: 'approved',
        },
        {
            id: 5,
            label: 'Allocated',
            total: 45,
            url: 'allocated',
        },
        {
            id: 6,
            label: 'Suspended',
            total: 45,
            url: 'suspended',
        },
        {
            id: 7,
            label: 'Expired',
            total: 45,
            url: 'expired',
        },
    ];
    return (
        <>
            <PageLayout head={'Internal practical trainings'}>
                <Box
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        mb: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Grid container spacing={2}>
                        {infoBoxData.map((data) => (
                            <Grid item sm={3} xs={6}>
                                <StatusInfoBox data={data} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Active internal practical trainings'}
                            height={400}
                            borderBottom
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <PracticalTrainingFilter width={500} /> */}
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(activePTs)}
                                            item={'Active PTs'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(activePTs) ? (
                                <>
                                    <NoContent
                                        height={360}
                                        message={
                                            'No active intenal practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {activePTs.map((index) => (
                                        <Grid item sm={6} key={index}>
                                            <FieldWidget />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomCard2>
                        <CustomPagination
                            head={'Active internal practical trainings'}
                            height={'51vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TrainingFilters
                                        filterByJobName={filterByPTName}
                                        // filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(trainings)}
                                        item={'Active PTs'}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(trainings) ? (
                                <>
                                    <NoContent
                                        height={360}
                                        message={
                                            'No active intenal practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidget training={training} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default InternalOrganizationPT;
