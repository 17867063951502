import React from 'react';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { DeleteForever } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeletePracticalTraingMutation } from '../../../api/practicalTraining/practicalTrainingsAPI';
import { removePracticalTraining } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import useRTK from '../../../hooks/useRTK';

const DeletePT = ({ training }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        deletePT,
        {
            isLoading: deleteLoading,
            isSuccess: deleteSuccess,
            isError: deleteIsError,
            error: deleteError,
            data: deleteData,
        },
    ] = useDeletePracticalTraingMutation();
    // ############# useEffect ##################
    const rtkActions = () => {
        setTimeout(() => {
            navigate(`/practical-trainings`);
        }, 0);
        dispatch(removePracticalTraining(training.id));
    };
    RTK.useRTKResponse(
        deleteSuccess,
        deleteIsError,
        deleteError,
        deleteData,
        rtkActions,
    );

    return (
        <>
            <CustomSubmitButton
                loading={deleteLoading}
                startIcon={<DeleteForever />}
                btnColor="error"
                sx={{ py: 0.5 }}
                onClick={() => {
                    deletePT(training.id);
                }}
            >
                Delete training
            </CustomSubmitButton>
        </>
    );
};

export default DeletePT;
