import React, { useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import excel from '../../../assets/media/images/studentImport.png';
import excelSample from '../../../assets/media/docs/studentImportSample.xlsx';
import { Form, Formik } from 'formik';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import { ArrowRightAltOutlined, ImportContacts } from '@mui/icons-material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import * as Yup from 'yup';
import { useImportStudentsMutation } from '../../../api/students/studentsAPI';
import { setStudents } from '../../../states/features/students/studentsSlice';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';

// * ################### VALIDATION SCHEMA ####################
const validateExcelFile = Yup.object({
    file: Yup.mixed().required('Please select students excel file'),
});

const ImportStudents = ({ handleDialogClose }) => {
    // ############## CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############## Comp State ##########
    const [filePreview, setFilePreview] = useState('');

    // ############## RTK ################
    const [
        importStudents,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useImportStudentsMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setStudents(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Box>
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            py: 1,
                            opacity: 0.9,
                        }}
                    >
                        Required excel format
                    </Typography>
                </Box>
                <Box>
                    <img src={excel} alt="" style={{ width: '100%' }} />
                </Box>
                <Formik
                    enableReinitialize
                    validationSchema={validateExcelFile}
                    initialValues={{
                        file: '',
                    }}
                    onSubmit={(payload) => {
                        const data = new FormData();
                        data.append('students_excel_file', payload.file);
                        importStudents(data);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                spacing={2}
                                justifyContent={'center'}
                            >
                                <Grid item sm={12}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: 53,
                                        }}
                                    >
                                        <CustomFileInput
                                            name="file"
                                            type="file"
                                            label="List of student in excel file format"
                                            formik={formik}
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            setFilePreview={setFilePreview}
                                        />
                                        <a
                                            href={excelSample}
                                            style={{ height: '100%' }}
                                            download="student import excel sample"
                                        >
                                            <Button
                                                startIcon={<Download />}
                                                variant="contained"
                                                color="info"
                                                sx={{ height: '100%', mt: 1 }}
                                            >
                                                Download sample
                                            </Button>
                                        </a>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 4,
                                            width: '100%',
                                        }}
                                    >
                                        <Typography className="error-message">
                                            <small>
                                                Please make sure uploaded excel
                                                file follow the provided format
                                                above.
                                            </small>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <Box>
                                        <CustomSubmitButton
                                            startIcon={<ImportContacts />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={'primary'}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 2, fontSize: 16 }}
                                        >
                                            Import students
                                        </CustomSubmitButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default ImportStudents;
