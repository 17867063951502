// => Get training seasons
const GET_TRAINING_SEASONS = '/training-seasons';

// => Get single training seasons
const GET_SINGLE_TRAINING_SEASON = '/training-seasons';

// => Create training season
const CREATE_TRAINING_SEASON = '/training-seasons';

// => Update training season
const UPDATE_TRAINING_SEASON = '/training-seasons';

// => Update training season
const DELETE_TRAINING_SEASON = '/training-seasons';

// => Open training season
const OPEN_TRAINING_SEASON = '/training-seasons/open';

// => Close training season
const CLOSE_TRAINING_SEASON = '/training-seasons/close';

export const trainingSeasonEndpoints = {
    GET_TRAINING_SEASONS,
    GET_SINGLE_TRAINING_SEASON,
    CREATE_TRAINING_SEASON,
    UPDATE_TRAINING_SEASON,
    DELETE_TRAINING_SEASON,
    OPEN_TRAINING_SEASON,
    CLOSE_TRAINING_SEASON,
};
