import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Divider, Grid, Tooltip } from "@mui/material";
import {
    PermIdentityOutlined,
    Phone,
} from "@mui/icons-material";
import { colors } from "../../../assets/utils/constants";
import { useSelector } from "react-redux";
import { jobsSelector } from "../../../states/features/selectors";
import { filter } from "lodash";
import AppliedJobWidget from "../../../components/widgets/AppliedJobWidget";
import ApproveAndDecline from "../CRUD/ApproveAndDecline";
import GroupMemberWidget from "../../../components/widgets/GroupMemberWidget";

// ##################### MUI STYLED ########################
// -> BoxTable
const BoxTable = styled(Box)({
    border: `1px solid ${colors.bgColor5}`,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    borderRadius: 2,
    position: "relative",
    display: "flex",
    height: 50,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-between",
    ".title": {
        fontSize: 15,
        fontWeight: "bold",
        opacity: 0.8,
    },
    ".value": {
        fontSize: 14,
        opacity: 0.8,
    },
});

const GroupApplicantDetails = ({ applicant: group, handleDialogClose }) => {
    // ############ Comp State #########
    const groupMembers = group.members;
    const groupAdmin = filter(groupMembers, ["role", "admin"])[0];

    // ############ Redux state ########
    const { jobs } = useSelector(jobsSelector);

    // ############ FUNC ############
    const getAppliedJob = () => {
        let appliedJob = filter(
            jobs,
            ({ id }) => id === group?.application.job_id
        );
        return appliedJob[0];
    };
    const appliedJob = getAppliedJob();

    return (
        <Grid container padding={2} spacing={2}>
            <Grid item sm={12}>
                <Box
                    className="neo-up"
                    sx={{
                        display: "flex",
                        position: "relative",
                        background: colors.bgColor,
                        p: 2,
                        borderRadius: 5,
                        borderBottom: `4px solid ${colors.secondary}`,
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Avatar
                            src={group.banner}
                            sx={{
                                height: 80,
                                width: 80,
                                borderRadius: 50,
                                border: `2px dotted ${colors.info}`,
                                p: 0.5,
                                img: {
                                    objectFit: "contain",
                                },
                            }}
                            variant="square"
                        />
                    </Box>
                    <Box
                        sx={{
                            height: 100,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            pl: 3,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    color: colors.info,
                                }}
                            >
                                {group.group_name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    color: colors.info,
                                }}
                            >
                                {`${group.group_category.name}`}
                            </Typography>
                            <Typography
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: colors.warning,
                                    ml: -0.5,
                                }}
                            >
                                <PermIdentityOutlined sx={{ fontSize: 14 }} />{" "}
                                {group.group_reg_number}
                            </Typography>
                        </Box>
                        {/* <Box
                            className="neo-up"
                            sx={{
                                bgcolor: colors.bgColor,
                                right: 20,
                                textAlign: "center",
                                borderRadius: 4,
                                p: 2,
                            }}
                        >
                            <InfoBox>
                                <Typography className="title">
                                    ORG Contacts
                                </Typography>
                                <Divider />
                                <Box
                                    sx={{
                                        p: 0.5,
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Tooltip
                                        title={group.phone_number}
                                        placement="top"
                                        arrow
                                        sx={{ mr: 1 }}
                                    >
                                        <Button color="info" variant="outlined">
                                            <Phone
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        title={group.email}
                                        placement="top"
                                        arrow
                                        sx={{ ml: 1 }}
                                    >
                                        <Button color="info" variant="outlined">
                                            <Email
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </InfoBox>
                        </Box> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            mb: 1,
                            color: colors.info,
                        }}
                    >
                        Group description
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                mr: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    opacity: 0.8,
                                }}
                            >
                                {group.description}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            mb: 1,
                            color: colors.info,
                        }}
                    >
                        Group admin
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Grid container>
                            <Grid item sm={8}>
                                <BoxTable>
                                    <Typography className="title">
                                        Full name
                                    </Typography>
                                    <Typography className="value">{`${groupAdmin.first_name} ${groupAdmin.middle_name} ${groupAdmin.last_name}`}</Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={4}>
                                <BoxTable>
                                    <Typography className="title">
                                        Gender
                                    </Typography>
                                    <Typography className="value">
                                        {groupAdmin.gender}
                                    </Typography>
                                </BoxTable>
                            </Grid>
                            <Grid item sm={12}>
                                <BoxTable>
                                    <Typography className="title">
                                        Phone
                                    </Typography>
                                    <Typography className="value">
                                        {groupAdmin.phone_number}
                                    </Typography>
                                    <Tooltip
                                        title={groupAdmin.phone_number}
                                        placement="top"
                                        arrow
                                        sx={{
                                            mr: 1,
                                            position: "absolute",
                                            right: 0,
                                            top: "25%",
                                        }}
                                    >
                                        <Button color="info" variant="outlined">
                                            <Phone
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </BoxTable>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            mb: 1,
                            color: colors.info,
                        }}
                    >
                        Group members
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Grid container>
                            {groupMembers.map((member) => (
                                <Grid item sm="4">
                                    <GroupMemberWidget applicant={member} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
                <Box
                    sx={{
                        p: 1.5,
                        borderRadius: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            mb: 1,
                            color: colors.info,
                        }}
                    >
                        Applied job
                    </Typography>
                    <Box>
                        <AppliedJobWidget job={appliedJob} applicant={group} />
                    </Box>
                </Box>
            </Grid>
            {/* ############# ACTION BUTTON ############# */}
            <Divider />
            <Grid item sm={12}>
                <ApproveAndDecline
                    applicant={group}
                    handleDialogClose={handleDialogClose}
                />
            </Grid>
        </Grid>
    );
};

export default GroupApplicantDetails;
