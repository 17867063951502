import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import SmallCustomTextField from "../../../components/Forms/SmallCustomTextField";
import SmallCustomSelectInput from "../../../components/Forms/SmallCustomSelectInput";
import CustomMultlineInput from "../../../components/Forms/CustomMultlineInput";
import { colors } from "../../../assets/utils/constants";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import {
    jobPostingSelector,
    settingsSelector,
} from "../../../states/features/selectors";
import { jobDetails } from "../../../states/features/job/JobPostingSlice";
import moment from "moment";
import { filter, isEmpty } from "lodash";
// import CustomMonoAutocomplete from "../../../components/Forms/CustomMonoAutocomplete";
import { regionList } from "../../../assets/utils/regionList";

// * ################### VALIDATION SCHEMA ####################
let jobPostingValidationSchema = Yup.object({
    title: Yup.string().required("Title is a required field"),
    salary: Yup.number().typeError("salary must be a number"),
    salary_scale: Yup.string(),
    open_date: Yup.date()
        .min(
            moment(new Date()).format("YYYY-MM-DD"),
            `open date must be later than today`
        )
        .required("Open date is a required field"),
    close_date: Yup.date()
        .min(Yup.ref("open_date"), `close date must be later than open date`)
        .test({
            name: "max",
            exclusive: false,
            params: {},
            message: "The date range must be within 30 days",
            test: function (value) {
                return (
                    value <=
                    moment(this.parent.open_date, "DD-MM-YYYY").add(30, "days")
                );
            },
        })
        .required("close date is a required field"),
    job_type: Yup.string().required("Job type is a required field"),
    job_category_id: Yup.string().required("Job category is a required field"),
    description: Yup.string().required("Description is a required field"),
    region_id: Yup.string().required("Region is a required field"),
});

const GeneralDetails = ({ steps, activeStep, handleNext, handleBack }) => {
    // ########## Comp state ###########
    const regions = regionList;
    const [districts, setDistricts] = useState([]);

    // -> redux state
    const dispatch = useDispatch();
    const { jobCategories, activeRegions } = useSelector(settingsSelector);
    const {
        title,
        salary,
        salary_scale,
        open_date,
        close_date,
        job_type,
        job_category_id,
        other_category,
        description,
        location,
        region_id,
        district_id,
    } = useSelector(jobPostingSelector);
    const [otherCategory, setOtherCategory] = useState(
        other_category ? true : false
    );

    const jobPostingValidationWithOtherSchema = Yup.object({
        title: Yup.string().required("Title is a required field"),
        salary: Yup.number().typeError("salary must be a number"),
        salary_scale: Yup.string(),
        open_date: Yup.date()
            .min(
                moment(new Date()).format("YYYY-MM-DD"),
                `open date must be later than today`
            )
            .required("Open date is a required field"),
        close_date: Yup.date()
            .min(
                Yup.ref("open_date"),
                `close date must be later than open date`
            )
            .required("close date is a required field"),
        job_type: Yup.string().required("Job type is a required field"),
        job_category_id: Yup.string().required(
            "Job category is a required field"
        ),
        other_category: Yup.string().required(
            "Other category name is required"
        ),
        description: Yup.string().required("Description is a required field"),
        location: Yup.string().required("Location is a required field"),
    });

    // ################# FUNC ###################
    const getSelectedCategory = (payload) => {
        let selectedCategory = filter(
            jobCategories,
            ({ id }) => id === payload
        );
        selectedCategory = selectedCategory[0];
        let categoryName = selectedCategory.name;
        if (categoryName.toLowerCase() === "other") {
            return setOtherCategory(true);
        }
        return setOtherCategory(false);
    };

    const getSelectedRegionDistricts = (payload) => {
        setDistricts("");
        let selectedRegion = filter(activeRegions, ({ id }) => id === payload);
        selectedRegion = selectedRegion[0];
        let selectedDistricts = selectedRegion.districts;
        setDistricts(selectedDistricts);
    };

    // ############## UseEffect ################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (!isEmpty(region_id)) {
                getSelectedRegionDistricts(region_id);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    title,
                    salary,
                    salary_scale,
                    open_date,
                    close_date,
                    job_type,
                    job_category_id,
                    other_category,
                    description,
                    location,
                    region_id,
                    district_id,
                }}
                validationSchema={
                    otherCategory
                        ? jobPostingValidationWithOtherSchema
                        : jobPostingValidationSchema
                }
                onSubmit={(payload) => {
                    dispatch(jobDetails(payload));
                    handleNext();
                }}
            >
                {(formik) => (
                    <Form>
                        <Box
                            sx={{
                                height: 450,
                                position: "relative",
                                overflow: "auto",
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                {/* ################## JOB TITLE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Job title
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={"Job title"}
                                                    name={"title"}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## SALARY ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Job salary{" "}
                                            <strong className="error-text">
                                                ***optional***
                                            </strong>
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={"Job Salary"}
                                                    name={"salary"}
                                                    type="text"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={"Salary scale"}
                                                    name={"salary_scale"}
                                                    type="text"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## DATE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Application date
                                            <Typography
                                                component={"span"}
                                                sx={{
                                                    float: "right",
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    opacity: 0.8,
                                                    color: "red",
                                                }}
                                            >
                                                ** Max post duration 30days **
                                            </Typography>
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={"Application start"}
                                                    name={"open_date"}
                                                    type={"date"}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={
                                                        "Application deadline"
                                                    }
                                                    name={"close_date"}
                                                    type={"date"}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## JOB LOCATION ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Job location
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    mr: 1,
                                                }}
                                            >
                                                {/* <CustomMonoAutocomplete
                                                    label={'Job location'}
                                                    name={'location'}
                                                    formik={formik}
                                                    options={regions}
                                                    defaultOption={location}
                                                    colors={colors.primary}
                                                /> */}

                                                <SmallCustomSelectInput
                                                    label={"Region"}
                                                    name={"region_id"}
                                                    formik={formik}
                                                    color={colors.primary}
                                                    handler={
                                                        getSelectedRegionDistricts
                                                    }
                                                >
                                                    {activeRegions.map(
                                                        (region) => (
                                                            <MenuItem
                                                                key={region.id}
                                                                value={
                                                                    region.id
                                                                }
                                                            >
                                                                {region.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </SmallCustomSelectInput>
                                            </Box>
                                            {!isEmpty(districts) && (
                                                <Box
                                                    sx={{
                                                        width: "100%",
                                                        mr: 1,
                                                    }}
                                                >
                                                    <SmallCustomSelectInput
                                                        label={"District"}
                                                        name={"district_id"}
                                                        formik={formik}
                                                        color={colors.primary}
                                                    >
                                                        {isEmpty(districts) ? (
                                                            <>
                                                                <MenuItem
                                                                    value={""}
                                                                >
                                                                    No district
                                                                </MenuItem>
                                                            </>
                                                        ) : (
                                                            districts.map(
                                                                (district) => (
                                                                    <MenuItem
                                                                        key={
                                                                            district.id
                                                                        }
                                                                        value={
                                                                            district.id
                                                                        }
                                                                    >
                                                                        {
                                                                            district.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )
                                                        )}
                                                    </SmallCustomSelectInput>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## JOB TYPE & CATEGORY ############ */}
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Job type and Category
                                        </Typography>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item sm={6}>
                                                    <SmallCustomSelectInput
                                                        label={"Job type"}
                                                        name={"job_type"}
                                                        formik={formik}
                                                        color={colors.primary}
                                                    >
                                                        <MenuItem value="internship">
                                                            Intentship
                                                        </MenuItem>
                                                        <MenuItem value="part_time">
                                                            Part time
                                                        </MenuItem>
                                                        <MenuItem value="full_time">
                                                            Full time
                                                        </MenuItem>
                                                    </SmallCustomSelectInput>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <SmallCustomSelectInput
                                                        label={"Job category"}
                                                        name={"job_category_id"}
                                                        formik={formik}
                                                        color={colors.primary}
                                                        handler={
                                                            getSelectedCategory
                                                        }
                                                    >
                                                        {jobCategories?.map(
                                                            (category) => (
                                                                <MenuItem
                                                                    key={
                                                                        category.id
                                                                    }
                                                                    value={
                                                                        category.id
                                                                    }
                                                                >
                                                                    {
                                                                        category.name
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </SmallCustomSelectInput>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={12}
                                                    sx={{
                                                        display: otherCategory
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            "Other category name"
                                                        }
                                                        name={"other_category"}
                                                        type="text"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## DESCRIPTION ############ */}
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb: 1,
                                            }}
                                        >
                                            Job descriptions
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    mr: 1,
                                                }}
                                            >
                                                <CustomMultlineInput
                                                    label={"Job description"}
                                                    name={"description"}
                                                    rows={4}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* ################ ACTION BUTONS ###################### */}
                        <NextAndBackBtn
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            action={formik.handleSubmit}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default GeneralDetails;
