import React, { useState } from 'react';
import { AddPhotoAlternate } from '@mui/icons-material';
import { Box, Grid, MenuItem } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import { colors } from '../../../assets/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from '../../../states/features/selectors';
import {
    useAddORGKeyPersonDocsMutation,
    useReUploadKeyPersonMutation,
    useUpdateORGKeyPersonDocsMutation,
} from '../../../api/profile/profileAPI';
import useRTK from '../../../hooks/useRTK';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import DocumentPreviewDialog from '../../../components/Dialogs/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';
import { isEmpty } from 'lodash';

// * -> key person docs
const keyPersonDocsValidationSchema = Yup.object({
    verification_type_id: Yup.string().required(
        'Verification type is required field',
    ),
    verification_number: Yup.string().required(
        'Verification number is required field',
    ),
    file: Yup.mixed().required('Verification document is required'),
});

const UploadKeyPersonDocs = ({
    orgKeyPerson,
    closeDialogy,
    refetchProfile,
}) => {
    // ############## IN-COMPENENT STATE ##########
    // -> handle file preview
    const [filePreview, setFilePreview] = useState('');

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################### Redux state #################
    const { verificationTypes } = useSelector(settingsSelector);

    // ############### RTK #####################
    const [
        addORGKeyPersonDocs,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useAddORGKeyPersonDocsMutation();
    const [
        updateORGKeyPersonDocs,
        {
            isLoading: updateLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updateUpdatededData,
        },
    ] = useUpdateORGKeyPersonDocsMutation();
    const [
        reUploadORGKeyPersonDocs,
        {
            isLoading: reUploadLoading,
            isSuccess: reUploadSuccess,
            isError: reUploadIsError,
            error: reUploadError,
            data: reUploadData,
        },
    ] = useReUploadKeyPersonMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    RTK.useRTKResponse(
        updateSuccess,
        updateIsError,
        updateError,
        updateUpdatededData,
        rtkActions,
    );
    RTK.useRTKResponse(
        reUploadSuccess,
        reUploadIsError,
        reUploadError,
        reUploadData,
        rtkActions,
    );

    const [openDocsDialog, setOpenDocsDialogy] = useState(false);
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Document preview'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={filePreview} />
            </DocumentPreviewDialog>

            <Formik
                enableReinitialize
                initialValues={{
                    file: '',
                    verification_type_id: !isEmpty(orgKeyPerson.documents)
                        ? orgKeyPerson.documents.verification_type.id
                        : '',
                    verification_number:
                        orgKeyPerson.documents.verification_number != null
                            ? orgKeyPerson.documents.verification_number
                            : '',
                }}
                validationSchema={keyPersonDocsValidationSchema}
                onSubmit={(payload) => {
                    const data = new FormData();
                    data.append('id', orgKeyPerson.id);
                    data.append('key_person_id', orgKeyPerson.id);
                    data.append(
                        'verification_type_id',
                        payload.verification_type_id,
                    );
                    data.append(
                        'verification_number',
                        payload.verification_number,
                    );
                    data.append('verification_attachment', payload.file);
                    // isEmpty(orgKeyPerson.documents)
                    //     ? addORGKeyPersonDocs(data)
                    //     : updateORGKeyPersonDocs(data);

                    orgKeyPerson?.documents.verification_status === 'pending'
                        ? updateORGKeyPersonDocs(data)
                        : orgKeyPerson?.documents.verification_status ===
                          'denied'
                        ? reUploadORGKeyPersonDocs(data)
                        : addORGKeyPersonDocs(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Verification type"
                                    type="text"
                                    name="verification_type_id"
                                    color={colors.primary}
                                    formik={formik}
                                >
                                    {verificationTypes?.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label={`Verification number`}
                                    type="text"
                                    name="verification_number"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomFileInput
                                    name="file"
                                    type="file"
                                    label="Verification attachment"
                                    formik={formik}
                                    accept="application/pdf"
                                    setFilePreview={setFilePreview}
                                    filePreview={filePreview}
                                    handleDocsPreview={handleDocsPreview}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<AddPhotoAlternate />}
                                        onClick={formik.handleSubmit}
                                        loading={
                                            isLoading
                                                ? isLoading
                                                : updateLoading
                                                ? updateLoading
                                                : reUploadLoading
                                        }
                                    >
                                        {orgKeyPerson.documents
                                            .verification_status === 'pending'
                                            ? 'Update docs'
                                            : orgKeyPerson.documents
                                                  .verification_status ===
                                              'denied'
                                            ? 'Re-upload docs'
                                            : 'Upload docs'}
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UploadKeyPersonDocs;
