import React from 'react';
import { useSelector } from 'react-redux';
import VerificationReq from '../components/VerificationReq';
import CompanyDashbord from './CompanyDashbord';
import InstitutionDashboard from './InstitutionDashboard';
import { profileSelector } from '../states/features/selectors';

// !#################### MAIN FUNC ######################
const Dashboard = () => {
    // ############## REDUX STATE ######################
    const { profile: user } = useSelector(profileSelector);

    return (
        <React.Fragment>
            {user.is_verified === 'verified' ? (
                user.organization_type.name === 'Company' ? (
                    <>
                        <CompanyDashbord />
                    </>
                ) : (
                    user.organization_type.name === 'Institution' && (
                        <>
                            <InstitutionDashboard />
                        </>
                    )
                )
            ) : (
                <VerificationReq />
            )}
        </React.Fragment>
    );
};

export default Dashboard;
