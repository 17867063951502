import {
    ArrowCircleRightOutlined,
    ArrowRight,
    WorkOutline,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { filter, size } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const JobVacancyCard = ({ jobs }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    borderRadius: 4,
                    overflow: 'hidden',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'space-between',
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary}) !important`,
                }}
            >
                <Box sx={{ display: 'flex', padding: 1.5 }}>
                    <Box
                        sx={{
                            p: 2,
                            borderBottom: `5px solid ${colors.secondary}`,
                            borderRadius: 5,
                            mr: 1.5,
                        }}
                    >
                        <WorkOutline
                            sx={{
                                fontSize: 60,
                                color: colors.secondary,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: 250,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    'fontSize': 20,
                                    'color': colors.secondary,
                                    'position': 'relative',
                                    ':before': {
                                        content: "''",
                                        height: 2,
                                        width: `100%`,
                                        position: 'absolute',
                                        bottom: -7,
                                    },
                                }}
                            >
                                Jobs vacancy
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 2,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />({size(jobs)}) Posted 
                                </Typography>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />(
                                    {size(
                                        filter(jobs, ['status', 'published']),
                                    )}
                                    ) Published 
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />(
                                    {size(filter(jobs, ['status', 'draft']))})
                                    Drafted 
                                </Typography>
                                <Typography
                                    sx={{
                                        color: colors.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 13,
                                    }}
                                >
                                    <ArrowRight />(
                                    {size(filter(jobs, ['status', 'banned']))})
                                    Banned 
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Link to={'/jobs'}>
                    <Box
                        sx={{
                            'bgcolor': colors.secondary,
                            'pl': 4,
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            'alignItems': 'center',
                            ':hover': {
                                '.icon': {
                                    mr: 3,
                                    transition: '.3s all ease-in-out',
                                },
                            },
                        }}
                    >
                        <Typography
                            sx={{ fontWeight: 'bold', color: colors.primary }}
                        >
                            Manage job vacancy
                        </Typography>
                        <Typography>
                            <ArrowCircleRightOutlined
                                className="icon"
                                sx={{
                                    mt: 1,
                                    mr: 4,
                                    transition: '.3s all ease-in-out',
                                    color: colors.primary,
                                }}
                            />
                        </Typography>
                    </Box>
                </Link>
            </Box>
        </React.Fragment>
    );
};

export default JobVacancyCard;
