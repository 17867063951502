import {
    ArrowCircleRightOutlined,
    ArrowRight,
    Construction,
    Groups,
} from '@mui/icons-material';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import { useSelector } from 'react-redux';
import {
    PTSelector,
    instituteProgramsSelector,
    studentsSelector,
} from '../../states/features/selectors';
import { size } from 'lodash';

const MainCard = styled(Box)({
    'height': 110,

    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'flexDirection': 'column',
    '& .icon': {
        position: 'absolute',
        opacity: 0.1,
    },
    '& .head': {
        fontSize: 16,
        fontWeight: 'bold',
    },
});

const InfoCard = styled(Box)({
    'height': 50,
    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'padding': 5,
    'borderRadius': 12,
    'border': `1px solid ${colors.bgColor4}`,
    'borderLeft': `4px solid ${colors.bgColor5}`,
    'transition': '.3s all ease-in-out',
    ':hover': {
        border: `1px solid ${colors.success}`,
        borderLeft: `4px solid ${colors.success}`,
        transition: '.3s all ease-in-out',
    },
    '& .total': {
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 40,
        marginRight: 10,
        fontWeight: 'bold',
        fontSize: 14,
        background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
        boxShadow: `1px 1px 5px 0px ${colors.bgColor1}`,
    },
});

const StudentsManagementCard = () => {
    // ############### Redux state ####################
    const { sectors, departments, sections } = useSelector(
        instituteProgramsSelector,
    );
    const { students } = useSelector(studentsSelector);
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item sm={7}>
                    <Box
                        sx={{
                            height: 225,
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                        }}
                    >
                        <MainCard
                            sx={{
                                borderTopLeftRadius: 30,
                                borderBottomRightRadius: 30,
                                background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary}) !important`,
                            }}
                        >
                            <Groups
                                className="icon"
                                sx={{
                                    fontSize: 180,
                                    color: colors.bgColor5,
                                }}
                            />
                            <Typography
                                className="head"
                                sx={{
                                    color: colors.secondary,
                                }}
                            >
                                Student managements
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.secondary,
                                }}
                            >
                                {size(students)}{' '}
                                <sup
                                    style={{
                                        fontSize: 12,
                                        color: colors.secondary,
                                    }}
                                >
                                    Students
                                </sup>
                            </Typography>
                            <Link to={'/students'}>
                                <Button
                                    endIcon={<ArrowCircleRightOutlined />}
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                >
                                    Manage
                                </Button>
                            </Link>
                        </MainCard>
                        <MainCard
                            sx={{
                                borderTopRightRadius: 30,
                                borderBottomLeftRadius: 30,
                                background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary})`,
                            }}
                        >
                            <Construction
                                className="icon"
                                sx={{
                                    fontSize: 130,
                                    color: colors.primary,
                                }}
                            />
                            <Typography className="head">
                                Students training applicantions
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.primary,
                                }}
                            >
                                {size(allPracticalTrainingApplicants)}{' '}
                                <sup
                                    style={{
                                        fontSize: 12,
                                        color: colors.primary,
                                    }}
                                >
                                    applicantions
                                </sup>
                            </Typography>
                            <Link to={'/applicants/practical-trainings'}>
                                <Button
                                    endIcon={<ArrowCircleRightOutlined />}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                >
                                    Manage
                                </Button>
                            </Link>
                        </MainCard>
                    </Box>
                </Grid>
                <Grid item sm={5}>
                    <Box
                        sx={{
                            height: 225,
                            display: 'flex',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            p: 1,
                            borderRadius: 2,
                        }}
                    >
                        <Link to={'/students/institute-programs'}>
                            <InfoCard>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography className="total">
                                        {size(sectors)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sectors
                                    </Typography>
                                </Box>
                                <ArrowRight />
                            </InfoCard>
                        </Link>
                        <Link to={'/students/institute-programs'}>
                            <InfoCard
                                sx={{
                                    height: 50,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: 0.5,
                                    borderRadius: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography className="total">
                                        {size(departments)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Depertments
                                    </Typography>
                                </Box>
                                <ArrowRight />
                            </InfoCard>
                        </Link>
                        <Link to={'/students/institute-programs'}>
                            <InfoCard
                                sx={{
                                    height: 50,
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor5})`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    p: 0.5,
                                    borderRadius: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography className="total">
                                        {size(sections)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Sections
                                    </Typography>
                                </Box>
                                <ArrowRight />
                            </InfoCard>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default StudentsManagementCard;
