import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../../assets/utils/constants';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, DoNotDisturbAlt } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import { useDeclinePracticalTraingMutation } from '../../../api/practicalTraining/practicalTrainingsAPI';
import { setPracticalTrainingDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import CustomTextArea from '../../../components/Forms/CustomTextArea';

// * ################### VALIDATION SCHEMA ####################
const declinePTValidationSchema = Yup.object({
    decline_reason: Yup.string().required('Decline reason is a required field'),
});

const DeclinePTRequest = ({ training, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        declineAction,
        {
            isLoading: declineLoading,
            isSuccess: declineSuccess,
            isError: declineIsError,
            error: declineError,
            data: declineData,
        },
    ] = useDeclinePracticalTraingMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setPracticalTrainingDetails(data));
    };
    RTK.useRTKResponse(
        declineSuccess,
        declineIsError,
        declineError,
        declineData,
        rtkActions,
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    pt_id: training.id,
                    decline_reason: '',
                }}
                validationSchema={declinePTValidationSchema}
                onSubmit={(payload) => {
                    declineAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <Box sx={{ mb: 2 }} className="error-message">
                                    <Typography>
                                        Please note that decline practical training is irrevisible process. So after decline student will not be able to apply this practical training.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        p: 1.5,
                                        borderRadius: 2,
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            mb: 1,
                                        }}
                                    >
                                        Decline reason
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mr: 1,
                                            }}
                                        >
                                            <CustomTextArea
                                                name={'decline_reason'}
                                                label={'Decline reason'}
                                                rows={5}
                                                maxChar={1000}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<DoNotDisturbAlt />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={'error'}
                                    variant={'contained'}
                                    loading={declineLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Decline practical training request
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default DeclinePTRequest;
