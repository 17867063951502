// ############# APPLICATION ############
// => Single job App
const SINGLE_JOB_APPLICATIONS = "/jobs/applications";

// => Companies App
const COMPANIES_APPLICATIONS = "/jobs/applications/companies";

// => Individual App
const INDIVIDUAL_APPLICATIONS = "/jobs/applications/individuals";

// => Group App
const GROUP_APPLICATIONS = "/jobs/applications/groups";

// => Save
const SAVE_APPLICATION = "/jobs/applications/save";

// => Shortlist
const SHORTLIST_APPLICATION = "/jobs/applications/shortlist";

// => Accept
const ACCEPT_APPLICATION = "/jobs/applications/accept";

// => decline
const DECLINE_APPLICATION = "/jobs/applications/decline";

// => applied org
const GET_APPLIED_ORG_JOBS = "/jobs/organizations/applied/all";

// => applied employer
const GET_APPLIED_EMPLOYER_JOBS = "/jobs/employers/applied/all";

// => remark applied org job
const REMARK_APPLIED_ORG_JOB =
    "/jobs/applications/applied/remark/organizations";

// => remark applied employer job
const REMARK_APPLIED_EMPLOYER_JOB =
    "/jobs/applications/applied/remark/employers";

export const jobApplicationEndpoints = {
    SINGLE_JOB_APPLICATIONS,
    COMPANIES_APPLICATIONS,
    INDIVIDUAL_APPLICATIONS,
    GROUP_APPLICATIONS,
    SAVE_APPLICATION,
    SHORTLIST_APPLICATION,
    ACCEPT_APPLICATION,
    DECLINE_APPLICATION,
    GET_APPLIED_ORG_JOBS,
    GET_APPLIED_EMPLOYER_JOBS,
    REMARK_APPLIED_ORG_JOB,
    REMARK_APPLIED_EMPLOYER_JOB,
};
