import React from "react";
import { colors } from "../../assets/utils/constants";
import {
    Avatar,
    Box,
    Chip,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";
import {
    LockClock,
    Payment,
    Pending,
    Person,
    Place,
    RequestQuote,
    SupervisedUserCircleOutlined,
    Verified,
} from "@mui/icons-material";
import moment from "moment/moment";
import useCurrency from "../../hooks/useCurrency";
import strHelper from "../../helpers/strHelper";
// import { useSelector } from "react-redux";
import { size } from "lodash";

// !############### MAIN FUNC #################
const EmployerJobTopInfoWidget = ({ jobDetails, applicantIsLoading }) => {
    // ########### CONST ##############
    const STR = strHelper();
    const currency = useCurrency();

    // ########### Redux state #########
    // const { singleJobApplications } = useSelector(jobApplicationsSelector);

    return (
        <React.Fragment>
            <Box
                className="neo-buldge"
                sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    marginBottom: 2,
                    transition: ".2s all ease-in-out",
                    border: `1px solid ${colors.secondary}`,
                    borderLeft: `4px solid ${
                        jobDetails.isApplied !== true
                            ? colors.warning
                            : jobDetails?.application.status === "applied"
                            ? colors.info
                            : jobDetails?.application.status === "accepted"
                            ? colors.success
                            : jobDetails?.application.status === "employed"
                            ? colors.success
                            : jobDetails?.application.status === "shortlisted"
                            ? colors.secondary
                            : jobDetails?.application.status === "declined"
                            ? "red"
                            : jobDetails?.application.status === "dropped"
                            ? colors.warning
                            : colors.primary
                    }`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3}) !important`,
                    "& .more": {
                        transition: ".2s all ease-in-out",
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                    },
                    ":hover": {
                        boxShadow: "4p 4px 4px 4px rgba(46, 119, 174, .8)",
                        transition: ".2s all ease-in-out",
                        "& .more": {
                            marginLeft: 1,
                            transition: ".2s all ease-in-out",
                        },
                    },
                }}
            >
                <Box>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                        columnSpacing={2}
                    >
                        <Grid item sm={6}>
                            <List
                                sx={{
                                    width: "100%",
                                    mb: 0,
                                    pb: 0,
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="logo"
                                            src={
                                                jobDetails.employer
                                                    .profile_picture
                                            }
                                            variant="square"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                                borderRadius: 2,
                                                border: `1px dotted ${colors.warning}`,
                                                padding: 0.2,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                                                img: {
                                                    objectFit: "contain",
                                                },
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        sx={{
                                            ".MuiListItemText-primary": {
                                                fontSize: 17,
                                                fontWeight: "bold",
                                            },
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                    opacity: 0.7,
                                                    ml: 1,
                                                }}
                                            >
                                                {STR.strCapitalizeFirstChar(
                                                    jobDetails.title
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: "inline",
                                                        fontWeight: "bold",
                                                        ml: 1,
                                                        fontSize: 14,
                                                        color: colors.info,
                                                    }}
                                                    component="span"
                                                >
                                                    {
                                                        jobDetails.jobCategory
                                                            .name
                                                    }
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={2.5}>
                            <Box
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: "1px solid silver",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    Applicants
                                </Typography>
                                {applicantIsLoading ? (
                                    <CircularProgress
                                        size={14}
                                        sx={{ mt: 0.5 }}
                                    />
                                ) : (
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            color: colors.warning,
                                            fontWeight: "bold",
                                        }}
                                        noWrap
                                    >
                                        {jobDetails?.totalApplicants}
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item sm={2.5}>
                            <Box
                                sx={{
                                    textAlign: "center",
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: "1px solid silver",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: 12,
                                        color: colors.primary,
                                        opacity: 0.8,
                                    }}
                                >
                                    Posted
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.info,
                                        fontWeight: "bold",
                                    }}
                                    noWrap
                                >
                                    {moment(jobDetails.created_at).fromNow()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography sx={{ pl: 1, ml: 1 }}>
                        {jobDetails.isFor === "company"
                            ? `For company`
                            : jobDetails.isFor === "group"
                            ? "For (groups)"
                            : "For (individuals)"}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        p: 1,
                        position: "relative",
                        "& .chip": {
                            border: `1px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                        },
                    }}
                >
                    <Chip
                        icon={<Place />}
                        label={`${jobDetails?.region?.name} , 
                                ${jobDetails?.district?.name}`}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<Person />}
                        label={jobDetails.isFor}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<LockClock />}
                        label={jobDetails.job_type.replace(/[^a-zA-Z ]/g, " ")}
                        sx={{ mr: 1, textTransform: "capitalize" }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    {jobDetails.salary_scale && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary scale: ${jobDetails.salary_scale}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    {jobDetails.salary && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary: ${currency.formatCurrency(
                                "TZS",
                                jobDetails.salary
                            )}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    <Chip
                        icon={<SupervisedUserCircleOutlined />}
                        label={
                            jobDetails.isApplied !== true
                                ? "Not applied"
                                : jobDetails?.application.status === "pending"
                                ? "Applied"
                                : jobDetails?.application.status
                        }
                        sx={{
                            textTransform: "capitalize",
                            fontSize: 12,
                            fontWeight: "bold",
                            px: 1,
                            opacity: 0.8,
                        }}
                        size="small"
                        color={
                            jobDetails.isApplied !== true
                                ? "warning"
                                : jobDetails?.application.status ===
                                      "applied" ||
                                  jobDetails?.application.status === "pending"
                                ? "info"
                                : jobDetails?.application.status === "accepted"
                                ? "success"
                                : jobDetails?.application.status === "employed"
                                ? "success"
                                : jobDetails?.application.status ===
                                  "shortlisted"
                                ? "secondary"
                                : jobDetails?.application.status === "declined"
                                ? "error"
                                : jobDetails?.application.status === "dropped"
                                ? "warning"
                                : "default"
                        }
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default EmployerJobTopInfoWidget;
