import React, { useEffect, useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Grid, Typography, styled } from "@mui/material";
import JobInfoCard from "../../components/cards/JobInfoCard";
import NoContent from "../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import { Link } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";
import JobsSidebar from "../../components/sidebars/JobsSidebar";
import { colors } from "../../assets/utils/constants";
import { useSelector } from "react-redux";
import { jobsSelector } from "../../states/features/selectors";
import CountBadge from "../../components/CountBadge copy";
import JobWidget from "../../components/widgets/JobWidget";
import CustomPagination from "../../components/paginations/CustomPagination";
import { paginator } from "../../helpers/paginationHelper";
import JobFilters from "../../components/filters/JobFilters";

const ForYouJobInfoCard = styled(Box)({
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-betwee",
    padding: 5,
    position: "relative",
    background: `linear-gradient(180deg, ${colors.secondary}, ${colors.secondary}) !important`,
    borderBottom: `4px solid ${colors.info}`,
    color: colors.primary,
    ".total": {
        borderRadius: "50%",
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 15,
        fontWeight: "bold",
        fontStyle: "italic",
        borderBottom: `4px solid ${colors.info}`,
        transition: ".3s all ease-in-out",
    },
    ":hover": {
        transition: ".3s all ease-in-out",
        "& .total": {
            transform: `scale(1.1)`,
            transition: ".3s all ease-in-out",
            borderBottom: `4px solid ${colors.info}`,
        },
        ".arrow-con": {
            right: 20,
            transition: ".3s all ease-in-out",
        },
    },
    ".arrow-con": {
        position: "absolute",
        right: 25,
        transition: ".3s all ease-in-out",
    },
});

const Jobs = () => {
    // ############## Comp state ##################
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [jobs, setJobs] = useState([]);

    // ############# Redux state ################
    const {
        jobs: allJobs,
        appliedORGJobs,
        appliedEmployerJobs,
    } = useSelector(jobsSelector);
    const appliedJobsForYou = [...appliedORGJobs, ...appliedEmployerJobs];

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Filter by job name
    const filterByJobName = (search) => {
        let filteredJobs = [];
        if (search) {
            filteredJobs = allJobs.filter((job) => {
                return job.title.toLowerCase().includes(search.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(allJobs);
    };

    // => Filter by category
    const filterByIsFor = (isFor) => {
        let filteredJobs = [];
        if (isFor) {
            filteredJobs = allJobs.filter((job) => {
                return job.isFor.toLowerCase().includes(isFor.toLowerCase());
            });
            return setJobs(filteredJobs);
        }
        return setJobs(allJobs);
    };

    // -> job status
    const jobStatus = [
        {
            id: 1,
            total: size(filter(jobs, ["status", "draft"])),
            name: "Drafted jobs",
            url: "status/draft",
        },
        {
            id: 2,
            total: size(filter(jobs, ["status", "published"])),
            name: "Published jobs",
            url: "status/published",
        },
        {
            id: 4,
            total: size(filter(jobs, ["status", "closed"])),
            name: "Closed jobs",
            url: "status/closed",
        },
        {
            id: 5,
            total: size(filter(jobs, ["status", "expired"])),
            name: "Expired jobs",
            url: "status/expired",
        },
        {
            id: 6,
            total: size(filter(jobs, ["status", "banned"])),
            name: "Banned jobs",
            url: "status/banned",
        },
    ];

    // => Pagination data
    const paginatedJobs = paginator(jobs, page, perPage);

    // ############### useEffect ###################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setJobs(allJobs);
        }

        return () => {
            isSubscribed = false;
        };
    }, [allJobs]);

    return (
        <>
            <PageLayout head="Jobs management" sidebar={<JobsSidebar />}>
                <Grid container spacing={2}>
                    {jobStatus?.map((info) => (
                        <Grid item sm={4} xs={12} key={info.id}>
                            <Link to={info.url}>
                                <JobInfoCard info={info} />
                            </Link>
                        </Grid>
                    ))}
                    <Grid item sm={4}>
                        <Link to={"for-you"}>
                            <ForYouJobInfoCard>
                                <Box className="total">
                                    {size(appliedJobsForYou)}
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: 14 }}>
                                        {"Jobs For You"}
                                    </Typography>
                                </Box>
                                <Box className="arrow-con">
                                    <ArrowRight sx={{ mt: 1 }} />
                                </Box>
                            </ForYouJobInfoCard>
                        </Link>
                    </Grid>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={"Latest posted jobs"}
                            height={"45vh"}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedJobs}
                            action={
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <JobFilters
                                        filterByJobName={filterByJobName}
                                        filterByIsFor={filterByIsFor}
                                    />
                                    <CountBadge
                                        total={size(jobs)}
                                        item={"Jobs"}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(jobs) ? (
                                <>
                                    <NoContent
                                        height={250}
                                        message={"No job posted"}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={1}>
                                    {paginatedJobs.data.map((job) => (
                                        <Grid item sm={12} xs={12} key={job.id}>
                                            <JobWidget job={job} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Jobs;
