// => GET organization types
const GET_ORG_TYPES = "/organization-types/active";

// => GET organization categories
const GET_ORG_CATEGORIES = "/organization-categories/active";

// => GET organization categories
const GET_VERIFICATION_TYPE = "/verification-types/active";

// -> get job categories
const GET_JOB_CATEGORIES = "/job-categories/active";

// -> get qualifications award
const GET_QUALIFICATION_AWARDS = "/qualifications/award-levels/active";

// -> get education levels
const GET_EDUCATION_LEVELS = "/qualifications/education-levels/active";

// -> get experience levels
const GET_EXPERIENCE_LEVELS = "/qualifications/experience-levels/active";

// => get posting fee framwork
const GET_POSTING_FEE_FRAMWORK = "/pricing-frameworks";

// => get payment methods
const GET_PAYMENT_METHODS = "/payment-methods/active";

// => get public org
const GET_PUBLIC_ORG = "/organizations/public";

// => get active sectors
const GET_ACTIVE_SECTORS = "/sectors/active";

// => get active departments
const GET_ACTIVE_DEPARTMENTS = "/departments/active";

// => get active sections
const GET_ACTIVE_SECTIONS = "/sections/active";

// => get active region
const GET_ACTIVE_REGIONS = "/regions/active";

// => get active free trial
const GET_ACTIVE_FREE_TRIALS = "/free-trials/organization";

export const settingEndpoints = {
    GET_ORG_TYPES,
    GET_ORG_CATEGORIES,
    GET_VERIFICATION_TYPE,
    GET_QUALIFICATION_AWARDS,
    GET_JOB_CATEGORIES,
    GET_EDUCATION_LEVELS,
    GET_EXPERIENCE_LEVELS,
    GET_POSTING_FEE_FRAMWORK,
    GET_PAYMENT_METHODS,
    GET_PUBLIC_ORG,
    GET_ACTIVE_SECTORS,
    GET_ACTIVE_DEPARTMENTS,
    GET_ACTIVE_SECTIONS,
    GET_ACTIVE_REGIONS,
    GET_ACTIVE_FREE_TRIALS,
};
