import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import CustomCard2 from "../../components/cards/CustomCard2";
import { VerticalBarChart } from "../../components/charts/VerticalBarChart";
import PageLayout from "../layouts/PageLayout";
import ReportButtonWidget from "../../components/widgets/ReportButtonWidget";
import ReportDialog from "../../components/Dialogs/ReportDialog";
import { PDFViewer } from "@react-pdf/renderer";
import JobReportCRUD from "./CRUD/JobReportCRUD";
import { useJobReportFilter } from "./filters/jobFilter";
import JobReport from "./reports/JobReport";
import ApplicantReport from "./reports/ApplicantReport";
import StudentReport from "./reports/StudentReport";
import TrainingReport from "./reports/TrainingReport";
import TransactionReport from "./reports/TransactionReport";
import { useTransactionReportFilter } from "./filters/transactionFilter";
import { useApplicantReportFilter } from "./filters/applicantFilter";
import { useStudentReportFilter } from "./filters/studentFilter";
import { useTrainingReportFilter } from "./filters/trainingFilter";
import TransactionReportCRUD from "./CRUD/TransactionReportCRUD";
import TrainingReportCRUD from "./CRUD/TrainingReportCRUD";
import StudentReportCRUD from "./CRUD/StudentReportCRUD";
import ApplicantReportCRUD from "./CRUD/ApplicantReportCRUD";
import { PTSelector, jobsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { chatDataFilter } from "./filters/chartDataFilter";

const AnalysisAndReports = () => {
    // ############### Comp State #################
    const [openDialog, setOpenDialog] = useState(false);
    const [actionType, setActionType] = useState("");
    const [actionHead, setActionHead] = useState("");

    // ############# Comp State ####################
    const [jobReportData, setJobReportData] = useState([]);
    const [trainingReportData, setTrainingReportData] = useState([]);
    const [studentReportData, setStudentReportData] = useState([]);
    const [applicantReportData, setApplicantReportData] = useState([]);
    const [transactionReportData, setTransactionReportData] = useState([]);

    // ############# Redux State ####################
    const { jobs: allJobs } = useSelector(jobsSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ############## FUNC #################
    // => Handle dialogy open
    const handleDialogOpen = (action, head) => {
        setActionHead(head);
        setActionType(action);
        setOpenDialog(true);
    };
    // => Handle dialogy close
    const handleDialogClose = () => {
        setActionHead("");
        setOpenDialog(false);
    };

    // => Chart data filters
    const jobsChartData = chatDataFilter(allJobs, 2024);
    const traingsChartData = chatDataFilter(ORGPracticalTrainings, 2024);

    // ################### Report Data Filters ###################
    const filterJobs = useJobReportFilter();
    const filterTrainings = useTrainingReportFilter();
    const filterStudents = useStudentReportFilter();
    const filterApplicants = useApplicantReportFilter();
    const filterTransactions = useTransactionReportFilter();

    const handleJobReportDataFilter = (payload) => {
        const filteredJobs = filterJobs.filterJob(payload);
        setJobReportData(filteredJobs);
    };
    const handleTrainingReportDataFilter = (payload) => {
        const filteredTrainings = filterTrainings.filterTraining(payload);
        setTrainingReportData(filteredTrainings);
    };
    const handleStudentReportDataFilter = (payload) => {
        const filteredStudents = filterStudents.filterStudent(payload);
        setStudentReportData(filteredStudents);
    };
    const handleApplicantReportDataFilter = (payload) => {
        const filteredApplicants = filterApplicants.filterApplicant(payload);
        setApplicantReportData(filteredApplicants);
    };
    const handleTransactionReportDataFilter = (payload) => {
        const filteredTransactions =
            filterTransactions.filterTransaction(payload);
        setTransactionReportData(filteredTransactions);
    };

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handleJobReportDataFilter([]);
            handleTrainingReportDataFilter([]);
            handleStudentReportDataFilter([]);
            handleApplicantReportDataFilter([]);
            handleTransactionReportDataFilter([]);
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const reports = [
        {
            id: 1,
            title: "Jobs",
            action: handleDialogOpen,
            actionType: "job",
        },
        {
            id: 2,
            title: "Trainings",
            action: handleDialogOpen,
            actionType: "training",
        },
        {
            id: 3,
            title: "Students",
            action: handleDialogOpen,
            actionType: "student",
        },
        // {
        //     id: 4,
        //     title: "Applicants",
        //     action: handleDialogOpen,
        //     actionType: "applicant",
        // },
        {
            id: 5,
            title: "Transactions",
            action: handleDialogOpen,
            actionType: "transaction",
        },
    ];

    return (
        <>
            {/* ############# DIALOG ############### */}
            <ReportDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                customWidth={"md"}
                handleDialogClose={handleDialogClose}
                head={actionHead}
            >
                <Box
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <PDFViewer style={{ height: "100%", width: "60%" }}>
                        {actionType === "job" ? (
                            <>
                                <JobReport jobs={jobReportData} />
                            </>
                        ) : actionType === "applicant" ? (
                            <>
                                <ApplicantReport
                                    applicants={applicantReportData}
                                />
                            </>
                        ) : actionType === "student" ? (
                            <>
                                <StudentReport students={studentReportData} />
                            </>
                        ) : actionType === "training" ? (
                            <>
                                <TrainingReport
                                    trainings={trainingReportData}
                                />
                            </>
                        ) : actionType === "transaction" ? (
                            <>
                                <TransactionReport
                                    transactions={transactionReportData}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </PDFViewer>
                    <Box
                        style={{
                            height: "100%",
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Grid
                            container
                            justifyContent={"center"}
                            sx={{ mt: 2 }}
                        >
                            <Grid item sm={11} md={11}>
                                <CustomCard2 head={actionHead}>
                                    {actionType === "job" ? (
                                        <>
                                            <JobReportCRUD
                                                handler={
                                                    handleJobReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "applicant" ? (
                                        <>
                                            <ApplicantReportCRUD
                                                handler={
                                                    handleApplicantReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "student" ? (
                                        <>
                                            <StudentReportCRUD
                                                handler={
                                                    handleStudentReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "training" ? (
                                        <>
                                            <TrainingReportCRUD
                                                handler={
                                                    handleTrainingReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : actionType === "transaction" ? (
                                        <>
                                            <TransactionReportCRUD
                                                handler={
                                                    handleTransactionReportDataFilter
                                                }
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </CustomCard2>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ReportDialog>

            <PageLayout head={"Analysis and reports"} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <CustomCard2 head={"Job posting stats"}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <VerticalBarChart
                                            chartData={jobsChartData}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={6}>
                        <CustomCard2 head={"Practical training posting stats"}>
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item sm={12}>
                                        <VerticalBarChart
                                            chartData={traingsChartData}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomCard2>
                    </Grid>
                </Grid>
                <Grid container mt={2}>
                    <Grid item sm={12}>
                        <CustomCard2 head={"Reports"}>
                            <Grid container spacing={2.5}>
                                {reports.map((report, index) => (
                                    <Grid item xs key={index}>
                                        <ReportButtonWidget report={report} />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default AnalysisAndReports;
