// LOGIN user
const USER_LOGIN = '/auth/login';

// REGISTER user
const REGISTER_USER = '/auth/register';

// REQUEST_EMAIL otp
const REQUEST_EMAIL_OTP = '/auth/request-email-verification';

// REQUEST_PASSWORD_RESET_EMAIL otp
const REQUEST_PASSWORD_RESET_OTP = '/auth/request-password-reset-email';

// RESET password
const RESET_PASSWORD = '/auth/password-reset';

// VERIFY email otp
const VERIFY_EMAIL_OTP = '/auth/verify-email-request';

export const authEndpoints = {
    USER_LOGIN,
    REQUEST_EMAIL_OTP,
    VERIFY_EMAIL_OTP,
    REGISTER_USER,
    RESET_PASSWORD,
    REQUEST_PASSWORD_RESET_OTP,
};
