import React from 'react';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { colors } from '../../../assets/utils/constants';
import CustomMultlineInput from '../../../components/Forms/CustomMultlineInput';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import { useDispatch, useSelector } from 'react-redux';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';
import { PTPostingSelector } from '../../../states/features/selectors';
import { practicalTrainingDetail } from '../../../states/features/practicalTraining/PTPostingSlice';
import CustomMonoAutocomplete from '../../../components/Forms/CustomMonoAutocomplete';
import { regionList } from '../../../assets/utils/regionList';
import moment from 'moment';
import { isEmpty } from 'lodash';

// * ################### VALIDATION SCHEMA ####################
const generalDetailsValidationSchema = Yup.object({
    title: Yup.string().required('Title is a required field'),
    open_date: Yup.date()
        .min(
            moment(new Date()).format('YYYY-MM-DD'),
            `open date must be later than today`,
        )
        .required('Open date is a required field'),
    end_date: Yup.date()
        .min(Yup.ref('open_date'), `end date must be later than open date`)
        .required('End date is a required field'),
    location: Yup.string().required('Location is a required field'),
    max_applicants: Yup.string().nullable(),
    positions: Yup.number()
        .nullable()
        .when('max_applicants', {
            is: (applicants) => applicants.length > 0,
            then: Yup.number().max(Yup.ref('max_applicants')),
        }),
    description: Yup.string().required('Description is a required field'),
});

// !################# MAIN FUNC ###############
const GeneralDetails = ({ handleBack, handleNext, steps, activeStep }) => {
    // ############### Const #################
    const regions = regionList;
    const dispatch = useDispatch();

    // ############## Redux comp #############
    const {
        title,
        open_date,
        end_date,
        max_applicants,
        location,
        positions,
        description,
    } = useSelector(PTPostingSelector);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    title,
                    open_date,
                    end_date,
                    max_applicants: !isEmpty(max_applicants)
                        ? max_applicants
                        : 0,
                    positions: !isEmpty(positions) ? positions : 0,
                    location,
                    description,
                }}
                validationSchema={generalDetailsValidationSchema}
                onSubmit={(payload) => {
                    dispatch(practicalTrainingDetail(payload));
                    handleNext();
                }}
            >
                {(formik) => (
                    <Form>
                        <Box
                            sx={{
                                height: 450,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                {/* ################## JOB TITLE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Occupation
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={
                                                        'Training title (Designation)'
                                                    }
                                                    name={'title'}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Location
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                    position: 'relative',
                                                }}
                                            >
                                                <CustomMonoAutocomplete
                                                    label={'Training location'}
                                                    name={'location'}
                                                    formik={formik}
                                                    options={regions}
                                                    defaultOption={location}
                                                    colors={colors.primary}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## DATE ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Application date
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Application start'}
                                                    name={'open_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={
                                                        'Application deadline'
                                                    }
                                                    name={'end_date'}
                                                    type={'date'}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## APPLICANTS ############ */}
                                <Grid item sm={6} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Applicants
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Max applicant'}
                                                    name={'max_applicants'}
                                                    type="number"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    ml: 1,
                                                }}
                                            >
                                                <SmallCustomTextField
                                                    label={'Number of position'}
                                                    name={'positions'}
                                                    type="number"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                {/* ################## DESCRIPTION ############ */}
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            borderRadius: 2,
                                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                mb: 1,
                                            }}
                                        >
                                            Practical training description
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    mr: 1,
                                                }}
                                            >
                                                <CustomMultlineInput
                                                    label={
                                                        'Training description'
                                                    }
                                                    name={'description'}
                                                    rows={4}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* ################ ACTION BUTONS ###################### */}
                        <NextAndBackBtn
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            action={formik.handleSubmit}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default GeneralDetails;
