import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ManageAccounts } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { useUpdateORGDescriptionMutation } from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';

// * -> description
const descriptionValidationSchema = Yup.object({
    description: Yup.string()
        .required('Organization description is required field')
        .max(500, 'Organization description must be at most 500 characters')
        .nullable(),
});

const UpdateORGDescription = ({ user, closeDialogy, refetchProfile }) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        updateORGDescription,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useUpdateORGDescriptionMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    description: user.description ? user.description : '',
                }}
                validationSchema={descriptionValidationSchema}
                onSubmit={(payload) => {
                    let id = user.id;
                    payload = {
                        id,
                        ...payload,
                    };
                    updateORGDescription(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container>
                                    <Grid item sm={12} xs={12} padding={1}>
                                        <CustomTextArea
                                            label={`${user.organization_type.name} description`}
                                            name={'description'}
                                            maxChar={500}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<ManageAccounts />}
                                        onClick={formik.handleSubmit}
                                        loading={isLoading}
                                    >
                                        Update description
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateORGDescription;
