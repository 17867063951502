import React from "react";
import { colors } from "../../assets/utils/constants";
import { Box, Chip, Typography, styled } from "@mui/material";
import moment from "moment";

const NotCard = styled(Box)({
    border: `1px solid ${colors.bgColor}`,
    overflow: "hidden",
    position: "relative",
    marginBottom: 5,
    marginTop: 5,
    transition: `.3s all ease-in-out`,
    boxShadow: `0px 4px 0px ${colors.secondary}`,
    ":hover": {
        borderBottom: `1px solid ${colors.primary} !important`,
        transition: `.3s all ease-in-out`,
    },
});

const NotHeader = styled(Box)({
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    ".head": {
        fontSize: 13,
        fontWeight: "bold",
        textAlign: "left",
        borderRadius: 5,
    },
    ".body": {
        fontSize: 14,
        color: "gray",
        padding: 5,
    },
});

const NotFooter = styled(Box)({
    height: 25,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
    opacity: 0.8,
    ".text": {
        fontSize: 10,
        fontWeight: "bold",
        color: colors.primary,
        opacity: 0.8,
        marginRight: -10,
    },
});

const NotificationWidget2 = ({ notification }) => {
    return (
        <React.Fragment>
            <NotCard
                sx={{
                    borderRadius: 1,
                    border: `1px solid ${colors.bgColor5}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                }}
            >
                {/* ####################### HEAD AND BODY ######################### */}
                <NotHeader>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Chip
                            variant="outlined"
                            color={notification.type}
                            label={notification.title}
                            className="head"
                            size="small"
                            sx={{
                                height: 22,
                                opacity: 0.8,
                                width: "75%",
                                cursor: "pointer",
                            }}
                        />
                        {!notification.isSeen && (
                            <Typography
                                sx={{
                                    fontSize: 11,
                                    fontWeight: "bold",
                                    color: "red",
                                    px: 1,
                                    borderRadius: 2,
                                    border: `2px dotted ${colors.danger}`,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                }}
                            >
                                New
                            </Typography>
                        )}
                    </Box>

                    {/* ########################## BODY ########################### */}
                    <Typography className="body" noWrap sx={{ width: 200 }}>
                        {notification.body}
                    </Typography>
                </NotHeader>
                {/* ########################## FOOTER ########################### */}
                <NotFooter>
                    <Typography
                        className="tex"
                        sx={{ fontSize: 11, color: colors.warning, ml: -1 }}
                    >
                        From: {notification.from}
                    </Typography>
                    <Typography className="text">
                        {moment(notification.created_at).fromNow()}
                    </Typography>
                </NotFooter>
            </NotCard>
        </React.Fragment>
    );
};

export default NotificationWidget2;
