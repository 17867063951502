import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Avatar, IconButton, Chip, Tooltip, Typography } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { Box } from '@mui/system';
import { RemoveRedEye } from '@mui/icons-material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// ######################## Table column #######################
const columns = [
    { id: 's_n', label: 'S/N', minWidth: 1, align: 'center' },
    { id: 'name', label: 'Student name' },
    {
        id: 'reg_status',
        label: 'Reg status',
        align: 'center',
    },
    {
        id: 'status',
        label: 'On-training status',
        align: 'center',
    },
    {
        id: 'action',
        label: 'Action',
        align: 'center',
    },
];
function createData(s_n, name, reg_status, status, action) {
    return {
        s_n,
        name,
        reg_status,
        status,
        action,
    };
}

// ######################## USER CARD #######################
const UserCard = ({ applicant }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 3,
            position: 'relative',
            overflow: 'hidden',
        }}
    >
        <Avatar
            src={applicant.profile_picture}
            sx={{
                width: 65,
                height: 65,
                mr: 2,
                p: 0.5,
                border: `1px dotted ${colors.info}`,
                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                img: {
                    objectFit: 'cover',
                },
            }}
        />
        <Box>
            <Typography
                sx={{
                    fontSize: 16,
                    color: colors.info,
                    fontWeight: 'bold',
                }}
                noWrap
            >
                {`${applicant.first_name} ${
                    applicant?.middle_name ? applicant?.middle_name : ''
                } ${applicant.last_name}`}
            </Typography>
            <Typography
                sx={{
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center',
                    color: colors.info,
                    fontWeight: 'bold',
                    opacity: 0.8,
                }}
            >
                {applicant.reg_id}
            </Typography>
            <Typography
                sx={{
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center',
                    color: colors.primary,
                    fontWeight: 'bold',
                    opacity: 0.8,
                }}
            >
                {/* {applicant.section.section.name} */}
            </Typography>
        </Box>
    </Box>
);

// !###################### MAIN FUNC ########################
const PTApplicantTable4 = ({ applicants, height }) => {
    // ################# Comp State ###################
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // ################# FUNC ###############
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // ######################## TABLE ROW #######################
    const rows = applicants?.map((applicant, index) => {
        return createData(
            <Typography sx={{ fontSize: 18, fontWeight: 'bold', opacity: 0.6 }}>
                {++index}
            </Typography>,
            <UserCard applicant={applicant} />,
            <Chip
                label={applicant.regStatus}
                color={
                    applicant.regStatus === 'on-progress' ? 'info' : 'success'
                }
                size="small"
                sx={{
                    textTransform: 'capitalize',
                    fontSize: 12,
                    fontWeight: 'bold',
                    px: 1,
                    opacity: 0.8,
                }}
            />,
            <Chip
                size="small"
                sx={{
                    textTransform: 'capitalize',
                    fontSize: 12,
                    fontWeight: 'bold',
                    px: 1,
                    opacity: 0.8,
                }}
                color={
                    applicant?.currTraining.onTrainingStatus === 'pending'
                        ? 'warning'
                        : applicant?.currTraining.onTrainingStatus ===
                          'completed'
                        ? 'success'
                        : applicant?.currTraining.onTrainingStatus ===
                          'on-training'
                        ? 'info'
                        : 'secondary'
                }
                label={
                    isEmpty(applicant?.currTraining?.onTrainingStatus)
                        ? 'Not approved'
                        : applicant?.currTraining?.onTrainingStatus
                }
            />,
            //? #################### ACTION BUTTON #######################
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'end',
                    textAlign: 'center',
                }}
            >
                <Tooltip title="Student details" arrow>
                    <Link to={`/students/${applicant.id}`}>
                        <IconButton color="info" variant="outlined">
                            <RemoveRedEye sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Link>
                </Tooltip>
            </Box>,
        );
    });

    return (
        <React.Fragment>
            <Paper
                sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}
            >
                <TableContainer
                    sx={{
                        height: height ? height : '57.5vh',
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                            fontSize: 12,
                                            color: colors.primary,
                                            whiteSpace: 'nowrap',
                                            opacity: 0.8,
                                        }}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            sx={{
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                    >
                                                        {column.format &&
                                                        typeof value ===
                                                            'number'
                                                            ? column.format(
                                                                  value,
                                                              )
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[1, 2, 5, 10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                />
            </Paper>
        </React.Fragment>
    );
};

export default PTApplicantTable4;
