import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../../../assets/utils/constants';
import SmallCustomTextField from '../../../../components/Forms/SmallCustomTextField';
import { isEmpty } from 'lodash';
import CustomSubmitButton from '../../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import useRTK from '../../../../hooks/useRTK';
import { useDispatch } from 'react-redux';
import {
    useClosePracticalTraingMutation,
    usePublishPracticalTraingMutation,
} from '../../../../api/practicalTraining/practicalTrainingsAPI';
import { setPracticalTrainingDetails } from '../../../../states/features/practicalTraining/practicalTrainingsSlice';
import moment from 'moment';
import {
    useCloseTrainingSeasonMutation,
    useOpenTrainingSeasonMutation,
} from '../../../../api/students/trainingSeasonAPI';
import { setTrainingSeasonDetails } from '../../../../states/features/students/trainingSeasonSlice';

// * ################### VALIDATION SCHEMA ####################
const jobPublishValidationSchema = Yup.object({
    open_date: Yup.date()
        .min(
            moment(new Date()).format('YYYY-MM-DD'),
            `open date must be later than today`,
        )
        .required('Open date is a required field'),
    close_date: Yup.date()
        .min(Yup.ref('open_date'), `close date must be later than open date`)
        .required('close date is a required field'),
});

const OpenCloseTrainingSeason = ({ trainingSeason, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        openAction,
        {
            isLoading: openLoading,
            isSuccess: openSuccess,
            isError: openIsError,
            error: openError,
            data: openData,
        },
    ] = useOpenTrainingSeasonMutation();
    const [
        closeAction,
        {
            isLoading: closeLoading,
            isSuccess: closeSuccess,
            isError: closeIsError,
            error: closeError,
            data: closeData,
        },
    ] = useCloseTrainingSeasonMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        setTrainingSeasonDetails(data);
        handleDialogClose();
    };
    RTK.useRTKResponse(
        openSuccess,
        openIsError,
        openError,
        openData,
        rtkActions,
    );
    RTK.useRTKResponse(
        closeSuccess,
        closeIsError,
        closeError,
        closeData,
        rtkActions,
    );

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    season_id: trainingSeason.id,
                    open_date: isEmpty(trainingSeason)
                        ? ''
                        : trainingSeason.open_date,
                    close_date: isEmpty(trainingSeason)
                        ? ''
                        : trainingSeason.close_date,
                }}
                validationSchema={jobPublishValidationSchema}
                onSubmit={(payload) => {
                    if (trainingSeason.status === 'inactive') {
                        openAction(payload);
                    } else if (trainingSeason.status === 'opened') {
                        closeAction(payload);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        {/* ############## DATE ############ */}
                        <Grid container>
                            {trainingSeason.status === 'inactive' ? (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{ mb: 2 }}
                                            className="warning-message"
                                        >
                                            <Typography>
                                                Please verify application open
                                                and close date before open
                                                training season.
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    mb: 1,
                                                }}
                                            >
                                                Application date
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        mr: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            'Application start'
                                                        }
                                                        name={'open_date'}
                                                        type={'date'}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        ml: 1,
                                                    }}
                                                >
                                                    <SmallCustomTextField
                                                        label={
                                                            'Application deadline'
                                                        }
                                                        name={'close_date'}
                                                        type={'date'}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{ mb: 2 }}
                                            className="warning-message"
                                        >
                                            <Typography>
                                                <strong>NOTE : </strong>
                                                You'r about to close this
                                                training season which will
                                                prevent student from enroll for
                                                this training season.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<PublishOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={
                                        trainingSeason.status === 'opened'
                                            ? 'warning'
                                            : trainingSeason.status ===
                                              'inactive'
                                            ? 'success'
                                            : 'default'
                                    }
                                    variant={'contained'}
                                    loading={openLoading || closeLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    {trainingSeason.status === 'opened'
                                        ? 'Close training season'
                                        : trainingSeason.status === 'closed'
                                        ? 'default'
                                        : trainingSeason.status === 'inactive'
                                        ? 'Open training season'
                                        : ''}
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default OpenCloseTrainingSeason;
