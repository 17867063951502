import React from "react";
import PageLayout from "../layouts/PageLayout";
import StatusInfoBox from "../../components/infoBoxes/StatusInfoBox";
import { colors } from "../../assets/utils/constants";
import { Box, Grid } from "@mui/material";
import CustomCard2 from "../../components/cards/CustomCard2";
import { jobApplicationsSelector } from "../../states/features/selectors";
import { useSelector } from "react-redux";
import { size } from "lodash";
import { filter } from "lodash";
import NoContent from "../../components/NoContent";
import { isEmpty } from "lodash";
import ApplicantsSidebar from "../../components/sidebars/ApplicantsSidebar";
import JobApplicantTable from "../../components/Tables/JobApplicantTable";

const JobApplicants = () => {
    // ############ Redux state ##################
    const {
        companyJobApplications,
        individualJobApplications,
        groupJobApplications,
    } = useSelector(jobApplicationsSelector);
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
        ...groupJobApplications,
    ];

    // ############## FUNC ###################
    // => // => Job Applicants
    const getJobApplicantsByStatus = () => {
        let pendingApplicants = [];
        let savedApplicants = [];
        let shortlistedApplicants = [];
        let acceptedApplicants = [];
        let declinedApplicants = [];
        // => Pending Job applications
        pendingApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "pending"
        );
        // => Saved
        savedApplicants = filter(
            allJobApplications,
            ({ application }) => application.isSaved === 1
        );
        // => Shortlisted
        shortlistedApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "shortlisted"
        );
        // => Accepted
        acceptedApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "accepted"
        );
        // => Declined
        declinedApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "declined"
        );
        return {
            pendingApplicants,
            savedApplicants,
            shortlistedApplicants,
            acceptedApplicants,
            declinedApplicants,
        };
    };
    // => Job applicant
    const JobApplicants = getJobApplicantsByStatus();

    const infoBoxData2 = [
        {
            id: 1,
            label: "Pending app's",
            total: size(JobApplicants.pendingApplicants),
            url: `pending`,
            color: colors.warning,
        },
        {
            id: 2,
            label: "Saved app's",
            total: size(JobApplicants.savedApplicants),
            url: `saved`,
            color: colors.secondary,
        },
        {
            id: 3,
            label: "Shortlisted app's",
            total: size(JobApplicants.shortlistedApplicants),
            url: `shortlisted`,
            color: colors.info,
        },
        {
            id: 4,
            label: "Accepted app's",
            total: size(JobApplicants.acceptedApplicants),
            url: `accepted`,
            color: colors.success,
        },
        {
            id: 5,
            label: "Declined app's",
            total: size(JobApplicants.declinedApplicants),
            url: `declined`,
            color: "red",
        },
    ];

    return (
        <>
            <PageLayout
                head={"Job applications managements"}
                sidebar={<ApplicantsSidebar />}
            >
                <Box
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        mb: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Grid container spacing={2}>
                        {infoBoxData2.map((data) => (
                            <Grid item xs key={data.id}>
                                <StatusInfoBox data={data} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CustomCard2 head={"Accepted applications"} padding={0}>
                            {isEmpty(JobApplicants.acceptedApplicants) ? (
                                <>
                                    <NoContent
                                        height={"62vh"}
                                        message={`No accepted applications`}
                                    />
                                </>
                            ) : (
                                <JobApplicantTable
                                    height={"55.5vh"}
                                    applicants={
                                        JobApplicants.acceptedApplicants
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default JobApplicants;
