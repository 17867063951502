import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { regionList } from '../../assets/utils/regionList';
import { colors } from '../../assets/utils/constants';

const RegionFilterForm = () => {
    const [region, setRegion] = React.useState('');

    const handleChange = (event) => {
        setRegion(event.target.value);
    };

    return (
        <FormControl fullWidth size="small">
            <InputLabel sx={{ borderRadius: 1 }}>Filter by region</InputLabel>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={region}
                label="Regions"
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {regionList?.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                        {region.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default RegionFilterForm;
