import React, { useState } from 'react';
import { AddPhotoAlternate } from '@mui/icons-material';
import { Box, Grid, MenuItem } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../assets/utils/constants';
import CustomFileInput from '../../../components/Forms/CustomFileInput';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import CustomTextField from '../../../components/Forms/CustomTextField';
import { settingsSelector } from '../../../states/features/selectors';
import useRTK from '../../../hooks/useRTK';
import {
    useAddORGDocsMutation,
    useReUploadORGDocsMutation,
    useUpdateORGDocsMutation,
} from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import DocumentPreviewDialog from '../../../components/Dialogs/DocumentPreviewDialog';
import DocumentPreview from '../../../components/DocumentPreview';

// * -> docs validation schema
const docsUploadValidationSchema = Yup.object({
    verification_type_id: Yup.string().required(
        'Verification type is required field',
    ),
    verification_number: Yup.string().required(
        'Verification number is required field',
    ),
    file: Yup.mixed().required('Verification document is required'),
});

const UploadORGDocs = ({ orgDocs, closeDialogy, refetchProfile }) => {
    const [filePreview, setFilePreview] = useState('');
    const [openDocsDialog, setOpenDocsDialogy] = useState(false);

    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################### Redux state #################
    const { verificationTypes } = useSelector(settingsSelector);

    // ############### RTK #####################
    const [
        addORGDocs,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useAddORGDocsMutation();
    const [
        updateORGDocs,
        {
            isLoading: updateLoading,
            isSuccess: updateSuccess,
            isError: updateIsError,
            error: updateError,
            data: updateUpdatedData,
        },
    ] = useUpdateORGDocsMutation();
    const [
        reUploadORGDocs,
        {
            isLoading: reUploadLoading,
            isSuccess: reUploadSuccess,
            isError: reUploadIsError,
            error: reUploadError,
            data: reUploadData,
        },
    ] = useReUploadORGDocsMutation();
    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);
    RTK.useRTKResponse(
        updateSuccess,
        updateIsError,
        updateError,
        updateUpdatedData,
        rtkActions,
    );
    RTK.useRTKResponse(
        reUploadSuccess,
        reUploadIsError,
        reUploadError,
        reUploadData,
        rtkActions,
    );

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDocsDialogy(false);
    };

    // => Handle docs preview
    const handleDocsPreview = () => {
        setOpenDocsDialogy(true);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <DocumentPreviewDialog
                head={'Document preview'}
                openDialog={openDocsDialog}
                handleDialogClose={handleDialogClose}
            >
                <DocumentPreview document={filePreview} />
            </DocumentPreviewDialog>

            <Formik
                enableReinitialize
                initialValues={{
                    file: '',
                    verification_type_id: orgDocs
                        ? orgDocs.verification_type_id
                        : '',
                    verification_number: orgDocs
                        ? orgDocs.verification_number
                        : '',
                }}
                validationSchema={docsUploadValidationSchema}
                onSubmit={(payload) => {
                    const data = new FormData();
                    data.append(
                        'verification_type_id',
                        payload.verification_type_id,
                    );
                    data.append(
                        'verification_number',
                        payload.verification_number,
                    );
                    data.append('attachment', payload.file);
                    orgDocs.verification_status === 'pending'
                        ? updateORGDocs(data)
                        : orgDocs.verification_status === 'denied'
                        ? reUploadORGDocs(data)
                        : addORGDocs(data);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item sm={6}>
                                <CustomSelectInput
                                    label="Verification type"
                                    type="text"
                                    name="verification_type_id"
                                    color={colors.primary}
                                    formik={formik}
                                >
                                    {verificationTypes?.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </CustomSelectInput>
                            </Grid>
                            <Grid item sm={6}>
                                <CustomTextField
                                    className="form-input"
                                    label={`Verification number`}
                                    type="text"
                                    name="verification_number"
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <CustomFileInput
                                    name="file"
                                    type="file"
                                    label="Verification attachment"
                                    formik={formik}
                                    accept="application/pdf"
                                    setFilePreview={setFilePreview}
                                    filePreview={filePreview}
                                    handleDocsPreview={handleDocsPreview}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<AddPhotoAlternate />}
                                        onClick={formik.handleSubmit}
                                        loading={
                                            isLoading
                                                ? isLoading
                                                : updateLoading
                                                ? updateLoading
                                                : reUploadLoading
                                        }
                                    >
                                        {orgDocs.verification_status ===
                                        'pending'
                                            ? 'Update docs'
                                            : orgDocs.verification_status ===
                                              'denied'
                                            ? 'Re-upload docs'
                                            : 'Upload docs'}
                                    </CustomSubmitButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UploadORGDocs;
