import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import TransactionSidebar from '../../components/sidebars/TransactionSidebar';
import TransactionTable from '../../components/Tables/TransactionTable';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../states/features/selectors';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';
import { filter, isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge copy';
import NoContent from '../../components/NoContent';

const TotalAmountComp = ({ amount }) => (
    <Typography
        component={'span'}
        sx={{ fontSize: 18, color: colors.info, ml: 1.5 }}
    >
        {numFormater(amount)}
        <sup
            style={{
                color: colors.success,
                fontSize: 10,
                fontWeight: 'bold',
            }}
        >
            TZS
        </sup>
    </Typography>
);

const Transactions = () => {
    // ################# Redux state #################
    const { jobTransactions } = useSelector(transactionSelector);

    // ################ FUNC ##################
    const getPaymentByStatus = (status) => {
        let transactions = filter(
            jobTransactions,
            ({ transaction }) => transaction.status === status,
        );
        let totalAmount = 0;
        transactions.forEach((transaction) => {
            totalAmount += parseInt(transaction.transaction.amount);
        });
        return {
            transactions,
            total: size(transactions),
            amount: totalAmount,
        };
    };

    const infoBoxData = [
        {
            id: 1,
            title1: (
                <TotalAmountComp
                    amount={getPaymentByStatus('not_paid').amount}
                />
            ),
            title2: 'Pending paid',
            total: getPaymentByStatus('not_paid').total,
            colorSign: colors.warning,
            url: 'pending-paid',
        },
        {
            id: 2,
            title1: (
                <TotalAmountComp
                    amount={getPaymentByStatus('pending').amount}
                />
            ),
            title2: 'On-progress',
            total: getPaymentByStatus('pending').total,
            colorSign: colors.info,
            url: 'on-progress',
        },
        {
            id: 3,
            title1: (
                <TotalAmountComp
                    amount={getPaymentByStatus('success').amount}
                />
            ),
            title2: 'Successed',
            total: getPaymentByStatus('success').total,
            colorSign: colors.success,
            url: 'completed',
        },
        {
            id: 4,
            title1: (
                <TotalAmountComp
                    amount={getPaymentByStatus('failure').amount}
                />
            ),
            title2: 'Failed',
            total: getPaymentByStatus('failure').total,
            colorSign: 'red',
            url: 'failed',
        },
    ];

    return (
        <>
            <PageLayout
                head={'Job posting fee transactions histories'}
                sidebar={<TransactionSidebar />}
            >
                <Grid container spacing={2}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={3} key={data.id}>
                            <InfoboxWidget2
                                data={data}
                                colorSign={data.colorSign}
                            />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Completed transactions history'}
                            action={
                                <Box>
                                    <CountBadge
                                        total={size(
                                            getPaymentByStatus('success')
                                                .transactions,
                                        )}
                                        item={'Transactions'}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(
                                getPaymentByStatus('success').transactions,
                            ) ? (
                                <NoContent
                                    height={'54.5vh'}
                                    message={'No completed transaction!'}
                                />
                            ) : (
                                <TransactionTable
                                    transactions={
                                        getPaymentByStatus('success')
                                            .transactions
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Transactions;
