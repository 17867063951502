import React from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { colors } from '../../../assets/utils/constants';
import { ArrowRightAltOutlined, Publish } from '@mui/icons-material';
import { useRequestORGVerificationMutation } from '../../../api/profile/profileAPI';
import { setUserProfile } from '../../../states/features/profile/profileSlice';
import useRTK from '../../../hooks/useRTK';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';

const VerificationRequest = ({
    user,
    closeDialogy,
    refetchProfile,
    orgDocs,
    orgKeyPerson,
}) => {
    // ################# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### RTK #####################
    const [
        requestORGVerification,
        { isLoading, isSuccess, isError, error, data: updatedData },
    ] = useRequestORGVerificationMutation();

    // ############### useEffect ################
    // => RTK action
    const rtkActions = (data) => {
        closeDialogy();
        refetchProfile();
        dispatch(setUserProfile(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, updatedData, rtkActions);

    return (
        <>
            <Grid container sx={{ textAlign: 'center' }}>
                <Grid item sm={12}>
                    <Box className="warning-message">
                        <Typography
                            sx={{
                                m: 2,
                                fontSize: 16,
                                opacity: 0.8,
                                color: colors.primary,
                                textAlign: 'left',
                            }}
                        >
                            <strong style={{ color: 'red' }}>Note: </strong>
                            <br />
                            Please ensure that all the information you provide
                            is accurate and up-to-date. Submitting false
                            information may result in account to not be
                            verified. We take verification seriously and
                            appreciate your cooperation.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {user.org_logo &&
                    user.description &&
                    user.phone_number_verified_at &&
                    user.email_verified_at &&
                    !isEmpty(orgDocs) &&
                    orgDocs.verification_status !== 'denied' &&
                    !isEmpty(orgKeyPerson) &&
                    !isEmpty(orgKeyPerson.documents) &&
                    orgKeyPerson.documents?.verification_status !== 'denied' ? (
                        <>
                            <Box sx={{ p: 2, m: 2 }}>
                                <Grid container>
                                    <Grid item sm={12}>
                                        <CustomSubmitButton
                                            startIcon={<Publish />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor="success"
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                requestORGVerification();
                                            }}
                                            sx={{ py: 2, fontSize: 16 }}
                                        >
                                            Submit profile for verification
                                        </CustomSubmitButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Typography
                                className="error-message"
                                sx={{ p: 3, m: 3 }}
                            >
                                Please make sure you provide all required
                                informations in order to request for
                                verification.
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default VerificationRequest;
