import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Replay } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';

const DataFetchError = () => {
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    height: '91.2vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid container justifyContent={'center'}>
                    <Grid item sm={5}>
                        <Box
                            sx={{
                                height: 300,
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                borderRadius: 5,
                                py: 5,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: 55,
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    400
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: colors.red,
                                    }}
                                >
                                    Opps! Data fetching error
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        fontSize: 13,
                                        textAlign: 'center',
                                        p: 3,
                                    }}
                                >
                                    Sorry! application fail to fetch some datas.
                                    Try to refresh the page, If problem persist
                                    contact our support team.
                                </Typography>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<Replay />}
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        navigate(0);
                                    }}
                                >
                                    Try to refresh
                                </Button>
                                {/* <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    startIcon={<Groups />}
                                    sx={{
                                        my: 2,
                                        mx: 2,
                                        boxShadow: 'none',
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        navigate('/dashboard');
                                    }}
                                >
                                    Contact team
                                </Button> */}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default DataFetchError;
