import React from "react";
import {
    ArrowRightAltOutlined,
    BookmarkBorderOutlined,
    CreditScoreOutlined,
    DoneAll,
    Home,
    RemoveRedEye,
    WorkOutline,
} from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { colors } from "../../../assets/utils/constants";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";
import SmallCustomSelectInput from "../../../components/Forms/SmallCustomSelectInput";
import SmallCustomTextField from "../../../components/Forms/SmallCustomTextField";
import NextAndBackBtn from "../../../components/steppers/NextAndBackBtn";
import { useDispatch, useSelector } from "react-redux";
import {
    addClassficationPer,
    addEducationLevel,
    addEducationPer,
    addExperienceLevel,
    addExperiencePer,
    addIsFor,
    addJobPostingFee,
    addMaxApplicants,
    addORGCategory,
    addPositionPer,
    addPositions,
    resetJobPosting,
    resetQualificationAward,
    setSaveAndPay,
} from "../../../states/features/job/JobPostingSlice";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContentLoader from "../../../components/ContentLoader";
import { filter } from "lodash";
import CustomGroupSelectList from "../../../components/Forms/CustomGroupSelectList";
import {
    jobPostingSelector,
    profileSelector,
    settingsSelector,
} from "../../../states/features/selectors";
import useCurrency from "../../../hooks/useCurrency";
import { useCreateJobMutation } from "../../../api/job/jobAPI2";
import useRTK from "../../../hooks/useRTK";
import { addJob } from "../../../states/features/job/jobsSlice";

// * ################### VALIDATION SCHEMA ####################
const jobPostingValidationSchema = Yup.object({
    max_applicants: Yup.string(),
    positions: Yup.string()
        .required("Position is a required field")
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Positions must be a number"
        ),
    isFor: Yup.string().required("Classfication is a required field"),
    education_level_id: Yup.string().when("isFor", {
        is: "individual",
        then: Yup.string().required("education level is required"),
    }),
    experience_level_id: Yup.string().when("isFor", {
        is: "individual",
        then: Yup.string().required("experience level is required"),
    }),
    org_category_id: Yup.string().when("isFor", {
        is: "company",
        then: Yup.string().required("organization category is required"),
    }),
});

const JobPostingFee = ({ steps, activeStep, handleNext, handleBack }) => {
    // ############### CONST #######################
    const RTK = useRTK();
    const currency = useCurrency();
    const navigate = useNavigate();

    // ################# Comp state ##################
    const [dispatchType, setDispatchType] = useState("");
    const [jobStatus, setJobStatus] = useState("");
    const [isForIndividual, setIsForIndividual] = useState(false);
    const [isForCompany, setIsForCompany] = useState(false);
    const [jobDetails, setJobDetails] = useState({});

    // ########## Redux state #################
    const {
        qualificationAwards: activeQualificationAwards,
        educationLevels: activeEducationLevels,
        organizationCategories: activeORGCategoriesLevels,
        experienceLevels: activeExperienceLevels,
        pricingFramework,
    } = useSelector(settingsSelector);
    const { profile } = useSelector(profileSelector);

    // ############ Pricing Framework #################
    const baseAmount = pricingFramework.base_amount;
    const baseDiscount = pricingFramework.position_base_discount;
    const basePositionCost = pricingFramework.base_position_cost;
    const individualPer = pricingFramework.individual;
    const groupPer = pricingFramework.group;
    const companyPer = pricingFramework.company;

    // => Hnadle Posting fee calculation
    const handlePostingFeeCalculations = () => {
        let newJobPostingFee =
            baseAmount +
            classficationPer +
            positionPer +
            educationPer +
            experiencePer;
        dispatch(addJobPostingFee(newJobPostingFee));
    };

    // => handle educationLevel change
    const handleEducationLevelChange = (educationLevelId) => {
        let educationLevel = filter(activeEducationLevels, {
            id: educationLevelId,
        });
        let percentage = educationLevel[0].percentage;
        let newEducationPer = (baseAmount * percentage) / 100;
        dispatch(addEducationLevel(educationLevelId));
        dispatch(addEducationPer(newEducationPer));
    };

    // => handle experience change
    const handleExperienceChange = (experienceId) => {
        let educationLevel = filter(activeExperienceLevels, {
            id: experienceId,
        });
        let percentage = educationLevel[0].percentage;
        let newExperiencePer = (baseAmount * percentage) / 100;
        dispatch(addExperienceLevel(experienceId));
        dispatch(addExperiencePer(newExperiencePer));
    };

    // => handle experience change
    const handleORGCategoryChange = (category) => {
        dispatch(addORGCategory(category));
    };

    // => handle position change
    const handlePositionChange = (positions) => {
        let newPositionPer = 0;
        let calcPosition = positions;
        if (calcPosition <= 0) {
            calcPosition = 1;
        }
        newPositionPer =
            basePositionCost * calcPosition - baseDiscount * (calcPosition - 1);

        dispatch(addPositions(positions));
        dispatch(addPositionPer(newPositionPer));
    };
    // => handle position change
    const handleMaxApplicantChange = (max_applicant) => {
        dispatch(addMaxApplicants(max_applicant));
    };

    // => handle applicant classfication
    const handleApplicantClassficationCalc = (classfication) => {
        let newClassficationPer = 0;
        dispatch(addIsFor(classfication));
        if (classfication === "individual") {
            newClassficationPer = (baseAmount * individualPer) / 100;
            setIsForIndividual(true);
            setIsForCompany(false);
        } else {
            dispatch(addEducationPer(0));
            setIsForIndividual(false);
            dispatch(resetQualificationAward(""));
            if (classfication === "company") {
                newClassficationPer = (baseAmount * companyPer) / 100;
                setIsForCompany(true);
            } else {
                newClassficationPer = (baseAmount * groupPer) / 100;
                setIsForCompany(false);
            }
        }
        dispatch(addClassficationPer(newClassficationPer));
    };

    // ############ Redux state #################
    const dispatch = useDispatch();
    // => Posting job state
    const {
        jobPostingFee,
        title,
        description,
        location,
        region_id,
        district_id,
        salary,
        salary_scale,
        max_applicants,
        positions,
        open_date,
        close_date,
        isFor,
        org_category_id,
        other_category,
        job_category_id,
        job_type,
        jobResponsibilities,
        jobRequirements,
        education_level_id,
        experience_level_id,
        classficationPer,
        positionPer,
        educationPer,
        experiencePer,
        saveAndPay,
    } = useSelector(jobPostingSelector);

    // ############ COMPONENTS ####################
    // -> Education level
    const EducationLevel = ({ formik }) => (
        <CustomGroupSelectList
            label={"Education level"}
            name={"education_level_id"}
            formik={formik}
            color={colors.primary}
            handler={handleEducationLevelChange}
        >
            <option value="" />
            {activeQualificationAwards?.map((qualification) => (
                <optgroup
                    style={{
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                    }}
                    label={
                        qualification.award + ` (${qualification.abbreviation})`
                    }
                    key={qualification.id}
                >
                    {filter(activeEducationLevels, {
                        award_id: qualification.id,
                    }).map((level) => (
                        <option value={level.id} key={level.id}>
                            {level.qualification}
                        </option>
                    ))}
                </optgroup>
            ))}
        </CustomGroupSelectList>
    );
    // -> Experience level
    const ExperienceLevel = ({ formik }) => (
        <SmallCustomSelectInput
            label={"Experience"}
            name={"experience_level_id"}
            formik={formik}
            color={colors.primary}
            handler={handleExperienceChange}
        >
            {activeExperienceLevels?.map((experience) => (
                <MenuItem value={experience.id} key={experience.id}>
                    {experience.experience}
                </MenuItem>
            ))}
        </SmallCustomSelectInput>
    );
    // -> Organization categories
    const OrganizationCategories = ({ formik }) => (
        <SmallCustomSelectInput
            label={"Organization categories"}
            name={"org_category_id"}
            formik={formik}
            color={colors.primary}
            handler={handleORGCategoryChange}
        >
            {activeORGCategoriesLevels?.map((org) => (
                <MenuItem value={org.id} key={org.id}>
                    {org.name}
                </MenuItem>
            ))}
        </SmallCustomSelectInput>
    );
    // ############ UseEffects #################
    // => Check is for
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isFor === "individual") {
                setIsForIndividual(true);
            }
            if (isFor === "company") {
                setIsForCompany(true);
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isFor]);

    // => Check is for
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(setSaveAndPay(false));
        }
        return () => {
            isSubscribed = false;
        };
    }, [setSaveAndPay]);

    // => Calculate postin fee
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            handlePostingFeeCalculations();
        }
        return () => {
            isSubscribed = false;
        };
    }, [classficationPer, positionPer, educationPer, experiencePer]);

    // #################### RTK #####################
    const [
        createJob,
        { isLoading, isSuccess, isError, error, data: createdJob },
    ] = useCreateJobMutation();

    // => RTK action
    const rtkActions = (data) => {
        dispatch(addJob(data));
        setJobDetails(data);
        dispatch(resetJobPosting());
    };

    // => RTK Response
    RTK.useRTKResponse(isSuccess, isError, error, createdJob, rtkActions);

    // ############ SUCCESS HANDLER #################
    const handleJobDetails = () => {
        navigate(`/jobs/${jobDetails.id}`);
        dispatch(resetJobPosting());
    };
    const handleNewJob = () => {
        navigate(`/jobs/`);
        dispatch(resetJobPosting());
    };
    const handleBackToDashboard = () => {
        navigate(`/dashboard`);
        dispatch(resetJobPosting());
    };

    return (
        <>
            {isLoading ? (
                <ContentLoader height={500} />
            ) : isSuccess ? (
                <Box
                    sx={{
                        height: 500,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                    textAlign: "center",
                                    p: 4,
                                    borderRadius: 3,
                                }}
                            >
                                <Box>
                                    <DoneAll
                                        color="success"
                                        sx={{ fontSize: 55 }}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        fontWeight: "bold",
                                        color: colors.success,
                                    }}
                                >
                                    Job created successfully
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={10}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    mt: 5,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="app-btn"
                                    color="info"
                                    startIcon={<RemoveRedEye />}
                                    onClick={handleJobDetails}
                                >
                                    Job details
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="app-btn"
                                    color="secondary"
                                    startIcon={<WorkOutline />}
                                    onClick={handleNewJob}
                                >
                                    Job management
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="app-btn"
                                    color="primary"
                                    startIcon={<Home />}
                                    onClick={handleBackToDashboard}
                                >
                                    Back to dashboard
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Formik
                    enableReinitialize
                    initialValues={{
                        title,
                        description,
                        location,
                        region_id,
                        district_id,
                        salary,
                        salary_scale,
                        max_applicants,
                        positions,
                        open_date,
                        close_date,
                        isFor,
                        job_category_id,
                        job_type,
                        job_responsibilities: jobResponsibilities,
                        job_requirements: jobRequirements,
                        education_level_id,
                        experience_level_id,
                        org_category_id,
                        other_category,
                        amount: jobPostingFee,
                        // => Payment values
                        send_payment: saveAndPay,
                    }}
                    validationSchema={jobPostingValidationSchema}
                    onSubmit={(payload) => {
                        if (dispatchType === "save") {
                            dispatch(setSaveAndPay(false));
                            payload["status"] = jobStatus;
                            payload["is_free"] = profile.isFreeTrial;
                            createJob(payload);
                        } else if (dispatchType === "save and pay") {
                            dispatch(setSaveAndPay(true));
                            handleNext();
                        }
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Box
                                sx={{
                                    height: 470,
                                    position: "relative",
                                    overflow: "auto",
                                }}
                            >
                                <Grid container padding={2} spacing={2}>
                                    {/* ################ APPLICANT CONTROLER ################### */}
                                    <Grid item sm={8}>
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                // height: 150,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    mb: 1,
                                                }}
                                            >
                                                Job applicant control
                                            </Typography>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ py: 2 }}
                                            >
                                                <Grid item sm={6} xs={12}>
                                                    <SmallCustomSelectInput
                                                        label={"Classfication"}
                                                        name={"isFor"}
                                                        formik={formik}
                                                        color={colors.primary}
                                                        handler={
                                                            handleApplicantClassficationCalc
                                                        }
                                                    >
                                                        <MenuItem value="individual">
                                                            Individual
                                                        </MenuItem>
                                                        <MenuItem value="group">
                                                            Group
                                                        </MenuItem>
                                                        <MenuItem value="company">
                                                            Company
                                                        </MenuItem>
                                                    </SmallCustomSelectInput>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={6}
                                                    xs={12}
                                                    sx={{
                                                        display: isForCompany
                                                            ? "block"
                                                            : "none",
                                                    }}
                                                >
                                                    <OrganizationCategories
                                                        formik={formik}
                                                    />
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <SmallCustomTextField
                                                        label={"Max applicant"}
                                                        name={"max_applicants"}
                                                        type="number"
                                                        getInputValue={
                                                            handleMaxApplicantChange
                                                        }
                                                    />
                                                    <strong className="error-text">
                                                        ***optional***
                                                    </strong>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <SmallCustomTextField
                                                        name={"positions"}
                                                        type="text"
                                                        label={
                                                            "Number of position"
                                                        }
                                                        getInputValue={
                                                            handlePositionChange
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Box
                                            sx={{
                                                height: `100%`,
                                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: 4,
                                                position: "relative",
                                                boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: 15,
                                                    background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                                    position: "absolute",
                                                    top: 20,
                                                    bottom: 20,
                                                    left: 0,
                                                    borderTopRightRadius: 100,
                                                    borderBottomRightRadius: 100,
                                                }}
                                            ></Box>
                                            <Box
                                                sx={{
                                                    width: 15,
                                                    background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                                    position: "absolute",
                                                    top: 20,
                                                    bottom: 20,
                                                    right: 0,
                                                    borderTopLeftRadius: 100,
                                                    borderBottomLeftRadius: 100,
                                                }}
                                            ></Box>
                                            {profile.isFreeTrial === false ? (
                                                <Typography
                                                    sx={{
                                                        fontSize: 25,
                                                        fontWeight: "bold",
                                                        color: colors.success,
                                                    }}
                                                >
                                                    {currency.formatCurrency(
                                                        "TZS",
                                                        jobPostingFee
                                                    )}
                                                </Typography>
                                            ) : (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontSize: 22,
                                                            fontWeight: "bold",
                                                            color: colors.success,
                                                        }}
                                                    >
                                                        Free job posting
                                                    </Typography>

                                                    <Typography
                                                        sx={{
                                                            fontSize: 15,
                                                            fontWeight: "bold",
                                                            color: colors.info,
                                                        }}
                                                        className="info-message"
                                                    >
                                                        free trial active
                                                    </Typography>
                                                </>
                                            )}
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                Posting fee
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={12}
                                        sx={{
                                            display: isForIndividual
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                p: 1.5,
                                                borderRadius: 2,
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                    mb: 1,
                                                }}
                                            >
                                                Qualification framework
                                            </Typography>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ py: 2 }}
                                            >
                                                {/* ############### Education levels ############## */}
                                                <Grid item sm={6} xs={12}>
                                                    <EducationLevel
                                                        formik={formik}
                                                    />
                                                </Grid>
                                                {/* ############### Experience level ############## */}
                                                <Grid item sm={6} xs={12}>
                                                    <ExperienceLevel
                                                        formik={formik}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <Grid container padding={4} spacing={5}>
                                        <Grid item sm={6}>
                                            <CustomSubmitButton
                                                startIcon={
                                                    <BookmarkBorderOutlined />
                                                }
                                                endIcon={
                                                    <ArrowRightAltOutlined />
                                                }
                                                btnColor="info"
                                                loading={isLoading}
                                                onClick={() => {
                                                    let dispatchType = "save";
                                                    setDispatchType(
                                                        dispatchType
                                                    );
                                                    setJobStatus('draft');
                                                    dispatch(
                                                        setSaveAndPay(false)
                                                    );
                                                    formik.handleSubmit();
                                                }}
                                                sx={{ py: 2, fontSize: 16 }}
                                            >
                                                Save to draft
                                            </CustomSubmitButton>
                                        </Grid>
                                        {profile.isFreeTrial === false ? (
                                            <Grid item sm={6}>
                                                <CustomSubmitButton
                                                    startIcon={
                                                        <CreditScoreOutlined />
                                                    }
                                                    endIcon={
                                                        <ArrowRightAltOutlined />
                                                    }
                                                    btnColor="success"
                                                    onClick={() => {
                                                        let dispatchType =
                                                            "save and pay";
                                                        setDispatchType(
                                                            dispatchType
                                                        );
                                                        formik.handleSubmit();
                                                    }}
                                                    sx={{ py: 2, fontSize: 16 }}
                                                >
                                                    Pay And Publish
                                                </CustomSubmitButton>
                                            </Grid>
                                        ) : (
                                            <Grid item sm={6}>
                                                <CustomSubmitButton
                                                    startIcon={
                                                        <CreditScoreOutlined />
                                                    }
                                                    endIcon={
                                                        <ArrowRightAltOutlined />
                                                    }
                                                    btnColor="success"
                                                    onClick={() => {
                                                        let dispatchType =
                                                            "save";
                                                    setJobStatus("published");
                                                    setDispatchType(
                                                        dispatchType
                                                    );
                                                        formik.handleSubmit();
                                                    }}
                                                    sx={{ py: 2, fontSize: 16 }}
                                                >
                                                    Publish job
                                                </CustomSubmitButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </Box>
                            {/* ################ ACTION BUTONS ###################### */}
                            <NextAndBackBtn
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                action={handleNext}
                                noNext
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default JobPostingFee;
