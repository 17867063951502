import React from "react";
import ReportIcon from "@mui/icons-material/Report";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PageLayout from "../layouts/PageLayout";
import AppLayout from "../layouts/AppLayout";

// !###################### MAIN FUNC ########################
function ErrorPage400({ error, resetError }) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <AppLayout>
                <PageLayout noSidebar>
                    <Box
                        sx={{
                            height: "89vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            className="error-box"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                px: 10,
                            }}
                        >
                            <Box>
                                <Typography>
                                    <ReportIcon sx={{ fontSize: 55, mr: 2 }} />
                                </Typography>
                                <Typography
                                    sx={{
                                        mt: -1,
                                        fontSize: 22,
                                        fontWeight: "bold",
                                    }}
                                >
                                    400 something went wrong!
                                </Typography>
                            </Box>
                            <Box sx={{ p: 2 }}>
                                <Typography>
                                    Ops! Sorry fail to load page data!
                                </Typography>
                                <Button
                                    startIcon={<ArrowLeft />}
                                    variant="outlined"
                                    color="warning"
                                    size="small"
                                    sx={{ mt: 2 }}
                                    onClick={() => {
                                        resetError();
                                        navigate("/dashboard");
                                    }}
                                >
                                    Go to dashboard
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </PageLayout>
            </AppLayout>
        </React.Fragment>
    );
}

export default ErrorPage400;
