import React from 'react';
import { Grid } from '@mui/material';
import StudentsTable from '../../components/Tables/StudentsTable';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { useState } from 'react';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import AddStudent from './CRUD/AddStudent';
import ImportStudents from './CRUD/ImportStudents';
import InstituteProgramWidget from '../../components/widgets/InstituteProgramWidget';
import { useDispatch, useSelector } from 'react-redux';
import {
    studentsSelector,
    trainingSeasonSelector,
} from '../../states/features/selectors';
import { isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import StudentSidebar from '../../components/sidebars/StudentSidebar';
import useRTK from '../../hooks/useRTK';
import { setTrainingSeason } from '../../states/features/students/trainingSeasonSlice';
import { useGetTrainingSeasonsQuery } from '../../api/students/trainingSeasonAPI';
import CountBadge from '../../components/CountBadge';

const StudentsManagement = () => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Comp State ################
    const [crudType, setCRUDType] = useState('');
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // ############### Redux state ##############
    const { students } = useSelector(studentsSelector);
    const { trainingSeasons } = useSelector(trainingSeasonSelector);

    // ############### FUNC ################
    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };
    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    // ############# RTK ##################
    const {
        isLoading: currSeasonLoading,
        isSuccess,
        data: TSData,
    } = useGetTrainingSeasonsQuery();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setTrainingSeason(data));
    };
    RTK.useRTKFetch(isSuccess, TSData, rtkActions);

    const infoBoxData = [
        {
            id: 1,
            title1: 'Training seasons',
            title2: 'Management',
            total: size(trainingSeasons),
            url: 'training-managements',
        },
        {
            id: 2,
            title1: 'Students management',
            title2: 'Practical training',
            total: size(students),
            url: '/students/trainings',
        },
    ];

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
                noDropClose
            >
                {crudType === 'ImportStudents' ? (
                    <ImportStudents handleDialogClose={handleDialogClose} />
                ) : (
                    <AddStudent handleDialogClose={handleDialogClose} />
                )}
            </CustomDialog>

            <PageLayout head={'Students management'} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <Grid container spacing={1.5}>
                            <Grid item sm={4}>
                                <InstituteProgramWidget />
                            </Grid>
                            {infoBoxData.map((data) => (
                                <Grid item sm={4} key={data.id}>
                                    <InfoboxWidget2 data={data} />
                                </Grid>
                            ))}
                            <Grid item sm={12}>
                                <CustomCard2
                                    head={'Registered students'}
                                    padding={0}
                                    action={
                                        <CountBadge
                                            item={'Students'}
                                            total={size(students)}
                                        />
                                    }
                                >
                                    {!isEmpty(students) ? (
                                        <StudentsTable
                                            height={'52.5vh'}
                                            students={students}
                                        />
                                    ) : (
                                        <NoContent
                                            height={'59vh'}
                                            message={'No student added'}
                                        />
                                    )}
                                </CustomCard2>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={4}>
                        <StudentSidebar
                            handleDialogyOpen={handleDialogyOpen}
                            currSeasonLoading={currSeasonLoading}
                        />
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default StudentsManagement;
