// ############### General #################
// => Get all jobs
const GET_ALL_JOBS = "/jobs";

// => Get jobs for you organization
const GET_JOBS_FOR_YOU_ORG = "/jobs/organizations";

// => Get jobs for you employers
const GET_JOBS_FOR_YOU_EMPLOYERS = "/jobs/employers";

// => Get single job organization
const GET_SINGLE_JOB_ORGANIZATION = "/jobs/organizations";

// => Get single job employer
const GET_SINGLE_JOB_EMPLOYER = "/jobs/employers";

// => Create job
const CREATE_JOB = "/jobs";

// => Create job and pay
const CREATE_JOB_AND_PAY = "/jobs";

// => Publish job
const PUBLISH_JOB = "/jobs/publish";

// => Unpublish job
const UNPUBLISH_JOB = "/jobs/un-publish";

// => Close job
const CLOSE_JOB = "/jobs/close";

// => Open job
const OPEN_JOB = "/jobs/open";

// => Delete job
const DELETE_JOB = "/jobs";

// => Pay  job posting fee
const PAY_JOB_POSTING_FEE = "/jobs/job-fee";

// ############## Responsibilities ###############
// => Add
const ADD_RESPONSIBILITY = "/job-responsibilities";

// => Update
const UPDATE_RESPONSIBILITY = "/job-responsibilities";

// => Delete
const DELETE_RESPONSIBILITY = "/job-responsibilities";

// ############## Requirement ###############
// => Add
const ADD_REQUIREMENT = "/job-requirements";

// => Update
const UPDATE_REQUIREMENT = "/job-requirements";

// => Delete
const DELETE_REQUIREMENT = "/job-requirements";

// ############## Tags ###############
// => Add
const ADD_TAG = "/jobs/job-tags";

// => Delete
const DELETE_TAG = "/jobs/job-tags";

// ############# APPLICATION ############
// => Apply job org
const APPLY_ORG_JOB = "/jobs/applications/organizations";

// => Apply job employer
const APPLY_EMPLOYER_JOB = "/jobs/applications/employers";

export const jobEndpoints = {
    GET_ALL_JOBS,
    GET_JOBS_FOR_YOU_ORG,
    GET_JOBS_FOR_YOU_EMPLOYERS,
    GET_SINGLE_JOB_ORGANIZATION,
    GET_SINGLE_JOB_EMPLOYER,
    CREATE_JOB,
    PUBLISH_JOB,
    UNPUBLISH_JOB,
    CLOSE_JOB,
    OPEN_JOB,
    DELETE_JOB,
    CREATE_JOB_AND_PAY,
    ADD_RESPONSIBILITY,
    UPDATE_RESPONSIBILITY,
    DELETE_RESPONSIBILITY,
    ADD_REQUIREMENT,
    UPDATE_REQUIREMENT,
    DELETE_REQUIREMENT,
    PAY_JOB_POSTING_FEE,
    ADD_TAG,
    DELETE_TAG,
    APPLY_ORG_JOB,
    APPLY_EMPLOYER_JOB,
};
