import { createSlice } from "@reduxjs/toolkit";
import { removeArrObj, updateArrObj } from "../../../helpers/arrayHelpers";
import { isEmpty } from "lodash";

// -> initial state
const initialState = {
    jobs: [],
    jobsForYouORG: [],
    jobsForYouEmployer: [],
    jobDetails: {},
    employerJobDetails: {},
    checkPaymentStatus: 0,
    appliedORGJobs: [],
    appliedEmployerJobs: [],
};

// !############### SLICE ###############
const jobsSlice = createSlice({
    name: "jobSlice",
    initialState,
    reducers: {
        setJobs: (state, action) => {
            let jobs = action.payload;
            state.jobs = jobs;
        },
        setAppliedORGJobs: (state, action) => {
            let jobs = action.payload;
            state.appliedORGJobs = jobs;
        },
        setAppliedEmployerJobs: (state, action) => {
            let jobs = action.payload;
            state.appliedEmployerJobs = jobs;
        },
        setJobsForYouORG: (state, action) => {
            let jobs = action.payload;
            state.jobsForYouORG = jobs;
        },
        setJobsForYouEmployer: (state, action) => {
            let jobs = action.payload;
            state.jobsForYouEmployer = jobs;
        },
        updateJobForYouDetailsORG: (state, actions) => {
            let newJob = actions.payload;
            let jobsForYou = updateArrObj("id", newJob, state.jobsForYouORG);
            state.jobsForYouORG = jobsForYou;
        },
        updateJobForYouDetailsEmployer: (state, actions) => {
            let newJob = actions.payload;
            let jobsForYou = updateArrObj(
                "id",
                newJob,
                state.jobsForYouEmployer
            );
            state.jobsForYouEmployer = jobsForYou;
        },
        addJob: (state, action) => {
            let job = action.payload;
            isEmpty(state.jobs)
                ? (state.jobs = [job, ...[]])
                : (state.jobs = [job, ...state.jobs]);
        },
        addCheckPaymentStatus: (state, action) => {
            state.checkPaymentStatus = action.payload;
        },
        setJobDetails: (state, action) => {
            let details = action.payload;
            state.jobDetails = details;
        },
        setEmployerJobDetails: (state, action) => {
            let details = action.payload;
            state.employerJobDetails = details;
        },
        removeJob: (state, actions) => {
            let payload = actions.payload;
            let jobs = removeArrObj("id", payload, state.jobs);
            state.jobs = jobs;
        },
        resetJobStates: (state) => {
            state.jobs = [];
            state.jobsForYou = [];
            state.jobDetails = {};
            state.checkPaymentStatus = 0;
        },
    },
});

export const {
    setJobs,
    setJobsForYouORG,
    setJobsForYouEmployer,
    setAppliedORGJobs,
    setAppliedEmployerJobs,
    addJob,
    removeJob,
    setJobDetails,
    setEmployerJobDetails,
    updateJobForYouDetailsORG,
    updateJobForYouDetailsEmployer,
    addCheckPaymentStatus,
    resetJobStates,
} = jobsSlice.actions;
export default jobsSlice.reducer;
