import { Add, Leaderboard, RemoveRedEyeOutlined } from '@mui/icons-material';
import {
    Box,
    Button,
    Chip,
    Grid,
    List,
    styled,
    Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import StatsInfoBox from '../infoBoxes/StatsInfoBox';
import { useSelector } from 'react-redux';
import { PTSelector } from '../../states/features/selectors';
import { filter, isEmpty, size, take } from 'lodash';
import CustomCard2 from '../cards/CustomCard2';
import FieldApplicantWidget from '../widgets/FieldApplicantWidget';
import FieldApplicantWidget2 from '../widgets/FieldApplicantWidget2';
import NoContent from '../NoContent';

const CreateJobBtn = styled(Box)({
    'background': colors.gray,
    'borderRadius': 15,
    'overflow': 'hidden',
    'boxShadow': `1px 2px 5px 10px  ${colors.bgColor3}`,
    '.btn': {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        fontSize: 16,
        display: 'flex',
        flexDirection: 'column',
    },
});

const PracticalTrainingSidebar = () => {
    const {
        ORGPracticalTrainings,
        allPracticalTrainingApplicants,
    } = useSelector(PTSelector);

    // => Drafted
    const draftedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'draft',
    );
    // => Published
    const publishedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'published',
    );
    // => Closed
    const closedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'closed',
    );
    // => Suspended
    const suspendedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'suspended',
    );
    const ptStats = [
        {
            id: 1,
            title: 'Drafted',
            stats: `${size(draftedPTs)} Training`,
            borderColor: colors.danger,
        },
        {
            id: 2,
            title: 'Published',
            stats: `${size(publishedPTs)} Training`,
            borderColor: colors.success,
        },
        {
            id: 3,
            title: 'Closed',
            stats: `${size(closedPTs)} Training`,
            borderColor: colors.warning,
        },
        {
            id: 4,
            title: 'Suspended',
            stats: `${size(suspendedPTs)} Training`,
            borderColor: 'red',
        },
    ];
    return (
        <>
            <Grid container rowSpacing={2}>
                <Grid item sm={12}>
                    <CreateJobBtn>
                        <Link to={'/practical-trainings/create'}>
                            <Button
                                className="btn"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{
                                    'border': `1px solid ${colors.bgColor3}`,
                                    ':hover': {
                                        bgcolor: colors.secondary,
                                        opacity: 0.9,
                                        border: `1px solid ${colors.bgColor5}`,
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        opacity: 0.9,
                                    }}
                                >
                                    <Add sx={{ mr: 1 }} />
                                    Create
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 'bold', opacity: 0.9 }}
                                >
                                    practical training vacancy
                                </Typography>
                            </Button>
                        </Link>
                    </CreateJobBtn>
                </Grid>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1 }} color="info" />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Overviews
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {ptStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomCard2
                        height={'34vh'}
                        head="Latest applicants"
                        action={
                            <Chip
                                label={`${size(
                                    allPracticalTrainingApplicants,
                                )} Applicants`}
                                size="small"
                                color="info"
                                sx={{
                                    opacity: 0.8,
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    height: 18,
                                    px: 0.5,
                                }}
                            />
                        }
                    >
                        {isEmpty(allPracticalTrainingApplicants) ? (
                            <>
                                <NoContent
                                    height={200}
                                    message={'No practical training applicants'}
                                />
                            </>
                        ) : (
                            <Box
                                sx={{
                                    overflow: 'auto',
                                    position: 'relative',
                                }}
                            >
                                <List
                                    sx={{
                                        width: '100%',
                                        p: 0,
                                    }}
                                >
                                    {take(
                                        allPracticalTrainingApplicants,
                                        10,
                                    )?.map((applicant) => (
                                        <FieldApplicantWidget2
                                            key={applicant.id}
                                            applicant={applicant}
                                        />
                                    ))}
                                </List>
                                {size(allPracticalTrainingApplicants) > 5 && (
                                    <Link
                                        to={'/applicants/practical-trainings'}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            fullWidth
                                            size={'small'}
                                            sx={{ mt: 2 }}
                                            endIcon={<RemoveRedEyeOutlined />}
                                        >
                                            View all
                                        </Button>
                                    </Link>
                                )}
                            </Box>
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default PracticalTrainingSidebar;
