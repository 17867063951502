import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { authEndpoints } from './authEndpoints';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: orgBaseURL,
    }),
    tagTypes: ['Login'],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.USER_LOGIN}`,
                method: 'POST',
                body: payload,
            }),
            providesTags: ['Login'],
        }),
        register: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.REGISTER_USER}`,
                method: 'POST',
                body: payload,
            }),
        }),
        // => Request Email ver
        requestORGEmailVerification: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.REQUEST_EMAIL_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Request password reset Email
        requestPasswordResetEmail: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.REQUEST_PASSWORD_RESET_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Reset password
        resetPassword: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.RESET_PASSWORD}`,
                method: `PUT`,
                body: payload,
            }),
        }),
        // => Verify Email ver code
        verifyORGEmail: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.VERIFY_EMAIL_OTP}`,
                method: `POST`,
                body: payload,
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useRegisterMutation,
    useRequestORGEmailVerificationMutation,
    useVerifyORGEmailMutation,
    useResetPasswordMutation,
    useRequestPasswordResetEmailMutation,
} = authAPI;
