import React from "react";
import { Button, Grid } from "@mui/material";
import PracticalTrainingCard from "../components/DashComponents/PracticalTrainingCard";
import DashboardLayout from "./layouts/DashboardLayout";
import DashSidebar from "../components/DashComponents/DashSidebar";
import InfoboxWidget from "../components/widgets/InfoboxWidget";
import CustomCard2 from "../components/cards/CustomCard2";
import {
    jobsSelector,
    profileSelector,
    settingsSelector,
} from "../states/features/selectors";
import JobVacancyCard from "../components/DashComponents/JobVacancyCard";
import { useSelector } from "react-redux";
import { isEmpty, size } from "lodash";
import DashApplicants2 from "../components/DashComponents/DashApplicants2";
import DashJobForYou from "../components/DashComponents/DashJobForYou";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import FreeTrialWidget from "../components/widgets/FreeTrialWidget";
import FreeTrialWidgetOne from "../components/widgets/FreeTrialWidgetOne";

const CompanyDashbord = () => {
    // ############## Redux state ###############
    const { activeFreeTrials } = useSelector(settingsSelector);
    const { profile: user } = useSelector(profileSelector);
    const { jobs, jobsForYouORG, jobsForYouEmployer } =
        useSelector(jobsSelector);
    const jobsForYou = [...jobsForYouORG, ...jobsForYouEmployer];

    const org_type =
        user.organization_type.name === "Company"
            ? "company"
            : user.organization_type.name === "Institution"
            ? "institution"
            : "";

    // ############## USEEFFECT ######################
    return (
        <React.Fragment>
            <DashboardLayout sidebar={<DashSidebar />}>
                {/* ############## MAIN INFO BOXES ################ */}
                <Grid container spacing={4}>
                    {user.freeTrial !== false ? (
                        <>
                            {user.isFreeTrial === true && (
                                <Grid item sm={12}>
                                    <FreeTrialWidgetOne
                                        freeTrial={user?.freeTrial}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            {!isEmpty(activeFreeTrials) && (
                                <Grid item sm={12}>
                                    <FreeTrialWidget
                                        freeTrial={activeFreeTrials}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item sm={6}>
                        <JobVacancyCard jobs={jobs} />
                    </Grid>
                    <Grid item sm={6}>
                        <PracticalTrainingCard />
                    </Grid>
                </Grid>

                {/* ############## INFO BOXES ################ */}
                <Grid container spacing={3} mt={0}>
                    <Grid item sm={12}>
                        <CustomCard2 head="Quick action links">
                            <Grid container spacing={3}>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Active job vacancy"}
                                        urlName="Manage vacancy"
                                        url={"/jobs/status/published"}
                                        total={0}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Active practical training"}
                                        urlName="Manage training"
                                        url={`/practical-trainings/${org_type}/published`}
                                        total={0}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Jobs for you"}
                                        urlName="Active jobs for you"
                                        url={"/jobs/for-you"}
                                        total={size(jobsForYou)}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>

                {/* ############## APPLICANTS ################ */}
                <Grid container spacing={3} mt={0}>
                    <Grid item sm={6}>
                        <DashApplicants2 />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomCard2
                            height={"30vh"}
                            head={"Jobs for you"}
                            borderBottom
                            action={
                                <Link to={"/jobs/for-you"}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="info"
                                        endIcon={<ArrowCircleRightOutlined />}
                                    >
                                        View all
                                    </Button>
                                </Link>
                            }
                        >
                            <DashJobForYou />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </DashboardLayout>
        </React.Fragment>
    );
};

export default CompanyDashbord;
