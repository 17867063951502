import React from 'react';
import PageLayout from '../layouts/PageLayout';
import StatusInfoBox from '../../components/infoBoxes/StatusInfoBox';
import { colors } from '../../assets/utils/constants';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PTApplicantTable2 from '../../components/Tables/PTApplicantTable2';
import { PTSelector } from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import { filter } from 'lodash';
import NoContent from '../../components/NoContent';
import { isEmpty } from 'lodash';
import ApplicantsSidebar from '../../components/sidebars/ApplicantsSidebar';

const TrainingApplicants = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);

    // ############## FUNC ###################
    const getApplicantsByStatus = () => {
        let pendingApplicants = [];
        let approvedApplicants = [];
        let onTrainingApplicants = [];
        let completedApplicants = [];
        // => Pending
        pendingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) => appliedTraining.status === 'pending',
        );
        // => Approved
        approvedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.status === 'approved' &&
                appliedTraining.onTrainingStatus === 'pending',
        );
        // => OnTraining
        onTrainingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'on-training',
        );
        // => Completed
        completedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === 'completed',
        );
        return {
            pendingApplicants,
            approvedApplicants,
            onTrainingApplicants,
            completedApplicants,
        };
    };
    const infoBoxData2 = [
        {
            id: 1,
            label: 'Pending applications',
            total: size(getApplicantsByStatus().pendingApplicants),
            url: `pending`,
            color: colors.warning,
        },
        {
            id: 2,
            label: 'Approved applications',
            total: size(getApplicantsByStatus().approvedApplicants),
            url: `approved`,
            color: colors.secondary,
        },
        {
            id: 3,
            label: 'On-training students',
            total: size(getApplicantsByStatus().onTrainingApplicants),
            url: `on-training`,
            color: colors.info,
        },
        {
            id: 4,
            label: 'Completed students',
            total: size(getApplicantsByStatus().completedApplicants),
            url: `completed`,
            color: colors.success,
        },
    ];

    return (
        <>
            <PageLayout
                head={'Practical training applicants managements'}
                sidebar={<ApplicantsSidebar />}
            >
                <Box
                    sx={{
                        padding: 2,
                        borderRadius: 2,
                        mb: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    }}
                >
                    <Grid container spacing={2}>
                        {infoBoxData2.map((data) => (
                            <Grid item sm={3} xs={6} key={data.id}>
                                <StatusInfoBox data={data} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <CustomCard2 head={'On training students'} padding={0}>
                            {isEmpty(
                                getApplicantsByStatus().onTrainingApplicants,
                            ) ? (
                                <>
                                    <NoContent
                                        height={'58.5vh'}
                                        message={`No on-training students`}
                                    />
                                </>
                            ) : (
                                <PTApplicantTable2
                                    height={'55.5vh'}
                                    applicants={
                                        getApplicantsByStatus()
                                            .onTrainingApplicants
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default TrainingApplicants;
