import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import PageLayout from "../layouts/PageLayout";
import FreeTrialWidget from "../../components/widgets/FreeTrialWidget";

const FreeTrialPage = () => {
    return (
        <>
            <PageLayout head={"Free trial"} noSidebar>
                <Grid container spacing={4}>
                    <Grid item sm={12}>
                        <FreeTrialWidget />
                    </Grid>
                    <Grid item sm={6}>
                        <Box>
                            <Typography>Benefit</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box
                            sx={{ height: 200, width: 200, bgcolor: "red" }}
                        ></Box>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default FreeTrialPage;
