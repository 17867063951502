import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';

const JobPostingFeeCard = ({ postingFee, title }) => {
    return (
        <>
            <Box
                sx={{
                    height: `100%`,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    position: 'relative',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                }}
            >
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        left: 0,
                        borderTopRightRadius: 100,
                        borderBottomRightRadius: 100,
                    }}
                ></Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        right: 0,
                        borderTopLeftRadius: 100,
                        borderBottomLeftRadius: 100,
                    }}
                ></Box>
                <Typography
                    sx={{
                        fontSize: 25,
                        fontWeight: 'bold',
                        color: colors.success,
                    }}
                >
                    {numFormater(postingFee)}
                    <sup
                        style={{
                            fontSize: 16,
                        }}
                    >
                        TZS
                    </sup>
                </Typography>
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        opacity: .8
                    }}
                >
                    {title?title:'Posting fee'}
                </Typography>
            </Box>
        </>
    );
};

export default JobPostingFeeCard;
