import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import CreateJobStepper from '../../components/steppers/CreateJobStepper';
import JobPostingSidebar from '../../components/sidebars/JobPostingSidebar';
import { resetJobPosting } from '../../states/features/job/JobPostingSlice';
import useJobs from '../../hooks/useJobs';

const CreateJob = () => {
    // ############ CONST ################
    const jobHook = useJobs();
    const dispatch = useDispatch();

    // ############# RTK #################
    jobHook.useMetaDataDispatch();

    // ############# USEEFFECT ###########
    // -> Refresh page if data is empty
    useEffect(() => {
        return () => {
            dispatch(resetJobPosting());
        };
    }, [dispatch]);

    return (
        <>
            <PageLayout
                head={'Create new job vacancy'}
                sidebar={<JobPostingSidebar />}
            >
                <CustomCard2>
                    <CreateJobStepper />
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default CreateJob;
