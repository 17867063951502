import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
    user: {},
    pinId: "",
    authToken: "",
    isAuthenticated: false,
    authorizationType: "",
    emailVerifiedFor: "registration",
};

// !########## SLICE #############
const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            let user = action.payload.user;
            let token = action.payload.token;
            state.user = user;
            state.authToken = token;
            state.isAuthenticated = true;
            state.authorizationType = user?.organization_type?.name;
        },
        setUpdatedAuthUser: (state, action) => {
            let user = action.payload;
            state.user = user;
            cookies.set("user", user);
        },
        setPinId: (state, action) => {
            let pinId = action.payload.pinId;
            state.pinId = pinId;
        },
        setEmailVerifiedFor: (state, action) => {
            state.emailVerifiedFor = action.payload;
        },
        removeAuthUser: (state) => {
            state.user = {};
            state.authToken = "";
            state.isAuthenticated = false;
            state.authorizationType = "";
        },
        resetAuthStates: (state) => {
            state.user = {};
            state.pinId = "";
            state.authToken = "";
            state.isAuthenticated = false;
            state.authorizationType = "";
            state.emailVerifiedFor = "registration";
            cookies.remove("user");
            cookies.remove("token");
        },
    },
});

export const {
    setPinId,
    setAuthUser,
    removeAuthUser,
    setUpdatedAuthUser,
    setEmailVerifiedFor,
    resetAuthStates,
} = authSlice.actions;
export default authSlice.reducer;
