import { practicalTraingsAPI } from './practicalTrainingsAPI';
import { practicalTrainingsEndpoints } from './practicalTrainingsEndpoints';

export const practicalTrainingApplicantsAPI = practicalTraingsAPI.injectEndpoints(
    {
        endpoints: (builder) => ({
            // => Get all applicants
            getAllTrainingApplicants: builder.query({
                query: () => `${practicalTrainingsEndpoints.ALL_PT_APPLICANTS}`,
                providesTags: ['allPTApplicants'],
            }),

            // => Get single training applicants
            getSingleTrainingApplicants: builder.query({
                query: (payload) =>
                    `${practicalTrainingsEndpoints.SINGLE_PT_APPLICANTS}/${payload}`,
                providesTags: ['singlePTApplicants'],
            }),

            // => Approve application
            approveApplication: builder.mutation({
                query: (payload) => ({
                    url: `${practicalTrainingsEndpoints.APPROVE_APPLICATION}/${payload.student_id}/${payload.training_id}`,
                    method: 'PUT',
                }),
                invalidatesTags: (result, error) =>
                    error ? [] : ['allPTApplicants', 'singlePTApplicants'],
            }),

            // => Decline application
            declineApplication: builder.mutation({
                query: (payload) => ({
                    url: `${practicalTrainingsEndpoints.DECLINE_APPLICATION}/${payload.student_id}/${payload.training_id}`,
                    method: 'PUT',
                }),
                invalidatesTags: (result, error) =>
                    error ? [] : ['allPTApplicants', 'singlePTApplicants'],
            }),

            // => Approve student arrival
            approveStudentArrival: builder.mutation({
                query: (payload) => ({
                    url: `${practicalTrainingsEndpoints.APPROVE_STUDENT_ARRIVAL}/${payload.student_id}/${payload.training_id}`,
                    method: 'POST',
                    body: payload,
                }),
                invalidatesTags: (result, error) =>
                    error ? [] : ['allPTApplicants', 'singlePTApplicants'],
            }),

            // => Approve student complete
            approveStudentComplete: builder.mutation({
                query: (payload) => ({
                    url: `${practicalTrainingsEndpoints.APPROVE_STUDENT_COMPLETE}/${payload.student_id}/${payload.training_id}`,
                    method: 'POST',
                    body: payload,
                }),
                invalidatesTags: (result, error) =>
                    error ? [] : ['allPTApplicants', 'singlePTApplicants'],
            }),
        }),
    },
);

export const {
    useGetAllTrainingApplicantsQuery,
    useGetSingleTrainingApplicantsQuery,
    useApproveApplicationMutation,
    useDeclineApplicationMutation,
    useApproveStudentArrivalMutation,
    useApproveStudentCompleteMutation,

    // => Lazy
    useLazyGetAllTrainingApplicantsQuery,
    useLazyGetSingleTrainingApplicantsQuery,
} = practicalTrainingApplicantsAPI;
