import toast from 'react-hot-toast';

export const successNotify = (message) => {
    toast.success(message);
};

export const errorNotify = (message) => {
    toast.error(message);
};

export const userNotify = (message) => {
    toast(message, {
        icon: '👏',
        position: 'top-right',
    });
};
