import { createSlice } from '@reduxjs/toolkit';
import { updateArrObj } from '../../../helpers/arrayHelpers';

// -> initial state
const initialState = {
    singleJobApplications: [],
    companyJobApplications: [],
    individualJobApplications: [],
    groupJobApplications: [],
};

// !############### SLICE ###############
const jobApplicationsSlice = createSlice({
    name: 'jobSlice',
    initialState,
    reducers: {
        // => Single
        setSingleJobApplications: (state, action) => {
            let applications = action.payload;
            state.singleJobApplications = applications;
        },
        // => Companies
        setCompanyJobApplications: (state, action) => {
            let applications = action.payload;
            state.companyJobApplications = applications;
        },
        // => Individual
        setIndividualJobApplications: (state, action) => {
            let applications = action.payload;
            state.individualJobApplications = applications;
        },
        // => Groups
        setGroupsJobApplications: (state, action) => {
            let applications = action.payload;
            state.groupJobApplications = applications;
        },
        updateORGApplicantDetails: (state, actions) => {
            let newApplicant = actions.payload;
            // => Single
            let singleJobApplicants = updateArrObj(
                'id',
                newApplicant,
                state.singleJobApplications,
            );
            state.singleJobApplications = singleJobApplicants;

            // => All
            let orgApplicants = updateArrObj(
                'id',
                newApplicant,
                state.companyJobApplications,
            );
            state.companyJobApplications = orgApplicants;
        },
    },
});

export const {
    setSingleJobApplications,
    setCompanyJobApplications,
    setIndividualJobApplications,
    setGroupsJobApplications,
    updateORGApplicantDetails,
} = jobApplicationsSlice.actions;
export default jobApplicationsSlice.reducer;
