import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Box, Stack, Badge, Typography } from "@mui/material";
import { colors } from "../assets/utils/constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { filter, isEmpty, size } from "lodash";
import { useDate } from "../hooks/useDate";
import NotificationsBar from "./NotificationsBar";
import {
    jobsSelector,
    notificationsSelector,
    profileSelector,
} from "../states/features/selectors";
import { Window, WorkHistoryOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

const drawerWidth = 250;
// ->
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundColor: colors.primary,
}));

const TopbarRight = styled(Stack)({
    "& .icon": {
        color: colors.gray,
    },
});

// !###################### MAIN FUNC ############################
const Topbar = ({ mobileOpen, handleDrawerToggle }) => {
    const [openNotBar, setOpenNotBar] = React.useState(false);
    const navigate = useNavigate();

    const toggleNotDrawer = () => {
        setOpenNotBar(true);
    };

    const closeNotDrawer = () => {
        setOpenNotBar(false);
    };

    // -> date
    const date = useDate();

    // ############## Redux state ################
    const { profile: user } = useSelector(profileSelector);
    const { notifications } = useSelector(notificationsSelector);
    const { jobsForYouORG, jobsForYouEmployer } = useSelector(jobsSelector);
    const jobsForYou = [...jobsForYouORG, ...jobsForYouEmployer];

    // => Get remain dates
    const date1 = moment(moment().format("YYYY-MM-DD"));
    const date2 = moment(user?.freeTrial?.end_date);
    const daysDifference = date2.diff(date1, "days");

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: "none",
                    width: {
                        xs: "100%",
                        sm: true ? `calc(100% - ${drawerWidth}px)` : `95.8%`,
                    },
                }}
            >
                {/* ############### Notificationbar ###############*/}
                <NotificationsBar
                    openNotBar={openNotBar}
                    closeNotDrawer={closeNotDrawer}
                />

                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* ############### Left ################## */}
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { md: 18, xs: 15 },
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                color: colors.bgColor4,
                            }}
                        >
                            Welcome back <br />
                            <Typography
                                component="span"
                                sx={{
                                    color: colors.warning,
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }}
                            >
                                {user.org_short_name &&
                                    ` ${user.org_short_name}`}
                            </Typography>
                        </Typography>
                    </Box>

                    {/* ############### Center ################## */}
                    <Stack variant="div" style={{ display: "flex" }}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .1), ${colors.primary}) !important`,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 2,
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    fontWeight: "bold",
                                    color: colors.bgColor5,
                                }}
                            >
                                {date.time}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    color: colors.warning,
                                }}
                            >
                                {date.date}
                            </Typography>
                        </Box>
                    </Stack>

                    {/* ############### Right ################## */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {user?.freeTrial !== false && (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        borderRadius: 10,
                                        opacity: 0.9,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                                    }}
                                >
                                    <Box sx={{ p: 1, px: 2 }}>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                color: colors.warning,
                                                textAlign: "center",
                                            }}
                                        >
                                            {daysDifference > 0
                                                ? daysDifference
                                                : 0}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 1, pl: 0, pr: 2 }}>
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                color: colors.info,
                                            }}
                                        >
                                            Free trial
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                                fontWeight: "bold",
                                                textAlign: "left",
                                                color: colors.info,
                                            }}
                                        >
                                            Days Remaing
                                        </Typography>
                                    </Box>
                                </Box>
                            </>
                        )}
                        {user.is_verified === "verified" && (
                            <Stack
                                spacing={4}
                                direction="row"
                                sx={{
                                    position: "relative",
                                    p: 1,
                                    borderRadius: 2,
                                    overflow: "hidden",
                                    background: `linear-gradient(180deg, rgb(255, 255, 255, .2), ${colors.primary})`,
                                }}
                            >
                                <Badge
                                    color="secondary"
                                    badgeContent={size(
                                        // filter(
                                        //     jobsForYou,
                                        //     ({ isApplied }) =>
                                        //         isApplied === false
                                        // )
                                        jobsForYou
                                    )}
                                    showZero
                                    sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        "& .MuiBadge-badge": {
                                            background: `linear-gradient(180deg, ${colors.info}, ${colors.info})`,
                                            fontWeight: "bold",
                                            height: 15,
                                            color: colors.white,
                                        },
                                    }}
                                    onClick={() => {
                                        navigate("/jobs/for-you");
                                    }}
                                >
                                    <WorkHistoryOutlined className="icon" />
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            ml: 0.5,
                                        }}
                                    >
                                        Jobs for you
                                    </Typography>
                                </Badge>
                            </Stack>
                        )}
                        <Stack
                            spacing={4}
                            direction="row"
                            sx={{
                                position: "relative",
                                p: 1,
                                borderRadius: 2,
                                overflow: "hidden",
                                background: `linear-gradient(180deg, rgb(255, 255, 255, .2), ${colors.primary})`,
                            }}
                        >
                            <Badge
                                color="error"
                                badgeContent={size(
                                    filter(
                                        notifications,
                                        ({ isSeen }) => isSeen === 0
                                    )
                                )}
                                showZero
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    "& .MuiBadge-badge": {
                                        bgcolor: "red",
                                        fontWeight: "bold",
                                        height: 15,
                                    },
                                }}
                                onClick={() => toggleNotDrawer()}
                            >
                                <NotificationsActiveIcon className="icon" />
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        fontWeight: "bold",
                                        ml: 0.5,
                                    }}
                                >
                                    Notification
                                </Typography>
                            </Badge>
                        </Stack>
                        <Box>
                            {mobileOpen ? (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                    sx={{
                                        display: { xs: "block", sm: "none" },
                                        marginTop: 1.3,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                    edge="start"
                                    sx={{
                                        display: { xs: "block", sm: "none" },
                                        marginTop: 1.3,
                                    }}
                                >
                                    <Window />
                                </IconButton>
                            )}
                        </Box>
                        {/* <Stack sx={{ position: 'relative' }}>
                            <UserAvatar
                                className="dp"
                                alt={`${user.organization_name}`}
                                sx={{ cursor: 'pointer' }}
                                src={
                                    !isEmpty(user.org_logo) ? user.org_logo : ''
                                }
                                onClick={() => {
                                    handleProfileMenuToggle();
                                }}
                            />
                            <AppBarMenu
                                menuToggle={profileMenuToggle}
                                title="Manage Profile"
                                subTitle="Manage your profile"
                                actionBtn="Manage profile"
                                onClose={() => {
                                    handleProfileMenuToggle();
                                }}
                                action="/profile"
                            >
                                <ProfileCard user={user} />
                            </AppBarMenu>
                        </Stack> */}
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Topbar;
