import { Box, Grid } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import PageHeader from '../../components/PageHeader';

const PageLayout = ({ sidebar, children, head, noSidebar }) => {
    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3}) !important`,
                }}
            >
                {head && (
                    <Grid
                        item
                        sm={12}
                        xs={12}
                        sx={{ position: 'sticky', top: 48, zIndex: 999 }}
                    >
                        <PageHeader head={head} />
                    </Grid>
                )}
                <Grid item sm={12}>
                    <Grid container spacing={2} sx={{ px: 2, pb: 1 }}>
                        <Grid item sm={noSidebar ? 12 : 9}>
                            <Box>{children}</Box>
                        </Grid>
                        {noSidebar ? (
                            <></>
                        ) : (
                            <Grid item sm={3}>
                                <Box
                                    sx={{
                                        height: head ? `80vh` : `88vh`,
                                        position: 'sticky',
                                        top: 145,
                                        py: 1.5,
                                        p: 1.5,
                                        overflow: 'auto',
                                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2}) !important`,
                                        borderRadius: 4,
                                    }}
                                >
                                    {sidebar}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLayout;
