import { Send } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import CustomMultlineInput from '../../components/Forms/CustomMultlineInput';
import CustomTextField from '../../components/Forms/CustomTextField';
import * as Yup from 'yup';
import useRTK from '../../hooks/useRTK';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../states/features/selectors';
import { useSendUsEmailMutation } from '../../api/helpCenter/contactUsAPI';

// ############# sendEmailValidationSchema ###################
const sendEmailValidationSchema = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
});

const SendMessage = () => {
    // ############## CONST ##############
    const RTK = useRTK();

    // ############# Redux state ##########
    const { profile: user } = useSelector(profileSelector);

    // ############## RTK ##################
    const [
        sendMessage,
        { isLoading, isSuccess, isError, error, data: emailData },
    ] = useSendUsEmailMutation();
    // => Response
    RTK.useRTKResponse(isSuccess, isError, error, emailData);

    return (
        <>
            <Formik
                initialValues={{
                    name: user.organization_name,
                    email: user.email,
                    subject: '',
                    message: '',
                }}
                validationSchema={sendEmailValidationSchema}
                onSubmit={(payload, { resetForm }) => {
                    sendMessage(payload);
                    // if (isSuccess) {
                    resetForm();
                    // }
                }}
            >
                {(formik) => (
                    <Form>
                        <Box sx={{ mb: 2 }}>
                            <CustomTextField
                                name="subject"
                                className="form-input"
                                label={`Subject`}
                                type="text"
                            />
                        </Box>
                        <Box>
                            <CustomMultlineInput
                                name="message"
                                rows={3}
                                label={'Leave us a message'}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                py: 1,
                            }}
                        >
                            <CustomSubmitButton
                                startIcon={<Send />}
                                variant="contained"
                                className="app-btn"
                                loading={isLoading}
                                onClick={formik.handleSubmit}
                            >
                                Send
                            </CustomSubmitButton>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default SendMessage;
