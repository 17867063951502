import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { colors } from '../../assets/utils/constants';
import SpacingContent from '../../components/SpacingContent';
import logo from '../../assets/media/images/logo.png';

import { useDispatch, useSelector } from 'react-redux';
import {
    useGetOrganizationCategoriesQuery,
    useGetOrganizationTypesQuery,
} from '../../api/settings/settingAPI';
import {
    setOrganizationCategories,
    setOrganizationTypes,
    setRegisterAs,
} from '../../states/features/settings/settingsSlice';
import { getRTKResponseData } from '../../helpers/RTKHelpers';
import InnerContentLoader from '../../components/InnerContentLoader';
import { settingsSelector } from '../../states/features/selectors';

//* ################# CATEGORY CARD ###################
const CategoryCard = styled(Grid)(() => ({
    'background': colors.primary,
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'borderRadius': 5,
    'flexDirection': 'column',
    '& .button': {
        color: colors.secondary,
        flexDirection: 'column',
        height: 100,
        width: '100%',
    },
}));

//*################# GET STARTED BUTTON ###########
const GetStartedLink = styled(Button)(({ theme }) => ({
    'transition': 'all ease-in-out .3s',
    '.text': {
        fontSize: 18,
    },
    '.name': {
        fontSize: 22,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    '.icon': {
        position: 'absolute',
        right: 30,
        fontSize: 33,
        transition: 'all ease-in-out .3s',
    },
    '&:hover': {
        '.icon': {
            right: 20,
        },
    },
    [theme.breakpoints.down('md')]: {
        'background': colors.secondary,
        '.text': {
            fontSize: 14,
            color: colors.primary,
        },
        '.name': {
            fontSize: 14,
            color: colors.primary,
        },
        '.icon': {
            transition: 'all ease-in-out .3s',
            color: colors.primary,
            display: 'none',
        },
    },
}));

//! ############### MAIN FUNCTION ###################
const RegisterAs = () => {
    // ################## CONST ###################
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // ############## Redux status ############
    const { organizationTypes } = useSelector(settingsSelector);

    // ############# RTK ##################
    const {
        isLoading: typesLoading,
        isSuccess: typesSuccess,
        data: typesData,
    } = useGetOrganizationTypesQuery();
    const {
        isLoading: categoriesLoading,
        isSuccess: categoriesSuccess,
        data: categoriesData,
    } = useGetOrganizationCategoriesQuery();

    // ############### Dispach Main Data ###############
    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (typesSuccess) {
                dispatch(setOrganizationTypes(getRTKResponseData(typesData)));
            }
            if (categoriesSuccess) {
                dispatch(
                    setOrganizationCategories(
                        getRTKResponseData(categoriesData),
                    ),
                );
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [typesSuccess, typesData, categoriesSuccess, categoriesData, dispatch]);
    return (
        <React.Fragment>
            <Box
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                {typesLoading || categoriesLoading ? (
                    <InnerContentLoader />
                ) : (
                    <SpacingContent maxPadding={2}>
                        <Grid
                            container
                            justifyContent={'center'}
                            sx={{ mb: 5, display: { md: 'none' } }}
                        >
                            <Grid item sm={2} xs={4}>
                                <img
                                    src={logo}
                                    alt=""
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Typography
                                    style={{
                                        fontSize: 50,
                                        textAlign: 'center',
                                        color: colors.secondary,
                                    }}
                                >
                                    Wazawa Max
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent={'space-around'}
                            spacing={2}
                        >
                            {organizationTypes?.map((type, index) => (
                                <CategoryCard item sm={5} xs={6} key={index}>
                                    <GetStartedLink
                                        className="button"
                                        onClick={() => {
                                            dispatch(setRegisterAs(type));
                                            navigate('/email-verification');
                                        }}
                                    >
                                        <Typography className="text">
                                            Get started as
                                        </Typography>
                                        <Typography className="name">
                                            {type.name}
                                        </Typography>
                                        <ArrowRightAltIcon className="icon" />
                                    </GetStartedLink>
                                </CategoryCard>
                            ))}

                            {/* ######### SIGN UP ######### */}
                            <Grid
                                item
                                sm={10}
                                md={10}
                                xs={10}
                                sx={{
                                    mt: { md: 10, xs: 5 },
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                    }}
                                >
                                    OR
                                </Typography>
                                <Link to={'/'}>
                                    <Typography
                                        marginTop={2}
                                        sx={{
                                            color: {
                                                md: colors.primary,
                                                xs: colors.secondary,
                                            },
                                        }}
                                    >
                                        Already have an acount?{' '}
                                        <strong>login now....</strong>
                                    </Typography>
                                </Link>
                            </Grid>
                            {/* <Grid
                                item
                                sm={10}
                                md={10}
                                xs={10}
                                sx={{
                                    mt: { md: 10, xs: 5 },
                                    textAlign: 'center',
                                }}
                            >
                                <Link to={'/'}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<ArrowCircleLeftOutlined />}
                                        sx={{
                                            color: {
                                                md: colors.primary,
                                                xs: colors.secondary,
                                            },
                                        }}
                                    >
                                        Back to home
                                    </Button>
                                </Link>
                            </Grid> */}
                        </Grid>
                    </SpacingContent>
                )}
            </Box>
        </React.Fragment>
    );
};

export default RegisterAs;
