import React from 'react';
import { PersonAdd } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { colors } from '../../../assets/utils/constants';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import { instituteProgramsSelector } from '../../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import CustomMonoIdAutocomplete from '../../../components/Forms/CustomMonoIdAutocomplete';
import {
    useCreateStudentMutation,
    useUpdateStudentMutation,
} from '../../../api/students/studentsAPI';
import {
    addStudent,
    setStudentDetails,
} from '../../../states/features/students/studentsSlice';
import useRTK from '../../../hooks/useRTK';

// * -> Validation
const addStudentValidationSchema = Yup.object({
    first_name: Yup.string().required('First name is required field'),
    last_name: Yup.string().required('Last name is required field'),
    reg_id: Yup.string().required('Registration No. is required field'),
    section_id: Yup.string().required('Occupation is required field'),
});

const UpdateStudent = ({ student, handleDialogClose }) => {
    // ############## CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Redux state ####################
    const { sections } = useSelector(instituteProgramsSelector);

    /**########## FUNC ############### */
    // => Restructure section obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                title: obj.section.name,
                label: obj.section.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    const availableSections = restructureArray(sections);

    // ############# RTK ##################
    const [
        updateStudent,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useUpdateStudentMutation(student.id);

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setStudentDetails(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    return (
        <>
            <Formik
                initialValues={{
                    student_id: student.id,
                    first_name: student ? student.first_name : '',
                    last_name: student ? student.last_name : '',
                    reg_id: student ? student.reg_id : '',
                    section_id: student ? student.section_id : '',
                }}
                validationSchema={addStudentValidationSchema}
                onSubmit={(payload) => {
                    updateStudent(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`First name`}
                                            type="text"
                                            name="first_name"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`Last name`}
                                            type="text"
                                            name="last_name"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`Student reg. number`}
                                            type="text"
                                            name="reg_id"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CustomMonoIdAutocomplete
                                            label="Program | Occupation | Section"
                                            name={'section_id'}
                                            formik={formik}
                                            options={availableSections}
                                            colors={colors.primary}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                startIcon={<PersonAdd />}
                                                onClick={formik.handleSubmit}
                                            >
                                                Update student
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateStudent;
