import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { differenceWith, isEmpty } from 'lodash';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { ArrowRightAltOutlined, PublishOutlined } from '@mui/icons-material';
import useRTK from '../../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { useAddSectionMutation } from '../../../api/students/instituteProgramsAPI';
import { setSections } from '../../../states/features/students/instituteProgramsSlice.js';
import CustomAutocomplete from '../../../components/Forms/CustomAutocomplete';
import { settingsSelector } from '../../../states/features/selectors';
import CustomCard2 from '../../../components/cards/CustomCard2';

// * ################### VALIDATION SCHEMA ####################
const sectionsValidationSchema = Yup.object({
    sections: Yup.array().required('Please select atleast one sections'),
});

const AddSections = ({
    addedSections,
    addedDepartments,
    handleDialogClose,
}) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp State #############
    const [sectionIds, setSectionsIds] = useState('');

    // ############# Redux state ################
    const { activeSections } = useSelector(settingsSelector);

    // ############# RTK ##################
    const [
        addSectionAction,
        { isLoading, isSuccess, isError, error, data: sectorData },
    ] = useAddSectionMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setSections(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, sectorData, rtkActions);

    // ########## FUNC ###############
    // => Restructure sector obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                title: obj.name,
                department_id: obj.department_id,
                label: obj.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };

    // => Get available sections
    const instituteDepartmentsSections = () => {
        let availableSections = [];
        if (!isEmpty(addedDepartments)) {
            availableSections = activeSections.filter((section) => {
                return addedDepartments.find(
                    (deparment) =>
                        section.department_id === deparment.department_id,
                );
            });
        }
        return availableSections;
    };
    // => Get unadded sections
    const getPendingAddedSections = () => {
        let pendingSections = instituteDepartmentsSections();
        if (!isEmpty(addedSections)) {
            pendingSections = differenceWith(
                pendingSections,
                addedSections,
                (a, b) => a.id === b.section_id,
            );
        }
        return pendingSections;
    };
    const availableSections = restructureArray(getPendingAddedSections());

    const handleSelectedValues = (payload) => {
        isEmpty(payload) ? setSectionsIds('') : setSectionsIds(payload);
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    sections: sectionIds,
                }}
                validationSchema={sectionsValidationSchema}
                onSubmit={(payload) => {
                    addSectionAction(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <CustomCard2 height={'50vh'}>
                            <Grid container>
                                <Grid item sm={12} xs={12}>
                                    <Box
                                        sx={{ mb: 2 }}
                                        className="warning-message"
                                    >
                                        <Typography>
                                            <strong>NOTE : </strong>
                                            Select sections which available on
                                            your institute.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <CustomAutocomplete
                                        label={'Select sections'}
                                        placeholder={'Sections'}
                                        name="sections"
                                        options={availableSections}
                                        getSelectedValues={handleSelectedValues}
                                        defaultOption={undefined}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography
                                        className="error-message"
                                        sx={{ mt: 3 }}
                                    >
                                        <strong>Note: </strong>
                                        Available sections | programs | courses
                                        based on added institute departments.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CustomCard2>
                        <Grid container>
                            <Grid item sm={12}>
                                <CustomSubmitButton
                                    startIcon={<PublishOutlined />}
                                    endIcon={<ArrowRightAltOutlined />}
                                    btnColor={'info'}
                                    variant={'contained'}
                                    loading={isLoading}
                                    onClick={() => {
                                        formik.handleSubmit();
                                    }}
                                    sx={{ py: 2, fontSize: 16 }}
                                >
                                    Add section(s)
                                </CustomSubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddSections;
