import { Box, Grid } from '@mui/material';
import React from 'react';
import StatusInfoBox from '../../../components/infoBoxes/StatusInfoBox';
import { useSelector } from 'react-redux';
import {
    PTSelector,
    profileSelector,
} from '../../../states/features/selectors';
import { filter, size } from 'lodash';
import { colors } from '../../../assets/utils/constants';

const PTStatusInfobox = () => {
    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ############## CONST ######################
    const userType =
        user?.organization_type?.name === 'Institution'
            ? 'institution'
            : 'company';

    // => Drafted
    const draftedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'draft',
    );
    // => Published
    const publishedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'published',
    );
    // => Closed
    const closedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'closed',
    );
    // => Suspended
    const suspendedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'suspended',
    );

    const infoBoxData2 = [
        {
            id: 1,
            label: 'Drafted',
            total: size(draftedPTs),
            url:
                userType === 'company'
                    ? `${userType}/drafted`
                    : `/practical-trainings/organization/drafted`,
            color: colors.warning,
        },
        {
            id: 2,
            label: 'Published',
            total: size(publishedPTs),
            url:
                userType === 'company'
                    ? `${userType}/published`
                    : `/practical-trainings/organization/published`,
            color: colors.success,
        },
        {
            id: 3,
            label: 'Closed',
            total: size(closedPTs),
            url:
                userType === 'company'
                    ? `${userType}/closed`
                    : `/practical-trainings/organization/closed`,
            color: colors.danger,
        },
        {
            id: 4,
            label: 'Suspended',
            total: size(suspendedPTs),
            url:
                userType === 'company'
                    ? `${userType}/suspended`
                    : `/practical-trainings/organization/suspended`,
            color: 'red',
        },
    ];

    return (
        <>
            <Box
                sx={{
                    padding: 2,
                    borderRadius: 2,
                    mb: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Grid container spacing={2}>
                    {infoBoxData2.map((data) => (
                        <Grid item sm={3} xs={6} key={data.id}>
                            <StatusInfoBox data={data} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default PTStatusInfobox;
