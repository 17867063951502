import React from 'react';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { colors } from '../../assets/utils/constants';
import CustomCard from '../../components/cards/CustomCard';
import { Article, Edit, ManageAccounts } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogs/CustomDialog';
import { useState } from 'react';
import UpdateORGDescription from "./CRUD's/UpdateORGDescription";

const ButtonCon = styled(Box)({
    '.btn': {
        marginTop: 15,
        fontSize: 12,
        paddingTop: 5,
        paddingBottom: 5,
    },
});

const ORGDescription = ({ user, refetchProfile }) => {
    const [openDialog, setOpenDialogy] = useState(false);
    // -> Dialogy open
    const handleDialogyOpen = () => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={'Update organization description'}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <UpdateORGDescription
                    user={user}
                    refetchProfile={refetchProfile}
                    closeDialogy={handleDialogClose}
                />
            </CustomDialog>
            <Grid item sm={12} xs={12} sx={{ p: 2 }}>
                <CustomCard
                    head={'Organization description'}
                    bodyPadding={3}
                    action={
                        <Button
                            sx={{
                                'border': `1px solid ${colors.primary}`,
                                'fontSize': 9,
                                'paddingTop': 0.2,
                                'paddingBottom': 0.2,
                                'paddingRight': 0,
                                'paddingLeft': 0,
                                '& .icon': {
                                    fontSize: 16,
                                    color: colors.primary,
                                },
                            }}
                            onClick={() => handleDialogyOpen()}
                        >
                            <Edit className="icon" />
                        </Button>
                    }
                >
                    <Grid container justifyContent={'center'}>
                        <Grid
                            item
                            sm={12}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            {isEmpty(user.description) ? (
                                <>
                                    <Typography>
                                        <Article
                                            sx={{
                                                fontSize: 44,
                                            }}
                                        />
                                    </Typography>
                                    <Typography>
                                        Provide a short description of your{' '}
                                        {user.org_type} so as people can get an
                                        insite on who you are.
                                    </Typography>
                                    <ButtonCon>
                                        <Button
                                            className="btn"
                                            variant="contained"
                                            startIcon={
                                                <ManageAccounts className="icon" />
                                            }
                                            onClick={() => handleDialogyOpen()}
                                        >
                                            Update {user.org_type} description
                                        </Button>
                                    </ButtonCon>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        sx={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        {user.description}
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CustomCard>
            </Grid>
        </>
    );
};

export default ORGDescription;
