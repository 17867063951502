import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomDialog from '../Dialogs/CustomDialog';
import ORGApplicantDetails from '../../pages/jobs/components/ORGApplicantDetails';

const JobApplicantWidget2 = ({ applicant }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############# Dialog ################ */}
            <CustomDialog
                head={'Job applicant details'}
                openDialog={openDialog}
                customWidth={'md'}
                handleDialogClose={handleDialogClose}
            >
                <ORGApplicantDetails
                    applicant={applicant}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>
            <Button
                sx={{ width: '100%', p: 0 }}
                onClick={() => {
                    handleDialogOpen();
                }}
            >
                <ListItem
                    alignItems="flex-start"
                    sx={{
                        'transition': '.3s all ease-in-out',
                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        'position': 'relative',
                        'mb': 0.5,
                        'p': 0,
                        'pl': 0.5,
                        'borderRadius': 3,
                        'transition': '.3s all ease-in-out',
                        'border': `1px solid ${colors.bgColor3}`,
                        ':hover': {
                            transition: '.3s all ease-in-out',
                            border: `1px solid ${colors.bgColor5}`,
                        },
                    }}
                >
                    <ListItemAvatar>
                        <Avatar
                            alt="Remy Sharp"
                            src={applicant.org_logo}
                            sx={{
                                height: 60,
                                width: 60,
                                mt: -0.5,
                                mr: 1,
                                border: `3px solid ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                img: {
                                    objectFit: 'contain',
                                },
                            }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    color: colors.info,
                                }}
                            >
                                {`${applicant.organization_name}`}
                            </Typography>
                        }
                        secondary={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                    }}
                                    component="span"
                                >
                                    {applicant.organization_category.name} - Org
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        color: colors.info,
                                    }}
                                    component="span"
                                >
                                    {applicant?.location}
                                </Typography>
                            </Box>
                        }
                    />
                </ListItem>
            </Button>
        </>
    );
};

export default JobApplicantWidget2;
