import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import CustomPasswordField from '../../components/Forms/CustomPasswordField';
import CustomSelectInput2 from '../../components/Forms/CustomSelectInput2';
import CustomTextField from '../../components/Forms/CustomTextField';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from '../../states/features/selectors';
import { isEmpty } from 'lodash';
import { useRegisterMutation } from '../../api/auth/authAPI';
import { errorNotify, successNotify } from '../../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../../helpers/RTKHelpers';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import useAuth from '../../hooks/useAuth';
import { setAuthUser } from '../../states/features/auth/authSlice';
import { setAuthUserProfile } from '../../states/features/profile/profileSlice';
import { ArrowRightAltOutlined, PersonAddAlt1Outlined } from '@mui/icons-material';

//* ################# FORM BOX ###################
const FormBox = styled(Box)(({ theme }) => ({
    'backgroundColor': colors.primary,
    'zIndex': 999,
    'borderTopLeftRadius': 50,
    'borderBottomRightRadius': 50,
    'padding': '25px',
    '& .head': {
        fontSize: 45,
        textAlign: 'center',
        marginBottom: 0,
        color: colors.secondary,
    },
    '& .sub-head': {
        fontSize: 18,
        textAlign: 'center',
        marginTop: -5,
        color: colors.gray,
    },
    '& .form-input': {
        '& label': {
            color: `${colors.secondary}!important`,
        },
        '& input': {
            color: `${colors.gray}!important`,
            paddingLeft: 16,
        },
        '& .icon': {
            color: `${colors.secondary}!important`,
        },
    },
    '& .sigin-btn': {
        'backgroundColor': colors.secondary,
        'color': colors.primary,
        'paddingLeft': 120,
        'paddingRight': 120,
        'paddingTop': 10,
        'paddingBottom': 10,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            backgroundColor: colors.secondary,
            opacity: 0.8,
            transition: 'all ease-in-out .2s',
        },
    },
    '& .sign_in': {
        'color': colors.gray,
        'transition': 'all ease-in-out .2s',
        '&:hover': {
            color: colors.secondary,
            transition: 'all ease-in-out .2s',
        },
    },
    [theme.breakpoints.down('md')]: {
        '& .head': {
            fontSize: 25,
        },
        '& .sub-head': {
            fontSize: 18,
        },
    },
}));
//* ############# VALIDATION SCHEMA ###############
const validationSchema = Yup.object({
    organization_name: Yup.string().required(
        'Organization name is required field',
    ),
    category_id: Yup.string().required(),
    location: Yup.string().required(),
    email: Yup.string().required().email(),
    password: Yup.string().required().min(8),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('confirm password is required field')
        .min(8, 'Password must be at least 8 characters'),
});

//! ############### MAIN FUNCTION ###################
const Register = () => {
    // ############## CONST ############
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useAuth();

    // ############## Redux status ############
    const { registerAs, organizationCategories, ORGEmail } = useSelector(
        settingsSelector,
    );
    const name = registerAs.name;

    // ############# RTK ####################
    const [
        registerUser,
        { isLoading, isSuccess, isError, error, data: registerData },
    ] = useRegisterMutation();

    // ############ useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                setTimeout(() => {
                    successNotify(getRTKSuccessMessage(registerData));
                }, 1000);
                dispatch(setAuthUser(getRTKResponseData(registerData)));
                dispatch(setAuthUserProfile(getRTKResponseData(registerData)));
                auth.saveAuthenticatedUser(getRTKResponseData(registerData));
            }
            if (isError) {
                errorNotify(getRTKErrorMessage(error));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, error, registerData, dispatch]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isEmpty(registerAs) || isEmpty(ORGEmail)) {
                navigate('/register-as');
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [registerAs, ORGEmail, navigate]);

    return (
        <React.Fragment>
            <Grid
                container
                sx={{
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Grid item sm={10} xs={12}>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            organization_name: '',
                            category_id: '',
                            location: '',
                            email: ORGEmail,
                            password: '',
                            confirm_password: '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(payload) => {
                            payload = {
                                organization_type_id: registerAs.id,
                                ...payload,
                            };
                            registerUser(payload);
                        }}
                    >
                        {(formik) => (
                            <FormBox>
                                <Grid
                                    container
                                    justifyContent={'center'}
                                    columnSpacing={2}
                                >
                                    <Grid item sm={12} marginBottom={5}>
                                        <Typography className="head">
                                            {name} registration
                                        </Typography>
                                        <Typography className="sub-head">
                                            Sign up as {name}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            className="form-input"
                                            label={`${name} name`}
                                            type="text"
                                            name="organization_name"
                                            bBottom={colors.secondary}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomSelectInput2
                                            bBottom={colors.secondary}
                                            className="form-input"
                                            label={`${name} category`}
                                            name="category_id"
                                            type={'text'}
                                            formik={formik}
                                            color={colors.secondary}
                                        >
                                            {organizationCategories?.map(
                                                ({ id, name }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                    >
                                                        {name}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </CustomSelectInput2>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            bBottom={colors.secondary}
                                            className="form-input"
                                            label={`${name} location`}
                                            type="text"
                                            name="location"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomTextField
                                            bBottom={colors.secondary}
                                            className="form-input"
                                            label={`${name} email`}
                                            type="email"
                                            name="email"
                                            readOnly={true}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomPasswordField
                                            id="password"
                                            bBottom={colors.secondary}
                                            className="form-input"
                                            label={'Password'}
                                            type="password"
                                            name="password"
                                        />
                                        <Typography
                                            sx={{
                                                py: 1,
                                                fontSize: 12,
                                                color: colors.gray,
                                                ml: 0.5,
                                            }}
                                        >
                                            Note :- password should contain
                                            atleast 8 characters
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <CustomPasswordField
                                            id="confirm_password"
                                            bBottom={colors.secondary}
                                            className="form-input"
                                            label={'Confirm password'}
                                            type="password"
                                            name="confirm_password"
                                        />
                                    </Grid>

                                    {/* ######### sign up btn ######### */}
                                    <Grid
                                        item
                                        sm={12}
                                        xs={12}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <CustomSubmitButton
                                            startIcon={<PersonAddAlt1Outlined />}
                                            endIcon={<ArrowRightAltOutlined />}
                                            btnColor={`secondary`}
                                            variant={'contained'}
                                            loading={isLoading}
                                            onClick={() => {
                                                formik.handleSubmit();
                                            }}
                                            sx={{ py: 1.5, fontSize: 16 }}
                                        >
                                            Sign up
                                        </CustomSubmitButton>
                                    </Grid>
                                    {/* ######### sign_in ######### */}
                                    <Grid
                                        item
                                        sm={12}
                                        xs={12}
                                        sx={{
                                            marginTop: 2.5,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Link to={'/'}>
                                            <Typography className="sign_in">
                                                Sign in here ...
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </FormBox>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Register;
