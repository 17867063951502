import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { Link } from "react-router-dom";
import { OpenInNew, RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import { numFormater } from "../../helpers/numFormater";
import { removeSpclChar } from "../../helpers/strHelper";

const columns = [
    { id: "s_n", label: "S/N", minWidth: 0, align: "center" },
    { id: "ref_id", label: "Payment Ref", minWidth: 0 },
    { id: "job_id", label: "Posted job", align: "center" },
    { id: "date", label: "Date payed" },
    { id: "method", label: "Method" },
    { id: "amount", label: "Amount", align: "center" },
    {
        id: "status",
        label: "Status",
        align: "center",
    },
];

function createData(s_n, ref_id, job_id, date, method, amount, status) {
    return { s_n, ref_id, job_id, date, method, amount, status };
}

const TransactionTable = ({ transactions, height }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = transactions?.map((transaction, index) => {
        return createData(
            <Typography sx={{ opacity: 0.8, fontWeight: "bold" }}>
                {++index}
            </Typography>,
            <Typography sx={{ fontWeight: "bold", fontSize: 13, opacity: 0.8 }}>
                {transaction.transaction.reference_number}
            </Typography>,
            <Box sx={{ py: 2 }}>
                <Link to={`/jobs/${transaction.job_id}`}>
                    <Tooltip title="Job details" arrow>
                        <Typography
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                textAlign: "center",
                                borderRadius: 3,
                                fontSize: 13,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: `1px solid ${colors.bgColor5}`,
                            }}
                        >
                            {transaction.job.job_number}
                            <OpenInNew
                                sx={{ fontSize: 15, ml: 2 }}
                                color="warning"
                            />
                        </Typography>
                    </Tooltip>
                </Link>
            </Box>,
            <Typography sx={{ fontSize: 14, opacity: 0.8 }}>
                {moment(transaction.transaction.updated_at).format("LL")}
            </Typography>,
            <Typography sx={{ fontSize: 16, opacity: 0.8 }}>
                {transaction.transaction.payment_method}
            </Typography>,
            <Typography sx={{ fontWeight: "bold", color: colors.info }}>
                {numFormater(transaction.transaction.amount)}
                <sup style={{ fontSize: 10, color: colors.success }}>TZS</sup>
            </Typography>,
            <Chip
                label={removeSpclChar(transaction.transaction.status)}
                color={
                    transaction.transaction.status === "not_paid"
                        ? "warning"
                        : transaction.transaction.status === "pending"
                        ? "warning"
                        : transaction.transaction.status === "complete" ||
                          transaction.transaction.status === "success"
                        ? "success"
                        : transaction.transaction.status === "failed" ||
                          transaction.transaction.status === "failure"
                        ? "error"
                        : "default"
                }
                size="small"
                sx={{ fontSize: 12, height: 20, fontWeight: "bold" }}
            />,
            <Link to={`/organization-details/`}>
                <Tooltip title="Student details" arrow>
                    <IconButton size="small">
                        <RemoveRedEye color="info" />
                    </IconButton>
                </Tooltip>
            </Link>
        );
    });

    return (
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
            <TableContainer
                sx={{
                    height: height ? height : "47vh",
                    overflow: "auto",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        fontSize: 14,
                                        color: colors.primary,
                                        whiteSpace: "nowrap",
                                        textTransform: "capitalize",
                                        opacity: 0.8,
                                    }}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                        }}
                    >
                        {rows
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {columns.map((column, index) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell
                                                    key={index}
                                                    align={column.align}
                                                    sx={{ py: 0 }}
                                                >
                                                    {column.format &&
                                                    typeof value === "number"
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[1, 2, 5, 10]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                }}
            />
        </Paper>
    );
};

export default TransactionTable;
