import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import ORGDocs from './ORGDocs';
import ORGKeyPerson from './ORGKeyPerson';
import { profileSelector } from '../../states/features/selectors';

const ORGDocuments = ({ refetchProfile }) => {
    const { profile: user, orgDocs, orgKeyPerson } = useSelector(
        profileSelector,
    );
    return (
        <>
            <Grid
                container
                justifyContent={'space-between'}
                padding={1}
                rowGap={4}
            >
                {/* ################### Verification Documents ########## */}
                <Grid item md={12} sm={12} xs={12}>
                    <ORGDocs
                        user={user}
                        orgDocs={orgDocs}
                        refetchProfile={refetchProfile}
                    />
                </Grid>
                {/* ################### Key person ########## */}
                <Grid item md={12} sm={12} xs={12}>
                    <ORGKeyPerson
                        user={user}
                        orgKeyPerson={orgKeyPerson}
                        refetchProfile={refetchProfile}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ORGDocuments;
