import React from 'react';
import { Box, Grid } from '@mui/material';
import Loader from 'react-js-loader';
import { colors } from '../assets/utils/constants';

const PageLoader = () => {
    return (
        <>
            <Grid container>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            height: '91.6vh',
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                position: 'absolute',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            }}
                        >
                            <Loader
                                type="bubble-ping"
                                bgColor={colors.primary}
                                color={colors.primary}
                                size={100}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PageLoader;
