import React, { useState } from 'react';
import {
    DoNotDisturbOn,
    Edit,
    Groups2Outlined,
    Publish,
    AllInclusive,
} from '@mui/icons-material';

import { Box, Grid, Typography, Button } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import CustomSubmitButton from '../Forms/CustomSubmitButton';
import OrganizationFieldWidget from '../widgets/OrganizationFieldWidget';
import CustomCard from '../cards/CustomCard';
import PublishPT from '../../pages/practicalTraining/CRUD/PublishPT';
import CustomDialog from '../Dialogs/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateDetails } from '../../states/features/practicalTraining/PTPostingSlice';
import UpdatePTInDialog from '../../pages/practicalTraining/UpdatePTInDialog';
import { errorNotify } from '../../helpers/notify';
import DeletePT from '../../pages/practicalTraining/CRUD/DeletePT';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import ApprovePTTOStudents from '../../pages/practicalTraining/CRUD/ApprovePTTOStudents';
import DeclinePTRequest from '../../pages/practicalTraining/CRUD/DeclinePTRequest';
import ContentLoader from '../ContentLoader';
import { filter, isEmpty, size } from 'lodash';
import { Link } from 'react-router-dom';

const PracticalTrainingDetailsSidebar = ({ training, applicantIsLoading }) => {
    // ############### CONST ###################
    const dispatch = useDispatch();
    const organization = training.organization;

    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionType, setActionType] = useState('');

    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { singlePracticalTrainingApplicants } = useSelector(PTSelector);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
        setActionType(actionType);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            <CustomDialog
                head={
                    actionType === 'publish'
                        ? `${
                              training.status == 'closed'
                                  ? 'Re-publish'
                                  : training.status == 'draft'
                                  ? 'Publish'
                                  : training.status == 'published'
                                  ? 'Close'
                                  : ''
                          } practical training`
                        : actionType === 'update'
                        ? 'Update practical training'
                        : actionType === 'approval'
                        ? 'Approve practical training to students'
                        : actionType === 'decline'
                        ? 'Decline practical training to request'
                        : ''
                }
                openDialog={openDialog}
                customWidth={actionType === 'update' ? 'lg' : 'sm'}
                handleDialogClose={handleDialogClose}
            >
                {actionType === 'publish' ? (
                    <PublishPT
                        training={training}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === 'update' ? (
                    <UpdatePTInDialog handleDialogClose={handleDialogClose} />
                ) : actionType === 'approval' ? (
                    <ApprovePTTOStudents
                        training={training}
                        handleClose={handleDialogClose}
                    />
                ) : actionType === 'decline' ? (
                    <DeclinePTRequest
                        training={training}
                        handleDialogClose={handleDialogClose}
                    />
                ) : (
                    ''
                )}
            </CustomDialog>
            <Grid container mb={2} spacing={3}>
                <Grid item sm={12}>
                    <CustomCard head={'Posted by'}>
                        <OrganizationFieldWidget organization={organization} />
                    </CustomCard>
                </Grid>
                <Grid item sm={6}>
                    <Box
                        className={
                            training.status === 'draft'
                                ? 'warning-box'
                                : training.status === 'published'
                                ? 'success-box'
                                : training.status === 'closed'
                                ? 'warning-box'
                                : training.status === 'expired'
                                ? ''
                                : training.status === 'suspended'
                                ? 'error-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                            }}
                        >
                            status
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                            }}
                        >
                            {training.status}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box
                        className={
                            training.type === 'private'
                                ? 'success-box'
                                : training.type === 'public'
                                ? 'info-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                            }}
                        >
                            Type
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                            }}
                        >
                            {training.type}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            pt: 1.5,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            textAlign: 'center',
                            height: 120,
                            position: 'relative',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                fontWeight: 'bold',
                                pb: 1,
                                borderBottom: `1px solid ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                color: colors.info,
                            }}
                        >
                            Overall training applicants
                        </Typography>
                        {applicantIsLoading ? (
                            <ContentLoader height={160} />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    p: 1.5,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.warning,
                                    }}
                                >
                                    <strong>
                                        <small>Applied</small>
                                    </strong>
                                    <Typography>
                                        {size(
                                            singlePracticalTrainingApplicants,
                                        )}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.success,
                                    }}
                                >
                                    <strong>
                                        <small>Approved</small>
                                    </strong>
                                    <Typography>
                                        {size(
                                            filter(
                                                singlePracticalTrainingApplicants,
                                                ({ appliedTraining }) =>
                                                    appliedTraining?.status ===
                                                    'approved',
                                            ),
                                        )}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        mr: 1,
                                        color: colors.info,
                                    }}
                                >
                                    <strong>
                                        <small>Available</small>
                                    </strong>
                                    {!isEmpty(training.positions) ||
                                    training.positions > 0 ? (
                                        <Typography>
                                            {training.positions -
                                                size(
                                                    filter(
                                                        singlePracticalTrainingApplicants,
                                                        ({ appliedTraining }) =>
                                                            appliedTraining?.status ===
                                                            'approved',
                                                    ),
                                                )}
                                        </Typography>
                                    ) : (
                                        <AllInclusive />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <CustomCard2 head={'Action buttons'}>
                <Grid container>
                    <Grid item sm={12}>
                        {training.organization_id === user.id ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                {training.status === 'draft' && (
                                    <CustomSubmitButton
                                        startIcon={<Edit />}
                                        btnColor="info"
                                        sx={{ py: 0.5 }}
                                        onClick={() => {
                                            let actionType = 'update';
                                            training.status === 'published'
                                                ? errorNotify(
                                                      'Can not update published practical training. Close training first!',
                                                  )
                                                : handleDialogOpen(actionType);
                                            dispatch(
                                                setUpdateDetails(training),
                                            );
                                        }}
                                    >
                                        Edit training
                                    </CustomSubmitButton>
                                )}
                                {training.status === 'suspended' ? (
                                    <>
                                        <Box className='error-message'>
                                            <Typography sx={{ fontSize: 14 }}>
                                                The practical training is
                                                suspended please contact use for
                                                more information.
                                            </Typography>
                                            <Link to="/help-center">
                                                <Button
                                                    variant="outlined"
                                                    size={'small'}
                                                    fullWidth
                                                    color='error'
                                                    sx={{ mt: 2 }}
                                                >
                                                    Contact us!
                                                </Button>
                                            </Link>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <CustomSubmitButton
                                            startIcon={<Publish />}
                                            btnColor={
                                                training.status == 'published'
                                                    ? 'warning'
                                                    : training.status ==
                                                          'closed' ||
                                                      training.status == 'draft'
                                                    ? 'success'
                                                    : 'primary'
                                            }
                                            sx={{ py: 0.5 }}
                                            onClick={() => {
                                                let actionType = 'publish';
                                                handleDialogOpen(actionType);
                                            }}
                                        >
                                            {training.status == 'published'
                                                ? 'Close training'
                                                : training.status == 'closed'
                                                ? 'Re-publish training'
                                                : training.status == 'draft'
                                                ? 'Publish training'
                                                : ''}
                                        </CustomSubmitButton>
                                        <DeletePT training={training} />
                                    </>
                                )}
                            </Box>
                        ) : (
                            !training.studentApproval && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CustomSubmitButton
                                        startIcon={<Groups2Outlined />}
                                        btnColor="info"
                                        sx={{ py: 0.5 }}
                                        onClick={() => {
                                            let actionType = 'approval';
                                            handleDialogOpen(actionType);
                                        }}
                                    >
                                        Approve to student
                                    </CustomSubmitButton>
                                    {training.type === 'private' &&
                                        training.assignStatus !== 'decline' && (
                                            <CustomSubmitButton
                                                startIcon={<DoNotDisturbOn />}
                                                btnColor="error"
                                                sx={{ py: 0.5 }}
                                                onClick={() => {
                                                    let actionType = 'decline';
                                                    handleDialogOpen(
                                                        actionType,
                                                    );
                                                }}
                                            >
                                                Decline training
                                            </CustomSubmitButton>
                                        )}
                                </Box>
                            )
                        )}
                    </Grid>
                </Grid>
            </CustomCard2>
        </>
    );
};

export default PracticalTrainingDetailsSidebar;
