import React from "react";
import { Button, List } from "@mui/material";
import {
    EngineeringOutlined,
    RemoveRedEye,
    WorkHistoryOutlined,
} from "@mui/icons-material";
import CustomTabs from "../tabs/CustomTabs";
import FieldApplicantWidget from "../widgets/FieldApplicantWidget";
import { useSelector } from "react-redux";
import {
    PTSelector,
    jobApplicationsSelector,
} from "../../states/features/selectors";
import { size } from "lodash";
import { filter } from "lodash";
import JobApplicantWidget from "../widgets/JobApplicantWidget";
import IndividualJobApplicantWidget from "../widgets/IndividualJobApplicantWidget";
import GroupJobApplicantWidget from "../widgets/GroupJobApplicantWidget";

const DashApplicants2 = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);
    const {
        companyJobApplications,
        individualJobApplications,
        groupJobApplications,
    } = useSelector(jobApplicationsSelector);
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
        ...groupJobApplications,
    ];

    // => Pending PT applicants
    const pendingTrainingApplicants = filter(
        allPracticalTrainingApplicants,
        ({ appliedTraining }) => appliedTraining.status === "pending"
    );
    // => Pending Job applicants
    const pendingJobApplicants = filter(
        allJobApplications,
        ({ application }) => application.status === "pending"
    );

    // ############ Tabs ##################
    const ApplicantsTabs = [
        {
            id: 2,
            title: "Job applicants",
            icon: <WorkHistoryOutlined />,
            tabsContent: true ? (
                <>
                    <List
                        sx={{
                            width: "100%",
                            p: 0,
                        }}
                    >
                        {pendingJobApplicants?.map((applicant) =>
                            applicant.type === "company" ? (
                                <JobApplicantWidget
                                    key={applicant.id}
                                    applicant={applicant}
                                />
                            ) : applicant.type === "group" ? (
                                <GroupJobApplicantWidget
                                    key={applicant.id}
                                    applicant={applicant}
                                />
                            ) : (
                                <>
                                    <IndividualJobApplicantWidget
                                        key={applicant.id}
                                        applicant={applicant}
                                    />
                                </>
                            )
                        )}
                    </List>
                    {size(pendingJobApplicants) > 5 && (
                        <Button
                            variant="outlined"
                            size={"large"}
                            sx={{ my: 2, float: "right" }}
                            endIcon={<RemoveRedEye />}
                        >
                            View all
                        </Button>
                    )}
                </>
            ) : (
                ""
            ),
            total: size(pendingJobApplicants),
            chipColor: "info",
        },
        {
            id: 1,
            title: "Field applicants",
            icon: <EngineeringOutlined />,
            tabsContent: true ? (
                <>
                    <List
                        sx={{
                            width: "100%",
                            p: 0,
                        }}
                    >
                        {pendingTrainingApplicants?.map((applicant) => (
                            <FieldApplicantWidget
                                key={applicant.id}
                                applicant={applicant}
                            />
                        ))}
                    </List>
                    {size(allPracticalTrainingApplicants) > 5 && (
                        <Button
                            variant="outlined"
                            size={"large"}
                            sx={{ my: 2, float: "right" }}
                            endIcon={<RemoveRedEye />}
                        >
                            View all
                        </Button>
                    )}
                </>
            ) : (
                ""
            ),
            total: size(pendingTrainingApplicants),
            chipColor: "info",
        },
    ];
    return (
        <>
            <CustomTabs
                tabs={ApplicantsTabs}
                height={"27.8vh"}
                noHeight={"22vh"}
            />
        </>
    );
};

export default DashApplicants2;
