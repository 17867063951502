import React from "react";
import { colors } from "../../assets/utils/constants";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    styled,
    Tooltip,
    Typography,
} from "@mui/material";

import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useState } from "react";
import { isEmpty } from "lodash";
import CustomDialog from "../../components/Dialogs/CustomDialog";
import UpdateLogo from "./CRUD's/UpdateLogo";
import UpdateLocation from "./CRUD's/UpdateLocation";
import UpdateEmail from "./CRUD's/UpdateEmail";
import UpdatePhone from "./CRUD's/UpdatePhone";

// -> custom MUI
const LogoCard = styled(Grid)(({ theme }) => ({
    position: "relative",
    background: colors.bgColor2,
    justifyContent: "space-around",
    alignItems: "center",
    "& .logo": {
        height: "6.5em",
        width: "100%",
        borderRadius: 15,
        padding: 10,
        border: `4px solid ${colors.bgColor3}`,
        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
        objectFit: "contain",
    },
    "& .logo-title": {
        textAlign: "center",
        fontWeight: "bold",
    },
}));

const InfoListCard = styled(List)({
    marginTop: 40,
    padding: 0,
    overflow: "hidden",
    "& .MuiListItemButton-root": {
        paddingLeft: 20,
        paddingRight: 20,
        background: colors.secondary,
        "& span": {
            color: colors.primary,
        },
    },
    "& .list-item": {
        background: colors.secondary,
        color: colors.primary,
        margin: 0,
        padding: 0,
        " p": {
            color: colors.primary,
        },
        " .icon": {
            color: colors.primary,
            fontSize: 16,
        },
        " .action-icon": {
            color: colors.secondary,
            fontSize: 14,
        },
        " .avatar": {
            background: colors.bgColor2,
        },
    },
});

// !############## MAIN FUNC ################
const LeftBar = ({ user, refetchProfile }) => {
    const [crudType, setCRUDType] = useState("");
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <React.Fragment>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                {crudType === "UpdateLogo" ? (
                    <UpdateLogo
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                ) : crudType === "UpdateLocation" ? (
                    <UpdateLocation
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                ) : crudType === "UpdateEmail" ? (
                    <UpdateEmail
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                ) : crudType === "UpdatePhone" ? (
                    <UpdatePhone
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                ) : (
                    <></>
                )}
            </CustomDialog>

            {/* ##################### CONTENTS ################# */}
            <Grid
                item
                md={3}
                sm={12}
                sx={{
                    background: colors.secondary,
                    position: "relative",
                    pb: 5,
                }}
            >
                <Box
                    sx={{
                        background: colors.secondary,
                        position: "sticky",
                        top: 64,
                    }}
                >
                    {/* ####################### LOGO ################### */}
                    <LogoCard
                        container
                        justifyContent={"space-between"}
                        sx={{
                            padding: 2,
                            paddingBottom: 6,
                        }}
                    >
                        <Grid
                            item
                            sm={6}
                            xs={6}
                            sx={{
                                background: colors.primary,
                                height: 100,
                                borderRadius: 5,
                            }}
                        >
                            {!isEmpty(user.org_logo) ? (
                                <>
                                    <img
                                        className="logo"
                                        src={user.org_logo}
                                        alt="dp"
                                    />
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ textAlign: "center" }}>
                                        <ImageIcon
                                            sx={{
                                                fontSize: 100,
                                                color: colors.bgColor5,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}

                            <Typography className="logo-title">
                                {user.org_type} logo
                            </Typography>
                        </Grid>
                        <Grid item sm={5} sx={{ textAlign: "center" }}>
                            <Tooltip title="upload logo" arrow>
                                <Button
                                    variant="contained"
                                    sx={{ marginTop: 2, fontSize: 11 }}
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => {
                                        let crudType = "UpdateLogo";
                                        let dialogHead =
                                            "Update organization logo";
                                        handleDialogyOpen(crudType, dialogHead);
                                    }}
                                    className="app-btn"
                                >
                                    Update logo
                                </Button>
                            </Tooltip>
                        </Grid>
                    </LogoCard>

                    {/* ####################### ORG ID ################### */}
                    <Grid
                        container
                        justifyContent={"center"}
                        sx={{ background: colors.secondary }}
                    >
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: colors.primary,
                                    marginTop: 2,
                                }}
                            >
                                {user.login_id}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                }}
                            >
                                Registration ID
                            </Typography>
                            <Divider />
                        </Grid>
                        {/* ####################### PROFILE COMPLETION ################### */}
                        <Grid
                            item
                            md={10}
                            sm={0}
                            xs={10}
                            sx={{ position: "relative" }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    marginTop: 2,
                                }}
                            >
                                Profile completion
                            </Typography>
                            <Typography
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    top: 20,
                                    fontWeight: "bold",
                                }}
                            >
                                {user.percentage_complete}%
                            </Typography>
                            <Typography
                                component={"div"}
                                sx={{
                                    width: "100%",
                                    height: 20,
                                    background: colors.primary,
                                    borderRadius: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        width: `${user.percentage_complete}%`,
                                        height: 20,
                                        background: "green",
                                        borderRadius: 1,
                                    }}
                                ></Typography>
                            </Typography>
                        </Grid>
                        {/* ####################### CONTACT INFO ################### */}
                        <Grid item md={11} sm={11} xs={11}>
                            <InfoListCard
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Box
                                    component="h1"
                                    sx={{
                                        cursor: "default",
                                        m: 0,
                                        p: 1,
                                    }}
                                >
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="Contact infos"
                                        primaryTypographyProps={{
                                            fontSize: 17,
                                            letterSpacing: 0,
                                            fontWeight: "bold",
                                        }}
                                    />
                                </Box>
                                <Divider />
                                {/* #### LOCATION ### */}
                                <ListItem
                                    className="list-item"
                                    secondaryAction={
                                        <Tooltip
                                            title={"Edit location"}
                                            arrow
                                            placement="right"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => {
                                                    let crudType =
                                                        "UpdateLocation";
                                                    let dialogHead =
                                                        "Update organization location";
                                                    handleDialogyOpen(
                                                        crudType,
                                                        dialogHead
                                                    );
                                                }}
                                                sx={{
                                                    bgcolor: colors.primary,
                                                    ":hover": {
                                                        bgcolor: colors.primary,
                                                    },
                                                }}
                                            >
                                                <EditIcon className="action-icon" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <LocationOnIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: "bold",
                                        }}
                                        primary={`${user.location}`}
                                        secondary="Location"
                                    />
                                </ListItem>
                                {/* #### Email ### */}
                                <ListItem
                                    className="list-item"
                                    secondaryAction={
                                        user.is_verified === "pending" && (
                                            <>
                                                <Tooltip
                                                    title={"Edit email"}
                                                    arrow
                                                    placement="right"
                                                >
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="edit"
                                                        onClick={() => {
                                                            let crudType =
                                                                "UpdateEmail";
                                                            let dialogHead =
                                                                "Update organization email";
                                                            handleDialogyOpen(
                                                                crudType,
                                                                dialogHead
                                                            );
                                                        }}
                                                        sx={{
                                                            bgcolor:
                                                                colors.primary,
                                                            ":hover": {
                                                                bgcolor:
                                                                    colors.primary,
                                                            },
                                                        }}
                                                    >
                                                        <EditIcon className="action-icon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <MailIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: "bold",
                                        }}
                                        primary={`${user.email}`}
                                        secondary="email"
                                    />
                                </ListItem>
                                {/* #### Phone ### */}
                                <ListItem
                                    className="list-item"
                                    secondaryAction={
                                        user.is_verified === "pending" && (
                                            <>
                                                <Tooltip
                                                    title={"Edit phone"}
                                                    arrow
                                                    placement="right"
                                                >
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="edit"
                                                        onClick={() => {
                                                            let crudType =
                                                                "UpdatePhone";
                                                            let dialogHead =
                                                                "Update organization phone number";
                                                            handleDialogyOpen(
                                                                crudType,
                                                                dialogHead
                                                            );
                                                        }}
                                                        sx={{
                                                            bgcolor:
                                                                colors.primary,
                                                            ":hover": {
                                                                bgcolor:
                                                                    colors.primary,
                                                            },
                                                        }}
                                                    >
                                                        <EditIcon className="action-icon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        )
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar className="avatar">
                                            <LocalPhoneIcon className="icon" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        secondaryTypographyProps={{
                                            fontWeight: "bold",
                                        }}
                                        primary={`${
                                            user.phone_number
                                                ? user.phone_number
                                                : "No phone details"
                                        }`}
                                        secondary="Phone"
                                    />
                                </ListItem>
                            </InfoListCard>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </React.Fragment>
    );
};

export default LeftBar;
