import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import CreateJobStepper from '../../components/steppers/CreateJobStepper';
import { resetJobPosting } from '../../states/features/job/JobPostingSlice';
import useJobs from '../../hooks/useJobs';

const CreateJob = () => {
    // ############ CONST ################
    const jobHook = useJobs();
    const dispatch = useDispatch();

    // ############# RTK #################
    jobHook.useMetaDataDispatch();

    // ############# USEEFFECT ###########
    useEffect(() => {
        return () => {
            dispatch(resetJobPosting());
        };
    }, [dispatch]);

    return (
        <>
            <CustomCard2>
                <CreateJobStepper />
            </CustomCard2>
        </>
    );
};

export default CreateJob;
