import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import JobVacancyCard from "../components/DashComponents/JobVacancyCard";
import PracticalTrainingCard from "../components/DashComponents/PracticalTrainingCard";
import DashboardLayout from "./layouts/DashboardLayout";
import DashSidebar from "../components/DashComponents/DashSidebar";
import InfoboxWidget from "../components/widgets/InfoboxWidget";
import CustomCard2 from "../components/cards/CustomCard2";
import StudentsManagementCard from "../components/DashComponents/StudentsManagementCard";
import DashApplicants2 from "../components/DashComponents/DashApplicants2";
import {
    PTSelector,
    jobsSelector,
    profileSelector,
    settingsSelector,
} from "../states/features/selectors";
import { filter, isEmpty, size } from "lodash";
import FreeTrialWidgetOne from "../components/widgets/FreeTrialWidgetOne";
import FreeTrialWidget from "../components/widgets/FreeTrialWidget";

const InstitutionDashboard = () => {
    // ############## Redux state ###############
    const { activeFreeTrials } = useSelector(settingsSelector);
    const { profile: user } = useSelector(profileSelector);
    const { jobs, jobsForYou } = useSelector(jobsSelector);
    const {
        ORGPracticalTrainings,
        specialPracticalTrainings,
        otherPracticalTrainings,
    } = useSelector(PTSelector);
    // => All PTs
    const allPracticalTrainings = () => {
        let trainings =
            user.organization_type.name === "Institution"
                ? [
                      ...ORGPracticalTrainings,
                      ...specialPracticalTrainings,
                      ...otherPracticalTrainings,
                  ]
                : [...ORGPracticalTrainings];
        return trainings;
    };

    // => Active PTs
    const activePTs = filter(
        allPracticalTrainings(),
        ({ status }) => status === "published"
    );

    return (
        <React.Fragment>
            <DashboardLayout sidebar={<DashSidebar />}>
                {/* ############## MAIN INFO BOXES ################ */}
                <Grid container spacing={4}>
                    {user.freeTrial !== false ? (
                        <>
                            {user.isFreeTrial === true && (
                                <Grid item sm={12}>
                                    <FreeTrialWidgetOne
                                        freeTrial={user?.freeTrial}
                                    />
                                </Grid>
                            )}
                        </>
                    ) : (
                        <>
                            {!isEmpty(activeFreeTrials) && (
                                <Grid item sm={12}>
                                    <FreeTrialWidget
                                        freeTrial={activeFreeTrials}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item sm={6}>
                        <JobVacancyCard jobs={jobs} />
                    </Grid>
                    <Grid item sm={6}>
                        <PracticalTrainingCard />
                    </Grid>
                </Grid>

                {/* ############## INFO BOXES ################ */}
                <Grid container spacing={3} mt={0}>
                    <Grid item sm={12}>
                        <CustomCard2 head="Quick action links">
                            <Grid container spacing={3}>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Active job vacancy"}
                                        urlName="Manage vacancy"
                                        url={"/jobs/status/published"}
                                        total={0}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Active practical training"}
                                        urlName="Manage training"
                                        url={"/practical-trainings"}
                                        total={size(activePTs)}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InfoboxWidget
                                        name={"Jobs for you"}
                                        urlName="Active jobs for you"
                                        url={"/jobs/for-you"}
                                        total={size(jobsForYou)}
                                    />
                                </Grid>
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>

                {/* ############## APPLICANTS ################ */}
                <Grid container spacing={3} mt={0.1}>
                    <Grid item sm={6}>
                        <DashApplicants2 />
                    </Grid>
                    <Grid item sm={6}>
                        <CustomCard2 height={255} head={"Students management"}>
                            <StudentsManagementCard />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </DashboardLayout>
        </React.Fragment>
    );
};

export default InstitutionDashboard;
