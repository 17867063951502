import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge copy';
import { useParams } from 'react-router-dom';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import PracticalTrainingSidebarCompany from '../../components/sidebars/PracticalTrainingSidebarCompany';

const IntenalPTByStatus = () => {
    // ################ CONST ####################
    const { status: filterStatus, org_type } = useParams();

    // ################ Comp state ####################
    const [ptByStatus, setPtByStatus] = useState([]);

    // ################ Redux state ####################
    const { profile: user } = useSelector(profileSelector);
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // ############## FILTER ####################
    // => Intenal PTs
    const intenalPTs = filter(
        ORGPracticalTrainings,
        ({ organization_id }) => organization_id === user.id,
    );

    // ############## FUNC ###################
    const getPTByStatus = () => {
        let ORGPracticalTrainings = [];
        if (filterStatus === 'drafted') {
            ORGPracticalTrainings = filter(
                intenalPTs,
                ({ status }) => status === 'draft',
            );
        }
        if (filterStatus === 'published') {
            ORGPracticalTrainings = filter(
                intenalPTs,
                ({ status }) => status === 'published',
            );
        }
        if (filterStatus === 'closed') {
            ORGPracticalTrainings = filter(
                intenalPTs,
                ({ status }) => status === 'closed',
            );
        }
        if (filterStatus === 'suspended') {
            ORGPracticalTrainings = filter(
                intenalPTs,
                ({ status }) => status === 'suspended',
            );
        }

        setPtByStatus(ORGPracticalTrainings);
    };

    // ############### useEffect ###############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getPTByStatus();
        }

        return () => {
            isSubscribed = false;
        };
    }, [ORGPracticalTrainings]);

    return (
        <>
            <PageLayout
                head={`Internal ${filterStatus} practical trainings`}
                sidebar={
                    user?.organization_type?.name === 'Institution' ? (
                        <PracticalTrainingSidebar />
                    ) : (
                        <PracticalTrainingSidebarCompany />
                    )
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={`All practical trainings`}
                            height={550}
                            borderBottom
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <PracticalTrainingFilter width={500} /> */}
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(ptByStatus)}
                                            item={`${filterStatus}  PTs`}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(ptByStatus) ? (
                                <>
                                    <NoContent
                                        height={360}
                                        message={`No ${filterStatus} ${org_type} practical trainings`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {ptByStatus.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default IntenalPTByStatus;
