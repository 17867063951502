import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { useDispatch, useSelector } from 'react-redux';
import { instituteSelection } from '../../../states/features/practicalTraining/PTPostingSlice';
import NextAndBackBtn from '../../../components/steppers/NextAndBackBtn';
import CustomAutocomplete from '../../../components/Forms/CustomAutocomplete';
import {
    PTPostingSelector,
    profileSelector,
    settingsSelector,
} from '../../../states/features/selectors';
import CountBadge from '../../../components/CountBadge';
import { filter, size } from 'lodash';

const InstitutionSelection = ({
    handleNext,
    handleBack,
    steps,
    activeStep,
}) => {
    // ############## CONST ###############
    const dispatch = useDispatch();
    const { profile: user } = useSelector(profileSelector);
    const { publicInstitutions } = useSelector(settingsSelector);

    /**########## FUNC ############### */
    const restructureInstitutions = () => {
        let restructured = [];
        let institutions = publicInstitutions;
        if (user.organization_type.name === 'Institution') {
            institutions = filter(institutions, ({ id }) => id !== user.id);
        }
        institutions?.forEach((institution) => {
            let newObj = {
                id: institution.id,
                title: institution.organization_name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    const availableInstitutions = restructureInstitutions();

    // ############## Redux state ##############
    const { institutions } = useSelector(PTPostingSelector);

    // ############## FUNC ###############
    const handleSelectedInstitution = (payload) => {
        dispatch(instituteSelection(payload));
    };

    return (
        <>
            <Formik
                initialValues={{
                    institution_ids: '',
                }}
                onSubmit={(payload) => {
                    // handleNext();
                }}
            >
                {(formik) => (
                    <Form>
                        <Box
                            sx={{
                                height: 450,
                                position: 'relative',
                                overflow: 'auto',
                            }}
                        >
                            <Grid container spacing={2} padding={2}>
                                <Grid item sm={12}>
                                    <CustomCard2
                                        head={
                                            'Select institutions for sending practical training vacancy'
                                        }
                                        height={350}
                                        action={
                                            <CountBadge
                                                total={size(
                                                    availableInstitutions,
                                                )}
                                                item={'Available institutions'}
                                            />
                                        }
                                    >
                                        <CustomAutocomplete
                                            label={'Select institutions'}
                                            placeholder={'Institution'}
                                            options={availableInstitutions}
                                            name="institutions"
                                            getSelectedValues={
                                                handleSelectedInstitution
                                            }
                                            defaultOption={institutions}
                                        />
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography
                                                className="error-message"
                                                sx={{ mt: 3 }}
                                            >
                                                <strong>Note: </strong>
                                                Selecting institution is an
                                                optional. Leave empty for all
                                                institutions to view it.
                                            </Typography>
                                        </Box>
                                    </CustomCard2>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* ################ ACTION BUTONS ###################### */}
                        <NextAndBackBtn
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            action={handleNext}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default InstitutionSelection;
