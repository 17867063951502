import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import TransactionSidebar from '../../components/sidebars/TransactionSidebar';
import TransactionTable from '../../components/Tables/TransactionTable';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { transactionSelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge copy';
import NoContent from '../../components/NoContent';
import { useParams } from 'react-router-dom';

const Transactions = () => {
    // ############# CONST #################
    let { status } = useParams();
    if (status === 'on-progress') {
        status = 'pending';
    }
    if (status === 'pending-paid') {
        status = 'not_paid';
    }
    if (status === 'completed') {
        status = 'success';
    }
    if (status === 'failed') {
        status = 'failure';
    }

    // ################# Redux state #################
    const { jobTransactions } = useSelector(transactionSelector);

    // ################ FUNC ##################
    const getPaymentByStatus = () => {
        let transactions = filter(
            jobTransactions,
            ({ transaction }) => transaction.status === status,
        );
        let totalAmount = 0;
        transactions.forEach((transaction) => {
            totalAmount += parseInt(transaction.transaction.amount);
        });
        return {
            transactions,
            total: size(transactions),
            amount: totalAmount,
        };
    };

    return (
        <>
            <PageLayout
                head={'Job posting fee transactions histories'}
                sidebar={<TransactionSidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={`${
                                status === 'pending'
                                    ? 'On-progress'
                                    : status === 'not_paid'
                                    ? 'Pending paid'
                                    : status
                            } transactions history`}
                            action={
                                <Box>
                                    <CountBadge
                                        total={size(
                                            getPaymentByStatus().transactions,
                                        )}
                                        item={'Transactions'}
                                    />
                                </Box>
                            }
                        >
                            {isEmpty(getPaymentByStatus().transactions) ? (
                                <NoContent
                                    height={'67.5vh'}
                                    message={`No ${
                                        status === 'pending'
                                            ? 'On-progress'
                                            : status === 'not_paid'
                                            ? 'Pending paid'
                                            : status
                                    } transaction!`}
                                />
                            ) : (
                                <TransactionTable
                                    height={'61vh'}
                                    transactions={
                                        getPaymentByStatus().transactions
                                    }
                                />
                            )}
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Transactions;
