import React, { useState } from "react";
import { RemoveRedEye } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    Typography,
    Chip,
} from "@mui/material";
import { colors } from "../../assets/utils/constants";
import CustomDialog from "../Dialogs/CustomDialog";
import GroupMemberDetails from "../../pages/jobs/components/GroupMemberDetails";

const GroupMemberWidget = ({ applicant }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            {/* ############# Dialog ################ */}
            <CustomDialog
                head={"Group member details"}
                openDialog={openDialog}
                customWidth={"sm"}
                handleDialogClose={handleDialogClose}
            >
                <GroupMemberDetails
                    applicant={applicant}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Chip
                            label={applicant.role}
                            size="small"
                            color="secondary"
                            sx={{
                                fontSize: 11,
                                fontWeight: "bold !important",
                                opacity: 0.8,
                                px: 1,
                                float: "right",
                                mb: 1,
                                height: 19,
                            }}
                        />
                        <Tooltip title="Applicant details" arrow>
                            <Button
                                sx={{
                                    bgcolor: colors.bgColor3,
                                }}
                                onClick={() => {
                                    handleDialogOpen();
                                }}
                                size="small"
                            >
                                <RemoveRedEye
                                    color="info"
                                    sx={{ fontSize: 16 }}
                                />
                            </Button>
                        </Tooltip>
                    </Box>
                }
                sx={{
                    transition: ".3s all ease-in-out",
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                    position: "relative",
                    mb: 2,
                    borderRadius: 2,
                    transition: ".3s all ease-in-out",
                    border: `1px solid ${colors.bgColor3}`,
                    ":hover": {
                        transition: ".3s all ease-in-out",
                        border: `1px solid ${colors.bgColor5}`,
                    },
                    p: 0.5,
                }}
            >
                <ListItemAvatar>
                    <Avatar
                        alt="DP"
                        src={applicant.profile_picture}
                        sx={{
                            height: 65,
                            width: 65,
                            mt: -0.5,
                            mr: 1,
                            border: `3px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                            img: {
                                p: 0.2,
                                borderRadius: 50,
                                objectFit: "cover",
                            },
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    sx={{ p: 0 }}
                    primary={
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: "bold",
                                opacity: 0.8,
                            }}
                        >
                            {`${applicant.first_name} ${applicant?.middle_name} ${applicant.last_name}`}
                        </Typography>
                    }
                    secondary={
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    color: colors.info,
                                    fontWeight: "bold",
                                }}
                                component="span"
                            >
                                {`${applicant?.slug_id}`}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    color: colors.warning,
                                    fontWeight: "bold",
                                    opacity: 0.8,
                                }}
                                component="span"
                            >
                                {applicant.phone_number}
                            </Typography>
                        </Box>
                    }
                />
            </ListItem>
        </>
    );
};

export default GroupMemberWidget;
