import React from 'react';
import { ManageAccounts } from '@mui/icons-material';
import {
    Avatar,
    Box,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { borderRadius, colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';

const ProfileCard = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between !important',
    padding: 15,
    borderRadius: 20,
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
});

const SidebarUserCard = ({ user }) => {
    return (
        <ProfileCard
            className="neo-bulge"
            sx={{ borderRadius: borderRadius.br1 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: borderRadius.br1,
                    position: 'relative',
                    overflow: 'hidden',
                    p: 0.4,
                    mb: 1,
                    borderBottom: '1px solid gray',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        src={!isEmpty(user.org_logo) ? user.org_logo : ''}
                        sx={{
                            width: 45,
                            height: 45,
                            mr: 2,
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.primary,
                                fontWeight: 'bold',
                                width: 100,
                            }}
                            noWrap
                        >
                            {user.organization_name}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                color: colors.primary,
                                width: 100,
                            }}
                        >
                            {user.organization_type.name}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Tooltip title="Manage account" arrow>
                        <Link to={'/profile'}>
                            <IconButton color="info">
                                <ManageAccounts />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                className={
                    user.is_verified === 'pending'
                        ? 'warning-box'
                        : user.is_verified === 'requested'
                        ? 'info-box'
                        : user.is_verified === 'on_progress'
                        ? 'info-box'
                        : user.is_verified === 'denied'
                        ? 'error-box'
                        : 'success-box'
                }
                sx={{ border: 0 }}
            >
                <Typography
                    sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                >
                    {user.is_verified === 'pending'
                        ? 'Pending'
                        : user.is_verified === 'requested'
                        ? 'Requested'
                        : user.is_verified === 'on_progress'
                        ? 'On-progress'
                        : user.is_verified === 'denied'
                        ? 'Denied'
                        : 'Verified'}
                </Typography>
            </Box>
            <Box
                sx={{
                    border: `1px solid ${colors.secondary}`,
                    p: 0.8,
                    borderRadius: 2,
                    bgcolor: colors.primary,
                    mt: 1,
                }}
            >
                <Typography
                    sx={{
                        color: colors.secondary,
                        fontSize: 14,
                        textAlign: 'center',
                        opacity: 0.9,
                    }}
                >
                    {user.login_id}
                </Typography>
            </Box>
        </ProfileCard>
    );
};

export default SidebarUserCard;
