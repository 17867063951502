import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

const CustomMonoAutocomplete = ({
    formik,
    label,
    color,
    children,
    handler,
    options,
    defaultOption,
    popperPlacement,
    popperWidth,
    ...props
}) => {
    const [field, meta] = useField(props);
    const [value, setValue] = React.useState(
        defaultOption ? defaultOption : '',
    );
    const [inputValue, setInputValue] = React.useState('');

    const handleChange = (payload) => {
        const fieldName = field.name;
        formik.setFieldValue(`${fieldName}`, payload);
        // -> Handler
        handler && handler(payload);
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: 1.5,
                    width: '100%',
                    position: 'relative',
                }}
            >
                <Autocomplete
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        handleChange(newInputValue);
                        setInputValue(newInputValue);
                    }}
                    options={options ? options : []}
                    size={'small'}
                    sx={{ color: `${color ? color : '#eee'}` }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            {...props}
                            label={label}
                            variant="filled"
                        />
                    )}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomMonoAutocomplete;
