import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { colors } from '../../assets/utils/constants';
import UpdateOrgNames from "./CRUD's/UpdateOrgNames";
import banner from '../../assets/media/images/banner.jpg';
import UpdateBannerImage from "./CRUD's/UpdateBannerImage";
import CustomDialog from '../../components/Dialogs/CustomDialog';
import { CloudUpload, Pending, Verified } from '@mui/icons-material';
import {
    Button,
    Box,
    Chip,
    Grid,
    styled,
    Typography,
} from '@mui/material';

// -> custom MUI
const BannerCard = styled(Grid)(({ theme }) => ({
    'display': 'flex',
    'position': 'relative',
    'background': colors.primary,
    'height': 180,
    'left': 0,
    'right': 0,
    'flexDirection': 'column',
    'justifyContent': 'center',
    'backgroundRepeat': 'no-repeat',
    'backgroundAttachment': 'fixed',
    'backgroundSize': `100% 100vh`,
    '& .name': {
        textAlign: 'left',
        fontSize: 30,
        fontWeight: 'bold',
        color: colors.bgColor1,
    },
    '& .short-name': {
        textAlign: 'left',
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.bgColor1,
    },
    '& .edit-name-btn': {
        fontSize: 12,
        color: colors.gray,
        border: '1px solid #eee',
        paddingTop: 2,
        paddingBottom: 2,
        marginTop: 4,
    },
    [theme.breakpoints.down('md')]: {
        height: 300,
        paddingLeft: 15,
        paddingRight: 15,
    },
}));

// !############## MAIN FUNC ################
function Banner({ user, refetchProfile }) {
    const [crudType, setCRUDType] = useState('');
    const [openDialog, setOpenDialogy] = useState(false);
    const [dialogHead, setDialogHead] = useState(false);

    // -> Dialogy open
    const handleDialogyOpen = (crudType, dialogHead) => {
        setCRUDType(crudType);
        setDialogHead(dialogHead);
        setOpenDialogy(true);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <React.Fragment>
            {/* ##################### DIALOG ################# */}
            <CustomDialog
                head={dialogHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                {crudType === 'UpdateOrgNames' ? (
                    <UpdateOrgNames
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                ) : (
                    <UpdateBannerImage
                        user={user}
                        refetchProfile={refetchProfile}
                        closeDialogy={handleDialogClose}
                    />
                )}
            </CustomDialog>

            {/* ##################### CONTENT ################# */}
            <BannerCard
                item
                sm={12}
                xs={12}
                sx={{
                    backgroundImage: `${
                        user.org_banner
                            ? `url(${user.org_banner})`
                            : `url(${banner})`
                    }`,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 5,
                        background: `linear-gradient(360deg, rgba(23, 35, 60, .4), rgba(23, 35, 60, .4))`,
                    }}
                >
                    <Grid container justifyContent={'space-between'}>
                        <Grid item md={5} sm={12} xs={12}>
                            <Typography className="name">
                                {user.organization_name}
                            </Typography>
                            <Typography
                                className="short-name"
                                sx={{ mb: { md: 'auto', xs: 2 } }}
                            >
                                ({' '}
                                {user.org_short_name
                                    ? user.org_short_name
                                    : 'short name'}{' '}
                                )
                            </Typography>
                            <Button
                                className="edit-name-btn"
                                variant="outlined"
                                startIcon={<EditIcon />}
                                sx={{ mr: 1 }}
                                onClick={() => {
                                    let crudType = 'UpdateOrgNames';
                                    let dialogHead =
                                        "Update organization name's";
                                    handleDialogyOpen(crudType, dialogHead);
                                }}
                            >
                                Update name
                            </Button>
                            <Button
                                className="edit-name-btn"
                                variant="outlined"
                                startIcon={<CloudUpload />}
                                onClick={() => {
                                    let crudType = 'UpdateBannerImage';
                                    let dialogHead =
                                        'Update organization banner image';
                                    handleDialogyOpen(crudType, dialogHead);
                                }}
                            >
                                Update banner
                            </Button>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            md={4}
                            sm={12}
                            xs={12}
                            sx={{ position: 'relative' }}
                        >
                            <Box
                                sx={{
                                    minWidth: '100%',
                                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                                    borderRadius: 4,
                                    p: 1,
                                    mt: { md: 0, xs: 2 },
                                }}
                            >
                                <Grid container>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Organization type
                                            </Typography>
                                            <Chip
                                                size="small"
                                                color="info"
                                                label={
                                                    user.organization_type.name
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                    color: colors.primary,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Organization category
                                            </Typography>
                                            <Chip
                                                size="small"
                                                color="info"
                                                label={
                                                    user.organization_category
                                                        .name
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                    color: colors.primary,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                padding: 0.6,
                                                paddingLeft: 1.2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: colors.primary,
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                Verification status
                                            </Typography>
                                            <Chip
                                                icon={
                                                    user.is_verified ===
                                                    'verified' ? (
                                                        <Verified />
                                                    ) : (
                                                        <Pending />
                                                    )
                                                }
                                                size="small"
                                                color={
                                                    user.is_verified ===
                                                    'pending'
                                                        ? 'warning'
                                                        : user.is_verified ===
                                                          'requested'
                                                        ? 'info'
                                                        : user.is_verified ===
                                                          'on_progress'
                                                        ? 'info'
                                                        : user.is_verified ===
                                                          'denied'
                                                        ? 'error'
                                                        : 'success'
                                                }
                                                label={
                                                    user.is_verified ===
                                                    'pending'
                                                        ? 'Pending'
                                                        : user.is_verified ===
                                                          'requested'
                                                        ? 'Requested'
                                                        : user.is_verified ===
                                                          'on_progress'
                                                        ? 'On-progress'
                                                        : user.is_verified ===
                                                          'denied'
                                                        ? 'Denied'
                                                        : 'Verified'
                                                }
                                                sx={{
                                                    fontSize: 10,
                                                    fontWeight: 'bold',
                                                    width: 100,
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </BannerCard>
        </React.Fragment>
    );
}

export default Banner;
