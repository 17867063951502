import React from 'react';
import { Box, Button, List } from '@mui/material';
import { size, take } from 'lodash';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import JobApplicantWidget2 from '../widgets/JobApplicantWidget2';
import { Link } from 'react-router-dom';

const LatestApplicants = ({ applicants }) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    overflow: 'auto',
                    position: 'relative',
                }}
            >
                <List
                    sx={{
                        width: '100%',
                        p: 0,
                    }}
                >
                    {take(applicants, 10)?.map((applicant) => (
                        <JobApplicantWidget2
                            key={applicant.id}
                            applicant={applicant}
                        />
                    ))}
                </List>
                {size(applicants) > 5 && (
                    <Link to={'/applicants/jobs'}>
                        <Button
                            variant="outlined"
                            color="info"
                            fullWidth
                            size={'small'}
                            sx={{ mt: 2 }}
                            endIcon={<RemoveRedEyeOutlined />}
                        >
                            View all
                        </Button>
                    </Link>
                )}
            </Box>
        </React.Fragment>
    );
};

export default LatestApplicants;
