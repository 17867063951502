import React from "react";
import JobPostingFeeCard from "../cards/JobPostingFeeCard";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
// -> Payment method logo import
import mpesa from "../../assets/media/images/mpesa.png";
import tigopesa from "../../assets/media/images/tigopesa.png";
import halopesa from "../../assets/media/images/halopesa.png";
import azampesa from "../../assets/media/images/azampesa.png";
import airtel from "../../assets/media/images/airtel.png";
import { Payments } from "@mui/icons-material";
import { jobPostingSelector } from "../../states/features/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
    useCreateJobAndPayMutation,
    usePayJobPostingFeeMutation,
} from "../../api/job/jobAPI2";
import Loader from "react-js-loader";
import { resetJobPosting } from "../../states/features/job/JobPostingSlice";
import useRTK from "../../hooks/useRTK";
import { useNavigate, useParams } from "react-router-dom";
import { addJob, setJobDetails } from "../../states/features/job/jobsSlice";

const CheckOut = ({
    checkoutDetails,
    handleDialogClose,
    handleDialogClose2,
}) => {
    // ############### CONST #######################
    const RTK = useRTK();
    const navigate = useNavigate();
    const { job_id } = useParams();
    const dispatch = useDispatch();

    // ############ Redux state #################
    // -> Posting job state
    const {
        jobPostingFee,
        title,
        description,
        location,
        region_id,
        district_id,
        salary,
        salary_scale,
        max_applicants,
        positions,
        open_date,
        close_date,
        isFor,
        job_category_id,
        org_category_id,
        other_category,
        job_type,
        jobResponsibilities,
        jobRequirements,
        education_level_id,
        experience_level_id,
        qualificationAwards,
        educationLevels,
        experienceLevels,
        paymentMethod,
        paymentNumber,
        saveAndPay,
    } = useSelector(jobPostingSelector);

    // #################### RTK #####################
    const [
        createJobAndPay,
        { isLoading, isSuccess, isError, error, data: createdJob },
    ] = useCreateJobAndPayMutation();
    const [
        payJobPostingFee,
        {
            isLoading: payIsLoading,
            isSuccess: payIsSuccess,
            isError: payIsError,
            error: payError,
            data: payData,
        },
    ] = usePayJobPostingFeeMutation();

    // => RTK action
    const rtkActions = (data) => {
        dispatch(addJob(data));
        setTimeout(() => {
            navigate(`/jobs/${data.id}`);
        }, 0);
        dispatch(resetJobPosting());
    };
    const rtkActions2 = (data) => {
        handleDialogClose();
        handleDialogClose2();
        dispatch(setJobDetails(data));
    };

    // => RTK Response
    RTK.useRTKResponse(isSuccess, isError, error, createdJob, rtkActions);
    RTK.useRTKResponse(
        payIsSuccess,
        payIsError,
        payError,
        payData,
        rtkActions2
    );

    // ############### FUNC ##################
    // => Save and pay
    const handleJobSaveAndPayment = () => {
        let payload = {
            title,
            description,
            location,
            region_id,
            district_id,
            salary,
            salary_scale,
            max_applicants,
            positions,
            open_date,
            close_date,
            isFor,
            job_category_id,
            org_category_id,
            other_category,
            job_type,
            job_responsibilities: jobResponsibilities,
            job_requirements: jobRequirements,
            education_level_id,
            experience_level_id,
            qualificationAwards,
            educationLevels,
            experienceLevels,
            paymentMethod,
            paymentNumber,
            // => Payment values
            amount: jobPostingFee,
            phone_number: checkoutDetails.phone_number,
            provider: checkoutDetails.selectedProvider,
            send_payment: saveAndPay,
        };
        createJobAndPay(payload);
    };

    // => Pay job posting fee
    const handlePayJobPostingFee = () => {
        let payload = {
            job_id,
            amount: jobPostingFee,
            provider: checkoutDetails.selectedProvider,
            phone_number: paymentNumber,
        };
        payJobPostingFee(payload);
    };

    return (
        <>
            <Box sx={{}}>
                <Grid container justifyContent={"center"} spacing={4}>
                    <Grid item sm={10}>
                        <Box sx={{ height: 120 }}>
                            <JobPostingFeeCard
                                postingFee={checkoutDetails.jobPostingFee}
                                title={"Posting fee amount"}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={10}>
                        <Box
                            sx={{
                                height: 50,
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                >
                                    Payment method
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                        color: colors.info,
                                    }}
                                >
                                    Mobile payment
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: 1,
                                my: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                >
                                    Mobile type
                                </Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <img
                                        src={`${
                                            checkoutDetails.selectedMethod ===
                                            "Mpesa"
                                                ? mpesa
                                                : checkoutDetails.selectedMethod ===
                                                  "tigo"
                                                ? tigopesa
                                                : checkoutDetails.selectedMethod ===
                                                  "airtel"
                                                ? airtel
                                                : checkoutDetails.selectedMethod ===
                                                  "halotel"
                                                ? halopesa
                                                : checkoutDetails.selectedMethod ===
                                                  "azam"
                                                ? azampesa
                                                : ""
                                        }?w=164&h=164&fit=crop&auto=format`}
                                        srcSet={`${
                                            checkoutDetails.selectedMethod ===
                                            "Mpesa"
                                                ? mpesa
                                                : checkoutDetails.selectedMethod ===
                                                  "tigo"
                                                ? tigopesa
                                                : checkoutDetails.selectedMethod ===
                                                  "airtel"
                                                ? airtel
                                                : checkoutDetails.selectedMethod ===
                                                  "halotel"
                                                ? halopesa
                                                : checkoutDetails.selectedMethod ===
                                                  "azam"
                                                ? azampesa
                                                : ""
                                        }?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        alt={checkoutDetails.selectedMethod}
                                        loading="lazy"
                                        style={{
                                            objectFit: "contain",
                                            width: 120,
                                            borderRadius: 10,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: 50,
                                border: `1px solid ${colors.bgColor5}`,
                                p: 2,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                    }}
                                >
                                    Phone number
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        opacity: 0.8,
                                        color: colors.info,
                                    }}
                                >
                                    {checkoutDetails.phone_number}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={10}>
                        <Box
                            sx={{
                                position: "relative",
                                textAlign: "center",
                                mt: 0,
                            }}
                        >
                            <Button
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{
                                    py: 1.5,
                                    fontWeight: "bold",
                                    fontSize: 14,
                                    textTransform: "uppercase !important",
                                }}
                                startIcon={<Payments />}
                                onClick={() => {
                                    saveAndPay
                                        ? handleJobSaveAndPayment()
                                        : handlePayJobPostingFee();
                                }}
                                disabled={
                                    isLoading || payIsLoading ? true : false
                                }
                            >
                                Make payment
                                {isLoading && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <Loader
                                            type="bubble-loop"
                                            bgColor={colors.info}
                                            color={colors.info}
                                            size={50}
                                        />
                                    </Box>
                                )}
                                {payIsLoading && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <Loader
                                            type="bubble-loop"
                                            bgColor={colors.info}
                                            color={colors.info}
                                            size={50}
                                        />
                                    </Box>
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default CheckOut;
