import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ArrowRightAltOutlined, StopCircle } from "@mui/icons-material";
import { useCloseJobMutation } from "../../../api/job/jobAPI2";
import { setJobDetails } from "../../../states/features/job/jobsSlice";
import useRTK from "../../../hooks/useRTK";
import { useDispatch } from "react-redux";
import CustomSubmitButton from "../../../components/Forms/CustomSubmitButton";

// * ################### VALIDATION SCHEMA ####################
const CloseJob = ({ job: jobDetails, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        closeAction,
        {
            isLoading: closeLoading,
            isSuccess: closeSuccess,
            isError: closeIsError,
            error: closeError,
            data: closeData,
        },
    ] = useCloseJobMutation();

    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setJobDetails(data));
    };
    RTK.useRTKResponse(
        closeSuccess,
        closeIsError,
        closeError,
        closeData,
        rtkActions
    );

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Box sx={{ mb: 2 }} className="warning-message">
                        <Typography>
                            Your about to close this job application. Therefor
                            applicants can not keep applying for this job.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <CustomSubmitButton
                        startIcon={<StopCircle />}
                        endIcon={<ArrowRightAltOutlined />}
                        btnColor="warning"
                        variant={"contained"}
                        loading={closeLoading}
                        onClick={() => {
                            closeAction(jobDetails.id);
                        }}
                        sx={{ py: 2, fontSize: 16 }}
                    >
                        Close job
                    </CustomSubmitButton>
                </Grid>
            </Grid>
        </>
    );
};

export default CloseJob;
