import * as React from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import CreatePracticalTrainingStep from '../../components/steppers/CreatePracticalTrainingStep';
import PTPostingSidebar from '../../components/sidebars/PTPostingSidebar';
import { useDispatch } from 'react-redux';
import { resetPTPostingDetails } from '../../states/features/practicalTraining/PTPostingSlice';
import usePT from '../../hooks/usePT';

const CreatePracticalTraining = () => {
    // ############ CONST ################
    const ptHook = usePT();
    const dispatch = useDispatch();

    // ############# RTK #################
    ptHook.useMetaDataDispatch();

    // ############# USEEFFECT ###########
    React.useEffect(() => {
        return () => {
            dispatch(resetPTPostingDetails());
        };
    }, [dispatch]);
    return (
        <>
            <PageLayout
                head={'Create practical training vacancy'}
                sidebar={<PTPostingSidebar />}
            >
                <CustomCard2>
                    <CreatePracticalTrainingStep />
                </CustomCard2>
            </PageLayout>
        </>
    );
};

export default CreatePracticalTraining;
