import { useSelector } from "react-redux";
import {
    PTSelector,
    profileSelector,
} from "../../../states/features/selectors";
import { filter, isEmpty } from "lodash";
import moment from "moment";

export const useTrainingReportFilter = () => {
    const { profile: user } = useSelector(profileSelector);
    const {
        ORGPracticalTrainings,
        specialPracticalTrainings,
        otherPracticalTrainings,
    } = useSelector(PTSelector);
    const allPracticalTrainings = () => {
        let trainings =
            user.organization_type?.name === "Institution"
                ? [
                      ...ORGPracticalTrainings,
                      ...specialPracticalTrainings,
                      ...otherPracticalTrainings,
                  ]
                : [...ORGPracticalTrainings];
        return trainings;
    };

    const filterTraining = (payload) => {
        let filteredTrainings = allPracticalTrainings();
        let training_status = payload?.training_status;
        let dateFrom = payload?.from;
        let dateTo = payload?.to;

        // => Filter by date
        var startDate = moment(dateFrom).format("YYYY-MM-DD");
        var endDate = moment(dateTo).format("YYYY-MM-DD");
        if (!isEmpty(dateFrom) || !isEmpty(dateTo)) {
            filteredTrainings = filter(
                filteredTrainings,
                ({ created_at }) =>
                    moment(created_at).format("YYYY-MM-DD") >= startDate &&
                    moment(created_at).format("YYYY-MM-DD") <= endDate
            );
        }

        // => Filter by status
        if (!isEmpty(training_status)) {
            filteredTrainings = filter(
                filteredTrainings,
                ({ status }) => status === training_status
            );
        }

        // => Return result
        if (
            !isEmpty(dateFrom) ||
            !isEmpty(dateTo) ||
            !isEmpty(training_status)
        ) {
            return filteredTrainings;
        } else {
            return allPracticalTrainings();
        }
    };

    return {
        filterTraining,
    };
};
