import { Add, Leaderboard } from '@mui/icons-material';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../cards/CustomCard2';
import OrgWithFieldVacancyTable from '../Tables/OrgWithFieldVacancyTable';
import StatsInfoBox from '../infoBoxes/StatsInfoBox';
import { useSelector } from 'react-redux';
import { PTSelector } from '../../states/features/selectors';
import { filter, size } from 'lodash';

const CreateJobBtn = styled(Box)({
    'background': colors.gray,
    'borderRadius': 15,
    'overflow': 'hidden',
    'boxShadow': `1px 2px 5px 10px  ${colors.bgColor3}`,
    '.btn': {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        fontSize: 16,
        display: 'flex',
        flexDirection: 'column',
    },
});

const PracticalTrainingSidebarCompany = () => {
    const { ORGPracticalTrainings } = useSelector(PTSelector);

    // => Drafted
    const draftedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'draft',
    );
    // => Published
    const publishedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'published',
    );
    // => Closed
    const closedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'closed',
    );
    // => Suspended
    const suspendedPTs = filter(
        ORGPracticalTrainings,
        ({ status }) => status === 'suspended',
    );
    const ptStats = [
        {
            id: 1,
            title: 'Drafted',
            stats: `${size(draftedPTs)} Training`,
            borderColor: colors.danger,
        },
        {
            id: 2,
            title: 'Published',
            stats: `${size(publishedPTs)} Training`,
            borderColor: colors.success,
        },
        {
            id: 3,
            title: 'Closed',
            stats: `${size(closedPTs)} Training`,
            borderColor: colors.warning,
        },
        {
            id: 4,
            title: 'Suspended',
            stats: `${size(suspendedPTs)} Training`,
            borderColor: 'red',
        },
    ];
    return (
        <>
            <Grid container rowSpacing={4.5}>
                <Grid item sm={12}>
                    <CreateJobBtn>
                        <Link to={'/practical-trainings/create'}>
                            <Button
                                className="btn"
                                variant="contained"
                                fullWidth
                                color="secondary"
                                sx={{
                                    'border': `1px solid ${colors.bgColor3}`,
                                    ':hover': {
                                        bgcolor: colors.secondary,
                                        opacity: 0.9,
                                        border: `1px solid ${colors.bgColor5}`,
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        opacity: 0.9,
                                    }}
                                >
                                    <Add sx={{ mr: 1 }} />
                                    Create
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 'bold', opacity: 0.9 }}
                                >
                                    practical training vacancy
                                </Typography>
                            </Button>
                        </Link>
                    </CreateJobBtn>
                </Grid>
                <Grid item sm={12}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                opacity: 0.8,
                            }}
                        >
                            <Leaderboard sx={{ mr: 1 }} color="info" />
                            <Typography
                                sx={{ fontSize: 15, fontWeight: 'bold' }}
                            >
                                Overviews
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                            {ptStats.map((stats) => (
                                <Grid item sm={6} key={stats.id}>
                                    <StatsInfoBox stats={stats} />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default PracticalTrainingSidebarCompany;
