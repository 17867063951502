import getEnv from '../../helpers/getEnv';

// => Local base url
const adminLocalBaseUrl = getEnv().REACT_APP_ADMIN_LOCAL_URL;
const orgLocalBaseUrl = getEnv().REACT_APP_ORG_LOCAL_URL;

// => Dev base url
const adminDevBaseUrl = getEnv().REACT_APP_ADMIN_DEV_URL;
const orgDevBaseUrl = getEnv().REACT_APP_ORG_DEV_URL;

// => Pro base url
const adminProBaseUrl = getEnv().REACT_APP_ADMIN_PRODUCTION_URL;
const orgProBaseUrl = getEnv().REACT_APP_ORG_PRODUCTION_URL;

// => Get Admin baseURL
const getAdminBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === 'dev') {
        return adminDevBaseUrl;
    }
    if (env === 'pro') {
        return adminProBaseUrl;
    }
    return adminLocalBaseUrl;
};
// => Get Org baseURL
const getOrgBaseURL = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === 'dev') {
        return orgDevBaseUrl;
    }
    if (env === 'pro') {
        return orgProBaseUrl;
    }
    return orgLocalBaseUrl;
};

export const adminBaseURL = getAdminBaseURL();
export const orgBaseURL = getOrgBaseURL();
