import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { orgBaseURL } from '../baseUrl/baseURL';
import { headers } from '../baseUrl/setHeaders';
import { trainingSeasonEndpoints } from './trainingSeasonEndpoints';

export const trainingSeasonAPI = createApi({
    reducerPath: 'trainingSeasonAPI',
    baseQuery: fetchBaseQuery({ baseUrl: orgBaseURL, prepareHeaders: headers }),
    tagTypes: ['trainingSeasons'],
    endpoints: (builder) => ({
        // => Get all
        getTrainingSeasons: builder.query({
            query: () => `${trainingSeasonEndpoints.CREATE_TRAINING_SEASON}`,
            providesTags: ['trainingSeasons'],
        }),
        // => Get single
        getSingleTrainingSeason: builder.query({
            query: (payload) =>
                `${trainingSeasonEndpoints.GET_SINGLE_TRAINING_SEASON}/${payload}`,
        }),
        // => Add
        addTrainingSeason: builder.mutation({
            query: (payload) => ({
                url: `${trainingSeasonEndpoints.CREATE_TRAINING_SEASON}`,
                method: `POST`,
                body: payload,
            }),
        }),
        // => Open
        openTrainingSeason: builder.mutation({
            query: (payload) => ({
                url: `${trainingSeasonEndpoints.OPEN_TRAINING_SEASON}/${payload.season_id}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    trainingSeasonAPI.util.invalidateTags(['trainingSeasons']),
                );
            },
        }),
        // => Close
        closeTrainingSeason: builder.mutation({
            query: (payload) => ({
                url: `${trainingSeasonEndpoints.CLOSE_TRAINING_SEASON}/${payload.season_id}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    trainingSeasonAPI.util.invalidateTags(['trainingSeasons']),
                );
            },
        }),
        // => Update
        updateTrainingSeason: builder.mutation({
            query: (payload) => ({
                url: `${trainingSeasonEndpoints.UPDATE_TRAINING_SEASON}`,
                method: `PUT`,
                body: payload,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    trainingSeasonAPI.util.invalidateTags(['trainingSeasons']),
                );
            },
        }),
        // => Delete
        deleteTrainingSeason: builder.mutation({
            query: () => ({
                url: `${trainingSeasonEndpoints.DELETE_TRAINING_SEASON}`,
                method: `DELETE`,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    trainingSeasonAPI.util.invalidateTags(['trainingSeasons']),
                );
            },
        }),
    }),
});

export const {
    useGetTrainingSeasonsQuery,
    useGetSingleTrainingSeasonQuery,
    useAddTrainingSeasonMutation,
    useOpenTrainingSeasonMutation,
    useCloseTrainingSeasonMutation,
    useUpdateTrainingSeasonMutation,
    useDeleteTrainingSeasonMutation,

    // => Lazy
    useLazyGetTrainingSeasonsQuery
} = trainingSeasonAPI;
