import React from 'react';
import {
    DeleteForeverOutlined,
    VerifiedUserOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import {
    useApproveApplicationMutation,
    useDeclineApplicationMutation,
} from '../../../api/practicalTraining/practicalTrainingApplicantsAPI';
import { updateApplicantDetails } from '../../../states/features/practicalTraining/practicalTrainingsSlice';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';

const ApproveAndDecline = ({ applicant, handleDialogClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        approveAction,
        {
            isLoading: approveLoading,
            isSuccess: approveSuccess,
            isError: approveIsError,
            error: approveError,
            data: approveData,
        },
    ] = useApproveApplicationMutation();
    const [
        declineAction,
        {
            isLoading: declineLoading,
            isSuccess: declineSuccess,
            isError: declineIsError,
            error: declineError,
            data: declineData,
        },
    ] = useDeclineApplicationMutation();

    // ############# useEffect ##################
    // => approve res
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(updateApplicantDetails(data));
    };
    RTK.useRTKResponse(
        approveSuccess,
        approveIsError,
        approveError,
        approveData,
        rtkActions,
    );
    // => decline res
    RTK.useRTKResponse(
        declineSuccess,
        declineIsError,
        declineError,
        declineData,
        rtkActions,
    );
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    mt: 2,
                    justifyContent: 'space-between',
                }}
            >
                <CustomSubmitButton
                    startIcon={<DeleteForeverOutlined />}
                    btnColor={'error'}
                    loading={declineLoading}
                    variant={'outlined'}
                    onClick={() => {
                        let payload = {
                            student_id: applicant?.appliedTraining?.student_id,
                            training_id:
                                applicant?.appliedTraining?.training_id,
                        };
                        declineAction(payload);
                    }}
                    sx={{
                        width: 320,
                        py: 1.5,
                        fontWeight: 'bold',
                    }}
                >
                    Deny application
                </CustomSubmitButton>
                <CustomSubmitButton
                    startIcon={<VerifiedUserOutlined />}
                    btnColor={'success'}
                    loading={approveLoading}
                    variant={'outlined'}
                    onClick={() => {
                        let payload = {
                            student_id: applicant?.appliedTraining?.student_id,
                            training_id:
                                applicant?.appliedTraining?.training_id,
                        };
                        approveAction(payload);
                    }}
                    sx={{
                        width: 320,
                        py: 1.5,
                        fontWeight: 'bold',
                    }}
                >
                    Approve application
                </CustomSubmitButton>
            </Box>
        </>
    );
};

export default ApproveAndDecline;
