import React from 'react';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { colors } from '../../assets/utils/constants';

const StudentWidget = ({ student }) => {
    return (
        <>
            <ListItem
                sx={{
                    'px': 2,
                    'position': 'relative',
                    'borderRadius': 2,
                    'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    'transition': `.3s ease-in-out all`,
                    'boxShadow': `0px 0px 5px ${colors.bgColor4}`,
                    ':hover': {
                        'boxShadow': `0px 0px 5px ${colors.warning}`,
                        'transition': `.3s ease-in-out all`,
                        '& .dp': {
                            transform: `scale(1.05) rotate(360deg)`,
                            transition: `.3s ease-in-out all`,
                        },
                    },
                }}
            >
                <ListItemAvatar
                    sx={{
                        mr: 1.5,
                    }}
                >
                    <Avatar
                        alt="Remy Sharp"
                        src={student.profile_picture}
                        className="dp"
                        sx={{
                            height: 65,
                            width: 65,
                            transition: `.3s ease-in-out all`,
                            border: `2px dotted ${colors.info}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                        }}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primaryTypographyProps={{ whiteSpace: 'nowrap' }}
                    primary={
                        <Typography
                            noWrap
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            {`${student.first_name} ${student.last_name}`}
                        </Typography>
                    }
                    secondary={
                        <Box>
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: 14,
                                    opacity: 0.8,
                                    color: colors.warning,
                                }}
                                component="span"
                            >
                                {student.reg_id}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                        fontWeight: 'bold',
                                        color: colors.success,
                                    }}
                                    component="span"
                                >
                                    {student.start_year}
                                </Typography>
                                <Typography
                                    noWrap
                                    sx={{
                                        fontSize: 12,
                                        opacity: 0.8,
                                        fontWeight: 'bold',
                                        color: 'red',
                                    }}
                                    component="span"
                                >
                                    {student.finish_year}
                                </Typography>
                                <Link to={`/students/${student.id}`}>
                                    <Tooltip title={'Details'} arrow>
                                        <IconButton size="small" color="info">
                                            <RemoveRedEye
                                                sx={{ opacity: 0.8 }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Box>
                        </Box>
                    }
                />
            </ListItem>
        </>
    );
};

export default StudentWidget;
