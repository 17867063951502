import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import NoContent from '../../components/NoContent';
import { isEmpty, size } from 'lodash';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import PracticalTrainingSidebarCompany from '../../components/sidebars/PracticalTrainingSidebarCompany';
import CustomPagination from '../../components/paginations/CustomPagination';
import { paginator } from '../../helpers/paginationHelper';
import CountBadge from '../../components/CountBadge copy';

const SpecialOrganizationPT = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const { specialPracticalTrainings } = useSelector(PTSelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedActivePTs = paginator(
        specialPracticalTrainings,
        page,
        perPage,
    );

    return (
        <>
            <PageLayout
                head={'Special practical trainings'}
                sidebar={
                    user?.organization_type?.name === 'Institution' ? (
                        <PracticalTrainingSidebar />
                    ) : (
                        <PracticalTrainingSidebarCompany />
                    )
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={'Special practical trainings'}
                            height={'64.5vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedActivePTs}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(
                                                paginatedActivePTs.data,
                                            )}
                                            item={'Active PTs'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(paginatedActivePTs.data) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={
                                            'No active practical trainings'
                                        }
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedActivePTs.data.map((training) => (
                                        <Grid item sm={6} key={training.id}>
                                            <FieldWidgetOne
                                                training={training}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default SpecialOrganizationPT;
