import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/constants";
import { filter, size } from "lodash";
import { useSelector } from "react-redux";
import {
    PTSelector,
    jobApplicationsSelector,
} from "../../states/features/selectors";

const ApplicantsSidebar = () => {
    // ############ Redux state ##################
    const { allPracticalTrainingApplicants } = useSelector(PTSelector);
    const {
        companyJobApplications,
        individualJobApplications,
        groupJobApplications,
    } = useSelector(jobApplicationsSelector);
    const allJobApplications = [
        ...companyJobApplications,
        ...individualJobApplications,
        ...groupJobApplications,
    ];

    // ############## FUNC ###################
    // => PT applicants
    const getPTApplicantsByStatus = () => {
        let pendingApplicants = [];
        let approvedApplicants = [];
        let onTrainingApplicants = [];
        let completedApplicants = [];
        // => Pending
        pendingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) => appliedTraining.status === "pending"
        );
        // => Approved
        approvedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.status === "approved" &&
                appliedTraining.onTrainingStatus === "pending"
        );
        // => OnTraining
        onTrainingApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === "on-training"
        );
        // => Completed
        completedApplicants = filter(
            allPracticalTrainingApplicants,
            ({ appliedTraining }) =>
                appliedTraining.onTrainingStatus === "completed"
        );
        return {
            pendingApplicants,
            approvedApplicants,
            onTrainingApplicants,
            completedApplicants,
        };
    };
    // => // => Job Applicants
    const getJobApplicantsByStatus = () => {
        let pendingApplicants = [];
        let savedApplicants = [];
        let shortlistedApplicants = [];
        let acceptedApplicants = [];
        // => Pending Job applications
        pendingApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "pending"
        );
        // => Saved
        savedApplicants = filter(
            allJobApplications,
            ({ application }) => application.isSaved === 1
        );
        // => Shortlisted
        shortlistedApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "shortlisted"
        );
        // => Accepted
        acceptedApplicants = filter(
            allJobApplications,
            ({ application }) => application.status === "accepted"
        );
        return {
            pendingApplicants,
            savedApplicants,
            shortlistedApplicants,
            acceptedApplicants,
        };
    };
    // => Job applicant
    const JobApplicants = getJobApplicantsByStatus();

    return (
        <div>
            <>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        px: 1,
                        pb: 1,
                        mb: 3,
                        borderRadius: 2,
                        // boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                        border: `1px dotted ${colors.info}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            mb: 2,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                opacity: 0.8,
                                fontWeight: "bold",
                                color: colors.info,
                            }}
                        >
                            PT's applicants stats
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: 110,
                            width: 110,
                            borderRadius: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            border: `5px solid ${colors.bgColor4}`,
                            boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 30,
                                fontWeight: "bold",
                                opacity: 0.8,
                                color: colors.info,
                                mt: -2,
                            }}
                        >
                            {size(allPracticalTrainingApplicants)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                opacity: 0.8,
                                mt: -1,
                                textAlign: "center",
                            }}
                        >
                            Training
                            <br /> Applicant(s)
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(
                                        getPTApplicantsByStatus()
                                            .pendingApplicants
                                    )}
                                    <sup
                                        style={{
                                            color: colors.warning,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {" "}
                                        Pending
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(
                                        getPTApplicantsByStatus()
                                            .approvedApplicants
                                    )}{" "}
                                    <sup
                                        style={{
                                            color: colors.success,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Approved
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(
                                        getPTApplicantsByStatus()
                                            .onTrainingApplicants
                                    )}{" "}
                                    <sup
                                        style={{
                                            color: colors.info,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        On-training
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(
                                        getPTApplicantsByStatus()
                                            .completedApplicants
                                    )}{" "}
                                    <sup
                                        style={{
                                            color: colors.success,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Completed
                                    </sup>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
            {/* ############################ JOB ############################## */}
            <>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        px: 1,
                        pb: 1,
                        borderRadius: 2,
                        // boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                        border: `1px dotted ${colors.info}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Box
                        sx={{
                            p: 1,
                            mb: 2,
                            borderRadius: 2,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                opacity: 0.8,
                                fontWeight: "bold",
                                color: colors.info,
                            }}
                        >
                            Job applicants stats
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: 110,
                            width: 110,
                            borderRadius: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            border: `5px solid ${colors.bgColor4}`,
                            boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 30,
                                fontWeight: "bold",
                                opacity: 0.8,
                                color: colors.info,
                                mt: -2,
                            }}
                        >
                            {size(allJobApplications)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                opacity: 0.8,
                                mt: -1,
                                textAlign: "center",
                            }}
                        >
                            Job
                            <br /> Applicant(s)
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(JobApplicants.pendingApplicants)}
                                    <sup
                                        style={{
                                            color: colors.warning,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {" "}
                                        Pending
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(JobApplicants.savedApplicants)}{" "}
                                    <sup
                                        style={{
                                            color: colors.primary,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Saved
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(JobApplicants.shortlistedApplicants)}{" "}
                                    <sup
                                        style={{
                                            color: colors.info,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Shortlisted
                                    </sup>
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        color: colors.info,
                                        borderBottom: `3px solid ${colors.bgColor5}`,
                                    }}
                                >
                                    {size(JobApplicants.acceptedApplicants)}{" "}
                                    <sup
                                        style={{
                                            color: colors.success,
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Accepted
                                    </sup>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </>
        </div>
    );
};

export default ApplicantsSidebar;
