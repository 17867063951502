import {
    Box,
    Button,
    Chip,
    IconButton,
    Switch,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../assets/utils/constants';
import CustomDialog from '../Dialogs/CustomDialog';
import OpenCloseTrainingSeason from '../../pages/studentsManagement/trainingSeason/CRUD/OpenCloseTrainingSeason';

const CurrentTrainingSeasonWidget = ({ currAddedSeason }) => {
    // ############### Comp State ##################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionHead, setActionHead] = useState('');

    // ############### FUNC ##################
    // -> Dialogy closer
    const handleDialogOpen = (actionHead) => {
        setOpenDialogy(true);
        setActionHead(actionHead);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <>
            <CustomDialog
                head={actionHead}
                openDialog={openDialog}
                handleDialogClose={handleDialogClose}
            >
                <OpenCloseTrainingSeason
                    trainingSeason={currAddedSeason}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2.5,
                    py: 2.7,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                }}
            >
                <Box sx={{}}>
                    <Typography
                        sx={{
                            fontSize: 16,
                            color: colors.info,
                            fontWeight: 'bold',
                        }}
                    >
                        {currAddedSeason.title}
                    </Typography>
                    <Chip
                        label={`Training season ${currAddedSeason.status}`}
                        color={
                            currAddedSeason.status === 'opened'
                                ? 'success'
                                : currAddedSeason.status === 'closed'
                                ? 'error'
                                : 'warning'
                        }
                        sx={{ mt: 1 }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                    }}
                >
                    {/* <IconButton
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                        }}
                    >
                        <Switch size="small" />
                    </IconButton> */}
                    {currAddedSeason.status !== 'closed' ? (
                        <Button
                            size="small"
                            variant="contained"
                            color={
                                currAddedSeason.status === 'opened'
                                    ? 'warning'
                                    : currAddedSeason.status === 'inactive'
                                    ? 'success'
                                    : 'primary'
                            }
                            sx={{ mt: 0.5, width: 120 }}
                            onClick={() => {
                                let head =
                                    currAddedSeason.status === 'opened'
                                        ? 'Close training season'
                                        : 'Open traning season';
                                handleDialogOpen(head);
                            }}
                        >
                            {currAddedSeason.status === 'opened'
                                ? 'Close training season'
                                : currAddedSeason.status === 'closed'
                                ? 'default'
                                : currAddedSeason.status === 'inactive'
                                ? 'Open training season'
                                : ''}
                        </Button>
                    ) : (
                        <Button
                            disabled={true}
                            variant="outlined"
                            color="info"
                            size="small"
                            sx={{ width: 120 }}
                        >
                            Training Season closed
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default CurrentTrainingSeasonWidget;
