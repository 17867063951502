import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NoContent from "../../components/NoContent";
import { filter, isEmpty, size } from "lodash";
import JobsSidebar from "../../components/sidebars/JobsSidebar";
import { jobsSelector } from "../../states/features/selectors";
import PageLayout from "../layouts/PageLayout";
import CountBadge from "../../components/CountBadge copy";
import JobFilters from "../../components/filters/JobFilters";
import { paginator } from "../../helpers/paginationHelper";
import CustomPagination from "../../components/paginations/CustomPagination";
import JobWidget from "../../components/widgets/JobWidget";
import JobForYouWidget from "../../components/widgets/JobForYouWidget";
import CustomTabs from "../../components/tabs/CustomTabs";
import { WorkHistory } from "@mui/icons-material";
import JobPagination from "../../components/paginations/JobPagination";

const JobForYouByStatus = () => {
    // ############ CONST ###################
    const { status } = useParams();
    const pageHead = () => {
        if (status === "shortlisted") {
            return "Shortlisted jobs";
        } else if (status === "applied") {
            return "Applied jobs";
        } else if (status === "declined") {
            return "Declined jobs";
        } else if (status === "accepted") {
            return "Accepted jobs";
        } else if (status === "on-going") {
            return "On-going jobs";
        } else if (status === "completed") {
            return "Completed jobs";
        }
    };

    // ################## redux state #####################
    // -> job state
    const { appliedORGJobs, appliedEmployerJobs } = useSelector(jobsSelector);
    const appliedJobsForYou = [...appliedORGJobs, ...appliedEmployerJobs];

    const jobByStatusOrg = () => {
        let jobs = [];
        if (status === "applied") {
            jobs = filter(
                appliedORGJobs,
                ({ isApplied }) => isApplied === true
            );
        } else if (status === "shortlisted") {
            jobs = filter(
                appliedORGJobs,
                ({ application }) => application.status === "shortlisted"
            );
        } else if (status === "declined") {
            jobs = filter(
                appliedORGJobs,
                ({ application }) => application.status === "declined"
            );
        } else if (status === "on-going") {
            jobs = filter(
                appliedORGJobs,
                ({ is_on_going }) => is_on_going === 1
            );
        } else if (status === "completed") {
            jobs = filter(
                appliedORGJobs,
                ({ is_completed }) => is_completed === 1
            );
        } else if (status === "accepted") {
            jobs = filter(
                appliedORGJobs,
                ({ application }) => application.status === "accepted"
            );
        }
        return jobs;
    };
    const jobByStatusEmployer = () => {
        let jobs = [];
        if (status === "applied") {
            jobs = filter(
                appliedEmployerJobs,
                ({ isApplied }) => isApplied === true
            );
        } else if (status === "shortlisted") {
            jobs = filter(
                appliedEmployerJobs,
                ({ application }) => application.status === "shortlisted"
            );
        } else if (status === "declined") {
            jobs = filter(
                appliedEmployerJobs,
                ({ application }) => application.status === "declined"
            );
        } else if (status === "on-going") {
            jobs = filter(
                appliedEmployerJobs,
                ({ is_on_going }) => is_on_going === 1
            );
        } else if (status === "completed") {
            jobs = filter(
                appliedEmployerJobs,
                ({ is_completed }) => is_completed === 1
            );
        } else if (status === "accepted") {
            jobs = filter(
                appliedEmployerJobs,
                ({ application }) => application.status === "accepted"
            );
        }
        return jobs;
    };

    const tabs = [
        {
            id: 1,
            title: `${status} organization jobs`,
            icon: <WorkHistory />,
            chipColor: "success",
            total: size(jobByStatusOrg()),
            tabsContent: (
                <JobPagination
                    jobs={jobByStatusOrg()}
                    height={"60vh"}
                    type={"org"}
                />
            ),
        },
        {
            id: 2,
            title: `${status} employer jobs`,
            icon: <WorkHistory />,
            chipColor: "info",
            total: size(jobByStatusEmployer()),
            tabsContent: (
                <JobPagination
                    jobs={jobByStatusEmployer()}
                    height={"60vh"}
                    type={"employer"}
                />
            ),
        },
    ];
    return (
        <React.Fragment>
            <PageLayout head={pageHead()} sidebar={<JobsSidebar />}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomTabs
                            tabs={tabs}
                            noHeight={"70vh"}
                            padding={"0 !important"}
                            borderBottom={false}
                        />
                    </Grid>
                </Grid>
            </PageLayout>
        </React.Fragment>
    );
};

export default JobForYouByStatus;
