export const regionList = [
    {
        id: 1,
        label: 'Arusha',
        name: 'Arusha',
    },
    {
        id: 2,
        label: 'Dar es Salaam ',
        name: 'Dar es Salaam ',
    },
    {
        id: 3,
        label: 'Geita',
        name: 'Geita',
    },
    {
        id: 4,
        label: 'Iringa',
        name: 'Iringa',
    },
    {
        id: 5,
        label: 'Kagera',
        name: 'Kagera',
    },
    {
        id: 6,
        label: 'Katavi',
        name: 'Katavi',
    },
    {
        id: 7,
        label: 'Kigoma',
        name: 'Kigoma',
    },
    {
        id: 8,
        label: 'Kilimanjaro',
        name: 'Kilimanjaro',
    },
    {
        id: 9,
        label: 'Lindi',
        name: 'Lindi',
    },
    {
        id: 10,
        label: 'Manyara',
        name: 'Manyara',
    },
    {
        id: 11,
        label: 'Mara',
        name: 'Mara',
    },
    {
        id: 12,
        label: 'Mbeya',
        name: 'Mbeya',
    },
    {
        id: 13,
        label: 'Mjini Magharibi',
        name: 'Mjini Magharibi',
    },
    {
        id: 14,
        label: 'Morogoro',
        name: 'Morogoro',
    },
    {
        id: 15,
        label: 'Mtwara',
        name: 'Mtwara',
    },
    {
        id: 16,
        label: 'Mwanza',
        name: 'Mwanza',
    },
    {
        id: 17,
        label: 'Njombe',
        name: 'Njombe',
    },
    {
        id: 18,
        label: 'Pemba',
        name: 'Pemba',
    },
    {
        id: 19,
        label: 'Pwani',
        name: 'Pwani',
    },
    {
        id: 20,
        label: 'Rukwa',
        name: 'Rukwa',
    },
    {
        id: 21,
        label: 'Ruvuma',
        name: 'Ruvuma',
    },
    {
        id: 22,
        label: 'Shinyanga',
        name: 'Shinyanga',
    },
    {
        id: 23,
        label: 'Simiyu',
        name: 'Simiyu',
    },
    {
        id: 24,
        label: 'Singida',
        name: 'Singida',
    },
    {
        id: 25,
        label: 'Songwe',
        name: 'Songwe',
    },
    {
        id: 26,
        label: 'Tabora',
        name: 'Tabora',
    },
    {
        id: 27,
        label: 'Tanga',
        name: 'Tanga',
    },
    {
        id: 28,
        label: 'Unguja',
        name: 'Unguja',
    },
];
