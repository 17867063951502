import { useDispatch } from 'react-redux';
import {
    useGetActiveDepartmentsQuery,
    useGetActiveSectionsQuery,
    useGetActiveSectorsQuery,
    useGetPublicORGQuery,
} from '../api/settings/settingAPI';
import {
    setActiveDepartments,
    setActiveSections,
    setActiveSectors,
    setPublicORG,
} from '../states/features/settings/settingsSlice';
import useRTK from './useRTK';

const usePT = () => {
    // => PT Posting Dependant data
    const useMetaDataDispatch = () => {
        // ############ CONST ################
        const RTK = useRTK();
        // -> redux state
        const dispatch = useDispatch();

        // ############# RTK #################
        const {
            isSuccess: PublicORGSuccess,
            data: PublicORGData,
        } = useGetPublicORGQuery();
        const {
            isSuccess: activeSectorSuccess,
            data: activeSectorData,
        } = useGetActiveSectorsQuery();
        const {
            isSuccess: activeDepartmentSuccess,
            data: activeDepartmentData,
        } = useGetActiveDepartmentsQuery();
        const {
            isSuccess: activeSectionSuccess,
            data: activeSectionsData,
        } = useGetActiveSectionsQuery();

        // ############# Dispatch settings ##########
        // => Public org
        const rtkPublicORGAction = (data) => {
            dispatch(setPublicORG(data));
        };
        RTK.useRTKFetch(PublicORGSuccess, PublicORGData, rtkPublicORGAction);
        // => Active sector
        const rtkActiveSectorsAction = (data) => {
            dispatch(setActiveSectors(data));
        };
        RTK.useRTKFetch(
            activeSectorSuccess,
            activeSectorData,
            rtkActiveSectorsAction,
        );
        // => Active department
        const rtkActiveDepartmentsAction = (data) => {
            dispatch(setActiveDepartments(data));
        };
        RTK.useRTKFetch(
            activeDepartmentSuccess,
            activeDepartmentData,
            rtkActiveDepartmentsAction,
        );
        // => Active sections
        const rtkActiveSectionsAction = (data) => {
            dispatch(setActiveSections(data));
        };
        RTK.useRTKFetch(
            activeSectionSuccess,
            activeSectionsData,
            rtkActiveSectionsAction,
        );
    };

    return {
        useMetaDataDispatch,
    };
};

export default usePT;
