import React from 'react';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import { useSelector } from 'react-redux';
import { PTSelector, profileSelector } from '../../states/features/selectors';
import FieldWidgetOne from '../../components/widgets/FieldWidgetOne';
import NoContent from '../../components/NoContent';
import { filter, isEmpty, size } from 'lodash';
import PracticalTrainingSidebar from '../../components/sidebars/PracticalTrainingSidebar';
import PracticalTrainingSidebarCompany from '../../components/sidebars/PracticalTrainingSidebarCompany';
import CustomPagination from '../../components/paginations/CustomPagination';
import { paginator } from '../../helpers/paginationHelper';
import CountBadge from '../../components/CountBadge copy';

const ForStudents = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############### Redux state #################
    const { profile: user } = useSelector(profileSelector);
    const {
        ORGPracticalTrainings,
        specialPracticalTrainings,
        otherPracticalTrainings,
    } = useSelector(PTSelector);

    // ############## CONST ####################
    // => All PTs
    const allPracticalTrainings = () => {
        let trainings =
            user.organization_type.name === 'Institution'
                ? [
                      ...ORGPracticalTrainings,
                      ...specialPracticalTrainings,
                      ...otherPracticalTrainings,
                  ]
                : [...ORGPracticalTrainings];
        return trainings;
    };
    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Get student PTs
    const studentsPracticalTrainings = () => {
        let forStudents = filter(
            allPracticalTrainings(),
            ({ studentApproval }) => studentApproval === 1,
        );
        return forStudents;
    };

    // => Pagination data
    const paginatedActivePTs = paginator(
        studentsPracticalTrainings(),
        page,
        perPage,
    );

    // const infoBoxData = [
    //     {
    //         id: 1,
    //         title1: 'Applied',
    //         title2: 'Practical training',
    //         total: 2,
    //         colorSign: colors.secondary,
    //         url: 'organization',
    //     },
    //     {
    //         id: 2,
    //         title1: 'On-going',
    //         title2: 'Practical training',
    //         total: 45,
    //         colorSign: colors.secondary,
    //         url: 'other-organizations',
    //     },
    //     {
    //         id: 3,
    //         title1: 'Completed',
    //         title2: 'Practical training',
    //         total: 45,
    //         colorSign: colors.secondary,
    //         url: 'other-organizations',
    //     },
    // ];

    return (
        <>
            <PageLayout
                head={'Practical trainings for students'}
                sidebar={
                    user?.organization_type?.name === 'Institution' ? (
                        <PracticalTrainingSidebar />
                    ) : (
                        <PracticalTrainingSidebarCompany />
                    )
                }
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Grid container spacing={2}>
                            {/* {infoBoxData.map((data) => (
                                <Grid item sm={4} key={data.id}>
                                    <InfoboxWidget2
                                        data={data}
                                        colorSign={data.colorSign}
                                    />
                                </Grid>
                            ))} */}
                            <Grid item sm={12}>
                                <CustomPagination
                                    head={'Approved practical trainings'}
                                    height={'64.5vh'}
                                    borderBottom
                                    handlePageChange={handlePageChange}
                                    handlePerPageChage={handlePerPageChage}
                                    data={paginatedActivePTs}
                                    action={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ ml: 1 }}>
                                                <CountBadge
                                                    total={size(
                                                        paginatedActivePTs.data,
                                                    )}
                                                    item={'Active PTs'}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                >
                                    {isEmpty(paginatedActivePTs.data) ? (
                                        <>
                                            <NoContent
                                                height={300}
                                                message={
                                                    'No active practical trainings'
                                                }
                                            />
                                        </>
                                    ) : (
                                        <Grid container spacing={3}>
                                            {paginatedActivePTs.data.map(
                                                (training) => (
                                                    <Grid
                                                        item
                                                        sm={6}
                                                        key={training.id}
                                                    >
                                                        <FieldWidgetOne
                                                            training={training}
                                                        />
                                                    </Grid>
                                                ),
                                            )}
                                        </Grid>
                                    )}
                                </CustomPagination>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default ForStudents;
