import React from 'react';
import { setJobDetails } from '../../../states/features/job/jobsSlice';
import { useDeleteRequirementMutation } from '../../../api/job/jobAPI2';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { Box, Button } from '@mui/material';
import Loader from 'react-js-loader';
import { colors } from '../../../assets/utils/constants';
import { DeleteForever } from '@mui/icons-material';

const DeleteRequirement = ({ requirement }) => {
    // ########### CONST #####################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ################### RTK ###################
    const rtkActions = (data) => {
        dispatch(setJobDetails(data));
    };
    // => Delete
    const [
        deleteRequirement,
        {
            isLoading: requirementDeleteLoading,
            isSuccess: requirementDeleteSuccess,
            isError: requirementDeleteIsError,
            error: requirementDeleteError,
            data: requirementDeleteData,
        },
    ] = useDeleteRequirementMutation();
    RTK.useRTKResponse(
        requirementDeleteSuccess,
        requirementDeleteIsError,
        requirementDeleteError,
        requirementDeleteData,
        rtkActions,
    );

    return (
        <>
            <Button
                disabled={requirementDeleteLoading ? true : false}
                variant="outlined"
                sx={{
                    p: 0.4,
                    m: 0.4,
                }}
                onClick={() => {
                    deleteRequirement(requirement.id);
                }}
            >
                <DeleteForever
                    color="error"
                    sx={{
                        fontSize: 14,
                    }}
                />
                {requirementDeleteLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                        }}
                    >
                        <Loader
                            type="bubble-loop"
                            bgColor={colors.info}
                            color={colors.info}
                            size={40}
                        />
                    </Box>
                )}
            </Button>
        </>
    );
};

export default DeleteRequirement;
