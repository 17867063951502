import React from 'react';
import { PersonAdd } from '@mui/icons-material';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { colors } from '../../../assets/utils/constants';
import SmallCustomTextField from '../../../components/Forms/SmallCustomTextField';
import { instituteProgramsSelector } from '../../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import CustomMonoIdAutocomplete from '../../../components/Forms/CustomMonoIdAutocomplete';
import { useCreateStudentMutation } from '../../../api/students/studentsAPI';
import { addStudent } from '../../../states/features/students/studentsSlice';
import useRTK from '../../../hooks/useRTK';
import moment from 'moment';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';

// * -> Validation
const addStudentValidationSchema = Yup.object({
    first_name: Yup.string().required('First name is required field'),
    last_name: Yup.string().required('Last name is required field'),
    reg_id: Yup.string().required('Registration No. is required field'),
    section_id: Yup.string().required('Occupation is required field'),
    start_year: Yup.number().required('Start year field is required'),
    finish_year: Yup.number()
        .required('Finish year field is required')
        .min(
            Yup.ref('start_year'),
            `finish year must be later than start year`,
        ),
});

const AddStudent = ({ handleDialogClose }) => {
    // ############## CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############### Redux state ####################
    const { sections } = useSelector(instituteProgramsSelector);

    /**########## FUNC ############### */
    // => Restructure section obj
    const restructureArray = (arr) => {
        let restructured = [];
        arr?.forEach((obj) => {
            let newObj = {
                id: obj.id,
                title: obj.section.name,
                label: obj.section.name,
            };
            restructured.push(newObj);
        });
        return restructured;
    };
    const availableSections = restructureArray(sections);

    // ############# RTK ##################
    const [
        createStudent,
        { isLoading, isSuccess, isError, error, data: studentData },
    ] = useCreateStudentMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(addStudent(data));
    };
    RTK.useRTKResponse(isSuccess, isError, error, studentData, rtkActions);

    const getYears = () => {
        const start = new Date().getFullYear() - 6;
        const end = new Date().getFullYear() + 6;
        let yearCount = [];
        for (let i = start; i < end; i++) {
            yearCount.push((i));
        }
        return yearCount;
    };

    return (
        <>
            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    reg_id: '',
                    section_id: '',
                    start_year: '',
                    finish_year: '',
                }}
                validationSchema={addStudentValidationSchema}
                onSubmit={(payload) => {
                    createStudent(payload);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid container justifyContent={'center'}>
                            <Grid item sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item sm={6} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`First name`}
                                            type="text"
                                            name="first_name"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`Last name`}
                                            type="text"
                                            name="last_name"
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <SmallCustomTextField
                                            className="form-input"
                                            label={`Student reg. number`}
                                            type="text"
                                            name="reg_id"
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <CustomSelectInput
                                            className="form-input"
                                            label={`Start`}
                                            name="start_year"
                                            color={colors.primary}
                                            formik={formik}
                                        >
                                            {getYears()?.map((year) => (
                                                <MenuItem
                                                    value={year}
                                                    key={year}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </CustomSelectInput>
                                    </Grid>
                                    <Grid item sm={3} xs={6}>
                                        <CustomSelectInput
                                            className="form-input"
                                            label={`Graduate`}
                                            name="finish_year"
                                            color={colors.primary}
                                            formik={formik}
                                        >
                                            {getYears()?.map((year) => (
                                                <MenuItem
                                                    value={year}
                                                    key={year}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </CustomSelectInput>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <CustomMonoIdAutocomplete
                                            label="Program | Occupation | Section"
                                            name={'section_id'}
                                            formik={formik}
                                            options={availableSections}
                                            colors={colors.primary}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <CustomSubmitButton
                                                loading={isLoading}
                                                startIcon={<PersonAdd />}
                                                onClick={formik.handleSubmit}
                                            >
                                                Add student
                                            </CustomSubmitButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddStudent;
